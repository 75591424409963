import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Button } from "../common/button-component"
import { JobDescription } from "../job/job-description-component"
import { Collapse } from "reactstrap"
import { getArrowClassName, linebreakToBr } from "../../lib/helpers"
import { ExternalJobCard } from "../external-job/external-job-card"
import { JobControlsLayout } from "../layouts/job-controls-layout"
import { defineMessages, FormattedMessage } from "react-intl"
import { hideExternalJob } from "../../actions/external-job-actions"
import { useDispatch, useSelector } from "react-redux"
import { isHidingSelector } from "../../selectors/external-job-selector"
import { config } from "../../config"

const messages = defineMessages({
  openButton: { id: "app.externalJob.open" },
  openText: { id: "app.externalJob.open.text" },
  hideButton: { id: "app.externalJob.hide" },
  hideText: { id: "app.externalJob.hide.text" },
})

export const ExternalJobDashboardCard = ({ job, isOpen, toggle }) => {
  const dispatch = useDispatch()
  const isHiding = useSelector(state =>
    isHidingSelector(state, { id: job.idStr }),
  )
  return (
    <div>
      <ExternalJobCard
        {...job}
        controls={
          <Button
            type="button"
            color="light"
            size="lg"
            pill
            className="btn-circle p-2"
            onClick={toggle}
          >
            <i className={getArrowClassName(isOpen)} />
          </Button>
        }
      />
      <Collapse isOpen={isOpen}>
        <JobControlsLayout
          left={
            <Fragment>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.moberries.api.url}/external_job/${job.idStr}?referred_from=${window.location.protocol}//${window.location.host}`}
              >
                <Button pill color="primary">
                  {" "}
                  <i className="fas fa-external-link-alt pr-1" />
                  <FormattedMessage {...messages.openButton} />
                </Button>
              </a>
              <span className="d-none d-md-inline px-2">
                <FormattedMessage {...messages.openText} />
              </span>
            </Fragment>
          }
          right={
            <Fragment>
              <span className="d-none d-md-inline px-2">
                <FormattedMessage {...messages.hideText} />
              </span>
              <Button
                onClick={() => dispatch(hideExternalJob({ jobId: job.idStr }))}
                loading={isHiding}
                disabled={isHiding}
                pill
                color="secondary"
              >
                <i className="fas fa-times pr-1" />
                <FormattedMessage {...messages.hideButton} />
              </Button>
            </Fragment>
          }
        />
        <JobDescription description={linebreakToBr(job.description)} />
      </Collapse>
    </div>
  )
}

ExternalJobDashboardCard.propTypes = {
  job: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

ExternalJobDashboardCard.defaultProps = {
  isOpen: false,
}
