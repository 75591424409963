import React from "react"

import { noAuthRequired } from "../../decorators/no-auth-required"
import { BaseLayout } from "../../components/layouts/base-layout"
import { DoubleEqualColumnLayout } from "../../components/layouts/double-equal-column-layout"
import { SignupInfoSection } from "../../components/signup/signup-info-section-component/signup-info-section-component"
import { SignupFormSection } from "../../components/signup/signup-form-section-component/signup-form-section-component"

import office from "../../assets/img/office-white.png"
import matching from "../../assets/img/matching-white.png"
import speech from "../../assets/img/motivational-speech-white.png"

const infoSectionProps = {
  header: {
    id: "app.signupRef.infoSection.title",
  },
  subheader: {
    id: "app.signupRef.infoSection.subtitle",
  },
  benefits: {
    header: {
      id: "app.signupRef.infoSection.title2",
    },
    items: [
      {
        icon: {
          src: office,
          alt: "Office",
        },
        title: {
          id: "app.signupRef.infoSection.companies.title",
        },
        subtitle: {
          id: "app.signupRef.infoSection.companies.subtitle",
        },
      },
      {
        icon: {
          src: matching,
          alt: "Matching",
        },
        title: {
          id: "app.signupRef.infoSection.matched.title",
        },
        subtitle: {
          id: "app.signupRef.infoSection.matched.subtitle",
        },
      },
      {
        icon: {
          src: speech,
          alt: "Speech",
        },
        title: {
          id: "app.signupRef.infoSection.feedback.title",
        },
        subtitle: {
          id: "app.signupRef.infoSection.feedback.subtitle",
        },
      },
    ],
  },
}

export const SignupInvitePageComponent = () => (
  <BaseLayout className="py-5">
    <DoubleEqualColumnLayout>
      <DoubleEqualColumnLayout.Left>
        <SignupInfoSection {...infoSectionProps} />
      </DoubleEqualColumnLayout.Left>

      <DoubleEqualColumnLayout.Right>
        <SignupFormSection />
      </DoubleEqualColumnLayout.Right>
    </DoubleEqualColumnLayout>
  </BaseLayout>
)

export const SignupInvitePage = noAuthRequired(SignupInvitePageComponent)
