import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import {
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap"
import { Button } from "../common/button-component"
import { userIdSelector } from "../../selectors/candidate-selector"
import { Input } from "../common/input-component"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTemporaryState } from "../../hooks/use-temporary-state"
import { config } from "../../config"
import {
  shareJobOnFacebook,
  shareJobOnLinkedin,
  shareJobOnTwitter,
} from "../../lib/helpers"
import {
  sendJobInvitation,
  copyJobInvitationLinkTrack,
  openJobInvitationModalTrack,
} from "../../actions/job-actions"
import { JobInvitationForm } from "../forms/job-invitation-form"
import {
  isSendingJobInvitationSelector,
  sendJobInvitationErrSelector,
} from "../../selectors/job-selector"
import { usePrevious } from "react-hanger"

const messages = defineMessages({
  modalInviteTitle: { id: "app.job.modal.invite.title" },
  modalInviteDescription: { id: "app.job.modal.invite.description" },
  copyLink: { id: "app.job.modal.invite.copyLink" },
  share: { id: "app.job.share" },
  send: { id: "app.common.send" },
})

export const JobInvitationModal = ({ job, isOpen, onClose }) => {
  const dispatch = useDispatch()
  const userId = useSelector(userIdSelector)
  const isSending = useSelector(isSendingJobInvitationSelector)
  const sendErr = useSelector(sendJobInvitationErrSelector)
  const prevIsSending = usePrevious(isSending)
  const prevIsOpen = usePrevious(isOpen)
  const [isCopied, setIsCopied] = useTemporaryState(false, 3000)

  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      dispatch(openJobInvitationModalTrack({ jobId: job.id }))
    }
  }, [dispatch, isOpen, prevIsOpen, job.id])

  useEffect(() => {
    if (!isSending && prevIsSending && !sendErr) {
      onClose()
    }
  }, [sendErr, isSending, prevIsSending, onClose])

  const jobInvitationURL = `${config.moberries.catalog.url}/job/${job?.id}?ref=u-${userId}`
  const handleSubmit = ({ email }) => {
    dispatch(
      sendJobInvitation({
        email,
        jobId: job?.id,
      }),
    )
  }
  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <FormattedMessage {...messages.modalInviteTitle} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.modalInviteDescription} />
        <JobInvitationForm onSubmit={handleSubmit} />

        <div className="mt-2">
          <Label for="copy-link">
            <FormattedMessage {...messages.copyLink} />
          </Label>
        </div>

        <InputGroup>
          <Input
            className="m-0 flex-grow-1"
            type="text"
            readOnly
            input={{
              defaultValue: jobInvitationURL,
            }}
            meta={{}}
            id="copy-link"
          />
          <InputGroupAddon addonType="append">
            <CopyToClipboard text={jobInvitationURL}>
              <Button
                color="secondary"
                disabled={isCopied}
                onClick={() => {
                  dispatch(
                    copyJobInvitationLinkTrack({
                      jobId: job?.id,
                    }),
                  )
                  setIsCopied(true)
                }}
              >
                {isCopied ? (
                  <i className="far fa-check-circle" />
                ) : (
                  <i className="far fa-copy" />
                )}
              </Button>
            </CopyToClipboard>
          </InputGroupAddon>
        </InputGroup>
        <div className="mt-2">
          <FormattedMessage {...messages.share} />
        </div>
        <div className="mt-2">
          <Button
            className="rounded-circle p-3 btn-circle mr-2"
            onClick={() => shareJobOnLinkedin({ jobId: job?.id })}
            color="linkedin"
          >
            <i className="fab fa-linkedin-in" />
          </Button>
          <Button
            className="rounded-circle p-3 btn-circle mr-2"
            onClick={() => shareJobOnFacebook({ jobId: job?.id })}
            color="facebook"
          >
            <i className="fab fa-facebook-f" />
          </Button>
          <Button
            className="rounded-circle p-3 btn-circle"
            onClick={() =>
              shareJobOnTwitter({
                jobId: job?.id,
                jobTitle: job?.title,
                companyName: job?.company.name,
              })
            }
            color="twitter"
          >
            <i className="fab fa-twitter" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

JobInvitationModal.propTypes = {
  job: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
