import React from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { FormattedDate } from "./formatted-date-component"
import { MATCH_SOURCES } from "../../constants"

const messages = defineMessages({
  applied: { id: "app.common.applied" },
})

export function Applied({ match }) {
  let date = null
  switch (match.source) {
    case MATCH_SOURCES.LIKE: // this is a deprecated match source used only to support old matches https://github.com/moberries/backend/issues/3481
    case MATCH_SOURCES.EXCLUSIVE:
      date = match.createdAt
      break
    default:
      date = match.canRepliedAt
  }

  if (!date) {
    return null
  }

  return (
    <small className="text-muted">
      <FormattedMessage
        {...messages.applied}
        values={{
          time: <FormattedDate value={date} />,
        }}
      />
    </small>
  )
}

Applied.propTypes = {
  match: PropTypes.object.isRequired,
}
