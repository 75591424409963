import humps from "humps"
import axios from "axios"
import { cond, always, __, contains, T } from "ramda"
import {
  CLIENT_ERROR,
  SERVER_ERROR,
  UNKNOWN_ERROR,
  NETWORK_ERROR,
  CANCEL_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  TIMEOUT_ERROR_CODES,
  NODEJS_CONNECTION_ERROR_CODES,
} from "../constants"

import { in400s, in500s, pageToOffset } from "./helpers"

export function saveOriginalInterceptor(res) {
  res.originalData = res.data
  return res
}

export function camelizeInterceptor(res) {
  if (res.data instanceof window.Blob) {
    return res
  }

  res.data = humps.camelizeKeys(res.data)
  return res
}

export function decamelizeInterceptor(req) {
  req.data = humps.decamelizeKeys(req.data)
  req.params = humps.decamelizeKeys(req.params)
  return req
}

export function pageToOffsetInterceptor(req) {
  if (req.params && req.params.page) {
    const { page, limit, ...params } = req.params
    const offset = pageToOffset(page, limit)
    req.params = { ...params, offset, limit }
  }

  return req
}

export function errorInterceptor(error) {
  if ("onLine" in window.navigator) {
    const { onLine } = window.navigator

    error.onLine = onLine
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    error.response.data = humps.camelizeKeys(error.response.data)
    error.problem = getProblemFromStatus(error.response.status)
    return Promise.reject(error)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an
    // instance of http.ClientRequest in node.js
    error.problem = getProblemFromError(error)
    return Promise.reject(error)
  } else {
    // Something happened in setting up the request that triggered an Error
    error.problem = getProblemFromError(error)
    return Promise.reject(error)
  }
}

const getProblemFromStatus = status => {
  return cond([
    [in400s, always(CLIENT_ERROR)],
    [in500s, always(SERVER_ERROR)],
    [T, always(UNKNOWN_ERROR)],
  ])(status)
}

const getProblemFromError = error => {
  if (error.message === "Network Error") return NETWORK_ERROR
  if (axios.isCancel(error)) return CANCEL_ERROR

  return cond([
    [contains(__, TIMEOUT_ERROR_CODES), always(TIMEOUT_ERROR)],
    [contains(__, NODEJS_CONNECTION_ERROR_CODES), always(CONNECTION_ERROR)],
    [T, always(UNKNOWN_ERROR)],
  ])(error.code)
}
