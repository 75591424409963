import { getActionType } from "../lib/helpers"
import { UPLOAD, REQUEST, TOPCV, TOGGLE } from "../constants"

export function uploadToTopCv() {
  return {
    type: getActionType(UPLOAD, TOPCV, REQUEST),
  }
}

export function toggleTopCv() {
  return {
    type: getActionType(TOGGLE, TOPCV),
  }
}
