import React from "react"
import { push } from "connected-react-router"
import { compose } from "redux"
import { connect } from "react-redux"

import {
  candidateSelector,
  updatingSelector,
} from "../../selectors/candidate-selector"
import { updateCandidate } from "../../actions/candidate-actions"
import { authRequired } from "../../decorators/auth-required"
import { EditPreferences } from "../edit-profile-shared/edit-preferences"

const ProfileEditMyPreference = props => (
  <EditPreferences
    {...props}
    backRoute="/profile/preferences"
    onSaveRoute="/profile/preferences"
  />
)

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
  updating: updatingSelector(state),
})

const mapDispatchToProps = {
  updateCandidate,
  push,
}

export const ProfileEditMyPreferencePage = compose(
  authRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(ProfileEditMyPreference)
