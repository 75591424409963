import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { defineMessages, FormattedMessage } from "react-intl"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { Button } from "../common/button-component"
import {
  isLoggedSelector,
  isCompleteSelector,
  candidateSelector,
  haveJustFinishedRegistrationSelector,
} from "../../selectors/candidate-selector"
import { isInitialApplicationModalVisibleSelector } from "../../selectors/job-selector"
import { closeInitialApplicationModal } from "../../actions/job-actions"

const messages = defineMessages({
  modalText: { id: "app.initialJobApply.modal.body" },
  apply: { id: "app.initialJobApply.modal.button.apply" },
  close: { id: "app.common.close" },
})

const InitialJobApplicationModalComponent = ({
  candidate,
  isVisible,
  closeModal,
}) => {
  const job = candidate?.tracking?.job

  if (!isVisible || !job) return null

  return (
    <Fragment>
      <Modal isOpen toggle={closeModal} centered>
        <ModalBody>
          <FormattedMessage
            {...messages.modalText}
            values={{
              jobTitle: (
                <Link to={`/job/${job.id}`} onClick={closeModal}>
                  {job.title}
                </Link>
              ),
              companyName: job.company.name,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} color="light">
            <FormattedMessage {...messages.close} />
          </Button>
          <Button
            tag={Link}
            color="primary"
            onClick={closeModal}
            to={{
              pathname: `/job/${job.id}`,
              search: "apply",
            }}
          >
            <FormattedMessage {...messages.apply} />
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

InitialJobApplicationModalComponent.propTypes = {
  candidate: PropTypes.object,
  isVisible: PropTypes.bool,
  closeModal: PropTypes.func,
}

InitialJobApplicationModalComponent.defaultProps = {
  isVisible: false,
}

const mapDispatchToProps = {
  closeModal: closeInitialApplicationModal,
}

const mapStateToProps = state => {
  const candidate = candidateSelector(state)
  const isLogged = isLoggedSelector(state)
  const isComplete = isCompleteSelector(state)
  const justFinishedRegistration = haveJustFinishedRegistrationSelector(state)

  return {
    isVisible:
      isInitialApplicationModalVisibleSelector(state) &&
      isLogged &&
      isComplete &&
      !justFinishedRegistration,
    candidate,
  }
}

export const InitialJobApplicationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialJobApplicationModalComponent)
