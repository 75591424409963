import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import DatePicker from "react-datepicker"
import { format } from "date-fns"
import { useIntl } from "react-intl"

import CalendarIcon from "../../../assets/img/calendar.svg"

import styles from "./datepicker-component.module.scss"
import "react-datepicker/dist/react-datepicker.css"

export const DatepickerComponent = ({
  className,
  onChange,
  value,
  placeholder,
  disabled,
}) => {
  const intl = useIntl()
  const date = value ? new Date(value) : null

  const dateChangeHandler = date => {
    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd")
      onChange(formattedDate)
    }
  }

  return (
    <div className={cn([styles.wrapper, className])}>
      <DatePicker
        className={cn([
          styles.datepicker,
          { [styles.datepicker_disabled]: disabled },
        ])}
        placeholderText={intl.formatMessage({
          id: placeholder,
        })}
        selected={date}
        dateFormat="dd.MM.yyyy"
        onChange={dateChangeHandler}
        disabled={disabled}
      />
      {!disabled && (
        <img className={styles.icon} src={CalendarIcon} alt="Calendar" />
      )}
    </div>
  )
}

DatepickerComponent.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}
