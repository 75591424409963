import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Input as Binput, FormGroup, Label } from "reactstrap"
import FormFeedback from "./form-feedback"
import cn from "classnames"
import { LabelWithHint } from "./label-with-hint-component"

export class CheckboxGroup extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    hint: PropTypes.node,
    inline: PropTypes.bool,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    isRequired: PropTypes.bool,
  }

  static defaultProps = {
    inline: false,
    options: [],
    isRequired: false,
  }

  render() {
    const {
      input,
      meta,
      label,
      id,
      inline,
      hint,
      options,
      isRequired,
    } = this.props

    const { error, valid, touched, invalid } = meta

    const css = cn(
      {
        "custom-control-inline": inline,
      },
      "custom-control custom-checkbox",
    )

    return (
      <FormGroup>
        <LabelWithHint label={label} hint={hint} isRequired={isRequired} />
        <div>
          {options.map(o => (
            <div key={o.id} className={css}>
              <Binput
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={input.onFocus}
                type="checkbox"
                id={`${id}-${o.id}`}
                valid={!touched || valid ? null : valid}
                invalid={!touched || valid ? null : invalid}
                value={o.id}
                checked={input.value.includes(o.id)}
                className="custom-control-input"
              />

              <Label className="custom-control-label" for={`${id}-${o.id}`}>
                <FormattedMessage {...o.name} />
              </Label>
            </div>
          ))}
        </div>
        <Binput
          type="hidden"
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
        <FormFeedback error={error} />
      </FormGroup>
    )
  }

  handleChange = ({ target: { value } }) => {
    const val = Number(value)

    this.props.input.onChange(
      this.props.input.value.includes(val)
        ? this.props.input.value.filter(v => v !== val)
        : this.props.input.value.concat(val),
    )
  }

  handleBlur = () => this.props.input.onBlur(this.props.input.value)
}
