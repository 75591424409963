import { useEffect } from "react"
import { useLocation } from "react-router"
import { history } from "../../history"
import { showAlert, alertTypes } from "../../lib/alerts"
import { ALERT_COLORS } from "../../constants"

export const QueryErrorHandler = () => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    switch (queryParams.get("error_code")) {
      case "redirect_error":
        showAlert({
          color: ALERT_COLORS.ERROR,
          code: alertTypes.redirectError,
        })
        queryParams.delete("error_code")
        history.replace({
          search: queryParams.toString(),
        })
        break

      default:
        break
    }
  }, [location.search])

  return null
}
