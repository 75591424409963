import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Img } from "../common/img-component"
import { BaseLayout } from "./base-layout"
import { Container, Row, Col } from "reactstrap"

export const HiPageLayout = ({ title, subtitle, logo, children }) => {
  const leftChildren = React.Children.toArray(children).filter(
    c => c.type === HiPageLayout.Left,
  )
  const rightChildren = React.Children.toArray(children).filter(
    c => c.type === HiPageLayout.Right,
  )

  return (
    <BaseLayout className="pb-5">
      <section className="present-invite-hero present-hero bg-dark text-center text-white pb-6 pt-4 py-md-9">
        <Container>
          {title}
          {subtitle && (
            <Row className="justify-content-center">
              <Col md={6}>
                <h5 className="mb-2 d-md-none">{subtitle}</h5>
                <h2 className="mb-5 d-none d-md-block">{subtitle}</h2>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      <section className="present-content">
        <Container>
          <div className="present-company-logo-wrapper text-center py-4 py-md-0 mb-md-5">
            <Img
              className="thumbnail-container d-inline-block rounded-circle"
              src={logo}
              width="128"
              height="128"
            />
          </div>
          <Row className="justify-content-center  flex-wrap-reverse">
            <Col md={5}>{leftChildren}</Col>
            <Col md={5}>
              <div className="present-form-wrapper bg-white p-3 mb-5">
                <h2 className="text-center">
                  <FormattedMessage id="app.hi.form.title" />
                </h2>
                {rightChildren}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </BaseLayout>
  )
}

HiPageLayout.Left = ({ children }) => children
HiPageLayout.Right = ({ children }) => children

HiPageLayout.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired,
}
