import React from "react"
import PropTypes from "prop-types"
import { noAuthRequired } from "../../decorators/no-auth-required"
import { LoginFormSection } from "./login-form-section"
import { BaseLayout } from "../../components/layouts/base-layout"
import { DoubleEqualColumnLayout } from "../../components/layouts/double-equal-column-layout"
import { LoginInfoSection } from "./login-info-section"

export const LoginPageComponent = ({ location }) => {
  return (
    <BaseLayout className="py-5">
      <DoubleEqualColumnLayout>
        <DoubleEqualColumnLayout.Left>
          <LoginInfoSection />
        </DoubleEqualColumnLayout.Left>

        <DoubleEqualColumnLayout.Right>
          <LoginFormSection location={location} />
        </DoubleEqualColumnLayout.Right>
      </DoubleEqualColumnLayout>
    </BaseLayout>
  )
}

LoginPageComponent.propTypes = {
  location: PropTypes.object.isRequired,
}

export const LoginPage = noAuthRequired(LoginPageComponent)
