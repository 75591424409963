import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Button } from "../common/button-component"
import { MessageListLoader } from "../common/message-list-loader"
import { TextLineLoader } from "../common/text-line-loader"
import { NewMessage } from "../messenger/new-message-component"
import { ThreadListLoader } from "../common/thread-list-loader"
import { BaseLayout } from "../layouts/base-layout"

export const MessengerPageSkeleton = () => {
  return (
    <BaseLayout className="py-5">
      <Container>
        <Row noGutters className="bg-white border-right">
          <Col lg="5" className="pr-lg-0 messenger-height">
            <div className="p-2 bg-white h-100">
              <ThreadListLoader />
            </div>
          </Col>
          <Col lg="7" className="d-flex flex-column messenger-height">
            <div className="p-3 border-top border-bottom d-flex">
              <div className="d-flex flex-grow-1 overflow-hidden">
                <Button
                  color="light"
                  className="mr-2 d-lg-none d-flex align-items-center"
                >
                  <i className="fas fa-chevron-left" />
                </Button>
                <div className="text-truncate">
                  <TextLineLoader width={80} />
                  <div className="text-muted text-truncate">
                    <TextLineLoader width={120} />
                  </div>
                </div>
              </div>
            </div>
            <div className="loading-thread flex-grow-1">
              <MessageListLoader />
            </div>
            <div className="loading-thread">
              <NewMessage onSend={() => {}} creating />
            </div>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  )
}
