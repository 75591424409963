import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"
export const useSkillsLoader = () => {
  const [skills, setSkills] = useState([])

  const loadSkills = useCallback(
    debounce(async search => {
      const res = await moApi.getSkills({ params: { search } })
      const skills = pathOr([], ["data", "results"], res)
      setSkills(skills)

      return skills
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [],
  )

  return { skills, loadSkills }
}
