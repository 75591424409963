import React, { Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Button } from "../common/button-component"
import { defineMessages, FormattedMessage } from "react-intl"
import { JobControlsLayout } from "../layouts/job-controls-layout"
import {
  jobSelector,
  loadingSelector as jobLoadingSelector,
  applyingJobIdSelector,
  discardingJobIdSelector,
  isApplyingForJobSelector,
  isDiscardingJobSelector,
} from "../../selectors/job-selector"
import {
  candidateSelector,
  offersDeclinedSelector,
  offersAcceptedSelector,
} from "../../selectors/candidate-selector"
import { wasJobAppliedOrDiscarded } from "../../lib/helpers"
import { useOpenClose } from "../../hooks/use-open-close"
import { JobDiscardConfirmationModal } from "./job-discard-confirmation-modal-component"
import { applyForJob, discardJob } from "../../actions/job-actions"
import { JobMismatchModal } from "./job-mismatch-modal"
import { JobFinalizeApplicationModal } from "./job-finalize-application-modal"
import { validateMatch, clearValidation } from "../../actions/match-actions"
import {
  applicationJobIdSelector,
  validationDataSelector,
  validatingSelector,
} from "../../selectors/match-selector"
import { isNil } from "ramda"

const messages = defineMessages({
  applyButton: { id: "app.job.apply" },
  discardButton: { id: "app.job.discard" },
  applyText: { id: "app.job.apply.text" },
  discardText: { id: "app.job.discard.text" },
})

export const JobApplicationLoggedControlsComponent = ({
  job,

  isApplying,
  offersAccepted,
  applyForJob,

  isDiscarding,
  offersDeclined,
  discardJob,

  validateMatch,
  validationData,
  applicationJobId,
  clearValidation,
  validating,

  isDisabled,

  apply,
}) => {
  const {
    value: isDiscardModalOpen,
    open: openDiscardModal,
    close: closeDiscardModal,
  } = useOpenClose({ initialValue: false })

  const handleApplyClick = () => {
    validateMatch({ jobId: job.id })
  }

  const handleCloseModal = () => {
    clearValidation()
  }

  const handleDiscardClick = () => {
    if (offersDeclined === 0) {
      openDiscardModal()
    } else {
      discardJob({ jobId: job.id })
    }
  }

  return (
    <Fragment>
      <JobFinalizeApplicationModal
        jobId={job.id}
        isOpen={applicationJobId === job.id && isNil(validationData)}
        onClose={handleCloseModal}
        hasQuestions={job.hasQuestions}
        isApplying={isApplying}
      />

      <JobDiscardConfirmationModal
        onClose={closeDiscardModal}
        isOpen={isDiscardModalOpen}
        isDiscarding={isDiscarding}
        isDisabled={isDisabled}
        onDiscard={() =>
          discardJob({
            jobId: job.id,
          })
        }
      />

      <JobMismatchModal
        isOpen={applicationJobId === job.id && !isNil(validationData)}
        mismatchReasons={validationData?.filterReasons}
        onClose={handleCloseModal}
      />

      <JobControlsLayout
        left={
          <Fragment>
            <Button
              onClick={handleApplyClick}
              loading={isApplying || validating}
              disabled={isDisabled || validating}
              pill
              color="primary"
            >
              <i className="fas fa-heart pr-1" />
              <FormattedMessage {...messages.applyButton} />
            </Button>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.applyText} />
            </span>
          </Fragment>
        }
        right={
          <Fragment>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.discardText} />
            </span>
            <Button
              onClick={handleDiscardClick}
              loading={isDiscarding}
              disabled={isDisabled}
              pill
              color="secondary"
            >
              <i className="fas fa-times pr-1" />
              <FormattedMessage {...messages.discardButton} />
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  const job = jobSelector(state, { id: ownProps.jobId })
  const candidate = candidateSelector(state)
  const isApplying = applyingJobIdSelector(state) === ownProps.jobId
  const isDiscarding = discardingJobIdSelector(state) === ownProps.jobId
  const isApplyingForOtherJob = isApplyingForJobSelector(state)
  const isDiscardingOtherJob = isDiscardingJobSelector(state)
  const isJobLoading = jobLoadingSelector(state)

  const isDisabled =
    !job ||
    isApplying ||
    isDiscarding ||
    isJobLoading ||
    isApplyingForOtherJob ||
    isDiscardingOtherJob ||
    wasJobAppliedOrDiscarded(job.match)

  const allLocations = [
    ...job.locations,
    ...candidate.locations,
    candidate.city,
  ].reduce((acc, l) => {
    acc[l.placeId] = l
    return acc
  }, {})

  return {
    job,
    candidate,
    isDisabled,
    isApplying,
    isDiscarding,
    offersAccepted: offersAcceptedSelector(state),
    offersDeclined: offersDeclinedSelector(state),
    allLocations,
    validationData: validationDataSelector(state),
    applicationJobId: applicationJobIdSelector(state),
    validating: validatingSelector(state),
  }
}

const mapDispatchToProps = {
  applyForJob,
  discardJob,
  validateMatch,
  clearValidation,
}

JobApplicationLoggedControlsComponent.propTypes = {
  job: PropTypes.object,
  candidate: PropTypes.object,
  allLocations: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  applyForJob: PropTypes.func.isRequired,
  isApplying: PropTypes.bool.isRequired,
  isDiscarding: PropTypes.bool.isRequired,
  offersAccepted: PropTypes.number,
  discardJob: PropTypes.func.isRequired,
  offersDeclined: PropTypes.number,
  apply: PropTypes.bool,
  validateMatch: PropTypes.func.isRequired,
  validationData: PropTypes.object,
  clearValidation: PropTypes.func.isRequired,
  applicationJobId: PropTypes.number,
  validating: PropTypes.bool.isRequired,
}

JobApplicationLoggedControlsComponent.defaultProps = {
  isDisabled: false,
  isApplying: false,
  isDiscarding: false,
  apply: false,
}

export const JobApplicationLoggedControls = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobApplicationLoggedControlsComponent)
