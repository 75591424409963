import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormGroup, Input } from "reactstrap"
import ReactSelect, { components as defaultComponents } from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import FormFeedback from "./form-feedback"
import { FormattedMessage, defineMessages } from "react-intl"
import { MenuList } from "./menu-list"
import { LabelWithHint } from "./label-with-hint-component"

const messages = defineMessages({
  typeToSearch: { id: "app.common.type.to.search" },
})

export class Select extends Component {
  static propTypes = {
    label: PropTypes.node,
    hint: PropTypes.node,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    async: PropTypes.bool.isRequired,
    creatable: PropTypes.bool.isRequired,
    underInput: PropTypes.node,
    wrapper: PropTypes.elementType,
    underLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    components: PropTypes.object.isRequired,
    withCaret: PropTypes.bool.isRequired,
    withGoogleLogo: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool,
  }

  static defaultProps = {
    async: false,
    creatable: false,
    components: {},
    withCaret: false,
    withGoogleLogo: false,
    isRequired: false,
  }

  render() {
    const {
      input,
      meta,
      label,
      hint,
      async,
      creatable,
      underInput,
      underLabel,
      wrapper,
      components,
      withCaret,
      withGoogleLogo,
      isRequired,
      ...rest
    } = this.props

    const { error, touched, valid, invalid } = meta

    const Component = !async
      ? ReactSelect
      : creatable
      ? AsyncCreatableSelect
      : AsyncSelect

    const WrapperComponent = wrapper || FormGroup

    return (
      <WrapperComponent>
        <LabelWithHint
          label={label}
          hint={hint}
          isRequired={isRequired}
          for={rest.id}
        />
        {typeof underLabel === "function" ? underLabel(this.props) : underLabel}
        <Component
          noOptionsMessage={() => (
            <FormattedMessage {...messages.typeToSearch} />
          )}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={input.onFocus}
          name={input.name}
          value={input.value}
          className="Select"
          classNamePrefix="Select"
          components={{
            DropdownIndicator: withCaret
              ? defaultComponents.DropdownIndicator
              : null,
            MenuList: withGoogleLogo ? MenuList : defaultComponents.MenuList,
            IndicatorSeparator: null,
            ...components,
          }}
          {...rest}
        />
        {underInput}
        <Input
          type="hidden"
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
        <FormFeedback error={error} />
      </WrapperComponent>
    )
  }

  handleChange = value => {
    this.props.input.onChange(value)
  }

  handleBlur = () => {
    // https://www.firehydrant.io/blog/using-react-select-with-redux-form/
    if (this.props.input.onBlur) {
      setTimeout(() => this.props.input.onBlur(this.props.input.value), 1)
    }
  }
}
