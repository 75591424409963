import { createSelector } from "reselect"
import { values } from "ramda"

export const stateSelector = state => state.threads

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const errorSelector = cs(state => state.err)
export const entitiesSelector = cs(state => state.entities)
export const countSelector = cs(state => state.count)
export const idsSelector = cs(state => state.threads)

export const threadSelector = cs(
  entitiesSelector,
  (state, { id }) => id,
  (_, entities, id) => entities[id],
)

export const threadsSelector = cs(
  entitiesSelector,
  idsSelector,
  (_, entities, ids) => ids.map(id => entities[id]),
)

export const unreadThreadsSelector = cs(entitiesSelector, (_, threads) =>
  values(threads).filter(t => t.unreadCount > 0),
)

export const lastFetchedPageSelector = cs(state => state.lastFetchedPage)
