import React from "react"
import { MbSvgLogo } from "../common/mb-svg-logo"
import { FormattedMessage } from "react-intl"

export const MoberriesAdvantages = () => (
  <div>
    <div className="present-icon-wrapper mb-3 text-center">
      <MbSvgLogo />
    </div>
    <h3 className="text-left font-weight-light">
      <FormattedMessage id="app.hi.advantages.moberries" />
    </h3>
    <ul>
      <li>
        <FormattedMessage id="app.hi.advantages.moberries.relevant" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.moberries.fast" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.moberries.matches" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.moberries.feedback" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.moberries.help" />
      </li>
    </ul>
  </div>
)
