import { routerMiddleware, push, replace } from "connected-react-router"
import { updateIntl } from "react-intl-redux"
import createSagaMiddleware from "redux-saga"
import soundsMiddleware from "redux-sounds"
import { persistStore, persistReducer } from "redux-persist"
import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"
import { stateSanitizer, actionSanitizer } from "../lib/logrocket-sanitizers"
import { FAIL, START, SUCCESS } from "../constants"
import * as userActions from "../actions/user-actions"
import * as candidateActions from "../actions/candidate-actions"
import * as matchActions from "../actions/match-actions"
import * as jobActions from "../actions/job-actions"
import * as threadActions from "../actions/thread-actions"
import * as messageActions from "../actions/message-actions"
import * as botActions from "../actions/bot-actions"
import * as intlActions from "../actions/intl-actions"
import * as topCvActions from "../actions/top-cv-actions"
import * as companyActions from "../actions/company-actions"

import { mapObjIndexed } from "ramda"
import { reducers } from "./reducers"
import { saga } from "./saga"
import { config } from "../config"
import { history } from "../history"
import { sagaErrorHandler } from "../lib/helpers"
import { reduxFormFixMiddleware } from "../middleware/rf-fix-middleware"

import {
  createStore,
  combineReducers,
  applyMiddleware,
  bindActionCreators,
  compose,
} from "redux"
import { sentryUserMiddleware } from "../middleware/sentry-user-middleware"
import { sentryFailedActionMiddleware } from "../middleware/sentry-failed-action-middleware"

let composeEnhancers = compose

const sagaMiddleware = createSagaMiddleware({
  onError: sagaErrorHandler,
})

let middleware = [
  reduxFormFixMiddleware,
  sagaMiddleware,
  soundsMiddleware(config.reduxSound),
  sentryUserMiddleware,
  sentryFailedActionMiddleware,
]

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: stateSanitizer,
  actionTransformer: actionSanitizer,
})

if (config.env.isDev) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionCreators: {
        user: userActions,
        candidate: candidateActions,
        match: matchActions,
        company: companyActions,
        job: jobActions,
        intl: intlActions,
        thread: threadActions,
        message: messageActions,
        bot: botActions,
        topCv: topCvActions,
        locale: { ...intlActions, updateIntl },
        router: { push, replace },
      },
    })
  }
}

if (process.env.NODE_ENV === "development") {
  const logger = require("redux-logger")

  const loggerMiddleware = logger.createLogger({
    collapsed: true,
    duration: true,
    colors: {
      ...logger.defaults.colors,
      title: ({ type }) => {
        if (type.includes(SUCCESS)) return "#4CAF50"
        if (type.includes(FAIL)) return "#F20404"
        if (type.includes(START)) return "#03A9F4"
      },
    },
    predicate: (getState, action) => !action.type.includes("redux-form"),
  })

  middleware.push(loggerMiddleware)
}

middleware.push(routerMiddleware(history))

middleware.push(
  LogRocket.reduxMiddleware({
    stateSanitizer,
    actionSanitizer,
  }),
)

function applyPersistors(reducers, config) {
  return mapObjIndexed((v, k) => {
    if (config[k]) {
      return persistReducer(config[k], v)
    }

    return v
  }, reducers)
}

export function configureStore() {
  const store = createStore(
    persistReducer(
      config.persist.root,
      combineReducers(applyPersistors(reducers, config.persist)),
    ),
    {},
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
  )

  const persistor = persistStore(store, {})

  if (config.env.isDev) {
    window.store = store

    window.actions = {
      user: bindActionCreators(userActions, store.dispatch),
      candidate: bindActionCreators(candidateActions, store.dispatch),
      match: bindActionCreators(matchActions, store.dispatch),
      company: bindActionCreators(companyActions, store.dispatch),
      job: bindActionCreators(jobActions, store.dispatch),
      thread: bindActionCreators(threadActions, store.dispatch),
      message: bindActionCreators(messageActions, store.dispatch),
      bot: bindActionCreators(botActions, store.dispatch),
      topCv: bindActionCreators(topCvActions, store.dispatch),
      intl: bindActionCreators(
        {
          ...intlActions,
          updateIntl,
        },
        store.dispatch,
      ),
      router: bindActionCreators({ push, replace }, store.dispatch),
    }
  }

  return {
    persistor,
    store,
    runSaga: () => sagaMiddleware.run(saga, { persistor }),
  }
}
