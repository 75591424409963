import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"

export const useDegrees = () => {
  const [degrees, setDegrees] = useState([])

  const loadDegrees = useCallback(
    debounce(async search => {
      const res = await moApi.getDegrees({ params: { search } })
      const degrees = pathOr([], ["data", "results"], res)
      setDegrees(degrees)

      return degrees
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [],
  )

  return { degrees, loadDegrees }
}
