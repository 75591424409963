import { getActionType, arrayToObject } from "../lib/helpers"
import {
  always,
  evolve,
  F,
  mergeLeft,
  T,
  over,
  lensProp,
  __,
  ifElse,
  equals,
  union,
} from "ramda"
import {
  GET,
  SUCCESS,
  THREAD,
  MESSAGE,
  LIST,
  FAIL,
  START,
  CREATE,
  UPDATE,
  LOGOUT,
} from "../constants"

export const defaultState = {
  count: null,
  threads: [],
  entities: {},
  loadingPage: null,
  creating: false,
  err: null,
  lastFetchedPage: 0,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, THREAD, LIST, START):
      return evolve(
        {
          loadingPage: always(payload.page),
        },
        state,
      )
    case getActionType(GET, THREAD, LIST, SUCCESS):
      return evolve(
        {
          loadingPage: always(null),
          err: always(null),
          entities: mergeLeft(arrayToObject(payload.threads, "id")),
          threads: ifElse(
            always(equals(payload.page, 1)),
            union(payload.threads.map(t => t.id)),
            union(
              __,
              payload.threads.map(t => t.id),
            ),
          ),
          count: always(payload.count),
          lastFetchedPage: ifElse(
            always(payload.page > state.lastFetchedPage),
            always(payload.page),
            always(state.lastFetchedPage || 1),
          ),
        },
        state,
      )

    case getActionType(GET, THREAD, SUCCESS):
      return evolve(
        {
          entities: over(
            lensProp(payload.thread.id),
            mergeLeft(payload.thread),
          ),
        },
        state,
      )

    case getActionType(GET, THREAD, LIST, FAIL):
      return evolve(
        {
          loadingPage: always(null),
          err: always(payload.err),
        },
        state,
      )

    case getActionType(CREATE, THREAD, START):
      return evolve(
        {
          creating: T,
        },
        state,
      )

    case getActionType(CREATE, THREAD, SUCCESS):
      return evolve(
        {
          creating: F,
          err: always(null),
          entities: over(
            lensProp(payload.thread.id),
            mergeLeft(payload.thread),
          ),
        },
        state,
      )

    case getActionType(CREATE, THREAD, FAIL):
      return evolve(
        {
          creating: F,
          err: always(payload.err),
        },
        state,
      )

    case getActionType(CREATE, MESSAGE, SUCCESS):
      return evolve(
        {
          threads: union([payload.threadId]),
        },
        state,
      )

    case getActionType(UPDATE, THREAD, LIST, SUCCESS):
      return evolve(
        {
          threads: union(payload.threads.map(t => t.id)),
          entities: mergeLeft(arrayToObject(payload.threads, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
