import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Media } from "reactstrap"
import { getFullName, isMessageFromManager } from "../../lib/helpers"
import { FormattedMessage, FormattedTime } from "react-intl"
import Linkify from "linkifyjs/react"
import { isToday, differenceInHours, parseISO } from "date-fns"
import { MessageReadStatus } from "./message-read-status-component"
import { PersonInitials } from "../common/person-initials"
import { FormattedRelative } from "../common/formatted-relative"
import { FormattedDate } from "../common/formatted-date-component"

const formatDate = timestamp => {
  const date = parseISO(timestamp)
  const now = new Date()

  if (differenceInHours(now, date) < 1) {
    return <FormattedRelative timestamp={timestamp} />
  } else if (isToday(date)) {
    return <FormattedTime value={date} />
  } else {
    return <FormattedDate value={date} />
  }
}

export const Message = ({ message, isArchived }) => {
  const isFromCandidate = !isMessageFromManager(message)
  const fullName = getFullName(message.authorData)

  return (
    <Media
      className={classNames("px-3 py-2", {
        "flex-row-reverse": isFromCandidate,
      })}
    >
      {!isFromCandidate && (
        <PersonInitials
          width={48}
          height={48}
          className={classNames(
            "rounded-circle h4 align-self-end mb-4 _lr-hide",
            {
              "bg-light": isArchived,
              "ml-2": isFromCandidate,
              "mr-2": !isFromCandidate,
            },
          )}
          firstName={message.authorData && message.authorData.firstName}
          lastName={message.authorData && message.authorData.lastName}
        />
      )}
      <div
        className={classNames("_lr-hide", {
          "pl-md-7": isFromCandidate,
          "pr-md-7": !isFromCandidate,
        })}
      >
        <div
          className={classNames("p-3 rounded", {
            "bg-success-light": isFromCandidate && !isArchived,
            "bg-light": !isFromCandidate || isArchived,
          })}
        >
          <div className="font-weight-bold mb-1">
            {fullName || <FormattedMessage id="app.common.user.deleted" />}
          </div>
          <div>
            {message.text.split(/\r\n|\r|\n/g).map((chunk, idx) => {
              return (
                <Fragment key={idx}>
                  <Linkify className="text-break">{chunk}</Linkify>
                  <br />
                </Fragment>
              )
            })}
          </div>
        </div>

        <div
          className={classNames("text-muted text-smaller mt-1", {
            "text-right": isFromCandidate,
          })}
        >
          {isFromCandidate && <MessageReadStatus isRead={message.isRead} />}
          {formatDate(message.created)}
        </div>
      </div>
    </Media>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  isArchived: PropTypes.bool,
}

Message.defaultProps = {
  isArchived: false,
}
