import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import cn from "classnames"
import { MatchSubscriptionModal } from "../modals/match-subscription-modal-component"
import { messageProviders } from "../../constants"
import { connect } from "react-redux"
import { botSubscriptionsSelector } from "../../selectors/candidate-selector"
import { capitalize } from "../../lib/helpers"

const MessageSubscriptionWidgetComponent = ({ botSubscriptions }) => {
  const [selectedProvider, setSelectedProvider] = useState(null)
  const hasSubscriptions = botSubscriptions.length > 0
  const subscriptionType = botSubscriptions[0]?.type || null

  return (
    <>
      <MatchSubscriptionModal
        toggle={() => setSelectedProvider(null)}
        isOpen={!!selectedProvider}
        selectedProvider={selectedProvider}
      />
      <div className="sidebar-widget bg-white rounded mb-3">
        <div className="p-3 d-flex align-items-center">
          <div className="mr-3">
            <span className="fa-stack fa-3x">
              <i className="fas fa-circle fa-stack-2x text-light" />
              <i className="fad fa-comment-alt-smile fa-stack-1x" />
            </span>
          </div>
          <div>
            <p className="mb-3">
              {hasSubscriptions ? (
                <FormattedMessage
                  id="app.widget.subscription.title.single"
                  values={{
                    botName: capitalize(subscriptionType),
                  }}
                />
              ) : (
                <FormattedMessage id="app.widget.subscription.title.all" />
              )}
            </p>
            <div className="d-flex">
              {messageProviders
                .filter(provider => {
                  if (!hasSubscriptions) return true

                  return provider.name === capitalize(subscriptionType)
                })
                .map((provider, index) => (
                  <Button
                    key={provider.name}
                    loading={false}
                    disabled={false}
                    onClick={() => setSelectedProvider(provider.name)}
                    color="light"
                    className={cn(
                      {
                        "mr-2": index !== messageProviders.length - 1,
                      },
                      "position-relative",
                      "py-4",
                      "w-100",
                    )}
                  >
                    <img
                      className="center-absolute"
                      src={provider.icon}
                      alt={provider.name}
                      width={28}
                    />
                  </Button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

MessageSubscriptionWidgetComponent.propTypes = {
  botSubscriptions: PropTypes.array,
}

const mapStateToProps = state => ({
  botSubscriptions: botSubscriptionsSelector(state),
})

const mapDispatchToProps = {}

export const MessageSubscriptionWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageSubscriptionWidgetComponent)
