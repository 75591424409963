import { getActionType, N } from "../lib/helpers"
import { always, evolve, F, T } from "ramda"
import { GET, SUCCESS, SURVEY, STATUS, FAIL, START, CLOSE } from "../constants"

export const defaultState = {
  isCompleted: true,
  isLoading: false,
  closingTimestamp: null,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, SURVEY, STATUS, START):
      return evolve({ isLoading: T }, state)

    case getActionType(GET, SURVEY, STATUS, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: N,
          isCompleted: always(payload.isCompleted),
        },
        state,
      )

    case getActionType(GET, SURVEY, STATUS, FAIL):
      return evolve({ isLoading: F, err: always(payload.err) }, state)

    case getActionType(CLOSE, SURVEY):
      return evolve(
        {
          closingTimestamp: always(payload.closingTimestamp),
        },
        state,
      )

    default:
      return state
  }
}
