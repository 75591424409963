import * as Sentry from "@sentry/react"
import {
  FAIL,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  UNKNOWN_ERROR,
} from "../constants"

export const sentryFailedActionMiddleware = () => next => action => {
  if (!action.type.includes(FAIL)) {
    return next(action)
  }

  const error = action?.payload?.err
  if (!error) {
    return next(action)
  }
  const shouldErrorBeReported =
    !error.problem ||
    [
      SERVER_ERROR,
      TIMEOUT_ERROR,
      CONNECTION_ERROR,
      NETWORK_ERROR,
      UNKNOWN_ERROR,
    ].includes(error.problem)

  if (shouldErrorBeReported) {
    Sentry.captureException(error)
  }

  return next(action)
}
