import React from "react"
import { push } from "connected-react-router"
import { compose } from "redux"
import { connect } from "react-redux"

import { updateCandidate } from "../actions/candidate-actions"
import { authRequired } from "../decorators/auth-required"
import { noCompletedProfileRequired } from "../decorators/no-completed-profile-required"
import {
  candidateSelector,
  updatingSelector,
} from "../selectors/candidate-selector"
import { EditPreferences } from "./edit-profile-shared/edit-preferences"

const RegistrationPreference = props => (
  <EditPreferences
    {...props}
    onSaveRoute="/registration/resume"
    backRoute="/registration/professional"
  />
)

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
  updating: updatingSelector(state),
})

const mapDispatchToProps = {
  updateCandidate,
  push,
}

export const RegistrationPreferencePage = compose(
  noCompletedProfileRequired,
  authRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(RegistrationPreference)
