import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"

import { PersonalDetailsPage } from "../personal-details-page"
import { BottomPanel } from "../../components/common/bottom-panel/bottom-panel"
import { ProfessionalDetailsForm } from "../../components/forms/professional-details-form/professional-details-form"
import { ProfileProfessionalDetailsValidator } from "../../validators/profile-professional-details-validator"

const professionalDetailsFormName = "ProfessionalDetailsForm"

const bottomPanelTabs = [
  {
    name: <FormattedMessage id="app.registration.tabs.professional.title" />,
    active: true,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.preferences.title" />,
    active: false,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.resume.title" />,
    active: false,
  },
]

const prepareAndSanitizeCandidateData = candidate => {
  const workExperience = candidate.workExperience
    .map(({ dbId, ...rest }) => rest)
    .filter(l => Object.keys(l).length)
  const certificates = candidate.certificates
    .map(({ id, ...rest }) => rest)
    .filter(l => Object.keys(l).length)
  const jobPosition = candidate.jobPosition
    .map(item => {
      const { id, ...rest } = item
      if (id === -1) return rest
      return item
    })
    .filter(item => Object.keys(item).length)
  const languages = candidate.languages
    .map(language => {
      const { id, ...rest } = language
      if (id === -1) return rest
      return language
    })
    .filter(l => Object.keys(l).length)
  const education = candidate.education
    .map(({ id, ...rest }) => rest)
    .filter(l => Object.keys(l).length)

  return {
    references: candidate.references,
    skills: candidate.skills,
    workExperience,
    jobPosition,
    languages,
    education,
    certificates,
  }
}

export const EditProfessionalDetails = ({
  candidate,
  location,
  updating: isCandidateUpdating,
  updateCandidate,
  backRoute,
  onSaveRoute,
}) => {
  const professionalDetailsSubmitHandler = candidate => {
    updateCandidate({
      candidate: prepareAndSanitizeCandidateData(candidate),
      formName: professionalDetailsFormName,
      routing: {
        pathname: onSaveRoute,
        search: location.search,
      },
    })
  }

  return (
    <>
      <PersonalDetailsPage formName="PersonalDetailsForm" onSubmit={() => {}} />

      <BottomPanel tabs={bottomPanelTabs}>
        <ProfessionalDetailsForm
          form={professionalDetailsFormName}
          initialValues={candidate}
          validate={ProfileProfessionalDetailsValidator}
          onSubmit={professionalDetailsSubmitHandler}
          isUpdating={isCandidateUpdating}
          backRoute={backRoute}
        />
      </BottomPanel>
    </>
  )
}

EditProfessionalDetails.propTypes = {
  candidate: PropTypes.object,
  location: PropTypes.object,
  updating: PropTypes.bool,
  updateCandidate: PropTypes.func,
  backRoute: PropTypes.string,
  onSaveRoute: PropTypes.string,
}
