import { createSelector } from "reselect"

export const stateSelector = state => state.externalJobs

const cs = (...args) => createSelector(stateSelector, ...args)

export const isLoadingSelector = cs(state => state.isLoading)
export const errorSelector = cs(state => state.err)
export const entitiesSelector = cs(state => state.entities)
export const countSelector = cs(state => state.count)
export const externalJobsIdsSelector = cs(state => state.externalJobs)
export const externalJobsSelector = cs(
  entitiesSelector,
  externalJobsIdsSelector,
  (_, entities, ids) => ids.map(id => entities[id]),
)
export const hidingExternalJobIdSelector = cs(
  state => state.hidingExternalJobId,
)
export const isHidingSelector = cs(
  hidingExternalJobIdSelector,
  (state, { id }) => id,
  (_, hidingExternalJobId, id) => hidingExternalJobId === id,
)
