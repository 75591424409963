import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Form, PopoverBody } from "reactstrap"
import { connect } from "react-redux"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage, injectIntl } from "react-intl"
import { Input } from "../common/input-component"
import { compose } from "ramda"
import { Select } from "../common/select-component"
import { getLocationName } from "../../lib/helpers"
import { PictureInputComponent as PictureInput } from "../common/picture-input-component"
import { useGoogleLocationsLoader } from "../../hooks/use-google-maps-places-loader"
import { Button } from "../common/button-component"
import { InfoHint } from "../common/info-hint-component"

const messages = defineMessages({
  firstName: { id: "app.common.name.first" },
  lastName: { id: "app.common.name.last" },
  phoneNumber: { id: "app.common.phone" },
  phoneHint: { id: "app.common.phone.hint" },
  email: { id: "app.common.email" },
  city: { id: "app.common.city" },
  select: { id: "app.common.select" },
  typeToSearch: { id: "app.common.type.to.search" },
  back: { id: "app.common.back" },
  save: { id: "app.common.save" },
})

const pictureInputProps = {
  rounded: "rounded-0",
  width: "200",
  height: "200",
  borderRadius: 0,
  buttons: {
    select: {
      text: { id: "app.common.image.upload" },
      size: "md",
      style: { width: 200 },
    },
    delete: {
      text: { id: "app.common.image.remove" },
      size: "md",
      style: { width: 200 },
    },
    crop: {
      text: { id: "app.common.confirm" },
      size: "md",
      style: { width: 95 },
    },
    cancel: {
      className: "mr-2",
      text: { id: "app.common.cancel" },
      size: "md",
      style: { width: 95 },
    },
  },
}

const PersonalDetails = ({ valid, submitting, handleSubmit, backRoute }) => {
  const { loadLocations } = useGoogleLocationsLoader({ types: ["cities"] })

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col lg="3">
          <Field
            id="picture"
            name="picture"
            component={PictureInput}
            {...pictureInputProps}
          />
        </Col>

        <Col className="pt-3 bg-white rounded" lg="9">
          <Row>
            <Col lg="6">
              <Field
                component={Input}
                label={
                  <b>
                    <FormattedMessage {...messages.firstName} />
                  </b>
                }
                className="_lr-hide"
                name="firstName"
                type="text"
                id="firstName"
                placeholder="John"
                isRequired
              />
            </Col>
            <Col lg="6">
              <Field
                component={Input}
                label={
                  <b>
                    <FormattedMessage {...messages.lastName} />
                  </b>
                }
                className="_lr-hide"
                name="lastName"
                type="text"
                id="lastName"
                placeholder="Doe"
                isRequired
              />
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <Field
                component={Input}
                hint={
                  <InfoHint
                    id="phoneNumberHint"
                    popover={
                      <PopoverBody>
                        <FormattedMessage {...messages.phoneHint} />
                      </PopoverBody>
                    }
                  />
                }
                label={
                  <b>
                    <FormattedMessage {...messages.phoneNumber} />
                  </b>
                }
                className="_lr-hide"
                name="phoneNumber"
                type="text"
                id="phoneNumber"
                placeholder="+4901234567890"
              />
            </Col>
            <Col lg="6">
              <Field
                component={Input}
                label={
                  <b>
                    <FormattedMessage {...messages.email} />
                  </b>
                }
                className="_lr-hide"
                name="email"
                type="email"
                id="email"
                placeholder="username@domain.com"
                isRequired
                readOnly
              />
            </Col>
          </Row>
          <Row className="pb-5">
            <Col lg="6">
              <Field
                name="city"
                id="city"
                async
                getOptionValue={option => option.placeId}
                getOptionLabel={option =>
                  getLocationName(option) || option.name
                }
                backspaceRemovesValue={false}
                withGoogleLogo
                noOptionsMessage={() => (
                  <FormattedMessage {...messages.typeToSearch} />
                )}
                placeholder={<FormattedMessage {...messages.select} />}
                loadOptions={loadLocations}
                component={Select}
                isRequired
                label={
                  <b>
                    <FormattedMessage {...messages.city} />
                  </b>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-end">
        <Col className="p-0" lg={backRoute ? "2" : "1"}>
          <div className="mt-5 d-flex">
            {backRoute && (
              <Button
                tag="a"
                href={backRoute}
                disabled={submitting}
                color="secondary"
                size="md"
                className="flex-shrink-0 flex-grow-1 mr-2 float-left"
              >
                <FormattedMessage {...messages.back} />
              </Button>
            )}
            <Button
              disabled={submitting || !valid}
              loading={submitting}
              color="primary"
              type="submit"
              size="md"
              className="flex-shrink-0 flex-grow-1  float-right"
            >
              <FormattedMessage {...messages.save} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

PersonalDetails.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  button: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

PersonalDetails.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
})

export const PersonalDetailsForm = compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(PersonalDetails)
