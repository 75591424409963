import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, defineMessages } from "react-intl"
import { CAREER_LEVELS, JOB_TYPES, LANG_LEVELS } from "../../default-options"
import { Badge } from "../common/badge-component"
import { Col, Row } from "reactstrap"
import { Button } from "../common/button-component"
import { useSelector } from "react-redux"
import { isLoggedSelector } from "../../selectors/candidate-selector"
import { useOpenClose } from "../../hooks/use-open-close"
import { JobInvitationModal } from "./job-invitation-modal"

const messages = defineMessages({
  languages: { id: "app.common.languages" },
  skills: { id: "app.common.skills" },
  relocation: { id: "app.job.relocation.title" },
  remote: { id: "app.job.remote.title" },
  invite: { id: "app.job.button.invite" },
})

export function JobInfo({ job, candidateSkills }) {
  const { jobTypes, careerLevel, relocate, languages, skills } = job
  const isLogged = useSelector(isLoggedSelector)
  const {
    value: isJobInvitationModalOpen,
    open: openJobInvitationModal,
    close: closeJobInvitationModal,
  } = useOpenClose({ initialValue: false })
  return (
    <div className="bg-white">
      <JobInvitationModal
        job={job}
        isOpen={isJobInvitationModalOpen}
        onClose={closeJobInvitationModal}
      />
      {isLogged && (
        <div className="border-bottom text-center p-2">
          <Button
            className="rounded text-muted py-1 px-2"
            onClick={openJobInvitationModal}
            color="light"
          >
            <i className="fal fa-envelope mr-2" />
            <FormattedMessage {...messages.invite} />
          </Button>
        </div>
      )}
      <div className="border-bottom p-3">
        {CAREER_LEVELS[careerLevel] && (
          <div className="d-inline-block mr-3">
            <i className="fas fa-briefcase pr-2 text-muted" />
            <FormattedMessage {...CAREER_LEVELS[careerLevel].name} />
          </div>
        )}
        {jobTypes.map(i => {
          const item = JOB_TYPES.find(t => t.id === i)
          if (!item) return null

          return (
            <div className="d-inline-block mr-3" key={item.id}>
              <i className="fas fa-clock pr-2 text-muted" />
              <FormattedMessage {...item.name} />
            </div>
          )
        })}
        {relocate && (
          <div className="d-inline-block mr-3">
            <i className="fas fa-map-marked-alt pr-2 text-muted" />
            <FormattedMessage {...messages.relocation} />
          </div>
        )}
      </div>
      <Row noGutters>
        {skills.length > 0 && (
          <Col md className="p-3">
            <div className="text-muted text-uppercase mb-1 text-smaller">
              <FormattedMessage {...messages.skills} />
            </div>
            <div className="tags-wrapper">
              {skills.map(s => (
                <Badge
                  key={s.id}
                  color={
                    candidateSkills.some(cs => cs.id === s.id)
                      ? "success-light"
                      : "light"
                  }
                >
                  {s.name}
                </Badge>
              ))}
            </div>
          </Col>
        )}
        {languages.length > 0 && (
          <Col md className="border-md-left p-3">
            <div className="text-muted text-uppercase mb-1 text-smaller">
              <FormattedMessage {...messages.languages} />
            </div>
            {languages.map(l => {
              const item = LANG_LEVELS.find(ll => ll.id === l.level)
              if (!item) return null

              return (
                <div key={l.id}>
                  <span>{l.name}</span> - <FormattedMessage {...item.name} />
                </div>
              )
            })}
          </Col>
        )}
      </Row>
    </div>
  )
}

JobInfo.propTypes = {
  job: PropTypes.object,
  candidateSkills: PropTypes.array,
}

JobInfo.defaultProps = {
  candidateSkills: [],
}
