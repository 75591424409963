import React from "react"
import PropTypes from "prop-types"

import plus from "../../../assets/img/plus.svg"
import cross from "../../../assets/img/cross.svg"

import styles from "./button-with-icon.module.scss"

const icons = {
  plus: {
    src: plus,
    alt: "add",
  },
  cross: {
    src: cross,
    alt: "remove",
  },
}

export const ButtonWithIcon = ({ iconName, onClick, ...props }) => (
  <button className={styles.button} type="button" onClick={onClick} {...props}>
    <img
      src={icons[iconName].src}
      width="35"
      height="35"
      alt={icons[iconName].alt}
    />
  </button>
)

ButtonWithIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
