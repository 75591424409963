import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import { logout } from "../../actions/user-actions"
import { NavLink as RLink, Link } from "react-router-dom"
import MBLogo from "../../assets/img/mb-logo.svg"
import enFlag from "../../assets/img/en.svg"
import deFlag from "../../assets/img/de.svg"
import defaultLogo from "../../assets/img/default-logo.png"
import { Button } from "./button-component"
import { loadingSelector, localeSelector } from "../../selectors/intl-selector"
import { links } from "../../lib/external-links"
import cn from "classnames"
import { getLocale } from "../../actions/intl-actions"
import { updateCandidateLocale } from "../../actions/candidate-actions"
import { Img } from "./img-component"
import {
  candidateSelector,
  isCompleteSelector,
  isLoggedSelector,
  localeUpdatingSelector,
  unreadCountSelector,
} from "../../selectors/candidate-selector"
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
} from "reactstrap"
import { useOpenClose } from "../../hooks/use-open-close"
import cookie from "js-cookie"
import { config } from "../../config"
import { getBrowserLanguage } from "../../lib/helpers"
import { AnnouncementBanner } from "./announcement-banner/announcement-banner"

const messages = defineMessages({
  germanLang: { id: "app.common.german" },
  englishLang: { id: "app.common.english" },
  navbarDashboard: { id: "app.common.dashboard" },
  navbarApplications: { id: "app.common.applications" },
  navbarMessages: { id: "app.navbar.messages" },
  navbarMore: { id: "app.navbar.more" },
  navbarProfile: { id: "app.navbar.profile" },
  navbarAccount: { id: "app.common.account.capitalized" },
  navbarLogout: { id: "app.navbar.logout" },
  navbarHowto: { id: "app.navbar.guide" },
  navbarSupport: { id: "app.common.support" },
  navbarPrivacy: { id: "app.common.privacy" },
  navbarLanguage: { id: "app.common.language" },
  navbarMenu: { id: "app.navbar.menu" },
  navbarToEnglish: { id: "app.navbar.switch.english" },
  navbarToGerman: { id: "app.navbar.switch.german" },
})

export const HeaderComponent = ({
  candidate,
  isLogged,
  isComplete,
  locale,
  logout,
  getLocale,
  updateCandidateLocale,
  updating,
  unreadCount,
}) => {
  const { value: isOpen, toggle, close } = useOpenClose({ initialValue: false })

  useEffect(() => {
    if (!isLogged && !cookie.get(config.cookie.keys.locale)) {
      getLocale({ locale: getBrowserLanguage() })
    }
  }, [getLocale, isLogged])

  const brand = (
    <NavbarBrand tag={Link} to="/" className={cn({ "mx-auto": !isLogged })}>
      <img src={MBLogo} width="32" height="32" alt="Moberries" />
    </NavbarBrand>
  )

  const localeText = (
    <Fragment>
      {locale === "en" && (
        <Fragment>
          <img src={enFlag} width="16" height="16" alt="enFlag" />{" "}
          <span className="align-middle">
            <FormattedMessage {...messages.navbarToEnglish} />
          </span>
        </Fragment>
      )}
      {locale === "de" && (
        <Fragment>
          <img src={deFlag} width="16" height="16" alt="deFlag" />{" "}
          <span className="align-middle">
            <FormattedMessage {...messages.navbarToGerman} />
          </span>
        </Fragment>
      )}
    </Fragment>
  )

  let navbarProps = {
    expand: "sm",
  }

  const handleLocaleChange = () => {
    let nextlocale

    switch (locale) {
      case "de":
        nextlocale = "en"
        break
      default:
        nextlocale = "de"
        break
    }

    if (isLogged) {
      updateCandidateLocale({ locale: nextlocale })
    } else {
      getLocale({ locale: nextlocale })
    }
  }

  return (
    <div className="sticky-top">
      {isLogged && <AnnouncementBanner />}
      <Navbar color="dark" dark {...navbarProps} className="sticky-top">
        <Container>
          {isLogged ? (
            <Fragment>
              {brand}
              {isComplete ? (
                <Fragment>
                  <Button color="dark" className="d-sm-none" onClick={toggle}>
                    <span
                      className={cn(
                        { "fa-bars": !isOpen, "fa-times": isOpen },
                        "fas",
                      )}
                    />
                  </Button>
                  <Collapse navbar>
                    <Nav className="ml-auto font-weight-bold" navbar>
                      <NavItem>
                        <NavLink tag={RLink} exact to="/">
                          <FormattedMessage {...messages.navbarDashboard} />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RLink} to="/applications">
                          <FormattedMessage {...messages.navbarApplications} />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RLink} to="/messages">
                          <FormattedMessage {...messages.navbarMessages} />
                          {unreadCount > 0 && (
                            <span className="messenger-badge">
                              {unreadCount}
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                      <UncontrolledDropdown nav>
                        <DropdownToggle nav caret>
                          <FormattedMessage {...messages.navbarMore} />
                        </DropdownToggle>
                        <DropdownMenu right className="bg-dark border-0">
                          <DropdownItem
                            href={links[locale].guide}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage {...messages.navbarHowto} />
                          </DropdownItem>
                          <DropdownItem
                            href={links[locale].support}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage {...messages.navbarSupport} />
                          </DropdownItem>
                          <DropdownItem
                            href={links[locale].FAQ}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            F.A.Q
                          </DropdownItem>
                          <DropdownItem
                            href={links[locale].privacy}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage {...messages.navbarPrivacy} />
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown nav>
                        <DropdownToggle nav className="p-0">
                          <Img
                            className="rounded-circle _lr-hide"
                            alt="User"
                            width="40"
                            height="40"
                            defaultImage={defaultLogo}
                            src={candidate && candidate.picture}
                          />
                        </DropdownToggle>
                        <DropdownMenu right className="bg-dark border-0">
                          <RLink
                            className="dropdown-item"
                            exact
                            to="/profile/personal"
                          >
                            <FormattedMessage {...messages.navbarProfile} />
                          </RLink>
                          <RLink className="dropdown-item" exact to="/account">
                            <FormattedMessage {...messages.navbarAccount} />
                          </RLink>
                          <DropdownItem divider />
                          <DropdownItem className="btn-link" onClick={logout}>
                            <FormattedMessage {...messages.navbarLogout} />
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Nav>
                  </Collapse>
                </Fragment>
              ) : (
                <Fragment>
                  <Nav className="ml-auto font-weight-bold flex-row" navbar>
                    <NavItem>
                      <Button
                        color="link"
                        className="nav-link"
                        onClick={logout}
                      >
                        <FormattedMessage {...messages.navbarLogout} />
                      </Button>
                    </NavItem>
                    <NavItem>
                      <Button
                        loading={updating}
                        disabled={updating}
                        className="nav-link"
                        color="link"
                        onClick={handleLocaleChange}
                      >
                        {localeText}
                      </Button>
                    </NavItem>
                  </Nav>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Col className="pl-0" />
              <Col className="text-center">{brand}</Col>
              <Col className="text-right pr-0">
                <Button
                  color="dark"
                  // className="nav-link d-inline-block text-muted"
                  loading={updating}
                  disabled={updating}
                  onClick={handleLocaleChange}
                >
                  {localeText}
                </Button>
              </Col>
            </Fragment>
          )}
        </Container>
      </Navbar>

      {/* Mobile Nav */}
      {isOpen && (
        <Nav
          vertical
          className="offcanvas-nav position-fixed h-100 py-3 d-md-none bg-white w-80"
        >
          <NavItem className="mb-3 pl-1">
            <Button
              color="link"
              className="text-dark font-weight-bold nav-link"
              onClick={close}
            >
              <i className="far fa-times pr-2" />
              <FormattedMessage {...messages.navbarMenu} />
            </Button>
          </NavItem>
          {isComplete && (
            <Fragment>
              <NavItem>
                <NavLink
                  tag={RLink}
                  className="text-dark font-weight-bold"
                  exact
                  to="/"
                >
                  <i className="far fa-home pr-2" />
                  <FormattedMessage {...messages.navbarDashboard} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  className="text-dark font-weight-bold"
                  to="/applications"
                >
                  <i className="fas fa-thumbs-up pr-2" />
                  <FormattedMessage {...messages.navbarApplications} />
                </NavLink>
              </NavItem>
              <NavItem className="mb-3">
                <NavLink
                  tag={RLink}
                  className="text-dark font-weight-bold"
                  to="/messages"
                >
                  <i className="fas fa-envelope pr-2" />
                  {unreadCount > 0 && `(${unreadCount}) `}
                  <FormattedMessage {...messages.navbarMessages} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  className="text-dark font-weight-bold"
                  exact
                  to="/profile/personal"
                >
                  <i className="fas fa-user pr-2" />
                  <FormattedMessage {...messages.navbarProfile} />
                </NavLink>
              </NavItem>
              <NavItem className="mb-3">
                <NavLink
                  tag={RLink}
                  className="text-dark font-weight-bold"
                  exact
                  to="/account"
                >
                  <i className="fas fa-cog pr-2" />
                  <FormattedMessage {...messages.navbarAccount} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="text-dark font-weight-bold"
                  href={links[locale].guide}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-question-circle pr-2" />
                  <FormattedMessage {...messages.navbarHowto} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="text-dark font-weight-bold"
                  href={links[locale].support}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-life-ring pr-2" />
                  <FormattedMessage {...messages.navbarSupport} />
                </NavLink>
              </NavItem>
            </Fragment>
          )}
          <NavItem className="mt-auto pl-1">
            <Button
              color="link"
              className="text-dark font-weight-bold nav-link"
              onClick={logout}
            >
              <i className="fas fa-sign-out-alt pr-2" />
              <FormattedMessage {...messages.navbarLogout} />
            </Button>
          </NavItem>
        </Nav>
      )}
    </div>
  )
}

HeaderComponent.propTypes = {
  candidate: PropTypes.object,
  isLogged: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  getLocale: PropTypes.func.isRequired,
  updateCandidateLocale: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number.isRequired,
}
HeaderComponent.defaultProps = {
  isLogged: false,
  isComplete: false,
  updating: false,
  unreadCount: 0,
}
const mapDispatchToProps = {
  logout,
  getLocale,
  updateCandidateLocale,
}

const mapStateToProps = function (state) {
  const candidate = candidateSelector(state)

  return {
    isLogged: isLoggedSelector(state),
    isComplete: isCompleteSelector(state),
    unreadCount: unreadCountSelector(state),
    candidate,
    updating: localeUpdatingSelector(state) || loadingSelector(state),
    locale: localeSelector(state),
  }
}

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderComponent)
