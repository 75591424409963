import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { ModalBody, ModalFooter } from "reactstrap"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Button } from "../common/button-component"
import { Textarea } from "../common/textarea-component"
import { useOpenClose } from "../../hooks/use-open-close"

const messages = defineMessages({
  confirmCoverNoteQuestion: { id: "app.cover.note.question" },
  confirmCoverNoteStatement: { id: "app.cover.note.statement" },
  addANote: { id: "app.cover.note.add" },
  apply: { id: "app.job.apply" },
  back: { id: "app.common.back" },
  characters: { id: "app.cover.counter" },
  placeholder: { id: "app.cover.note.placeholder" },
})

export const MotivationalNoteComponent = ({ handleSubmit, isApplying }) => {
  const [coverNoteText, setCoverNoteText] = useState("")
  const {
    value: isTextFieldOpen,
    open: openTextField,
    close: closeTextField,
  } = useOpenClose({ initialValue: false })
  const intl = useIntl()

  return (
    <Fragment>
      <ModalBody>
        <p>
          <FormattedMessage {...messages.confirmCoverNoteQuestion} />
        </p>
        <p>
          <FormattedMessage {...messages.confirmCoverNoteStatement} />
        </p>
        {isTextFieldOpen && (
          <Textarea
            meta={{}}
            minRows={6}
            maxRows={15}
            minLength={1}
            maxLength={2000}
            type="textarea"
            placeholder={intl.formatMessage(messages.placeholder)}
            input={{
              value: coverNoteText,
              onChange: e => setCoverNoteText(e.target.value),
            }}
            underInput={
              <Fragment>
                {`${2000 - coverNoteText.length} `}
                <FormattedMessage {...messages.characters} />
              </Fragment>
            }
            className="w-100 border-light p-2"
          />
        )}
      </ModalBody>
      <ModalFooter>
        {isTextFieldOpen ? (
          <Fragment>
            <Button color="light" onClick={closeTextField}>
              <FormattedMessage {...messages.back} />
            </Button>
            <Button
              color="primary"
              disabled={coverNoteText.length === 0 || isApplying}
              loading={isApplying}
              onClick={() => handleSubmit(coverNoteText)}
            >
              <FormattedMessage {...messages.apply} />
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button outline color="success" onClick={openTextField}>
              <FormattedMessage {...messages.addANote} />
            </Button>
            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={isApplying}
              loading={isApplying}
            >
              <FormattedMessage {...messages.apply} />
            </Button>
          </Fragment>
        )}
      </ModalFooter>
    </Fragment>
  )
}

MotivationalNoteComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isApplying: PropTypes.bool.isRequired,
}
