import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage } from "react-intl"
import { Form, Row, Col } from "reactstrap"

import { Button } from "../../common/button-component"
import { Input } from "../../common/input-component"

import styles from "./signup-form.module.scss"

const messages = defineMessages({
  firstName: { id: "app.common.name.first" },
  lastName: { id: "app.common.name.last" },
  email: { id: "app.common.email" },
  password: { id: "app.common.password" },
})

export const SignupFormComponent = ({
  invalid,
  error,
  pristine,
  submitting,
  handleSubmit,
  buttonLabel,
  underPassword,
  disabled,
  terms,
  newsletter,
}) => (
  <Form onSubmit={handleSubmit}>
    <Row>
      <Col lg="6">
        <Field
          component={Input}
          label={
            <span>
              <FormattedMessage {...messages.firstName} />
            </span>
          }
          className={styles.label}
          name="firstName"
          type="text"
          id="firstName"
        />
      </Col>

      <Col lg="6">
        <Field
          component={Input}
          label={
            <span>
              <FormattedMessage {...messages.lastName} />
            </span>
          }
          className={styles.label}
          name="lastName"
          type="text"
          id="lastName"
        />
      </Col>
    </Row>

    <Field
      component={Input}
      label={
        <span>
          <FormattedMessage {...messages.email} />
        </span>
      }
      className={styles.label}
      name="email"
      type="email"
      id="email"
    />

    <Field
      component={Input}
      label={
        <span>
          <FormattedMessage {...messages.password} />
        </span>
      }
      className={styles.label}
      name="password"
      type="password"
      id="password"
      underFeedback={underPassword}
    />

    {terms}

    {newsletter}

    <Button
      className={styles.submit}
      disabled={disabled || submitting || pristine || (invalid && !error)}
      loading={submitting}
      block
      color="secondary"
      type="submit"
      size="lg"
    >
      {buttonLabel}
    </Button>
  </Form>
)

SignupFormComponent.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.node,
  underPassword: PropTypes.node,
  terms: PropTypes.node,
  newsletter: PropTypes.node,
}

SignupFormComponent.defaultProps = {
  disabled: false,
}

export const SignupForm = reduxForm({
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  },
})(SignupFormComponent)
