import { useState, useRef } from "react"

export const useTemporaryState = (initialValue, timeout) => {
  const [value, setValue] = useState(initialValue)

  const timeoutIdRef = useRef()

  const set = newValue => {
    setValue(newValue)
    if (timeoutIdRef) {
      clearTimeout(timeoutIdRef.current)
    }
    timeoutIdRef.current = setTimeout(() => {
      timeoutIdRef.current = null
      setValue(initialValue)
    }, timeout)
  }

  return [value, set]
}
