import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { Select } from "../../../../common/select-component"
import { useInstitutions } from "../../../../../hooks/use-institutions"

export const InstitutionInputs = ({ className, item, changeProp }) => {
  const { loadInstitutions } = useInstitutions()

  return (
    <div className={className}>
      <Select
        id="education-institutions"
        async
        creatable
        input={{
          onChange: value =>
            changeProp(
              {
                id: value.id || null,
                name: value.name || value.value,
              },
              item.id,
              "institution",
            ),
          value: item.institution || null,
        }}
        wrapper="div"
        meta={{}}
        loadOptions={loadInstitutions}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name || option.label}
        placeholder={
          <FormattedMessage id="app.registration.tabs.professional.input.education.placeholder.institution" />
        }
        noOptionsMessage={() => (
          <FormattedMessage id="app.common.input.placeholder.noResults" />
        )}
        formatCreateLabel={inputValue => inputValue}
        backspaceRemovesValue={false}
      />
    </div>
  )
}

InstitutionInputs.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  changeProp: PropTypes.func.isRequired,
}
