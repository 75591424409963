import { useLocation } from "react-router"
import { updateQueryParams } from "../lib/helpers"
import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import { identity } from "ramda"
import qs from "qs"

export const useFilter = ({
  filterOptions,
  filterKey,
  filterName,
  getValue = identity,
  getTitle = identity,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const filters = filterOptions.map(opt => {
    const value = opt && getValue(opt)
    return {
      value,
      title: getTitle(opt),
      key: filterKey,
      name: filterName,
      to: {
        pathname: location.pathname,
        search: updateQueryParams({
          search: location.search,
          params: { [filterKey]: value },
        }),
      },
    }
  })

  const applyFilter = filter => {
    dispatch(
      push(
        filter?.to ?? {
          pathname: location.pathname,
          search: updateQueryParams({
            search: location.search,
            params: { [filterKey]: null },
          }),
        },
      ),
    )
  }
  const activeFilter =
    filters.find(filter => params[filter.key] === String(filter.value)) ?? null
  return {
    activeFilter,
    filters,
    applyFilter,
  }
}
