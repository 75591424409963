import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { authRequired } from "../decorators/auth-required"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { config } from "../config"
import {
  getBotConnectionList,
  createBotConnectionCode,
} from "../actions/bot-actions"
import {
  codeSelector,
  connectionsSelector,
  creatingSelector,
  errSelector,
  loadingSelector,
} from "../selectors/bot-selector"

export class ConnectMoberriesBot extends Component {
  static propTypes = {
    err: PropTypes.object,
    code: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    creating: PropTypes.bool.isRequired,
    connections: PropTypes.array.isRequired,
    getBotConnectionList: PropTypes.func.isRequired,
    createBotConnectionCode: PropTypes.func.isRequired,
  }

  static defaultProps = {
    connections: [],
  }

  componentDidUpdate(prevProps) {
    const { connections } = this.props
    let url = `${config.fbChatBot.baseShortLinkUrl}/${config.fbChatBot.name}`

    if (prevProps.loading && !this.props.loading && !this.props.err) {
      if (connections.some(c => c.provider === "facebook")) {
        window.location.assign(url)
      } else {
        this.props.createBotConnectionCode()
      }
    }

    if (prevProps.creating && !this.props.creating && this.props.code) {
      window.location.assign(`${url}?ref=${this.props.code}`)
    }
  }

  componentDidMount() {
    this.props.getBotConnectionList()
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { getBotConnectionList, createBotConnectionCode }

const mapStateToProps = function (state) {
  return {
    err: errSelector(state),
    code: codeSelector(state),
    loading: loadingSelector(state),
    creating: creatingSelector(state),
    connections: connectionsSelector(state),
  }
}

export const ConnectMoberriesBotPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(ConnectMoberriesBot)
