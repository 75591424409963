/* eslint-disable */
import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

const wavyPartLength = 500
const singleWaveLength = 30

const durationMS = 17000
const frameTime = 1000 / 60
const frameCount = durationMS / frameTime
const delta = wavyPartLength / frameCount
let direction = 1
let offsetX = 0

export const LoaderLogo = ({ className }) => {
  const waveOpaque = useRef(null)
  const waveTransparent = useRef(null)

  const generateWavyPart = () => {
    let wavyPart = ""
    let waveCount = wavyPartLength / singleWaveLength

    for (let i = 0; i < waveCount; i++) {
      wavyPart += "t-30 0"
    }

    return wavyPart
  }

  const generatePathsFromOffsets = (offsetX = 0, offsetY = 0) => {
    return `M-${wavyPartLength - 120 - offsetX} 180 l${wavyPartLength} 0l0 -${
      120 + offsetY
    }q-15 7, -30 0${generateWavyPart()}z`
  }

  const frame = () => {
    let newOffset = offsetX + delta * direction
    offsetX = newOffset

    if (
      offsetX >= wavyPartLength - 130 ||
      (direction === -1 && offsetX <= delta * 2 + 5)
    ) {
      direction *= -1
    }
    waveOpaque.current?.setAttribute("d", generatePathsFromOffsets(newOffset))
    waveTransparent.current?.setAttribute(
      "d",
      generatePathsFromOffsets(newOffset * 0.5 + 5, 2),
    )
    window.requestAnimationFrame(frame)
  }

  useEffect(() => {
    frame()
  }, [frame])

  return (
    <svg className={className} width="120" height="120" fill="#f0f0f0">
      <path d="M81.2131 64.1233C81.2131 74.5195 74.246 83.2827 64.2581 85.215C57.3999 86.5485 51.4398 84.453 46.5411 79.6359C37.288 70.5461 38.1316 55.0335 48.2556 46.9234C58.8423 38.4595 74.4365 42.7867 79.6346 55.6594C80.712 58.3496 81.2484 61.2257 81.2131 64.1233Z" />
      <path d="M32.7156 84.9701C32.7428 79.9625 34.1852 76.2341 37.0973 73.0499C38.2131 71.8252 38.2675 71.8252 38.8935 73.3493C42.1048 81.0239 47.6839 86.0587 55.9573 88.0182C57.7182 88.3937 59.5203 88.54 61.3187 88.4537C62.9516 88.4264 62.9516 88.4264 62.3801 90.0049C60.094 96.2372 54.27 100.428 47.9561 100.374C41.5333 100.319 35.6821 95.9106 33.6137 89.5967C32.9878 87.8277 32.6612 86.0587 32.7156 84.9701Z" />
      <path d="M91.1194 28.6621C90.7383 36.4184 87.1732 42.9772 79.934 47.5493C79.0359 48.1208 78.4371 48.2297 77.6479 47.3044C76.0315 45.4143 74.0744 43.8449 71.8783 42.6778C71.2524 42.324 71.1163 42.0247 71.4701 41.3443C74.1644 36.1734 76.0966 30.676 77.9473 25.1513C79.6074 20.3615 81.6757 15.7621 85.2137 11.9792C85.5131 11.6798 85.8396 11.3805 86.139 11.0539C86.6017 10.564 86.9282 10.6185 87.146 11.2444C89.2143 16.7146 90.9833 22.1849 91.1194 28.6621Z" />
      <path d="M85.9755 10.5368C85.7795 10.7663 85.5705 10.9844 85.3495 11.19C81.8932 13.9387 79.4983 17.4494 77.6477 21.3412C75.416 25.9678 73.4566 30.7032 70.9528 35.2481C70.0912 36.8417 69.1276 38.378 68.068 39.8475C67.6325 40.4462 67.2243 40.6639 66.4351 40.4462C63.9857 39.793 61.4547 39.4664 58.8965 39.793C57.944 39.9291 57.7807 39.4392 57.6446 38.6228C56.1206 28.4171 61.8085 17.6672 73.13 13.5577C76.9673 12.1697 80.9135 11.2716 84.8869 10.5368C85.2492 10.5086 85.6132 10.5086 85.9755 10.5368Z" />
      <path d="M23 63.8239C23.1905 57.7005 25.4493 52.2847 30.7563 48.3113C32.8518 46.7601 35.2468 45.8892 37.8322 45.481C38.1489 45.4101 38.4787 45.423 38.7889 45.5185C39.0991 45.6139 39.3791 45.7886 39.6012 46.0253C40.4721 46.8689 41.9417 47.4949 42.1866 48.4746C42.4315 49.4544 41.0436 50.4613 40.4448 51.4955C37.6026 56.4341 36.5185 62.1904 37.3695 67.8245C37.4279 68.112 37.3996 68.4103 37.2881 68.6817C37.1767 68.953 36.9872 69.1851 36.7436 69.3486C33.8907 71.6493 31.7874 74.7473 30.7018 78.2479C30.2664 79.527 30.1848 79.5815 29.1506 78.5745C24.9867 74.6283 23.1088 69.7568 23 63.8239Z" />
      <path d="M96.9977 65.4024C96.8072 71.7163 94.8477 77.241 89.7041 81.3232C87.418 83.1466 87.3636 83.0922 86.4655 80.4523C85.7538 78.2672 84.5638 76.2682 82.9819 74.6011C82.4649 74.0568 82.356 73.5941 82.6281 72.8866C85.2408 65.5113 84.6965 58.4081 80.8592 51.5499C80.4782 50.8423 80.587 50.543 81.2402 50.1347C82.3951 49.4451 83.5041 48.6815 84.5604 47.8487C84.8104 47.6111 85.1281 47.4572 85.4695 47.4084C85.8109 47.3596 86.159 47.4184 86.4655 47.5765C91.6908 49.8898 94.6028 54.0537 96.1813 59.279C96.7532 61.2689 97.0283 63.3322 96.9977 65.4024Z" />
      <path d="M84.3426 86.9568C84.3426 92.4542 79.961 97.8428 73.6199 98.9858C69.837 99.6662 66.4623 98.5504 63.5503 96.0738C63.0332 95.6384 62.9244 95.2574 63.3054 94.6586C64.4853 92.8483 65.3246 90.8377 65.782 88.7258C65.8227 88.4488 65.9498 88.1917 66.1453 87.9913C66.3408 87.7909 66.5947 87.6574 66.8706 87.6099C72.6129 85.9226 76.9946 82.5207 80.206 77.5676C80.7503 76.7511 81.0496 76.8055 81.5939 77.5131C83.4173 80.0169 84.3971 82.7657 84.3426 86.9568Z" />
      <path d="M42.9759 17.0411C42.8942 21.8582 44.9626 25.886 47.2486 29.8322C49.1265 33.0436 50.9771 36.2822 52.2834 39.7657C52.6372 40.7182 52.5283 41.2081 51.4942 41.6163C49.232 42.54 47.157 43.868 45.3708 45.5353C44.7176 46.134 44.255 46.0524 43.6018 45.5625C37.3423 41.0176 34.9746 33.6423 37.5056 26.4031C38.7031 22.974 40.6626 19.9259 42.9759 17.0411Z" />
      <path d="M60.0942 109.763C56.2568 109.763 52.8822 107.804 51.7663 104.783C51.0588 102.905 51.3581 102.959 52.8549 102.551C55.6088 101.821 58.1294 100.397 60.1758 98.4145C60.7745 97.8429 61.1555 97.8157 61.7815 98.3872C63.4804 99.905 65.5276 100.98 67.7416 101.517C68.6941 101.735 68.9118 102.225 68.8574 103.068C68.6941 105.055 67.7416 106.579 66.1903 107.776C64.4608 109.146 62.2983 109.85 60.0942 109.763Z" />
      <path d="M54.3519 38.4321C51.9841 33.9144 48.8816 30.0499 46.269 25.886C44.7145 23.4841 43.7022 20.7722 43.3025 17.9392C43.2481 17.5309 43.0304 17.0139 43.4658 16.7417C43.8468 16.524 44.2006 16.8778 44.5544 17.0411C48.5006 18.9461 52.3379 20.96 55.2772 24.2803C56.0936 25.1784 56.2841 25.9948 55.8215 27.1651C54.6512 30.186 54.4335 33.3701 54.5696 36.5815C54.5968 37.0714 54.5968 37.534 54.5968 37.9967C54.6512 38.0783 54.5424 38.16 54.3519 38.4321Z" />

      <clipPath id="berry">
        <path d="M81.2131 64.1233C81.2131 74.5195 74.246 83.2827 64.2581 85.215C57.3999 86.5485 51.4398 84.453 46.5411 79.6359C37.288 70.5461 38.1316 55.0335 48.2556 46.9234C58.8423 38.4595 74.4365 42.7867 79.6346 55.6594C80.712 58.3496 81.2484 61.2257 81.2131 64.1233Z" />
        <path d="M32.7156 84.9701C32.7428 79.9625 34.1852 76.2341 37.0973 73.0499C38.2131 71.8252 38.2675 71.8252 38.8935 73.3493C42.1048 81.0239 47.6839 86.0587 55.9573 88.0182C57.7182 88.3937 59.5203 88.54 61.3187 88.4537C62.9516 88.4264 62.9516 88.4264 62.3801 90.0049C60.094 96.2372 54.27 100.428 47.9561 100.374C41.5333 100.319 35.6821 95.9106 33.6137 89.5967C32.9878 87.8277 32.6612 86.0587 32.7156 84.9701Z" />
        <path d="M91.1194 28.6621C90.7383 36.4184 87.1732 42.9772 79.934 47.5493C79.0359 48.1208 78.4371 48.2297 77.6479 47.3044C76.0315 45.4143 74.0744 43.8449 71.8783 42.6778C71.2524 42.324 71.1163 42.0247 71.4701 41.3443C74.1644 36.1734 76.0966 30.676 77.9473 25.1513C79.6074 20.3615 81.6757 15.7621 85.2137 11.9792C85.5131 11.6798 85.8396 11.3805 86.139 11.0539C86.6017 10.564 86.9282 10.6185 87.146 11.2444C89.2143 16.7146 90.9833 22.1849 91.1194 28.6621Z" />
        <path d="M85.9755 10.5368C85.7795 10.7663 85.5705 10.9844 85.3495 11.19C81.8932 13.9387 79.4983 17.4494 77.6477 21.3412C75.416 25.9678 73.4566 30.7032 70.9528 35.2481C70.0912 36.8417 69.1276 38.378 68.068 39.8475C67.6325 40.4462 67.2243 40.6639 66.4351 40.4462C63.9857 39.793 61.4547 39.4664 58.8965 39.793C57.944 39.9291 57.7807 39.4392 57.6446 38.6228C56.1206 28.4171 61.8085 17.6672 73.13 13.5577C76.9673 12.1697 80.9135 11.2716 84.8869 10.5368C85.2492 10.5086 85.6132 10.5086 85.9755 10.5368Z" />
        <path d="M23 63.8239C23.1905 57.7005 25.4493 52.2847 30.7563 48.3113C32.8518 46.7601 35.2468 45.8892 37.8322 45.481C38.1489 45.4101 38.4787 45.423 38.7889 45.5185C39.0991 45.6139 39.3791 45.7886 39.6012 46.0253C40.4721 46.8689 41.9417 47.4949 42.1866 48.4746C42.4315 49.4544 41.0436 50.4613 40.4448 51.4955C37.6026 56.4341 36.5185 62.1904 37.3695 67.8245C37.4279 68.112 37.3996 68.4103 37.2881 68.6817C37.1767 68.953 36.9872 69.1851 36.7436 69.3486C33.8907 71.6493 31.7874 74.7473 30.7018 78.2479C30.2664 79.527 30.1848 79.5815 29.1506 78.5745C24.9867 74.6283 23.1088 69.7568 23 63.8239Z" />
        <path d="M96.9977 65.4024C96.8072 71.7163 94.8477 77.241 89.7041 81.3232C87.418 83.1466 87.3636 83.0922 86.4655 80.4523C85.7538 78.2672 84.5638 76.2682 82.9819 74.6011C82.4649 74.0568 82.356 73.5941 82.6281 72.8866C85.2408 65.5113 84.6965 58.4081 80.8592 51.5499C80.4782 50.8423 80.587 50.543 81.2402 50.1347C82.3951 49.4451 83.5041 48.6815 84.5604 47.8487C84.8104 47.6111 85.1281 47.4572 85.4695 47.4084C85.8109 47.3596 86.159 47.4184 86.4655 47.5765C91.6908 49.8898 94.6028 54.0537 96.1813 59.279C96.7532 61.2689 97.0283 63.3322 96.9977 65.4024Z" />
        <path d="M84.3426 86.9568C84.3426 92.4542 79.961 97.8428 73.6199 98.9858C69.837 99.6662 66.4623 98.5504 63.5503 96.0738C63.0332 95.6384 62.9244 95.2574 63.3054 94.6586C64.4853 92.8483 65.3246 90.8377 65.782 88.7258C65.8227 88.4488 65.9498 88.1917 66.1453 87.9913C66.3408 87.7909 66.5947 87.6574 66.8706 87.6099C72.6129 85.9226 76.9946 82.5207 80.206 77.5676C80.7503 76.7511 81.0496 76.8055 81.5939 77.5131C83.4173 80.0169 84.3971 82.7657 84.3426 86.9568Z" />
        <path d="M42.9759 17.0411C42.8942 21.8582 44.9626 25.886 47.2486 29.8322C49.1265 33.0436 50.9771 36.2822 52.2834 39.7657C52.6372 40.7182 52.5283 41.2081 51.4942 41.6163C49.232 42.54 47.157 43.868 45.3708 45.5353C44.7176 46.134 44.255 46.0524 43.6018 45.5625C37.3423 41.0176 34.9746 33.6423 37.5056 26.4031C38.7031 22.974 40.6626 19.9259 42.9759 17.0411Z" />
        <path d="M60.0942 109.763C56.2568 109.763 52.8822 107.804 51.7663 104.783C51.0588 102.905 51.3581 102.959 52.8549 102.551C55.6088 101.821 58.1294 100.397 60.1758 98.4145C60.7745 97.8429 61.1555 97.8157 61.7815 98.3872C63.4804 99.905 65.5276 100.98 67.7416 101.517C68.6941 101.735 68.9118 102.225 68.8574 103.068C68.6941 105.055 67.7416 106.579 66.1903 107.776C64.4608 109.146 62.2983 109.85 60.0942 109.763Z" />
        <path d="M54.3519 38.4321C51.9841 33.9144 48.8816 30.0499 46.269 25.886C44.7145 23.4841 43.7022 20.7722 43.3025 17.9392C43.2481 17.5309 43.0304 17.0139 43.4658 16.7417C43.8468 16.524 44.2006 16.8778 44.5544 17.0411C48.5006 18.9461 52.3379 20.96 55.2772 24.2803C56.0936 25.1784 56.2841 25.9948 55.8215 27.1651C54.6512 30.186 54.4335 33.3701 54.5696 36.5815C54.5968 37.0714 54.5968 37.534 54.5968 37.9967C54.6512 38.0783 54.5424 38.16 54.3519 38.4321Z" />
      </clipPath>
      <path
        fill="#DD1843"
        clipPath="url(#berry)"
        ref={waveTransparent}
        d="M-120 180 l240 0l0 -150q-15 7, -30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0z"
      ></path>
      <path
        fill="#DD1843"
        clipPath="url(#berry)"
        ref={waveOpaque}
        d="M-120 180 l240 0l0 -150q-15 7, -30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0t-30 0z"
      ></path>
    </svg>
  )
}

LoaderLogo.propTypes = {
  className: PropTypes.string,
}
