import { getActionType } from "../lib/helpers"
import { GET, STATUS, SURVEY, CLOSE, REQUEST } from "../constants"

export function getSurveyStatus({ candidateId }) {
  return {
    type: getActionType(GET, SURVEY, STATUS, REQUEST),
    payload: { candidateId },
  }
}

export function closeSurvey() {
  return {
    type: getActionType(CLOSE, SURVEY),
    payload: { closingTimestamp: new Date().toISOString() },
  }
}
