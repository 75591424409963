import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { BaseLayout } from "../layouts/base-layout"
import { SmallSingleColumnLayout } from "../layouts/small-single-column-layout"
import defaultImage from "../../assets/img/default-state.svg"

const messages = defineMessages({
  header: { id: "app.page.messages.threads.empty.header" },
  subheader: { id: "app.page.messages.threads.empty.subheader" },
})

export function MessengerPageEmpty() {
  return (
    <BaseLayout className="py-5">
      <SmallSingleColumnLayout>
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
        <p className="text-muted">
          <FormattedMessage {...messages.subheader} />
        </p>
        <div className="p-3 text-center">
          <img
            alt="Empty"
            width={600}
            className="img-fluid"
            src={defaultImage}
          />
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}
