import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"

export const useStudyFields = () => {
  const [studyFields, setStudyFields] = useState([])

  const loadStudyFields = useCallback(
    debounce(async search => {
      const res = await moApi.getStudyFields({ params: { search, limit: 5 } })
      const values = pathOr([], ["data", "results"], res)
      setStudyFields(values)

      return values
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [],
  )

  return { studyFields, loadStudyFields }
}
