import nanoid from "nanoid"
import { config } from "../../config"
import cn from "classnames"
import {
  curry,
  always,
  map,
  head,
  reduce,
  set,
  lensProp,
  fromPairs,
  toPairs,
  adjust,
  uniq,
  concat,
  without,
  filter,
  values,
} from "ramda"
import { LOCALES } from "../../constants"

export function stringifyListParams(parameterNames, params = {}) {
  const paramsCopy = { ...params }
  parameterNames.forEach(paramName => {
    if (paramsCopy[paramName]) {
      paramsCopy[paramName] = paramsCopy[paramName].join(",")
    }
  })

  return paramsCopy
}

export function pageToOffset(page, limit) {
  return limit * (page - 1)
}

export function ensureLocale(locale) {
  if (!locale || !LOCALES || !LOCALES.includes || !LOCALES.includes(locale)) {
    return "en"
  }

  return locale
}

export async function getMessages(locale) {
  const messages = await import(
    /* webpackChunkName: "locale/[request]" */ `../../locales/${locale}.json`
  )
  return messages
}

export const isMessageFromManager = message => {
  if (!message.authorData) {
    return false
  }
  return message.authorData.type === "manager"
}

export function formatUnreadCount(unreadCount) {
  return unreadCount > 10 ? "10+" : unreadCount
}

export function createAlert(opts) {
  return {
    dismissAfter: 5000,
    id: nanoid(),
    color: "success",
    hideOnRouteChange: true,
    ...opts,
  }
}

// ramda stuff
export const arrayToObject = curry((array, idProp) =>
  reduce((prev, curr) => set(lensProp(curr[idProp]), curr, prev), {}, array),
)

export const N = always(null)

export function stopPropagation(fn) {
  return e => {
    e.stopPropagation()
    return fn(e)
  }
}

export function preventDefault(fn) {
  return e => {
    e.preventDefault()
    return fn(e)
  }
}

export const PopupCenter = (url, title, w, h) => {
  // Fixes dual-screen position
  const dualScreenLeft =
    typeof window.screenLeft !== "undefined"
      ? window.screenLeft
      : window.screen.left

  const dualScreenTop =
    typeof window.screenTop !== "undefined"
      ? window.screenTop
      : window.screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left,
  )

  // Puts focus on the newWindow
  if (newWindow?.focus) {
    newWindow.focus()
  }
}

export function randomInteger(min, max) {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1))
}

export function sample(array) {
  if (!array || array.length === 0) return null
  if (array.length === 1) return head(array)

  return array[randomInteger(0, array.length - 1)]
}

export function getArrowClassName(isOpen) {
  return cn(
    {
      "fa-angle-down": !isOpen,
      "fa-angle-up": isOpen,
    },
    "far",
  )
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onabort = error => {
      reject(error)
    }

    reader.onerror = error => {
      reject(error)
    }
  })
}

export function shouldInitTracker(isInitialized) {
  const { navigator = {} } = window
  const dntEnabled = navigator.doNotTrack === "1"

  return (
    !isInitialized &&
    !dntEnabled &&
    config.branch.isRelease &&
    config.env.isProd
  )
}

export const hasMore = (totalCount, lastFetchedPage, itemsPerPage) =>
  lastFetchedPage < Math.ceil(totalCount / itemsPerPage)

export const convertStringToInteger = (string = "", base = 10) => {
  return (
    string
      .split("")
      .map(c => c.charCodeAt(0))
      .reduce((acc, n) => acc + n, 0) % base
  )
}

export const isGreaterThanZero = num => Math.abs(num) > 0

export const shareJobOnLinkedin = ({ jobId }) => {
  PopupCenter(
    `https://www.linkedin.com/shareArticle?mini=true&url=${config.moberries.catalog.url}/job/${jobId}`,
    "Share in Linkedin",
    400,
    500,
  )
}

export const shareJobOnFacebook = ({ jobId }) => {
  PopupCenter(
    `https://www.facebook.com/sharer/sharer.php?u=${config.moberries.catalog.url}/job/${jobId}`,
    "Share in Facebook",
    400,
    500,
  )
}

export const shareJobOnTwitter = ({ jobId, jobTitle, companyName }) => {
  PopupCenter(
    `http://twitter.com/share?text=${jobTitle} ${companyName}&url=${config.moberries.catalog.url}/job/${jobId}&hashtags=moberries`,
    "Share in Twitter",
    400,
    500,
  )
}

export const mapKeys = curry((fn, obj) =>
  fromPairs(map(adjust(0, fn), toPairs(obj))),
)

export const getBrowserLanguage = () => {
  const lang = window.navigator.language
  if (lang.includes("de")) {
    return "de"
  }
  return "en"
}

export const noneOrEmpty = array => {
  return !array || !array.length
}

export const transformDate = stringDate =>
  stringDate
    ? stringDate.replace(/-/g, ".").split(".").reverse().join(".")
    : stringDate

export const capitalize = str =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : ""

export const getMissingPositionIds = ({
  experience = [],
  certificates = [],
  positions = [],
}) => {
  const existingIds = uniq(
    concat(
      map(we => we.id, experience),
      map(c => c.roleId, certificates),
    ),
  )
  const preferredIds = filter(
    v => v > 0,
    map(v => v.id, positions),
  )

  return without(existingIds, preferredIds)
}

export const xorObjectValues = obj => {
  const objValues = values(obj)
  const empties = objValues.filter(v => !v)
  return !!empties.length && empties.length !== objValues.length
}
