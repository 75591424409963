import React from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/react"
import { connect } from "react-redux"
import { ToastContainer, Slide } from "react-toastify"
import { Routes } from "./routes"
import { loadedSelector as localeLoadedSelector } from "./selectors/intl-selector"
import { ScrollToTop } from "./components/common/scroll-to-top"
import { releaseSelector } from "./selectors/system-selector"
import { ReleasePage } from "./pages/release-page"
import { Head } from "./components/common/head-component"
import { CookieConsent } from "./components/common/cookie-consent-component"
import { InactiveMatchingReminder } from "./components/common/inactive-matching-reminder-component"
import { TermsHandler } from "./components/terms/terms-handler"
import { InitialJobApplicationModal } from "./components/job/initial-job-application-modal-component"
import { VersionUpdatePrompt } from "./components/modals/version-update-prompt-component"
import { TypeFormWidget } from "./components/forms/typeform-widget"
import { ErrorPage } from "./pages/error-page"
import { QueryErrorHandler } from "./components/common/query-error-handler"
import { Hotjar } from "./components/common/hotjar/hotjar"
import { ProductUpdates } from "./components/common/product-updates-component"

function AppComponent({ loading, relese }) {
  if (relese) {
    return <ReleasePage />
  }

  if (loading) {
    return null
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <ErrorPage err={error} />
      }}
    >
      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        hideProgressBar={true}
        transition={Slide}
      />
      <Head />
      <Hotjar />
      <InactiveMatchingReminder />
      <ProductUpdates />
      <ScrollToTop />
      <Routes />
      <CookieConsent />
      <TermsHandler />
      <InitialJobApplicationModal />
      <VersionUpdatePrompt />
      <TypeFormWidget />
      <QueryErrorHandler />
    </Sentry.ErrorBoundary>
  )
}

AppComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  relese: PropTypes.bool.isRequired,
}

const mapDispatchToProps = {}

const mapStateToProps = state => {
  return {
    relese: releaseSelector(state),
    loading: !localeLoadedSelector(state),
  }
}

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent)
