import React from "react"
import PropTypes from "prop-types"
import { FormattedRelativeTime } from "react-intl"
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
} from "date-fns"
import { isGreaterThanZero } from "../../lib/helpers"

const getRelativeTimeProps = date => {
  const now = new Date()
  const days = differenceInDays(date, now)
  if (isGreaterThanZero(days)) {
    return {
      value: days,
      unit: "day",
      numeric: "always",
      updateIntevalInSeconds: 0,
    }
  }

  const hours = differenceInHours(date, now)
  if (isGreaterThanZero(hours)) {
    return {
      value: hours,
      unit: "hour",
      numeric: "always",
      updateIntevalInSeconds: 0,
    }
  }

  const minutes = differenceInMinutes(date, now)
  if (isGreaterThanZero(minutes)) {
    return {
      value: minutes,
      unit: "minute",
      numeric: "always",
      updateIntevalInSeconds: 10,
    }
  }

  const seconds = differenceInSeconds(date, now)
  return {
    value: seconds,
    unit: "second",
    numeric: "auto",
    updateIntevalInSeconds: 10,
  }
}

export const FormattedRelative = ({ timestamp }) => {
  const date = parseISO(timestamp)

  return <FormattedRelativeTime {...getRelativeTimeProps(date)} />
}

FormattedRelative.propTypes = {
  timestamp: PropTypes.string.isRequired,
}
