import { createSelector } from "reselect"
import { values } from "ramda"

export const stateSelector = state => state.companies

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const loadedSelector = cs(state => state.loaded)
export const errorSelector = cs(state => state.err)
export const entitiesSelector = cs(state => state.entities)
export const countSelector = cs(state => state.count)

export const companySelector = cs(
  entitiesSelector,
  (state, { id }) => id,
  (_, entities, id) => entities[id],
)

export const companyJobListSelector = cs(
  entitiesSelector,
  (state, { id }) => id,
  (_, entities, id) => entities[id],
)

export const companyBySlugSelector = cs(
  entitiesSelector,
  (state, { slug }) => slug,
  (_, entities, slug) => {
    let id

    values(entities).forEach(c => {
      if (c.slug === slug) {
        id = c.id
      }
    })

    return entities[id]
  },
)

export const companiesSelector = cs(entitiesSelector, (_, entities, ids) =>
  ids.map(id => entities[id]),
)
