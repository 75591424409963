import React from "react"
import PropTypes from "prop-types"
import { ModalHeader } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { useApplicationQuestions } from "../../hooks/use-application-questions"

const messages = defineMessages({
  questionsTitle: { id: "app.job.questions.title" },
  confirmCoverNoteTitle: { id: "app.cover.note.title" },
})
export const JobApplicationModalHeader = ({
  onClose,
  jobId,
  step,
  hasQuestions,
}) => {
  const { applicationQuestions } = useApplicationQuestions({ jobId })

  return (
    <ModalHeader toggle={onClose}>
      {hasQuestions && step < applicationQuestions?.length && (
        <FormattedMessage {...messages.questionsTitle} />
      )}
      {(!hasQuestions || step === applicationQuestions?.length) && (
        <FormattedMessage {...messages.confirmCoverNoteTitle} />
      )}
    </ModalHeader>
  )
}

JobApplicationModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  hasQuestions: PropTypes.bool.isRequired,
}
