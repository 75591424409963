import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isCompleteSelector } from "../../selectors/candidate-selector"
import { JobApplicationLoggedControls } from "./job-application-logged-controls-component"
import { JobApplicationNonLoggedControls } from "./job-application-non-logged-controls-component"

export function JobApplicationControlsComponent(props) {
  return props.isComplete ? (
    <JobApplicationLoggedControls {...props} />
  ) : (
    <JobApplicationNonLoggedControls {...props} />
  )
}

JobApplicationControlsComponent.propTypes = {
  isComplete: PropTypes.bool.isRequired,
}

JobApplicationControlsComponent.defaultProps = {
  isComplete: false,
}

const mapStateToProps = state => ({
  isComplete: isCompleteSelector(state),
})

export const JobApplicationControls = connect(mapStateToProps)(
  JobApplicationControlsComponent,
)
