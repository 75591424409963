import { getActionType, N } from "../lib/helpers"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  inc,
  assoc,
  reject,
  omit,
  over,
  lensProp,
} from "ramda"
import {
  GET,
  SUCCESS,
  CANDIDATE,
  FAIL,
  LOGOUT,
  START,
  LOGIN,
  CREATE,
  CONFIRM,
  EMAIL,
  CONNECT,
  FACEBOOK,
  LINKEDIN,
  UPDATE,
  ACTIVATE,
  ACTIVATION,
  SETTINGS,
  DEACTIVATE,
  MATCHING,
  LOCALE,
  ACCEPT,
  MATCH,
  JOB,
  DISCARD,
  UPLOAD,
  CV,
  INITIAL,
  TRACKING,
  SET,
  ID,
  TERMS,
  DATA,
  EXPORT,
  DISCONNECT,
  REQUEST,
  CANCELLED,
  APPLICATION,
  PREDICTION,
  APPLY,
  NEWSLETTER,
  ON,
  ACTIVE,
  INITIATE,
  SUBSCRIPTION,
  CANCEL,
  CLOSE,
  MODAL,
} from "../constants"

export const defaultState = {
  entity: null,
  creating: false,
  updating: false,
  cvType: null,
  cvUpdating: false,
  cvCreating: false,
  localeUpdating: false,
  termsUpdating: false,
  isEmailOnMatchUpdating: false,
  isNewsletterUpdating: false,
  isCancelingMessageSubscription: false,
  trackingActivating: false,
  trackingDeactivating: false,
  matchingActivating: false,
  matchingDeactivating: false,
  matchingDeactivatingErr: null,
  loading: false,
  loaded: false,
  updatingErr: null,
  applicationId: null,
  termsUpdatingErr: null,
  cvUpdatingErr: null,
  cvCreatingErr: null,
  err: null,
  creatingErr: null,
  exporting: false,
  matchingActivationRequestCancelled: false,
  prediction: null,
  isLoadingPrediction: false,
  isUpdatingTracking: false,
  trackingUpdatingErr: null,
  isRequestingForSubscriptionVerification: false,
  messageProviderVerification: null,
  haveJustFinishedRegistration: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, CANDIDATE, START):
      return evolve({ loading: T }, state)

    case getActionType(CONFIRM, EMAIL, SUCCESS):
    case getActionType(LOGIN, SUCCESS):
    case getActionType(GET, CANDIDATE, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: N,
          entity: always(payload.candidate),
        },
        state,
      )

    case getActionType(CONNECT, LINKEDIN, SUCCESS):
    case getActionType(CONNECT, FACEBOOK, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: N,
          entity: always(payload.candidate),
        },
        state,
      )

    case getActionType(DISCONNECT, FACEBOOK, SUCCESS):
      return evolve(
        {
          entity: {
            socialAccounts: reject(a => a.platform === "facebook"),
          },
        },
        state,
      )

    case getActionType(DISCONNECT, LINKEDIN, SUCCESS):
      return evolve(
        {
          entity: {
            socialAccounts: reject(a => a.platform === "linkedin"),
          },
        },
        state,
      )

    case getActionType(GET, CANDIDATE, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(EXPORT, CANDIDATE, DATA, START):
      return evolve({ exporting: T }, state)

    case getActionType(EXPORT, CANDIDATE, DATA, SUCCESS):
    case getActionType(EXPORT, CANDIDATE, DATA, FAIL):
      return evolve({ exporting: F }, state)

    case getActionType(CREATE, CANDIDATE, START):
      return evolve({ creating: T }, state)

    case getActionType(CREATE, CANDIDATE, SUCCESS):
      return evolve({ creating: F, creatingErr: N }, state)

    case getActionType(CREATE, CANDIDATE, FAIL):
      return evolve({ creating: F, creatingErr: always(payload.err) }, state)

    case getActionType(UPDATE, CANDIDATE, START):
    case getActionType(ACTIVATE, CANDIDATE, INITIAL, START):
      return evolve({ updating: T }, state)

    case getActionType(UPDATE, CANDIDATE, SUCCESS):
    case getActionType(ACTIVATE, CANDIDATE, INITIAL, SUCCESS):
      return evolve(
        {
          updating: F,
          updatingErr: N,
          entity: mergeLeft(payload.candidate),
          ...(!state.entity.isComplete
            ? { haveJustFinishedRegistration: T }
            : {}),
        },
        state,
      )

    case getActionType(UPDATE, CANDIDATE, FAIL):
    case getActionType(ACTIVATE, CANDIDATE, INITIAL, FAIL):
      return evolve({ updating: F, updatingErr: always(payload.err) }, state)

    case getActionType(CREATE, CANDIDATE, CV, START):
      return evolve({ cvCreating: T, cvType: always(payload.cvType) }, state)

    case getActionType(CREATE, CANDIDATE, CV, SUCCESS):
      return evolve(
        {
          cvCreating: F,
          entity: assoc("candidateCreatedCv", payload.cv),
          cvCreatingErr: N,
        },
        state,
      )
    case getActionType(CREATE, CANDIDATE, CV, FAIL):
      return evolve(
        { cvCreating: F, cvCreatingErr: always(payload.err) },
        state,
      )

    case getActionType(UPLOAD, CANDIDATE, CV, START):
      return evolve({ cvUpdating: T, cvType: always(payload.cvType) }, state)

    case getActionType(UPLOAD, CANDIDATE, CV, SUCCESS):
      return evolve(
        {
          cvUpdating: F,
          entity: assoc("candidateCv", payload.cv),
          cvUpdatingErr: N,
        },
        state,
      )

    case getActionType(UPLOAD, CANDIDATE, CV, FAIL):
      return evolve(
        { cvUpdating: F, cvUpdatingErr: always(payload.err) },
        state,
      )

    case getActionType(UPDATE, CANDIDATE, LOCALE, START):
      return evolve({ localeUpdating: T }, state)

    case getActionType(UPDATE, CANDIDATE, LOCALE, SUCCESS):
      return evolve(
        { localeUpdating: F, entity: mergeLeft(payload.candidate) },
        state,
      )

    case getActionType(UPDATE, CANDIDATE, LOCALE, FAIL):
      return evolve({ localeUpdating: F }, state)

    case getActionType(UPDATE, CANDIDATE, TRACKING, START):
      return evolve({ isUpdatingTracking: T }, state)
    case getActionType(UPDATE, CANDIDATE, TRACKING, SUCCESS):
      return evolve(
        {
          isUpdatingTracking: F,
          entity: over(lensProp("tracking"), mergeLeft(payload.tracking)),
          trackingUpdatingErr: N,
        },
        state,
      )
    case getActionType(UPDATE, CANDIDATE, TRACKING, FAIL):
      return evolve(
        {
          isUpdatingTracking: F,
          trackingUpdatingErr: always(payload.err),
        },
        state,
      )

    case getActionType(SET, EMAIL, ON, MATCH, ACTIVE, START):
      return evolve({ isEmailOnMatchUpdating: T }, state)

    case getActionType(SET, EMAIL, ON, MATCH, ACTIVE, SUCCESS):
      return evolve(
        {
          isEmailOnMatchUpdating: F,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(SET, EMAIL, ON, MATCH, ACTIVE, FAIL):
      return evolve({ isEmailOnMatchUpdating: F }, state)

    case getActionType(SET, NEWSLETTER, ACTIVE, START):
      return evolve({ isNewsletterUpdating: T }, state)

    case getActionType(SET, NEWSLETTER, ACTIVE, SUCCESS):
      return evolve(
        {
          isNewsletterUpdating: F,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(SET, NEWSLETTER, ACTIVE, FAIL):
      return evolve({ isNewsletterUpdating: F }, state)

    case getActionType(INITIATE, SUBSCRIPTION, REQUEST, START):
      return evolve(
        {
          isRequestingForSubscriptionVerification: T,
          messageProviderVerification: always({
            provider: payload.messageProvider,
          }),
        },
        state,
      )

    case getActionType(INITIATE, SUBSCRIPTION, REQUEST, SUCCESS):
      return evolve(
        {
          isRequestingForSubscriptionVerification: F,
          messageProviderVerification: always(payload),
        },
        state,
      )

    case getActionType(INITIATE, SUBSCRIPTION, REQUEST, FAIL):
      return evolve(
        {
          isRequestingForSubscriptionVerification: F,
          messageProviderVerification: always(null),
        },
        state,
      )

    case getActionType(CANCEL, SUBSCRIPTION, REQUEST, START):
      return evolve({ isCancelingMessageSubscription: T }, state)

    case getActionType(CANCEL, SUBSCRIPTION, REQUEST, SUCCESS):
      return evolve(
        {
          isCancelingMessageSubscription: F,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(CANCEL, SUBSCRIPTION, REQUEST, FAIL):
      return evolve({ isCancelingMessageSubscription: F }, state)

    case getActionType(CLOSE, SUBSCRIPTION, MODAL):
      return evolve({ haveJustFinishedRegistration: F }, state)

    case getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, START):
      return evolve({ trackingActivating: T }, state)

    case getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS):
      return evolve(
        {
          trackingActivating: F,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, FAIL):
      return evolve({ trackingActivating: F }, state)

    case getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, START):
      return evolve({ trackingDeactivating: T }, state)

    case getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS):
      return evolve(
        {
          trackingDeactivating: F,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, FAIL):
      return evolve({ trackingDeactivating: F }, state)

    case getActionType(ACTIVATE, CANDIDATE, MATCHING, START):
      return evolve({ matchingActivating: T }, state)

    case getActionType(ACTIVATE, CANDIDATE, MATCHING, SUCCESS):
      return evolve(
        { matchingActivating: F, entity: mergeLeft(payload.candidate) },
        state,
      )

    case getActionType(ACTIVATE, CANDIDATE, MATCHING, FAIL):
      return evolve({ matchingActivating: F }, state)

    case getActionType(SET, MATCHING, ACTIVATION, REQUEST, CANCELLED):
      return evolve(
        { matchingActivationRequestCancelled: always(payload.newStatus) },
        state,
      )

    case getActionType(DEACTIVATE, CANDIDATE, MATCHING, START):
      return evolve({ matchingDeactivating: T }, state)

    case getActionType(DEACTIVATE, CANDIDATE, MATCHING, SUCCESS):
      return evolve(
        {
          matchingDeactivating: F,
          matchingDeactivatingErr: N,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(DEACTIVATE, CANDIDATE, MATCHING, FAIL):
      return evolve(
        {
          matchingDeactivating: F,
          matchingDeactivatingErr: always(payload.err),
        },
        state,
      )

    case getActionType(ACCEPT, TERMS, START):
      return evolve({ termsUpdating: T }, state)

    case getActionType(ACCEPT, TERMS, SUCCESS):
      return evolve(
        {
          termsUpdating: F,
          termsUpdatingErr: N,
          entity: mergeLeft(payload.candidate),
        },
        state,
      )

    case getActionType(ACCEPT, TERMS, FAIL):
      return evolve(
        {
          termsUpdating: F,
          termsUpdatingErr: always(payload.err),
        },
        state,
      )

    case getActionType(APPLY, JOB, SUCCESS):
      return evolve({ entity: { statistics: { offersAccepted: inc } } }, state)

    case getActionType(DISCARD, JOB, SUCCESS):
      return evolve({ entity: { statistics: { offersDeclined: inc } } }, state)

    case getActionType(SET, APPLICATION, ID):
      return evolve(
        {
          applicationId: always(payload.id),
        },
        state,
      )

    case getActionType(GET, CANDIDATE, PREDICTION, START):
      return evolve(
        {
          isLoadingPrediction: T,
        },
        state,
      )

    case getActionType(GET, CANDIDATE, PREDICTION, SUCCESS):
      return evolve(
        {
          isLoadingPrediction: F,
          prediction: always(payload.prediction),
        },
        state,
      )

    case getActionType(GET, CANDIDATE, PREDICTION, FAIL):
      return evolve(
        {
          isLoadingPrediction: F,
          predictionErr: always(payload.err),
        },
        state,
      )

    case getActionType(APPLY, CANDIDATE, PREDICTION, SUCCESS): {
      return evolve(
        {
          prediction: omit(payload.fields),
        },
        state,
      )
    }

    case getActionType(LOGOUT, SUCCESS):
      return evolve({ err: N, entity: N }, state)

    default:
      return state
  }
}
