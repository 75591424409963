import io from "socket.io-client"
import { config } from "../config"
import * as Sentry from "@sentry/react"

export const socket = io(config.moberries.ws.url, config.moberries.ws.options)

const errors = [
  "xhr poll error",
  "xhr post error",
  "timeout",
  "websocket error",
]

function ignoreErrors(err) {
  if (!err) return false
  if (errors.includes(err)) return true
  if (errors.includes(err.message)) return true
  return false
}

socket.on("error", err => {
  if (ignoreErrors(err)) return
  console.error(err)
  Sentry.captureException(err, "info")
})

socket.on("connect_error", err => {
  if (ignoreErrors(err)) return
  console.error(err)
  Sentry.captureException(err, "info")
})

// socket.on("connect_timeout", () => console.log("connect_timeout"))
// socket.on("reconnect", attempt => console.log("reconnect: " + attempt))
// socket.on("reconnect_attempt", () => console.log("reconnect_attempt"))
// socket.on("reconnecting", attempt => console.log("reconnecting: " + attempt))

socket.on("reconnect_error", err => {
  if (ignoreErrors(err)) return
  console.error(err)
  Sentry.captureException(err, "info")
})

socket.on("reconnect_failed", () => console.error("reconnect_failed"))
