import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { authRequired } from "../decorators/auth-required"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { Button } from "../components/common/button-component"
import { Switch } from "../components/common/switch-component"
import { FormGroup, TabPane } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { removeLocalData } from "../actions/user-actions"
import {
  exportCandidateData,
  activateCandidateTracking,
  deactivateCandidateTracking,
} from "../actions/candidate-actions"
import {
  exportingSelector,
  trackingEnabledSelector,
  trackingActivatingSelector,
  trackingDeactivatingSelector,
  cvPdfSelector,
} from "../selectors/candidate-selector"

const messages = defineMessages({
  trackingTitle: { id: "app.account.tracking.title" },
  trackingDescription: { id: "app.account.tracking.description" },
  cookiesTitle: { id: "app.account.cookies.title" },
  cookiesDescription: { id: "app.account.cookies.description" },
  cookiesButton: { id: "app.account.cookies.button" },
  exportTitle: { id: "app.account.export.title" },
  exportDescription: { id: "app.account.export.description" },
  exportDataButton: { id: "app.account.export.button.data" },
  downloadCvButton: { id: "app.account.export.button.cv" },
})

class AccountPrivacy extends Component {
  static propTypes = {
    trackingEnabled: PropTypes.bool.isRequired,
    trackingActivating: PropTypes.bool.isRequired,
    trackingDeactivating: PropTypes.bool.isRequired,
    exporting: PropTypes.bool.isRequired,
    exportCandidateData: PropTypes.func.isRequired,
    activateCandidateTracking: PropTypes.func.isRequired,
    deactivateCandidateTracking: PropTypes.func.isRequired,
    removeLocalData: PropTypes.func.isRequired,
    cvLink: PropTypes.string.isRequired,
  }

  static defaultProps = {
    trackingEnabled: true,
  }

  render() {
    const {
      removeLocalData,
      exporting,
      trackingEnabled,
      trackingActivating,
      trackingDeactivating,
      cvLink,
    } = this.props

    return (
      <AccountPageLayout>
        <TabPane>
          <FormGroup>
            <legend>
              <FormattedMessage {...messages.trackingTitle} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.trackingDescription} />
            </p>
            <Switch
              id="tracking"
              disabled={trackingActivating || trackingDeactivating}
              input={{
                checked:
                  (trackingEnabled && !trackingDeactivating) ||
                  (!trackingEnabled && trackingActivating),
                onChange: this.handleTrackingChange,
              }}
              meta={{}}
            />
          </FormGroup>
          <FormGroup>
            <legend>
              <FormattedMessage {...messages.cookiesTitle} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.cookiesDescription} />
            </p>
            <Button size="lg" block color="danger" onClick={removeLocalData}>
              <FormattedMessage {...messages.cookiesButton} />
            </Button>
          </FormGroup>
          <FormGroup>
            <legend>
              <FormattedMessage {...messages.exportTitle} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.exportDescription} />
            </p>
            <Button
              size="lg"
              block
              color="secondary"
              loading={exporting}
              disabled={exporting}
              onClick={this.handleExport}
            >
              <FormattedMessage {...messages.exportDataButton} />
            </Button>
            {cvLink && (
              <Button
                tag="a"
                href={cvLink}
                target="_blank"
                rel="noopener noreferrer"
                size="lg"
                block
                color="secondary"
              >
                <FormattedMessage {...messages.downloadCvButton} />
              </Button>
            )}
          </FormGroup>
        </TabPane>
      </AccountPageLayout>
    )
  }

  handleTrackingChange = e => {
    const {
      trackingEnabled,
      activateCandidateTracking,
      deactivateCandidateTracking,
    } = this.props

    if (trackingEnabled) {
      deactivateCandidateTracking({})
    } else {
      activateCandidateTracking({})
    }
  }

  handleExport = e => {
    this.props.exportCandidateData({})
  }
}

const mapDispatchToProps = {
  exportCandidateData,
  activateCandidateTracking,
  deactivateCandidateTracking,
  removeLocalData,
}

const mapStateToProps = function (state) {
  return {
    exporting: exportingSelector(state),
    trackingEnabled: trackingEnabledSelector(state),
    trackingActivating: trackingActivatingSelector(state),
    trackingDeactivating: trackingDeactivatingSelector(state),
    cvLink: cvPdfSelector(state),
  }
}

export const AccountPrivacyPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountPrivacy)
