import React from "react"
import PropTypes from "prop-types"
import { includes, mergeLeft } from "ramda"
import { FormGroup, PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { useJobRoles } from "../../../../hooks/use-job-roles"
import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { InfoHint } from "../../../common/info-hint-component"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { Select } from "../../../common/select-component"

import styles from "./job-position-section.module.scss"
import { connect } from "react-redux"
import { candidateSelector } from "../../../../selectors/candidate-selector"
import { WarningMessage } from "../../../common/warning-message/warning-message"
import { getMissingPositionIds } from "../../../../lib/helpers"

const JOB_POSITION_LIMIT = 5

const JobPositionSectionComponent = ({ input, candidate }) => {
  let { value: values, onChange } = input
  const { loadJobRoles } = useJobRoles({ selected: values })

  const { workExperience: experience, certificates } = candidate
  const missingJobRoleIds = getMissingPositionIds({
    positions: values,
    experience,
    certificates,
  })

  const changeJobPositionHandler = (newValue, oldValue) => {
    const updatedValues = values.map(v =>
      v.id === oldValue.id ? mergeLeft(newValue, v) : v,
    )
    onChange(updatedValues)
  }

  const addItemHandler = () => {
    if (values[0].id === -1 || values.length >= JOB_POSITION_LIMIT) return

    const newValues = [{ id: -1 }, ...values]
    onChange(newValues)
  }

  const removeItemHandler = id => {
    const newValues = values.filter(v => v.id !== id)
    onChange(newValues)
  }

  // For the first render when "jobPosition" is empty
  if (!values?.length) values = [{ id: -1 }]

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.jobPosition.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.jobPosition.text" />
          </span>
        }
        hint={
          <InfoHint
            id="jobPositionHint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.registration.tabs.preferences.input.jobPosition.hint" />
              </PopoverBody>
            }
          />
        }
      />

      {values.map((item, i) => (
        <div key={item.id} className={styles.formItem}>
          {includes(item.id, missingJobRoleIds) && (
            <div className={styles.formItem__warning}>
              <i className="fas fa-exclamation-triangle text-warning" />
            </div>
          )}
          <div className={styles.formItem__input}>
            <Select
              id="job-position-role"
              async
              input={{
                onChange: newValue => changeJobPositionHandler(newValue, item),
                value: item.id === -1 ? null : item,
              }}
              wrapper="div"
              meta={{}}
              loadOptions={loadJobRoles}
              defaultOptions
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              placeholder={
                <FormattedMessage id="app.registration.tabs.preferences.input.jobPosition.placeholder" />
              }
              noOptionsMessage={() => (
                <FormattedMessage id="app.common.input.placeholder.noResults" />
              )}
              backspaceRemovesValue={false}
            />
          </div>
          <div className={styles.formItem__actions}>
            {i === 0 && item.id > -1 && values.length < JOB_POSITION_LIMIT ? (
              <ButtonWithIcon iconName="plus" onClick={addItemHandler} />
            ) : (
              <ButtonWithIcon
                iconName="cross"
                onClick={() => removeItemHandler(item.id)}
              />
            )}
          </div>
        </div>
      ))}

      {missingJobRoleIds.length > 0 && (
        <div className="mt-2">
          <WarningMessage id="app.registration.tabs.preferences.input.jobPosition.warning" />
        </div>
      )}
    </FormGroup>
  )
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

const mapDispatchToProps = {}

JobPositionSectionComponent.propTypes = {
  input: PropTypes.object,
  candidate: PropTypes.object,
}

export const JobPositionSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobPositionSectionComponent)
