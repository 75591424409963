import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { connectSocialProvider } from "../actions/user-actions"

export class AuthCallback extends Component {
  static propTypes = {
    connectSocialProvider: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.connectSocialProvider()
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { connectSocialProvider }

export const AuthCallbackPage = connect(null, mapDispatchToProps)(AuthCallback)
