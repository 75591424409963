import React from "react"
import { times } from "ramda"
import { CardLoader } from "./card-loader"
import PropTypes from "prop-types"

export const ThreadListLoader = ({ count }) =>
  times(
    i => (
      <div key={i} className="bg-white p-2 mb-1">
        <CardLoader />
      </div>
    ),
    count,
  )

ThreadListLoader.propTypes = {
  count: PropTypes.number,
}

ThreadListLoader.defaultProps = {
  count: 7,
}
