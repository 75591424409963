import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, defineMessages } from "react-intl"
import { Button } from "../common/button-component"
import cn from "classnames"
import { useOpenClose } from "../../hooks/use-open-close"

const messages = defineMessages({
  fullDescription: { id: "app.job.description.full" },
  details: { id: "app.common.details" },
})

export const JobDescription = ({ description }) => {
  const { value: isOpen, toggle } = useOpenClose({ initialValue: false })

  const className = cn(
    { "infobox-short": !isOpen },
    "bg-white rounded-bottom p-3 border-top",
  )

  return (
    <Fragment>
      <div className={className}>
        <h4>
          <FormattedMessage {...messages.details} />
        </h4>
        <div
          className="text-break pr-lg-6"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      {!isOpen && (
        <div className="bg-white rounded-bottom p-3">
          <Button color="link" className="p-0" onClick={toggle}>
            <FormattedMessage {...messages.fullDescription} />
          </Button>
        </div>
      )}
    </Fragment>
  )
}

JobDescription.propTypes = {
  description: PropTypes.string.isRequired,
}

JobDescription.defaultProps = {
  description: "",
}
