import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  decamelizeInterceptor, //eslint-disable-line no-unused-vars
  errorInterceptor,
} from "./axios-interceptors"

export const http = axios.create({
  baseURL: config.moberries.pdfService.url,
  timeout: 30000,
  headers: {},
})

axiosRetry(http, { retries: 3 })

http.interceptors.response.use(response => response, errorInterceptor)
http.interceptors.response.use(saveOriginalInterceptor)
http.interceptors.response.use(camelizeInterceptor)

// http.interceptors.request.use(decamelizeInterceptor)

export const api = {
  setDefaultHeader: ({ header, value }) => {
    http.defaults.headers[header] = value
  },

  createCandidateCv: ({ data, token }) =>
    http({
      url: "/pdf/generate-cv-v2/",
      method: "POST",
      params: { candidateToken: token },
      data,
      responseType: "blob", // browser only
      timeout: 300000, // On some machines forming the request can take up to 3 minutes if the file is big enough
    }),
}
