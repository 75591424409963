import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"
import { Form } from "reactstrap"

const messages = defineMessages({
  email: { id: "app.common.email" },
})

export function Email(props) {
  const { submitting, handleSubmit } = props
  const { buttonLabel, disabled, overInput, emailLabel, className } = props
  return (
    <Form onSubmit={handleSubmit} className={className}>
      {overInput}
      <Field
        component={Input}
        label={
          emailLabel || (
            <span>
              <FormattedMessage {...messages.email} />
            </span>
          )
        }
        className="_lr-hide"
        name="email"
        type="email"
        id="email"
        placeholder="email@example.com"
        isRequired
      />
      <Button
        disabled={disabled || submitting}
        loading={submitting}
        block
        color="primary"
        type="submit"
        size="lg"
      >
        {buttonLabel}
      </Button>
    </Form>
  )
}

Email.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  buttonLabel: PropTypes.node.isRequired,
  emailLabel: PropTypes.node,
  overInput: PropTypes.node,
}

Email.defaultProps = {
  buttonLabel: "Send",
  disabled: false,
  emailLabel: null,
  overInput: null,
}

export const EmailForm = reduxForm({
  initialValues: {
    email: "",
  },
})(Email)
