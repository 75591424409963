import { createSelector } from "reselect"
import { TelegramMessageProvider } from "../constants"

export const stateSelector = state => state.candidate

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const loadedSelector = cs(state => state.loaded)
export const creatingSelector = cs(state => state.creating)
export const updatingSelector = cs(state => state.updating)
export const updatingErrSelector = cs(state => state.updatingErr)
export const cvTypeSelector = cs(state => state.cvType)
export const cvCreatingSelector = cs(state => state.cvCreating)
export const cvCreatingErrSelector = cs(state => state.cvCreatingErr)
export const cvUpdatingSelector = cs(state => state.cvUpdating)
export const cvUpdatingErrSelector = cs(state => state.cvUpdatingErr)
export const exportingSelector = cs(state => state.exporting)
export const matchingActivationRequestCancelledSelector = cs(
  state => state.matchingActivationRequestCancelled,
)
export const localeUpdatingSelector = cs(state => state.localeUpdating)
export const termsUpdatingSelector = cs(state => state.termsUpdating)
export const isNewsletterUpdatingSelector = cs(
  state => state.isNewsletterUpdating,
)
export const isRequestingForSubscriptionVerificationSelector = cs(
  state => state.isRequestingForSubscriptionVerification,
)
export const messageProviderVerificationSelector = cs(
  state => state.messageProviderVerification,
)
export const haveJustFinishedRegistrationSelector = cs(
  state => state.haveJustFinishedRegistration,
)
export const isEmailOnMatchUpdatingSelector = cs(
  state => state.isEmailOnMatchUpdating,
)
export const isCancelingMessageSubscriptionSelector = cs(
  state => state.isCancelingMessageSubscription,
)
export const trackingActivatingSelector = cs(state => state.trackingActivating)
export const trackingDeactivatingSelector = cs(
  state => state.trackingDeactivating,
)
export const matchingActivatingSelector = cs(state => state.matchingActivating)
export const matchingDeactivatingSelector = cs(
  state => state.matchingDeactivating,
)
export const matchingDeactivatingErrSelector = cs(
  state => state.matchingDeactivatingErr,
)
export const errSelector = cs(state => state.err)

export const creatingErrSelector = cs(state => state.creatingErr)

export const applicationIdSelector = cs(state => state.applicationId)

export const candidateSelector = cs(state => state.entity)

export const predictionSelector = cs(state => state.prediction)

export const isLoadingPredictionSelector = cs(
  state => state.isLoadingPrediction,
)

export const pictureSelector = cs(
  candidateSelector,
  (_, entity) => entity?.picture,
)
export const firstNameSelector = cs(
  candidateSelector,
  (_, entity) => entity?.firstName,
)
export const lastNameSelector = cs(
  candidateSelector,
  (_, entity) => entity?.lastName,
)
export const emailSelector = cs(candidateSelector, (_, entity) => entity.email)
export const settingsSelector = cs(
  candidateSelector,
  (_, entity) => entity?.settings,
)
export const referencesSelector = cs(
  candidateSelector,
  (_, entity) => entity.references,
)

export const createdCvSelector = cs(
  candidateSelector,
  (_, entity) => entity?.candidateCreatedCv,
)

export const cvIsCreatedSelector = cs(createdCvSelector, (_, cv) => Boolean(cv))

export const cvSelector = cs(
  candidateSelector,
  (_, entity) => entity?.candidateCv,
)

export const cvUrlsSelector = cs(cvSelector, (_, cv) => cv?.urls.images || [])
export const cvStatusSelector = cs(cvSelector, (_, cv) => cv?.status)

export const cvPdfSelector = cs(cvSelector, (_, cv) => cv?.urls.pdf || "")

export const cvIsUploadedSelector = cs(cvSelector, (_, cv) =>
  Boolean(cv?.isUploaded),
)

export const activeSelector = cs(candidateSelector, (_, entity) =>
  entity ? entity.active : null,
)

export const idSelector = cs(candidateSelector, (_, entity) => entity?.id)

export const userIdSelector = cs(candidateSelector, (_, entity) => entity?.user)

export const isEmailOnMatchActiveSelector = cs(
  settingsSelector,
  (_, settings) => settings.notifyAlerts,
)
export const isNewsletterActiveSelector = cs(
  settingsSelector,
  (_, settings) => settings?.notifyNews,
)

export const isTelegramSubscriptionActiveSelector = cs(
  candidateSelector,
  (_, candidate) =>
    (candidate.botSubscription || []).find(
      s => s.type === TelegramMessageProvider.toLowerCase(),
    )?.isSubscribed || false,
)

export const hasAnyActiveSubscriptionSelector = cs(
  candidateSelector,
  (_, candidate) =>
    (candidate.botSubscription || []).some(s => s.isSubscribed) || false,
)

export const botSubscriptionsSelector = cs(
  candidateSelector,
  (_, candidate) => candidate.botSubscription || [],
)

export const termsVersionSelector = cs(
  settingsSelector,
  (_, settings) => settings?.termsVersion,
)

export const localeSelector = cs(
  settingsSelector,
  (_, settings) => settings.locale,
)

export const trackingSelector = cs(candidateSelector, (_, candidate) =>
  candidate ? candidate.tracking : null,
)

export const trackingEnabledSelector = cs(
  settingsSelector,
  (_, settings) => settings && settings.useExternalTracking,
)

export const profileCompletionSelector = cs(
  candidateSelector,
  (_, candidate) => candidate.completion,
)

export const isLoggedSelector = cs(candidateSelector, (_, candidate) =>
  Boolean(candidate),
)

export const isCompleteSelector = cs(candidateSelector, (_, candidate) =>
  Boolean(candidate?.isComplete && candidate.active !== null),
)

export const statisticsSelector = cs(
  candidateSelector,
  (_, candidate) => candidate?.statistics,
)

export const applicationsTotalSelector = cs(
  statisticsSelector,
  (_, statistics) => statistics?.applicationsTotal,
)

export const applicationsAcceptedSelector = cs(
  statisticsSelector,
  (_, statistics) => statistics?.applicationsAccepted,
)

export const offersAcceptedSelector = cs(
  statisticsSelector,
  (_, statistics) => statistics?.offersAccepted,
)

export const offersDeclinedSelector = cs(
  statisticsSelector,
  (_, statistics) => statistics?.offersDeclined,
)

export const socialAccountListSelector = cs(
  candidateSelector,
  (_, entity) => entity?.socialAccounts,
)

export const facebookConnectedSelector = cs(
  socialAccountListSelector,
  (_, accounts) => {
    return Boolean(accounts?.some(a => a.platform === "facebook"))
  },
)
export const linkedinConnectedSelector = cs(
  socialAccountListSelector,
  (_, accounts) => {
    return Boolean(accounts?.some(a => a.platform === "linkedin"))
  },
)

export const linkedinWebReferenceSelector = cs(
  socialAccountListSelector,
  (_, accounts) => {
    const linkedin = accounts?.find(a => a.platform === "linkedin")
    return linkedin && linkedin.webReference
  },
)

export const unreadCountSelector = cs(
  candidateSelector,
  (_, entity) => entity?.unreadMessages || 0,
)

export const careerLevelSelector = cs(
  candidateSelector,
  (_, entity) => entity.careerLevel,
)

export const jobTypesSelector = cs(
  candidateSelector,
  (_, entity) => entity.jobTypes,
)
