import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { FormattedMessage } from "react-intl"

import { useDegrees } from "../../../../../hooks/use-degrees"
import { useStudyFields } from "../../../../../hooks/use-study-fields"
import { Select } from "../../../../common/select-component"

import styles from "./details-inputs.module.scss"

export const DetailsInputs = ({ className, item, changeProp }) => {
  const { loadDegrees } = useDegrees()
  const { loadStudyFields } = useStudyFields()

  return (
    <div className={cn(className, styles.wrapper)}>
      <Select
        id="education-degrees"
        async
        input={{
          onChange: value => changeProp(value, item.id, "degree"),
          value: item.degree,
        }}
        wrapper="div"
        meta={{}}
        loadOptions={loadDegrees}
        defaultOptions
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        placeholder={
          <FormattedMessage id="app.registration.tabs.professional.input.education.placeholder.degree" />
        }
        noOptionsMessage={() => (
          <FormattedMessage id="app.common.input.placeholder.noResults" />
        )}
        backspaceRemovesValue={false}
      />

      <Select
        id="education-study-fields"
        async
        input={{
          onChange: value => changeProp(value, item.id, "fieldOfStudy"),
          value: item.fieldOfStudy,
        }}
        wrapper="div"
        meta={{}}
        loadOptions={loadStudyFields}
        defaultOptions
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        placeholder={
          <FormattedMessage id="app.registration.tabs.professional.input.education.placeholder.faculty" />
        }
        noOptionsMessage={() => (
          <FormattedMessage id="app.common.input.placeholder.noResults" />
        )}
        backspaceRemovesValue={false}
      />
    </div>
  )
}

DetailsInputs.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  changeProp: PropTypes.func.isRequired,
}
