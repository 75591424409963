import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
} from "./axios-interceptors"

export const http = axios.create({
  baseURL: config.moberries.webpush.url,
  timeout: 30000,
  headers: {},
})

axiosRetry(http, { retries: 3 })

http.interceptors.response.use(response => response, errorInterceptor)
http.interceptors.response.use(saveOriginalInterceptor)
http.interceptors.response.use(camelizeInterceptor)

export const api = {
  setDefaultHeader: ({ header, value }) => {
    http.defaults.headers[header] = value
  },

  putFcmToken: ({ token }) =>
    http({
      url: "/fcm/token",
      method: "PUT",
      data: { token },
    }),

  deleteFcmToken: ({ token }) =>
    http({
      url: "/fcm/token",
      method: "DELETE",
      data: { token },
    }),
}
