import fbIcon from "./assets/img/facebook.svg"
import telegramIcon from "./assets/img/telegram.svg"
import whatsappIcon from "./assets/img/whatsapp.svg"
import {
  config,
  JOB_SUGGESTIONS_ML_URL,
  JOB_SUGGESTIONS_RB_URL,
} from "./config"

export const REQUEST = "REQUEST"
export const START = "START"
export const STOP = "STOP"
export const SUCCESS = "SUCCESS"
export const FAIL = "FAIL"

export const GET = "GET"
export const LIST = "LIST"
export const CREATE = "CREATE"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const PREDICT = "PREDICT"
export const CLOSE = "CLOSE"
export const OPEN = "OPEN"

export const USER = "USER"
export const CANDIDATE = "CANDIDATE"
export const MATCH = "MATCH"
export const ACCEPT = "ACCEPT"
export const DECLINE = "DECLINE"
export const JOB = "JOB"
export const SUGGESTION = "SUGGESTION"
export const COMPANY = "COMPANY"
export const DISCARD = "DISCARD"
export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const SIGNUP = "SIGNUP"
export const TOKEN = "TOKEN"
export const REFRESH = "REFRESH"
export const FACEBOOK = "FACEBOOK"
export const CONNECT = "CONNECT"
export const DISCONNECT = "DISCONNECT"
export const LINKEDIN = "LINKEDIN"
export const LOCALE = "LOCALE"
export const PENDING = "PENDING"
export const ALERT = "ALERT"
export const NEWS = "NEWS"
export const SHOW = "SHOW"
export const HIDE = "HIDE"
export const FORM = "FORM"
export const CONFIRM = "CONFIRM"
export const EMAIL = "EMAIL"
export const MOBOT = "MOBOT"
export const REDIRECT = "REDIRECT"
export const SOCIAL = "SOCIAL"
export const PROVIDER = "PROVIDER"
export const SET = "SET"
export const APPLICATION = "APPLICATION"
export const APPLY = "APPLY"
export const RESEND = "RESEND"
export const SEND = "SEND"
export const CONFIRMATION = "CONFIRMATION"
export const RESET = "RESET"
export const RECOVER = "RECOVER"
export const PASSWORD = "PASSWORD"
export const NEW = "NEW"
export const ACCOUNT = "ACCOUNT"
export const MESSAGE = "MESSAGE"
export const MESSAGES = "MESSAGES"
export const THREAD = "THREAD"
export const MATCHING = "MATCHING"
export const ACTIVATE = "ACTIVATE"
export const DEACTIVATE = "DEACTIVATE"
export const NOTIFICATION = "NOTIFICATION"
export const SETTINGS = "SETTINGS"
export const BOT = "BOT"
export const CODE = "CODE"
export const CONNECTION = "CONNECTION"
export const WITH = "WITH"
export const SIMILAR = "SIMILAR"
export const MARK = "MARK"
export const HIRED = "HIRED"
export const NOT = "NOT"
export const CV = "CV"
export const TOPCV = "TOPCV"
export const FROM = "FROM"
export const UPLOAD = "UPLOAD"
export const REFERRAL = "REFERRAL"
export const INITIAL = "INITIAL"
export const INITIATE = "INITIATE"
export const CANCEL = "CANCEL"
export const UNREAD = "UNREAD"
export const COUNT = "COUNT"
export const RELEASE = "RELEASE"
export const ON = "ON"
export const OFF = "OFF"
export const LOGROCKET = "LOGROCKET"
export const WS = "WS"
export const API = "API"
export const TOGGLE = "TOGGLE"
export const PAGE = "PAGE"
export const RELOAD = "RELOAD"
export const TRACKING = "TRACKING"
export const DATA = "DATA"
export const REMOVE = "REMOVE"
export const EXPORT = "EXPORT"
export const ID = "ID"
export const TERMS = "TERMS"
export const COOKIE = "COOKIE"
export const USAGE = "USAGE"
export const FCM = "FCM"
export const PERMISSION = "PERMISSION"
export const INIT = "INIT"
export const SUPPORTED = "SUPPORTED"
export const BY = "BY"
export const SLUG = "SLUG"
export const NOT_HIRED = "NOT_HIRED"
export const ACTIVATION = "ACTIVATION"
export const CANCELLED = "CANCELLED"
export const INVITE = "INVITE"
export const ADDITIONAL = "ADDITIONAL"
export const PLAY = "PLAY"
export const SOUND = "SOUND"
export const READ = "READ"
export const PREDICTION = "PREDICTION"
export const AND = "AND"
export const ONLINE = "ONLINE"
export const VISIBILITY = "VISIBILITY"
export const CHANGE = "CHANGE"
export const QUEUE = "QUEUE"
export const DEQUEUE = "DEQUEUE"
export const FEEDBACK = "FEEDBACK"
export const SUMMARY = "SUMMARY"
export const LAST = "LAST"
export const APP = "APP"
export const VERSIONS = "VERSIONS"
export const INVITATION = "INVITATION"
export const COPY = "COPY"
export const LINK = "LINK"
export const NEWSLETTER = "NEWSLETTER"
export const SUBSCRIPTION = "SUBSCRIPTION"
export const ACTIVE = "ACTIVE"
export const MODAL = "MODAL"
export const STATISTICS = "STATISTICS"
export const MATCHES = "MATCHES"
export const STATUS = "STATUS"
export const SURVEY = "SURVEY"
export const EXTERNAL = "EXTERNAL"
export const SUGGESTIONS = "SUGGESTIONS"
export const JOBS = "JOBS"
export const VALIDATION = "VALIDATION"
export const CLEAR = "CLEAR"

export const ACT = "ACT"
export const PAU = "PAU"
export const REV = "REV"
export const DEL = "DEL"

export const LOCALES = ["en", "de"]

export const MATCH_STATUSES = {
  DRAFT: -10,
  CANDIDATE_PENDING: 0,
  CANDIDATE_DECLINED: 1,
  CANDIDATE_ACCEPTED: 2,
  COMPANY_DECLINED: 3,
  COMPANY_ACCEPTED: 4,
  CANDIDATE_EXPIRED: 5,
  COMPANY_EXPIRED: 6,
  COMPANY_REJECTED: 7,
  COMPANY_APPROVED: 8,
  EXPORTED: 9,
}

export const MATCH_SOURCES = {
  MANUAL: 0,
  LIKE: 2, // this is a deprecated match source used only to support old matches https://github.com/moberries/backend/issues/3481
  AUTOMATCH: 1,
  EXCLUSIVE: 3,
}

export const FEEDBACK_SETS = {
  CANDIDATE_DELETION_REASONS: 0,
  CANDIDATE_NOT_HIRED_REASONS: 1,
  CANDIDATE_DECLINE_JOB_REASONS: 6,
}

export const NONE = "NONE"
export const CLIENT_ERROR = "CLIENT_ERROR"
export const SERVER_ERROR = "SERVER_ERROR"
export const TIMEOUT_ERROR = "TIMEOUT_ERROR"
export const CONNECTION_ERROR = "CONNECTION_ERROR"
export const NETWORK_ERROR = "NETWORK_ERROR"
export const UNKNOWN_ERROR = "UNKNOWN_ERROR"
export const CANCEL_ERROR = "CANCEL_ERROR"

export const TIMEOUT_ERROR_CODES = ["ECONNABORTED"]
export const NODEJS_CONNECTION_ERROR_CODES = [
  "ENOTFOUND",
  "ECONNREFUSED",
  "ECONNRESET",
]

export const KeyCodes = {
  Enter: 13,
}

export const ACCENT_COLORS = [
  "#e4cfce",
  "#e6dccf",
  "#e1e6cf",
  "#d3e6cf",
  "#cfe6d8",
  "#cfe6e6",
  "#cfd8e6",
  "#d3cfe7",
  "#e2cfe7",
  "#e5dacf",
]

export const SEARCH_DEBOUNCE_TIMEOUT = 400

export const ALL_MATCH_STATUSES = [
  MATCH_STATUSES.CANDIDATE_ACCEPTED,
  MATCH_STATUSES.COMPANY_DECLINED,
  MATCH_STATUSES.COMPANY_ACCEPTED,
  MATCH_STATUSES.CANDIDATE_EXPIRED,
  MATCH_STATUSES.COMPANY_EXPIRED,
  MATCH_STATUSES.COMPANY_REJECTED,
  MATCH_STATUSES.COMPANY_APPROVED,
  MATCH_STATUSES.EXPORTED,
]

export const JOB_SUGGESTIONS_URLS = {
  ML: JOB_SUGGESTIONS_ML_URL,
  RB: JOB_SUGGESTIONS_RB_URL,
}

export const APPLICATION_QUESTION_TYPES = {
  SHORT_TEXT: "short_text",
  LONG_TEXT: "long_text",
  SINGLE_SELECTION: "single_selection",
  MULTIPLE_SELECTION: "multiple_selection",
  BOOLEAN: "boolean",
}

export const ALERT_COLORS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
}

export const CV_TYPES = {
  UPLOAD: "custom",
  CREATE: "questionnaire",
}

export const CV_STATUSES = {
  PROCESSING: "processing",
  ERROR: "error",
  SUCCESS: "success",
}

export const ONBOARDING_FUNNEL = {
  SHARE: "share",
  DIRECT: "direct",
  CATALOG: "catalog_app",
}

export const FA_ICONS = {
  CLOUD_UPLOAD: "fas fa-cloud-upload",
  FILE_PDF: "fas fa-file-pdf",
  FILE_PLUS: "fas fa-file-medical",
}

export const FbMessageProvider = "Facebook"
export const TelegramMessageProvider = "Telegram"
export const WhatsappMessageProvider = "Whatsapp"

export const messageProviders = [
  {
    icon: telegramIcon,
    name: TelegramMessageProvider,
    link: `${config.telegramBot.baseShortLinkUrl}/${config.telegramBot.name}?start=<code>`,
  },
  {
    icon: whatsappIcon,
    name: WhatsappMessageProvider,
    link: `${config.whatsappBot.baseShortLinkUrl}/send/?phone=${config.whatsappBot.name}&text=/start%20<code>`,
  },
  {
    icon: fbIcon,
    name: FbMessageProvider,
    link: `${config.fbChatBot.baseShortLinkUrl}/${config.fbChatBot.name}?ref=/start%20<code>`,
  },
]
