import { createSelector } from "reselect"

export const stateSelector = state => state.user
const cs = (...args) => createSelector(stateSelector, ...args)

export const userSelector = cs(state => state.entity)
export const loadingSelector = cs(state => state.loading)
export const loadedSelector = cs(state => state.loaded)
export const errSelector = cs(state => state.err)
export const deletingSelector = cs(state => state.deleting)
export const deletingErrSelector = cs(state => state.deletingErr)
export const passwordRecoveryLoadingSelector = cs(
  state => state.passwordRecoveryLoading,
)
export const passwordRecoveryErrSelector = cs(
  state => state.passwordRecoveryErr,
)
export const passwordResetLoadingSelector = cs(
  state => state.passwordResetLoading,
)
export const passwordResetErrSelector = cs(state => state.passwordResetErr)
export const confirmationEmailResendingSelector = cs(
  state => state.confirmationEmailResending,
)
export const confirmationEmailResendingErrSelector = cs(
  state => state.confirmationEmailResendingErr,
)
export const tokenSelector = cs(state => state.token)
export const tokenUpdatedAtSelector = cs(state => state.tokenUpdatedAt)

export const connectingLinkedinSelector = cs(state => state.connectingLinkedin)
export const connectingFacebookSelector = cs(state => state.connectingFacebook)
export const disconnectingLinkedinSelector = cs(
  state => state.disconnectingLinkedin,
)
export const disconnectingFacebookSelector = cs(
  state => state.disconnectingFacebook,
)

export const cookieConsentSelector = cs(state => state.cookieConsent)
