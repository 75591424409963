import React from "react"
import PropTypes from "prop-types"
import { compose } from "redux"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"

import { cvPdfSelector } from "../../selectors/candidate-selector"

export const DownloadCVButtonComponent = ({ cvPdf, ...rest }) => {
  return cvPdf ? (
    <Button
      {...rest}
      tag="a"
      href={cvPdf}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="app.myProfile.button.resume.download" />
    </Button>
  ) : null
}

DownloadCVButtonComponent.propTypes = {
  cvPdf: PropTypes.string,
}

const mapStateToProps = state => ({
  cvPdf: cvPdfSelector(state),
})

const mapDispatchToProps = {
  push,
}

export const DownloadCVButton = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DownloadCVButtonComponent)
