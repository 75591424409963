import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"
import { compose } from "ramda"
import cn from "classnames"

import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { candidateSelector } from "../../selectors/candidate-selector"

import styles from "./profile-professional-details-panel.module.scss"
import { transformDate } from "../../lib/helpers"

export const LANG_LEVELS = [
  { id: 0, name: "A1 - Beginner" },
  { id: 1, name: "A2 - Elementary" },
  { id: 2, name: "B1 - Intermediate" },
  { id: 3, name: "B2 - Upper Intermediate" },
  { id: 4, name: "C1 - Advanced" },
  { id: 5, name: "C2 - Mastery" },
  { id: 6, name: "Native", native: true },
]

export const ProfileProfessionalDetails = ({ candidate, push, location }) => {
  const languages = candidate.languages.map(l => ({
    name: l.name,
    level: LANG_LEVELS.find(ll => ll.id === l.level)?.name,
  }))
  const skills = candidate.skills.map(s => s.name)

  return (
    <div className={styles.container}>
      <Button
        className={styles.editButton}
        onClick={() =>
          push({
            pathname: "/profile/personal/edit",
            search: location?.search,
          })
        }
      >
        <FormattedMessage id="app.common.edit" />{" "}
        <i className={cn("fas fa-edit", styles.icon)} />
      </Button>
      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.professional.input.languages.label" />
        </h2>
        <div className={styles.containerTags}>
          {languages.map(l => (
            <div className={styles.tag} key={l.name}>
              <div>{l.name}</div>
              <div className={styles.level}>{`(${l.level})`}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.common.skills" />
        </h2>
        <div className={styles.containerTags}>
          {skills.map(s => (
            <div className={styles.tag} key={s}>
              {s}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.profile.job.roles.experience" />
        </h2>
        <div>
          {candidate.workExperience.map((e, i) => (
            <div className={styles.containerBullets} key={i}>
              <p className={styles.bullet}>{e.name}</p>
              <p className={styles.bulletItem}>{e.companyName ?? "Unknown"}</p>
              <p className={styles.bulletItemDate}>
                {`${transformDate(e.startDate)} - ${
                  transformDate(e.endDate) ?? "Present"
                }`}
              </p>
            </div>
          ))}
        </div>
      </div>

      {candidate.certificates.length > 0 && (
        <div className={styles.section}>
          <h2 className={styles.label}>
            <FormattedMessage id="app.registration.tabs.professional.input.certifications.label" />
          </h2>
          <div>
            {candidate.certificates.map((c, i) => (
              <div className={styles.containerBullets} key={i}>
                <p className={styles.bullet}>
                  {`${c.certificateName} / ${c.roleName}`}
                </p>
                <p className={styles.bulletItem}>{c.issuingOrganization}</p>
                <p className={styles.bulletItemDate}>
                  {transformDate(c.issueDate)}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {candidate.education.length > 0 && (
        <div className={styles.section}>
          <h2 className={styles.label}>
            <FormattedMessage id="app.registration.tabs.professional.input.education.label" />
          </h2>
          <div>
            {candidate.education.map((e, i) => (
              <div className={styles.containerBullets} key={i}>
                <p className={styles.bullet}>
                  {`${e.degree.name} | ${e.fieldOfStudy.name}`}
                </p>
                <p className={styles.bulletItem}>{e.institution.name}</p>
                <p className={styles.bulletItemDate}>
                  {`${transformDate(e.startDate)} - ${
                    transformDate(e.endDate) ?? "Present"
                  }`}{" "}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {candidate.references.length > 0 && (
        <div className={styles.section}>
          <h2 className={styles.label}>
            <FormattedMessage id="app.registration.tabs.professional.input.portfolio.label" />
          </h2>
          <div>
            {candidate.references.map((r, i) => (
              <a href={r} className="d-block" key={i}>
                {r}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

ProfileProfessionalDetails.propTypes = {
  candidate: PropTypes.object.isRequired,
  location: PropTypes.object,
  push: PropTypes.func,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

const mapDispatchToProps = {
  push,
}

export const ProfileProfessionalDetailsPanel = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(ProfileProfessionalDetails)
