import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useSelector, connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { Label, Input } from "reactstrap"
import cn from "classnames"

import { useOpenClose } from "../../../hooks/use-open-close"
import { links } from "../../../lib/external-links"
import { localeSelector } from "../../../selectors/intl-selector"
import { connectingLinkedinSelector } from "../../../selectors/user-selector"
import { creatingSelector } from "../../../selectors/candidate-selector"
import { signupFormValidator } from "../../../validators/signup-form-validator"
import { createCandidate } from "../../../actions/candidate-actions"
import { signupWithLinkedin } from "../../../actions/user-actions"
import { SocialAuth } from "../../social/social-auth-component"
import FormFeedback from "../../common/form-feedback"
import { SignupForm } from "../../forms/signup-form/signup-form"

import styles from "./signup-form-section-component.module.scss"

export const SignupFormSectionComponent = ({
  signupWithLinkedin,
  isConnectingLinkedin,
  isCreatingCandidate,
  createCandidate,
  setTracker,
}) => {
  const {
    value: isNewsletterActive,
    toggle: toggleIsNewsletterActive,
  } = useOpenClose({ initialValue: false })
  const {
    value: areTermsAccepted,
    toggle: areTermsAcceptedToggle,
  } = useOpenClose({ initialValue: false })
  const { value: areTermsTouched, open: touchTerms } = useOpenClose({
    initialValue: false,
  })
  const locale = useSelector(localeSelector)

  const handleSubmit = ({ firstName, lastName, email, password }) => {
    touchTerms()
    if (!areTermsAccepted) {
      return
    }
    setTracker && setTracker()
    createCandidate({
      settings: {
        locale,
        isNewsletterActive,
      },
      firstName,
      lastName,
      email,
      password,
    })
  }

  return (
    <div className={styles.container}>
      <SignupForm
        form="SignupForm"
        disabled={isCreatingCandidate || isConnectingLinkedin}
        onSubmit={handleSubmit}
        validate={signupFormValidator}
        buttonLabel={<FormattedMessage id="app.signup.register" />}
        terms={
          <div className={styles.termsContainer}>
            <div className="custom-control custom-checkbox">
              <Input
                id="terms"
                type="checkbox"
                className="custom-control-input"
                checked={areTermsAccepted}
                onChange={() => {
                  touchTerms()
                  areTermsAcceptedToggle()
                }}
                invalid={areTermsTouched && !areTermsAccepted}
              />
              <Label
                for="terms"
                className={cn("custom-control-label", styles.checkboxLabel)}
              >
                <FormattedMessage
                  id="app.signup.agreement.text"
                  values={{
                    terms: (...children) => (
                      <a
                        href={links[locale].terms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {children}
                      </a>
                    ),
                    privacy: (...children) => (
                      <a
                        href={links[locale].privacy}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {children}
                      </a>
                    ),
                  }}
                />
              </Label>
              <FormFeedback
                error={<FormattedMessage id="app.signup.agreement.text.hint" />}
              />
            </div>
          </div>
        }
        newsletter={
          <div className={styles.newsletterContainer}>
            <div className="custom-control custom-checkbox">
              <Input
                id="newsletter"
                type="checkbox"
                className="custom-control-input"
                checked={isNewsletterActive}
                onChange={toggleIsNewsletterActive}
              />
              <Label
                for="newsletter"
                className={cn("custom-control-label", styles.checkboxLabel)}
              >
                <FormattedMessage id="app.signup.agreement.checkbox" />
              </Label>
            </div>
          </div>
        }
      />

      <div className={styles.orContainer}>
        <hr className={styles.horizontalLine} />
        <div className={styles.boxOR}>
          <FormattedMessage id="app.common.or.caps" />
        </div>
        <hr className={styles.horizontalLine} />
      </div>

      <div>
        <SocialAuth
          withFacebook={false}
          isDisabled={isConnectingLinkedin || isCreatingCandidate}
          onLinkedinClick={() => {
            if (areTermsAccepted) {
              signupWithLinkedin({
                isNewsletterActive,
              })
              setTracker && setTracker()
            } else {
              touchTerms()
            }
          }}
        />
      </div>

      <div className={styles.footer}>
        <FormattedMessage
          id="app.signup.login"
          values={{
            a: (...children) => <Link to="/login">{children}</Link>,
          }}
        />
      </div>
    </div>
  )
}

SignupFormSectionComponent.propTypes = {
  isConnectingLinkedin: PropTypes.bool.isRequired,
  isCreatingCandidate: PropTypes.bool.isRequired,
  createCandidate: PropTypes.func.isRequired,
  signupWithLinkedin: PropTypes.func,
  setTracker: PropTypes.func,
}

SignupFormSectionComponent.defaultProps = {
  isConnectingLinkedin: false,
  isCreatingCandidate: false,
}

const mapStateToProps = state => ({
  isConnectingLinkedin: connectingLinkedinSelector(state),
  isCreatingCandidate: creatingSelector(state),
})

const mapDispatchToProps = {
  createCandidate,
  signupWithLinkedin,
}

export const SignupFormSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupFormSectionComponent)
