import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import styles from "./signup-info-section-component.module.scss"

export const SignupInfoSection = ({ header, subheader, benefits }) => (
  <div className={styles.outerContainer}>
    <div className={styles.innerContainer}>
      <div>
        <h2 className={styles.header}>
          <FormattedMessage {...header} />
        </h2>
        <p className={styles.subheader}>
          <FormattedMessage {...subheader} />
        </p>
      </div>

      {benefits.header && (
        <div>
          <h2 className={styles.benefitsHeader}>
            <FormattedMessage {...benefits.header} />
          </h2>
        </div>
      )}
      {benefits.items.map((item, idx) => (
        <div key={idx}>
          <h3 className={styles.title}>
            <img
              className={styles.icon}
              src={item.icon.src}
              alt={item.icon.alt}
            />
            <FormattedMessage {...item.title} />
          </h3>
          <p className={styles.description}>
            <FormattedMessage {...item.subtitle} />
          </p>
        </div>
      ))}
    </div>
  </div>
)

SignupInfoSection.propTypes = {
  header: PropTypes.object,
  subheader: PropTypes.object,
  benefits: PropTypes.object,
}
