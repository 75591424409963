import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  take,
} from "redux-saga/effects"
import {
  candidateSelector,
  trackingEnabledSelector,
  idSelector,
} from "../selectors/candidate-selector"
import { shouldInitTracker, getActionType, getFullName } from "../lib/helpers"
import {
  SUCCESS,
  EMAIL,
  CANDIDATE,
  INITIAL,
  MATCHING,
  FACEBOOK,
  LINKEDIN,
  ACCOUNT,
  JOB,
  LOGIN,
  CONFIRM,
  GET,
  CREATE,
  UPDATE,
  DELETE,
  LOGOUT,
  ACTIVATE,
  DEACTIVATE,
  CONNECT,
  DISCONNECT,
  DISCARD,
  TRACKING,
  SETTINGS,
  RELOAD,
  PAGE,
  REQUEST,
  SEND,
  INVITATION,
  MODAL,
  OPEN,
  COPY,
  LINK,
  APPLY,
} from "../constants"

function loadGtm() {
  if (shouldInitTracker(window.dataLayer instanceof Array)) {
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      })
      const f = d.getElementsByTagName(s)[0], // eslint-disable-line
        j = d.createElement(s),
        dl = l !== "dataLayer" ? `&l=${l}` : ""
      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", "GTM-MD64MXL")
  }
}

const track = (function (queue) {
  return function (event) {
    const { dataLayer } = window

    if (dataLayer) {
      if (queue.length > 0) {
        queue.forEach(item => {
          dataLayer.push(item)
        })
        queue = []
      }

      return call([dataLayer, dataLayer.push], event)
    } else {
      queue.push(event)
    }
  }
})([])

function* toggleGtmSaga() {
  const candidate = yield select(candidateSelector)
  const trackingEnabled = candidate
    ? yield select(trackingEnabledSelector)
    : true

  if (trackingEnabled) {
    yield call(loadGtm)
  } else if (window.dataLayer) {
    yield put({ type: getActionType(RELOAD, PAGE) })
  }
}

function* loginWithEmailAndPasswordSaga(action) {
  const { accountType, candidate = {} } = action.payload

  yield track({
    event: "login",
    email: candidate.email,
    payload: { platform: "email", account_type: accountType },
  })
}

function* confirmEmailWithCodeSaga(action) {
  const { candidate = {} } = action.payload

  yield track({
    event: "candidateRegisterCompleteEmail",
    email: candidate.email,
  })
}

function* connectFacebookSaga(action) {
  const { candidate = {} } = action.payload

  yield track({
    event: "socialConnect",
    email: candidate.email,
    payload: {
      platform: FACEBOOK.toLowerCase(),
      companyId: candidate?.tracking?.company,
    },
  })
}

function* connectLinkedinSaga(action) {
  const { candidate = {} } = action.payload

  yield track({
    event: "socialConnect",
    email: candidate.email,
    payload: {
      platform: LINKEDIN.toLowerCase(),
      companyId: candidate?.tracking?.company,
    },
  })
}

function* createCandidateSaga(action) {
  yield track({
    event: "candidateRegister",
    email: action.payload.email,
    payload: {
      platform: "email",
      companyId: action.payload?.tracking?.company,
    },
  })
}

function* activateCandidateFirstTimeSaga() {
  yield track({
    event: "activateCandidateFirstTime",
  })
}

function* updateCandidateSaga(action) {
  yield track({
    event: "updateProfile",
    payload: action.payload,
  })
}

function* updateCandidateTrackingSaga(action) {
  const { tracking } = action.payload
  yield take(getActionType(UPDATE, CANDIDATE, TRACKING, SUCCESS))
  if (tracking.company) {
    yield track({
      event: "updateReferralCompany",
      payload: { companyId: tracking.company },
    })
  }
}

function* logoutSaga() {
  yield track({ event: "logout" })
}

function* toggleActivateMatchingSaga(action) {
  const { candidate } = action.payload

  yield track({
    event: "updateActivation",
    payload: {
      active: candidate.active,
    },
  })
}

function* deleteAccountSaga() {
  yield track({ event: "deleteAccount" })
}

function* disconnectFacebookSaga() {
  yield track({
    event: "socialDisconnect",
    payload: { platform: FACEBOOK.toLowerCase() },
  })
}

function* disconnectLinkedinSaga() {
  yield track({
    event: "socialDisconnect",
    payload: { platform: LINKEDIN.toLowerCase() },
  })
}

function* applyForJobSaga(action) {
  const { match } = action.payload

  yield track({
    event: "jobApplication",
    payload: { jobId: match.job.id, source: match.source },
  })
}

function* discardJobSaga(action) {
  const { jobId } = action.payload

  yield track({
    event: "jobDiscard",
    payload: { jobId },
  })
}

function* getCandidateSaga(action) {
  const { candidate } = action.payload

  yield track({
    event: "fetchProfile",
    payload: {
      id: candidate.id,
      email: candidate.email,
      name: getFullName(candidate),
    },
  })
}

function* sendJobInvitationSaga(action) {
  const { jobId } = action.payload
  const candidateId = yield select(idSelector)
  yield track({
    event: "sendEmailJobInvitation",
    payload: { candidateId, jobId },
  })
}

function* openJobInvitationModalSaga(action) {
  const { jobId } = action.payload
  const candidateId = yield select(idSelector)
  yield track({
    event: "openJobInvitationModal",
    payload: { candidateId, jobId },
  })
}

function* copyJobInvitationLinkTrackSaga(action) {
  const { jobId } = action.payload
  const candidateId = yield select(idSelector)
  yield track({
    event: "copyJobInvitationLinkTrack",
    payload: { candidateId, jobId },
  })
}

export const saga = function* () {
  yield call(toggleGtmSaga)
  yield all([
    takeLatest(
      [
        getActionType(GET, CANDIDATE, SUCCESS),
        getActionType(LOGIN, SUCCESS),
        getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS),
        getActionType(CONFIRM, EMAIL, SUCCESS),
        getActionType(CONNECT, FACEBOOK, SUCCESS),
        getActionType(CONNECT, LINKEDIN, SUCCESS),
      ],
      toggleGtmSaga,
    ),
    takeEvery(getActionType(LOGIN, SUCCESS), loginWithEmailAndPasswordSaga),
    takeEvery(getActionType(CONFIRM, EMAIL, SUCCESS), confirmEmailWithCodeSaga),
    takeEvery(getActionType(CREATE, CANDIDATE, SUCCESS), createCandidateSaga),
    takeEvery(
      getActionType(ACTIVATE, CANDIDATE, INITIAL, SUCCESS),
      activateCandidateFirstTimeSaga,
    ),
    takeEvery(getActionType(UPDATE, CANDIDATE, SUCCESS), updateCandidateSaga),
    takeLatest(
      getActionType(UPDATE, CANDIDATE, TRACKING, REQUEST),
      updateCandidateTrackingSaga,
    ),
    takeEvery(getActionType(CONNECT, FACEBOOK, SUCCESS), connectFacebookSaga),
    takeEvery(getActionType(CONNECT, LINKEDIN, SUCCESS), connectLinkedinSaga),
    takeEvery(getActionType(LOGOUT, SUCCESS), logoutSaga),
    takeEvery(
      getActionType(ACTIVATE, CANDIDATE, MATCHING, SUCCESS),
      toggleActivateMatchingSaga,
    ),
    takeEvery(
      getActionType(DEACTIVATE, CANDIDATE, MATCHING, SUCCESS),
      toggleActivateMatchingSaga,
    ),
    takeEvery(
      getActionType(DISCONNECT, FACEBOOK, SUCCESS),
      disconnectFacebookSaga,
    ),
    takeEvery(
      getActionType(DISCONNECT, LINKEDIN, SUCCESS),
      disconnectLinkedinSaga,
    ),
    takeEvery(
      getActionType(CONFIRM, DELETE, ACCOUNT, SUCCESS),
      deleteAccountSaga,
    ),
    takeEvery(getActionType(APPLY, JOB, SUCCESS), applyForJobSaga),
    takeEvery(getActionType(DISCARD, JOB, SUCCESS), discardJobSaga),
    takeEvery(getActionType(GET, CANDIDATE, SUCCESS), getCandidateSaga),
    takeEvery(
      getActionType(SEND, JOB, INVITATION, SUCCESS),
      sendJobInvitationSaga,
    ),
    takeEvery(
      getActionType(OPEN, JOB, INVITATION, MODAL),
      openJobInvitationModalSaga,
    ),
    takeEvery(
      getActionType(COPY, JOB, INVITATION, LINK),
      copyJobInvitationLinkTrackSaga,
    ),
  ])
}
