import { all, call, put, takeLatest } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType } from "../lib/helpers"
import { pluck } from "ramda"
import {
  JOB,
  LIST,
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  EXTERNAL,
  HIDE,
} from "../constants"

export function* getExternalJobListSaga() {
  yield put({ type: getActionType(GET, EXTERNAL, JOB, LIST, START) })

  try {
    const { data } = yield call(moberriesApi.getExternalJobList)

    const jobs = pluck("job", data)

    yield put({
      type: getActionType(GET, EXTERNAL, JOB, LIST, SUCCESS),
      payload: { jobs, count: jobs.length },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, EXTERNAL, JOB, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* hideExternalJobSaga(action) {
  const { jobId } = action.payload

  yield put({
    type: getActionType(HIDE, EXTERNAL, JOB, START),
    payload: { jobId },
  })

  try {
    yield call(moberriesApi.hideExternalJob, { jobId })

    yield put({
      type: getActionType(HIDE, EXTERNAL, JOB, SUCCESS),
      payload: { jobId },
    })
  } catch (err) {
    yield put({
      type: getActionType(HIDE, EXTERNAL, JOB, FAIL),
      payload: { err },
    })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, EXTERNAL, JOB, LIST, REQUEST),
      getExternalJobListSaga,
    ),
    takeLatest(
      getActionType(HIDE, EXTERNAL, JOB, REQUEST),
      hideExternalJobSaga,
    ),
  ])
}
