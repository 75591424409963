import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, defineMessages } from "react-intl"
import { connect } from "react-redux"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Button } from "../common/button-component"
import { Switch } from "../common/switch-component"
import { acceptTerms } from "../../actions/candidate-actions"
import { config } from "../../config"
import { links } from "../../lib/external-links"
import { localeSelector } from "../../selectors/intl-selector"
import {
  isNewsletterActiveSelector,
  termsVersionSelector,
  termsUpdatingSelector,
  isLoggedSelector,
  firstNameSelector,
} from "../../selectors/candidate-selector"
import { useOpenClose } from "../../hooks/use-open-close"

const messages = defineMessages({
  confirm: { id: "app.common.confirm" },
  confirmTitle: { id: "app.account.confirm.title" },
  newsletterDescription: {
    id: "app.account.notification.newsletter.description",
  },
  greeting: { id: "app.gdpr.agreement.greeting" },
  text: { id: "app.gdpr.agreement.text" },
  agreementTerms: { id: "app.signup.agreement.terms" },
  agreementPrivacy: { id: "app.signup.agreement.privacy" },
})

export const TermsHandlerComponent = ({
  isLogged,
  isUpdating,
  termsVersion,
  firstName,
  locale,
  acceptTerms,
  isNewsletterActive,
}) => {
  const { value: isOpen, toggle } = useOpenClose({
    initialValue: isNewsletterActive,
    enableReinitialize: true,
  })

  if (!isLogged) return null

  if (termsVersion && termsVersion === config.termsVersion) {
    return null
  }

  return (
    <Modal isOpen centered>
      <ModalHeader>
        <FormattedMessage {...messages.confirmTitle} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          {...messages.greeting}
          values={{
            firstName,
          }}
        />
        <br />
        <br />
        <FormattedMessage
          {...messages.text}
          values={{
            terms: (
              <a
                href={links[locale].terms}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.agreementTerms} />
              </a>
            ),
            policy: (
              <a
                href={links[locale].privacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.agreementPrivacy} />
              </a>
            ),
          }}
        />
        <br />
        <br />
        <FormattedMessage {...messages.newsletterDescription} />
        <Switch
          disabled={isUpdating}
          input={{
            onChange: toggle,
            checked: isOpen,
          }}
          meta={{}}
          id="termsHandler"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            acceptTerms({
              isNewsletterActive: isOpen,
              termsVersion: config.termsVersion,
            })
          }
          loading={isUpdating}
          disabled={isUpdating}
        >
          <FormattedMessage {...messages.confirm} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapDispatchToProps = { acceptTerms }

const mapStateToProps = function (state) {
  return {
    locale: localeSelector(state),
    firstName: firstNameSelector(state),
    isLogged: isLoggedSelector(state),
    isUpdating: termsUpdatingSelector(state),
    isNewsletterActive: isNewsletterActiveSelector(state),
    termsVersion: termsVersionSelector(state),
  }
}

TermsHandlerComponent.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  termsVersion: PropTypes.string,
  firstName: PropTypes.string,
  locale: PropTypes.string,
  acceptTerms: PropTypes.func.isRequired,
  isNewsletterActive: PropTypes.bool,
}

TermsHandlerComponent.defaultProps = {
  isLogged: false,
  isUpdating: false,
}

export const TermsHandler = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsHandlerComponent)
