import React, { useState } from "react"
import PropTypes from "prop-types"
import { Select } from "./select-component"
import { FormattedMessage, defineMessages } from "react-intl"
import { CANDIDATE_DELETION_REASONS } from "../../default-options"
import { FEEDBACK_SETS } from "../../constants"
import { Textarea } from "./textarea-component"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"

const messages = defineMessages({
  suggestion: { id: "app.account.delete.confirmation.suggestion" },
  reasonsTitle: { id: "app.account.delete.confirmation.reasons.title" },
  commentTitle: { id: "app.account.delete.confirmation.comment.title" },
  select: { id: "app.common.select" },
})

export const CandidateDeletionFeedback = ({ render }) => {
  const [comment, setComment] = useState("")
  const [selectedReason, setSelectedReason] = useState(null)
  const handleTextareaChange = e => {
    const { value } = e.target
    setComment(value)
  }
  const { feedbackChoices } = useFeedbackChoices({
    feedbackSetType: FEEDBACK_SETS.CANDIDATE_DELETION_REASONS,
  })
  const content = (
    <div>
      <p>
        <FormattedMessage {...messages.suggestion} />
      </p>
      <br />
      <Select
        options={feedbackChoices}
        defaultOptions
        input={{
          onBlur: reason => {
            if (reason) {
              setSelectedReason(reason)
            }
          },
          onChange: setSelectedReason,
          value: selectedReason,
        }}
        getOptionValue={option => option.id}
        getOptionLabel={option => (
          <FormattedMessage
            id={
              CANDIDATE_DELETION_REASONS.find(r => r.value === option.value)
                .name.id
            }
          />
        )}
        isSearchable={false}
        withCaret
        placeholder={<FormattedMessage {...messages.select} />}
        label={
          <span>
            <FormattedMessage {...messages.reasonsTitle} />
          </span>
        }
        meta={{}}
      />
      <Textarea
        label={
          <span>
            <FormattedMessage {...messages.commentTitle} />
          </span>
        }
        type="textarea"
        name="candidate-deletion-comment"
        id="candidate-deletion-comment"
        meta={{}}
        input={{
          value: comment,
          onChange: handleTextareaChange,
        }}
        className="bg-white w-100"
        minRows={3}
        maxRows={15}
      />
    </div>
  )

  if (render) {
    return render({
      content,
      comment,
      selectedReason,
    })
  }

  return content
}

CandidateDeletionFeedback.propTypes = {
  render: PropTypes.func,
}
