import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Form } from "reactstrap"
import { compose } from "redux"
import { Field, Fields, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"

import { JobPositionSection } from "./job-position-section/job-position-section"
import { CareerLevelSection } from "./career-level-section/career-level-section"
import { EmploymentTypeSection } from "./employment-type-section/employment-type-section"
import { RemoteSection } from "./remote-section/remote-section"
import { RelocateSection } from "./relocate-section/relocate-section"
import { AvailabilitySection } from "./availability-section/availability-section"
import { SalarySection } from "./salary-section/salary-section"

import styles from "./preferences-form.module.scss"

const Preferences = ({ handleSubmit, backRoute, isUpdating }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <Field
            name="jobPosition"
            id="jobPosition"
            component={JobPositionSection}
          />
          <Field
            name="careerLevel"
            id="careerLevel"
            component={CareerLevelSection}
          />
          <Field
            name="jobTypes"
            id="jobTypes"
            component={EmploymentTypeSection}
          />
        </div>

        <div className={styles.rightSection}>
          <Field
            name="availability"
            id="availability"
            component={AvailabilitySection}
          />
          <Field name="salaryMin" id="salaryMin" component={SalarySection} />
          <div className={styles.remoteRelocationWrapper}>
            <div className={styles.remoteRelocationItem}>
              <Fields
                names={["relocate", "locations", "relocationCountries"]}
                component={RelocateSection}
              />
            </div>
            <div className={styles.remoteRelocationItem}>
              <Field name="remote" id="remote" component={RemoteSection} />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Link to={backRoute}>
          <button
            className={styles.backButton}
            type="button"
            disabled={isUpdating}
          >
            <FormattedMessage id="app.common.back" />
          </button>
        </Link>
        <button
          className={styles.submitButton}
          type="submit"
          disabled={isUpdating}
        >
          {isUpdating && <i className="fas fa-circle-notch fa-spin mr-1" />}
          <FormattedMessage id="app.common.save" />
        </button>
      </div>
    </Form>
  )
}

export const PreferencesForm = compose(
  injectIntl,
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(Preferences)

Preferences.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  backRoute: PropTypes.string,
  isUpdating: PropTypes.bool,
}
