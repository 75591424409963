import React from "react"
import { FormattedMessage } from "react-intl"

export const RecommendationAdvantages = () => (
  <div>
    <div className="present-icon-wrapper mb-3 text-center">
      <i className="fas fa-trophy" />
    </div>
    <h3 className="text-left font-weight-light">
      <FormattedMessage id="app.hi.advantages.recomendaton" />
    </h3>
    <ul>
      <li>
        <FormattedMessage id="app.hi.advantages.recomendaton.companies" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.recomendaton.verified" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.recomendaton.speed" />
      </li>
      <li>
        <FormattedMessage id="app.hi.advantages.recomendaton.count" />
      </li>
    </ul>
  </div>
)
