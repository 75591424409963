import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./bottom-panel.module.scss"

export function BottomPanel({ tabs, children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.backdrop} />
      <div className={styles.panel}>
        <ul className={styles.tabs}>
          {tabs.map((tab, i) => (
            <li
              key={i}
              className={cn(styles.tab, {
                [styles.tab_active]: tab.active,
              })}
            >
              {tab.name}
            </li>
          ))}
        </ul>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

BottomPanel.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.node.isRequired,
}

BottomPanel.defaultProps = {
  tabs: [],
}
