import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { localeSelector } from "../../../selectors/intl-selector"

import styles from "./announcement-banner.module.scss"

const DISPLAY_BANNER = false

const bannerContent = {
  en: (
    <>
      <i className={`fas fa-bell ${styles.banner__icon}`} />
      <p className={styles.banner__text}>
        Join our free workshop and learn how to create your personal
        website&nbsp;
        <a
          className={styles.banner__link}
          href="https://www.eventbrite.com/e/learn-how-to-create-the-ultimate-personal-website-tickets-483012201497"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </>
  ),
  de: (
    <>
      <i className={`fas fa-bell ${styles.banner__icon}`} />
      <p className={styles.banner__text}>
        Nimm an unserem kostenlosen Workshop teil und lerne&nbsp;
        <a
          className={styles.banner__link}
          href="https://www.eventbrite.com/e/learn-how-to-create-the-ultimate-personal-website-tickets-483012201497"
          target="_blank"
          rel="noopener noreferrer"
        >
          hier
        </a>
        , wie du deine persönliche Website erstellst.
      </p>
    </>
  ),
}

const AnnouncementBannerComponent = ({ locale }) => {
  const initialBannerState = Boolean(localStorage.getItem("bannerClosed"))
  const [isBannerClosed, setIsBannerClosed] = useState(initialBannerState)

  if (isBannerClosed || !DISPLAY_BANNER) return null

  const closeBanner = () => {
    setIsBannerClosed(true)
    localStorage.setItem("bannerClosed", "true")
  }

  return (
    <div className={styles.banner}>
      <div className={styles.banner__content}>{bannerContent[locale]}</div>
      <button className={styles.banner__closeBtn} onClick={closeBanner}>
        <i className="fas fa-times" />
      </button>
    </div>
  )
}

AnnouncementBannerComponent.propTypes = {
  locale: PropTypes.string,
}

const mapStateToProps = state => ({
  locale: localeSelector(state),
})

const mapDispatchToProps = {}

export const AnnouncementBanner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnouncementBannerComponent)
