import { all, call, put, takeLatest } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType } from "../lib/helpers"
import {
  COMPANY,
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  BY,
  SLUG,
} from "../constants"

export function* getCompanyBySlugSaga(action) {
  const { slug } = action.payload

  yield put({ type: getActionType(GET, COMPANY, BY, SLUG, START) })

  try {
    const { data: company } = yield call(moberriesApi.getCompanyBySlug, {
      slug,
    })

    yield put({
      type: getActionType(GET, COMPANY, BY, SLUG, SUCCESS),
      payload: { company },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, COMPANY, BY, SLUG, FAIL),
      payload: { err },
    })
  }
}

export function* getCompanySaga(action) {
  const { id } = action.payload

  yield put({ type: getActionType(GET, COMPANY, START) })

  try {
    const { data: company } = yield call(moberriesApi.getCompany, { id })

    yield put({
      type: getActionType(GET, COMPANY, SUCCESS),
      payload: { company },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, COMPANY, FAIL), payload: { err } })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, COMPANY, BY, SLUG, REQUEST),
      getCompanyBySlugSaga,
    ),
    takeLatest(getActionType(GET, COMPANY, REQUEST), getCompanySaga),
  ])
}
