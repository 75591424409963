import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"

import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  decamelizeInterceptor,
  errorInterceptor,
  pageToOffsetInterceptor,
} from "./axios-interceptors"
import { stringifyListParams } from "./helpers"

export const http = axios.create({
  baseURL: config.moberries.api.url,
  timeout: 30000,
  headers: {}, // https://github.com/axios/axios/pull/1391
})

axiosRetry(http, { retries: 3 })

http.interceptors.response.use(response => response, errorInterceptor)
http.interceptors.response.use(saveOriginalInterceptor)
http.interceptors.response.use(camelizeInterceptor)

http.interceptors.request.use(pageToOffsetInterceptor)
http.interceptors.request.use(decamelizeInterceptor)

export const api = {
  setDefaultHeader: ({ header, value }) => {
    http.defaults.headers[header] = value
  },

  loginWithEmailAndPassword: ({ email, password }) =>
    http({
      url: "/api/v2/obtain_token/",
      method: "POST",
      data: {
        email,
        password,
      },
    }),

  confirmEmailWithCode: ({ code }) =>
    http({
      url: `/api/v2/users/activate/${code}/`,
      method: "PUT",
      data: { code },
    }),

  confirmNewEmailWithCode: ({ code }) =>
    http({
      url: `/api/v2/users/reset_email/activate/${code}/`,
      method: "PUT",
      data: { code },
    }),

  refreshToken: ({ token }) =>
    http({
      url: "/api/v2/refresh_token/",
      method: "POST",
      data: { token },
    }),

  connectSocialAccount: ({
    accessToken,
    platform,
    tracking,
    invitationTracking,
    settings,
    referral,
  }) =>
    http({
      url: "/api/v2/social_accounts/",
      method: "POST",
      data: {
        accessToken,
        platform,
        tracking,
        invitationTracking,
        settings,
        referral,
      },
    }),

  updatePassword: ({ password, passwordNew }) =>
    http({
      url: "/api/v2/users/password_change/",
      method: "PUT",
      data: { password, passwordNew },
    }),

  resendConfirmationEmail: ({ email }) =>
    http({
      url: "/api/v2/users/resend_activation/",
      method: "POST",
      data: { email },
    }),

  recoverPassword: ({ email }) =>
    http({
      url: "/api/v2/users/password_reset/",
      method: "POST",
      data: { email },
    }),

  resetPassword: ({ password, code }) =>
    http({
      url: `/api/v2/users/password_reset/activate/${code}/`,
      method: "PUT",
      data: { password, code },
    }),

  disconnectFacebook: () =>
    http({
      url: `/api/v2/social_accounts/facebook/`,
      method: "DELETE",
    }),

  disconnectLinkedin: () =>
    http({
      url: `/api/v2/social_accounts/linkedin/`,
      method: "DELETE",
    }),

  deleteAccount: () =>
    http({
      url: "/api/v2/users/delete/",
      method: "POST",
    }),

  confirmDeleteAccountWithCode: ({ code }) =>
    http({
      url: `/api/v2/users/delete/confirm/${code}/`,
      method: "PUT",
    }),

  getCandidate: ({ id = "me" }) => http(`/api/v2/candidates/${id}/`),

  exportCandidateData: ({ id = "me" }) =>
    http({
      url: `/api/v2/candidates/export_data/${id}/`,
      responseType: "blob",
    }),

  createCandidate: ({
    firstName,
    lastName,
    email,
    password,
    settings,
    tracking,
    invitationTracking,
    referral,
  }) =>
    http({
      url: "/api/v2/candidates/",
      method: "POST",
      data: {
        firstName,
        lastName,
        email,
        password,
        settings,
        tracking,
        invitationTracking,
        referral,
      },
    }),

  updateCandidate: ({ id = "me", candidate }) =>
    http({
      url: `/api/v2/candidates/${id}/`,
      method: "PATCH",
      data: candidate,
    }),

  uploadCandidateCv: ({ data, cvType }) =>
    http({
      url: "/api/v2/candidates/cv/",
      method: "POST",
      data: { data, cvType },
      // On some machines forming the request can take up to 3 minutes if the file is big enough
      // So we increase the timeout
      timeout: 300000,
    }),

  createCandidateTracking: ({ tracking }) =>
    http({
      url: `/api/v2/candidates/me/tracking/`,
      method: "POST",
      data: tracking,
    }),

  getJobSuggestionList: () =>
    http(`${config.moberries.api.jobSuggestionsURL}`, {
      params: { limit: 20, ordering: "-score" },
    }),

  getJob: ({ jobId }) => http(`/api/v2/jobs/${jobId}/`),

  getJobList: params =>
    http(`/api/v2/jobs/`, { params: { limit: 10, page: 1, ...params } }),

  getMatchList: ({ candidateId = "me", params }) => {
    return http(`/api/v2/candidates/${candidateId}/matches/`, {
      params: {
        limit: 10,
        page: 1,
        ordering: "-created_at",
        ...stringifyListParams(["status__in", "source__in"], params),
      },
    })
  },

  getMatch: ({ matchId }) => http(`/api/v2/candidates/me/matches/${matchId}/`),

  validateMatch: ({ jobId }) =>
    http(`/api/v2/candidates/me/matches/validate/${jobId}/`),

  createMatch: ({ jobId, status, motivationalNote, answers }) =>
    http({
      url: "/api/v2/candidates/me/matches/",
      method: "POST",
      data: {
        job: jobId,
        status,
        coverNote: motivationalNote,
        applicationAnswers: answers,
      },
    }),

  markMatchHired: ({ matchId }) =>
    http({
      url: `/api/v2/candidates/me/matches/${matchId}/`,
      method: "PATCH",
      data: { canHired: true },
    }),

  markMatchNotHired: ({ matchId }) =>
    http({
      url: `/api/v2/candidates/me/matches/${matchId}/`,
      method: "PATCH",
      data: {
        canHired: false,
      },
    }),

  getThreadList: params =>
    http(`/api/v2/threads/`, {
      params: {
        limit: 10,
        page: 1,
        ordering: "last_message_timestamp",
        ...params,
      },
    }),

  getThread: ({ id }) =>
    http({
      url: `/api/v2/threads/${id}/`,
    }),

  getMessageList: ({ threadId, params }) =>
    http(`/api/v2/threads/${threadId}/messages/`, {
      params: { limit: 10, page: 1, ...params },
    }),

  getMessage: ({ threadId, messageId }) =>
    http(`/api/v2/threads/${threadId}/messages/${messageId}/`),

  createMessage: ({ threadId, text }) =>
    http({
      method: "POST",
      url: `/api/v2/threads/${threadId}/messages/`,
      data: { text },
    }),

  getCompanyBySlug: ({ slug }) => http(`/api/v2/companies/${slug}/`),

  getCompany: ({ id }) => http(`/api/v2/companies/${id}/`),

  getCompanyJobsList: ({ id, params }) =>
    http(`/api/v2/companies/${id}/jobs/`, {
      params: { limit: 10, page: 1, ...params },
    }),

  getCompanies: ({ params }) =>
    http(`/api/v2/companies/`, {
      params: {
        limit: 100,
        offset: 0,
        ordering: "name",
        ...params,
      },
    }),

  getProductUpdates: () =>
    http("/api/v2/product_updates/", {
      params: { seen: false, appType: "candidate_app" },
    }),

  markProductUpdateAsRead: ({ id }) =>
    http.post("/api/v2/product_updates/read/", { productUpdate: id }),

  getBotConnectionCodeList: () =>
    http("/api/v2/candidates/me/bot_connection_codes/"),

  createBotConnectionCode: () =>
    http({
      method: "POST",
      url: "/api/v2/candidates/me/bot_connection_codes/",
    }),

  getBotConnectionList: () => http("/api/v2/candidates/me/bot_connections/"),

  getJobCategories: ({ params }) =>
    http("/api/v2/jobrolecategories/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getSkills: ({ params }) =>
    http("/api/v2/skills/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getAssociatedSkills: ({ jobRoleId, params }) =>
    http(`/api/v2/jobroles/${jobRoleId}/associated_skills/`, {
      params: { limit: 50, offset: 0, ...params },
    }),

  getLanguages: ({ params }) =>
    http("/api/v2/languages/", {
      params: { limit: 10, offset: 0, ...params },
    }),

  getFeedbackSets: ({ params }) =>
    http("/api/v2/feedback-sets/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getFeedbackChoices: ({ type, params }) =>
    http(`/api/v2/feedback-sets/${type}/choices/`, {
      params: { limit: 100, offset: 0, ...params },
    }),

  getJobRoles: ({ params }) =>
    http("/api/v2/jobroles/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getInstitutions: ({ params }) =>
    http("/api/v2/education_institution/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getDegrees: ({ params }) =>
    http("/api/v2/education_degree/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  getStudyFields: ({ params }) =>
    http("/api/v2/education_study_field/", {
      params: { limit: 100, offset: 0, ...params },
    }),

  createFeedback: ({ answers, comment }) =>
    http({
      method: "POST",
      url: "/api/v2/feedbacks/",
      data: { answers, comment },
    }),

  linkFeedbackWithMatch: ({ candidateId = "me", matchId, feedback }) =>
    http({
      method: "POST",
      url: `/api/v2/candidates/${candidateId}/matches/${matchId}/feedbacks/`,
      data: { feedback },
    }),

  linkSkillsWithFeedback: ({
    candidateId = "me",
    matchId,
    matchFeedbackId,
    skills,
  }) =>
    http({
      method: "POST",
      url: `/api/v2/candidates/${candidateId}/matches/${matchId}/feedbacks/${matchFeedbackId}/skills/`,
      data: { skills },
    }),

  getIndustriesList: ({ params } = {}) => {
    return http({
      url: "/api/v2/industries/",
      method: "GET",
      params: { limit: 200, ...params },
    })
  },

  getFeedbackSummary: () => http(`/api/v2/candidates/me/matches/feedbacks/`),

  sendJobInvitation: ({ jobId, email }) =>
    http({
      method: "POST",
      url: `/api/v2/jobs/${jobId}/invitations/`,
      data: { email },
    }),

  getMatchesStatistics: () => http(`/api/v2/candidates/me/matches/statistics/`),

  getExternalJobList: () =>
    http(`/api/v2/candidates/me/external_job_recommendations/`, {
      params: { limit: 20 },
    }),

  hideExternalJob: ({ jobId }) =>
    http(`/api/v2/candidates/me/external_job_recommendations/${jobId}/hide/`),

  getApplicationQuestions: ({ params } = {}) =>
    http("/api/v2/application_questions/", {
      params: { ordering: "position", isActive: true, limit: 99, ...params },
    }),

  getMessageProviderVerification: () =>
    http.post("/api/v2/candidates/me/bot_connection_codes/"),

  cancelMessengerSubscription: ({ id }) =>
    http.patch(`/api/v2/candidates/me/bot_subscriptions/${id}/`, {
      isSubscribed: false,
    }),
}
