import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import { compose } from "ramda"
import { Container } from "reactstrap"
import {
  confirmationEmailResendingErrSelector,
  confirmationEmailResendingSelector,
} from "../selectors/user-selector"
import { BaseLayout } from "../components/layouts/base-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { EmailForm } from "../components/forms/email-form"
import { emailValidator } from "../validators/email-validator"
import { resendEmailConfirmation } from "../actions/user-actions"
import { usePrevious } from "react-hanger"
import { useOpenClose } from "../hooks/use-open-close"

const messages = defineMessages({
  header: { id: "app.resend.header" },
  placeholder: { id: "app.common.email.placeholder" },
  send: { id: "app.common.send" },
  successTitle: { id: "app.email.sent.title" },
  successSubtitle: { id: "app.email.sent.subtitle" },
})

export const Resend = ({ resendConfirmationEmail, isResending, err }) => {
  const handleSubmit = ({ email }) => {
    resendConfirmationEmail({ email })
  }
  const prevIsResending = usePrevious(isResending)
  const { value: isOpen, open } = useOpenClose({ initialValue: false })
  useEffect(() => {
    if (prevIsResending && !isResending && !err) {
      open()
    }
  }, [prevIsResending, isResending, err, open])

  if (isOpen) {
    return (
      <BaseLayout className="py-5">
        <Container>
          <h1 className="text-center mb-4">
            <FormattedMessage {...messages.header} />
          </h1>
        </Container>
        <SmallSingleColumnLayout>
          <div className="bg-white px-3 py-5">
            <SmallInnerFormLayout>
              <h3 className="text-center mb-4">
                <FormattedMessage {...messages.successTitle} />
              </h3>
              <h6 className="text-center">
                <FormattedMessage {...messages.successSubtitle} />
              </h6>
              <img
                className="img-fluid"
                src="//assets.moberries.com/images/mail.gif"
                alt="Mail"
              />
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage {...messages.header} />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <div className="mb-4">
              <img
                className="img-fluid"
                alt="Resend Email"
                src="http://i.giphy.com/Z1kpfgtHmpWHS.gif"
              />
            </div>
            <EmailForm
              form="ResendEmailConfirmationForm"
              onSubmit={handleSubmit}
              validate={emailValidator}
              buttonLabel={<FormattedMessage {...messages.send} />}
            />
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

Resend.propTypes = {
  resendConfirmationEmail: PropTypes.func.isRequired,
  isResending: PropTypes.bool.isRequired,
  err: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
}

const mapDispatchToProps = { resendConfirmationEmail: resendEmailConfirmation }

const mapStateToProps = function (state) {
  return {
    isResending: confirmationEmailResendingSelector(state),
    err: confirmationEmailResendingErrSelector(state),
  }
}

export const ResendPage = compose(
  noAuthRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(Resend)
