import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from "../common/button-component"
import { Message } from "./message-component"
import { Link } from "react-router-dom"
import { MessageListLoader } from "../common/message-list-loader"
import { TextLineLoader } from "../common/text-line-loader"
import { InfiniteScroll } from "../common/infinite-scroll-component"
import { NewMessage } from "./new-message-component"
import { Img } from "../common/img-component"
import { propOr, last } from "ramda"
import { isMatchRejected } from "../../lib/helpers"
import { FormattedMessage } from "react-intl"
import { FormattedDate } from "../common/formatted-date-component"

export const Thread = ({
  messages,
  loadMore,
  hasMore,
  loadingPage,
  threadId,
  createMessage,
  creatingMessage,
  creatingMessageErr,
  job,
  match,
  reviewedAt,
}) => {
  const isRejected = isMatchRejected(match)
  return (
    <Fragment>
      <div className="p-3 border-top border-bottom d-flex">
        <div className="d-flex flex-grow-1 overflow-hidden">
          <Button
            tag={Link}
            to="/messages"
            color="light"
            className="mr-2 d-lg-none d-flex align-items-center"
          >
            <i className="fas fa-chevron-left" />
          </Button>
          <div className="text-truncate">
            {job ? (
              <Fragment>
                <Link
                  to={`/company/${job.company.id}`}
                  className="text-truncate d-block _lr-hide"
                >
                  {job.company.name}
                </Link>
                <Link
                  to={`/job/${job.id}`}
                  className="text-truncate text-truncate d-block _lr-hide"
                >
                  {job.title}
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <TextLineLoader width={80} />
                <div className="text-muted text-truncate _lr-hide">
                  <TextLineLoader width={120} />
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <Img
          width="40"
          height="40"
          className="flex-shrink-0"
          src={job ? job.company.logo : ""}
        />
      </div>
      <div
        className={classNames("flex-grow-1 overflow-auto h-15", {
          "loading-thread": loadingPage === 1,
        })}
      >
        {messages.length ? (
          <InfiniteScroll
            id={threadId}
            lastItemId={propOr(0, "id", last(messages))}
            onLoadMore={loadMore}
            hasMore={hasMore}
            rootMargin={200}
            upsideDown
            loader={
              <div className="text-center display-4 text-light h-100">
                <i className="fas fa-circle-notch fa-spin" />
              </div>
            }
          >
            {messages.map(m => (
              <Message message={m} isArchived={isRejected} key={m.id} />
            ))}
          </InfiniteScroll>
        ) : (
          <MessageListLoader />
        )}
      </div>
      <div
        className={classNames({
          "loading-thread": loadingPage === 1,
        })}
      >
        {isRejected ? (
          <div className="bg-danger-light text-danger py-3 flex-grow-1 text-center">
            <FormattedMessage
              values={{
                date: reviewedAt && <FormattedDate value={reviewedAt} />,
              }}
              id="app.page.messages.threads.rejected"
            />
          </div>
        ) : (
          <NewMessage
            onSend={createMessage}
            threadId={threadId}
            creating={creatingMessage}
            err={creatingMessageErr}
          />
        )}
      </div>
    </Fragment>
  )
}

Thread.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  threadId: PropTypes.number,
  loadingPage: PropTypes.number,
  createMessage: PropTypes.func.isRequired,
  creatingMessage: PropTypes.bool.isRequired,
  creatingMessageErr: PropTypes.object,
  job: PropTypes.object,
  match: PropTypes.object,
  reviewedAt: PropTypes.string,
}

Thread.defaultProps = {
  messages: [],
  hasMore: false,
  creatingMessage: false,
}
