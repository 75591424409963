import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { LabelWithText } from "../../common/label-with-text-component/label-with-text-component"
import { ResumeUploader } from "./resume-uploader/resume-uploader"
import { ResumeCreator } from "./resume-creator/resume-creator"

import styles from "./resume-form.module.scss"

export const ResumeForm = ({ backRoute, onFinish, hasCv }) => {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <LabelWithText
            label={
              <span>
                <FormattedMessage id="app.registration.tabs.resume.upload.label" />
              </span>
            }
            text={
              <span className={styles.labelSubtitle}>
                <FormattedMessage id="app.registration.tabs.resume.upload.text" />
              </span>
            }
          />
          <div className={styles.uploaderWrapper}>
            <ResumeUploader />
            <div className={styles.orLabel}>
              <FormattedMessage id="app.common.or" />
            </div>
          </div>
        </div>

        <div className={styles.rightSection}>
          <LabelWithText
            label={
              <span>
                <FormattedMessage id="app.registration.tabs.resume.create.label" />
              </span>
            }
            text={
              <span className={styles.labelSubtitle}>
                <FormattedMessage id="app.registration.tabs.resume.create.text" />
              </span>
            }
          />
          <ResumeCreator />
        </div>
      </div>

      <div className={styles.actions}>
        {backRoute && (
          <Link to={backRoute}>
            <button className={styles.backButton} type="button">
              <FormattedMessage id="app.common.back" />
            </button>
          </Link>
        )}

        <button
          className={styles.submitButton}
          type="button"
          onClick={onFinish}
          disabled={!hasCv}
        >
          {backRoute ? (
            <FormattedMessage id="app.common.finish" />
          ) : (
            <FormattedMessage id="app.common.save" />
          )}
        </button>
      </div>
    </>
  )
}

ResumeForm.propTypes = {
  backRoute: PropTypes.string,
  onFinish: PropTypes.func,
  hasCv: PropTypes.bool,
}
