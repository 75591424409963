import { config } from "../config"
import * as Sentry from "@sentry/react"

let environment = "local"
if (config.branch.isMaster) {
  environment = "development"
} else if (config.branch.isRelease) {
  environment = "production"
}

if (environment !== "local") {
  Sentry.init({
    dsn: "https://a816e61564904f96bd49799a35a57f92@sentry.io/136966",
    release: `${config.package.name}@${config.package.version}`,
    normalizeDepth: 7,
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === "console" && !config.branch.isRelease) {
        return null
      }
      return breadcrumb
    },
    environment: environment,
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      // custom
      "vid_mate_check is not defined",
      'Blocked a frame with origin "https://app.moberries.com" from accessing a cross-origin frame.',
      "window._LRLogger is not a constructor",
      "SecurityError: The operation is insecure",
      "UnhandledRejection: Non-Error",
      "a[b].target.className.indexOf is not a function.",
      "Error: ResizeObserver loop limit exceeded",
      "TypeError: null is not an object (evaluating 'n.title')",
    ],
  })
}
