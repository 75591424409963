import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

export const DoubleColumnLayout = ({ content, sidebar }) => {
  return (
    <Container>
      <Row>
        <Col lg="8" className="mb-4 mb-lg-0">
          {content}
        </Col>
        <Col lg="4">{sidebar}</Col>
      </Row>
    </Container>
  )
}

DoubleColumnLayout.propTypes = {
  content: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
}
