import React, { useEffect } from "react"
import cookie from "js-cookie"
import { HiPageLayout } from "../components/layouts/hi-page-layout"
import { RecommendationAdvantages } from "../components/signup/recommendation-advantages-component"
import { MoberriesAdvantages } from "../components/signup/moberries-advantages-component"
import { getCompanyBySlug } from "../actions/company-actions"
import { useDispatch, useSelector } from "react-redux"
import { useRouteMatch, Redirect } from "react-router"
import {
  companyBySlugSelector,
  companySelector,
  errorSelector,
} from "../selectors/company-selector"
import { SignupSection } from "../components/signup/signup-section-component"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { noAuthRequired } from "../decorators/no-auth-required"
import { CLIENT_ERROR } from "../constants"
import { ErrorPage } from "./error-page"
import { config } from "../config"

export const SignupHiPageComponent = () => {
  const dispatch = useDispatch()
  const routeMatch = useRouteMatch()
  const companySlugOrId = routeMatch.params.slugOrId

  const company = useSelector(state => {
    if (/^\d+$/.test(companySlugOrId)) {
      return companySelector(state, { id: companySlugOrId })
    } else {
      return companyBySlugSelector(state, { slug: companySlugOrId })
    }
  })

  const err = useSelector(errorSelector)

  useEffect(() => {
    dispatch(getCompanyBySlug({ slug: companySlugOrId }))
  }, [companySlugOrId, dispatch])

  useEffect(() => {
    // Since this page should work without the ref param,
    // We simulate its existence by setting the cookie,
    // As if it would be done if the param was there
    if (company) {
      cookie.set(config.cookie.keys.ref, `c-${company.id}`, {
        expires: 365 * 2,
        domain: config.cookie.domain,
      })
    }
  }, [company])

  if (err) {
    if (err.problem === CLIENT_ERROR && err.response.status === 404) {
      return <Redirect to="/signup" />
    } else {
      return <ErrorPage err={err} />
    }
  }

  if (!company) {
    return null
  }

  return (
    <HiPageLayout
      logo={company.logo}
      title={
        <h1 className="display-4 font-weight-light mb-5">
          <FormattedMessage id="app.hi.title" />
        </h1>
      }
      subtitle={
        <FormattedMessage
          id="app.hi.subtitle"
          values={{
            companyName: (
              <Link to={`/company/${company.id}`} className="text-success">
                {company.name}
              </Link>
            ),
          }}
        />
      }
    >
      <HiPageLayout.Left>
        {/* We can't inject this with the google tag manager, as this page works without the ref param. 
            Can be dropped after the all referrals are routed via berries.io/r/ */}
        {
          <img
            src={`${config.moberries.authProxy.url}/collect/referral_click?ref=c-${company.id}`}
            alt=""
          />
        }
        <RecommendationAdvantages />
        <MoberriesAdvantages />
      </HiPageLayout.Left>
      <HiPageLayout.Right>
        <SignupSection />
      </HiPageLayout.Right>
    </HiPageLayout>
  )
}

export const SignupHiPage = noAuthRequired(SignupHiPageComponent)
