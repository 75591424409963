import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import cn from "classnames"
import { Img } from "../common/img-component"
import { convertStringToInteger, getLocationName } from "../../lib/helpers"
import { Badge } from "../common/badge-component"
import { FormattedMessage, defineMessages } from "react-intl"
import { ACCENT_COLORS } from "../../constants"

const messages = defineMessages({
  remote: { id: "app.common.remote" },
})

export const ExternalJobCard = ({
  company,
  title,
  locations,
  remote,
  controls,
  style,
  className,
}) => {
  const cardProps = {}

  if (style) {
    cardProps.style = style
  }

  return (
    <Media className={cn("bg-white rounded p-3", className)} {...cardProps}>
      <Media
        style={{
          width: "80px",
          height: "80px",
        }}
        className="mr-3 d-none d-md-flex align-items-center justify-content-center"
        left
      >
        {company.logo ? (
          <Img src={company.logo} alt={company.name} className="w-100" />
        ) : (
          <div
            style={{
              backgroundColor:
                ACCENT_COLORS[convertStringToInteger(company.name)],
            }}
            className="w-100 h-100 d-md-flex align-items-center justify-content-center"
          >
            <h1>{company.name[0].toUpperCase()}</h1>
          </div>
        )}
      </Media>
      <Media body>
        <small>{company.name}</small>
        <h4 className="mb-1">{title}</h4>
        <div className="mb-2">
          {remote && (
            <Badge color="info-light mr-2">
              <i className="fas fa-globe-americas" />
              <FormattedMessage {...messages.remote} />
            </Badge>
          )}
          {locations.length > 0 &&
            locations.map(l => (
              <span
                key={l.placeId}
                className="d-inline-block text-muted text-smaller mr-2 align-middle"
              >
                <i className="fas fa-map-marker-alt mr-1" />
                {getLocationName(l)}
              </span>
            ))}
        </div>
      </Media>
      {controls && <div className="pl-2 text-right">{controls}</div>}
    </Media>
  )
}

ExternalJobCard.propTypes = {
  id: PropTypes.number.isRequired,
  company: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  controls: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  remote: PropTypes.bool,
}

ExternalJobCard.defaultProps = {
  companyLogo: "",
  companyName: "",
  jobTitle: "",
  salaryMin: "",
  salaryMax: "",
  locations: [],
  className: "",
  remote: false,
}
