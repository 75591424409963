import { connectedReduxRedirect } from "redux-auth-wrapper/history4/redirect"
import { push } from "connected-react-router"

export const authRequired = connectedReduxRedirect({
  redirectPath: "/login",
  authenticatedSelector: state => Boolean(state.candidate.entity),
  wrapperDisplayName: "authRequired",
  redirectQueryParamName: "redirectTo",
  redirectAction: push,
})
