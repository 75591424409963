import React, { useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { compose, prop, sum } from "ramda"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Col, Row } from "reactstrap"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { ErrorPage } from "./error-page"
import { authRequired } from "../decorators/auth-required"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { getMatchesStatistics, getMatchList } from "../actions/match-actions"
import { Pagination } from "../components/common/pagination-component"
import { JobCardStatus } from "../components/job/job-card-status-label-component"
import {
  getHiringReward,
  shouldRenderApplied,
  matchStatusToQueryTransformer,
} from "../lib/helpers"
import { JobCard } from "../components/job/job-card-component"
import { Applied } from "../components/common/applied-component"
import { ApplicationsPageLayout } from "../components/layouts/applications-page-layout"
import { ApplicationsPageSkeleton } from "../components/skeletons/applications-page-skeleton"
import { ApplicationsPageEmpty } from "../components/empty-states/applications-page-empty"
import { BaseSidebar } from "../components/sidebar/base-sidebar"
import {
  countSelector,
  errorSelector,
  loadingSelector,
  matchesSelector,
  matchCountByStatusSelector,
} from "../selectors/match-selector"
import { ReportHireBlock } from "../components/common/report-hire-block-component"
import {
  candidateSelector,
  hasAnyActiveSubscriptionSelector,
} from "../selectors/candidate-selector"
import { Select } from "../components/common/select-component"
import { MATCH_LIST_FILTER_OPTIONS } from "../default-options"
import { ApplicationsLoader } from "../components/common/applications-loader"
import { useFilter } from "../hooks/use-filter"

const messages = defineMessages({
  title: { id: "app.applications.title" },
  subtitle: { id: "app.applications.description" },
  applicationsCount: { id: "app.applications.count" },
  statusFilter: { id: "app.applications.statusFilter" },
})

export const ApplicationsPageComponent = ({
  matches,
  err,
  count,
  limit,
  page,
  loading,
  candidate,
  getMatchList,
  getMatchesStatistics,
  matchCountByStatus,
  hasAnyActiveSubscription,
}) => {
  const { activeFilter, filters, applyFilter } = useFilter({
    filterOptions: MATCH_LIST_FILTER_OPTIONS,
    filterKey: "status",
    getValue: filter => filter.value,
    getTitle: filter => filter.name,
  })

  const intl = useIntl()
  const status = prop("value", activeFilter)
  useEffect(() => {
    getMatchList({
      params: {
        page,
        limit,
        status__in: matchStatusToQueryTransformer(status),
      },
    })
  }, [page, limit, getMatchList, status])

  useEffect(() => {
    getMatchesStatistics()
  }, [getMatchesStatistics])

  if (err) {
    return <ErrorPage err={err} />
  }
  if (count === 0 && !activeFilter && !loading) {
    return <ApplicationsPageEmpty />
  }

  if (count === null) {
    return <ApplicationsPageSkeleton />
  }

  if (matches.length === 0 && page > 1) {
    return <Redirect to="/applications" />
  }

  return (
    <ApplicationsPageLayout
      header={
        <Fragment>
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <p className="text-muted">
            <FormattedMessage {...messages.subtitle} />
          </p>
        </Fragment>
      }
      content={
        <Fragment>
          {candidate.active && candidate.statistics.matchesAccepted >= 1 && (
            <ReportHireBlock />
          )}
          <Row className="bg-white p-3 mb-2 mx-0">
            <Col
              xs="12"
              md="4"
              className="d-flex align-items-center px-0 mb-2 mb-md-0"
            >
              <FormattedMessage
                {...messages.applicationsCount}
                values={{ count }}
              />
            </Col>

            <div className="flex-grow-1">
              <Select
                formatOptionLabel={({ title, value }) => {
                  const matchStatuses = matchStatusToQueryTransformer(value)
                  const matchStatusesCounts = matchStatuses.map(
                    status => matchCountByStatus[status],
                  )
                  const matchesCount = sum(matchStatusesCounts)
                  return (
                    <div className="d-flex w-100 justify-content-between">
                      <div>{intl.formatMessage(title)}</div>
                      <div>
                        <span className="matches-badge"> {matchesCount}</span>
                      </div>
                    </div>
                  )
                }}
                meta={{}}
                options={filters}
                getOptionLabel={o => intl.formatMessage(o.title)}
                placeholder={intl.formatMessage(messages.statusFilter)}
                input={{
                  onChange: applyFilter,
                  value: activeFilter,
                }}
                wrapper={Fragment}
                isSearchable={false}
                withCaret
                isClearable
                styles={{
                  singleValue: (provided, state) => ({
                    ...provided,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    display: "flex",
                    justifyContent: "space-between",
                  }),
                }}
              />
            </div>
          </Row>

          {loading ? (
            <ApplicationsLoader />
          ) : (
            matches.map(m => (
              <div key={m.id} className="mb-2">
                <JobCard
                  id={m.job.id}
                  companyLogo={m.job.company.logo}
                  companyName={m.job.company.name}
                  companyId={m.job.company.id}
                  jobTitle={m.job.title}
                  jobId={m.job.id}
                  salaryMin={m.job.salaryMin}
                  salaryMax={m.job.salaryMax}
                  locations={m.job.locations}
                  hiringRewardEuros={getHiringReward(m)}
                  isRemote={m.job.remote}
                  hasQuestions={m.job.hasQuestions}
                  controls={
                    <Fragment>
                      {shouldRenderApplied(m) && (
                        <div className="mb-1 d-none d-md-block">
                          <Applied match={m} />
                        </div>
                      )}
                      <JobCardStatus
                        matchId={m.id}
                        matchStatus={m.status}
                        jobStatus={m.job.status}
                      />
                    </Fragment>
                  }
                />
              </div>
            ))
          )}

          {count > limit && (
            <Pagination
              className="mt-3"
              listClassName="justify-content-end"
              page={page}
              count={count}
              disabled={loading}
              limit={limit}
              basePath="/applications"
            />
          )}
        </Fragment>
      }
      sidebar={<BaseSidebar showBotSubscription={false} />}
    />
  )
}

ApplicationsPageComponent.propTypes = {
  getMatchList: PropTypes.func.isRequired,
  getMatchesStatistics: PropTypes.func.isRequired,
  matchCountByStatus: PropTypes.object,
  matches: PropTypes.array.isRequired,
  count: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  err: PropTypes.object,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  candidate: PropTypes.object.isRequired,
  hasAnyActiveSubscription: PropTypes.bool.isRequired,
}

ApplicationsPageComponent.defaultProps = {
  matches: [],
  page: 1,
  limit: 10,
}

const mapDispatchToProps = { getMatchList, getMatchesStatistics }

const mapStateToProps = (state, { match }) => ({
  page: match.params.page && parseInt(match.params.page, 10),
  matches: matchesSelector(state),
  count: countSelector(state),
  err: errorSelector(state),
  loading: loadingSelector(state),
  candidate: candidateSelector(state),
  matchCountByStatus: matchCountByStatusSelector(state),
  hasAnyActiveSubscription: hasAnyActiveSubscriptionSelector(state),
})

export const ApplicationsPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationsPageComponent)
