import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Header } from "../common/header-component"
import { Footer } from "../common/footer-component"
import { NoCvAlert } from "../common/no-cv-alert-component"
import cn from "classnames"

export const BaseLayout = props => {
  const css = cn("main-content", props.className)
  return (
    <Fragment>
      <Header />
      <NoCvAlert />
      <main role="main" className={css}>
        {props.children}
      </main>
      <Footer />
    </Fragment>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
