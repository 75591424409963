import { connectedReduxRedirect } from "redux-auth-wrapper/history4/redirect"
import { push } from "connected-react-router"
import { isCompleteSelector } from "../selectors/candidate-selector"

export const noCompletedProfileRequired = connectedReduxRedirect({
  redirectPath: "/profile",
  allowRedirectBack: false,
  authenticatedSelector: state => !isCompleteSelector(state),
  wrapperDisplayName: "noCompletedProfileRequired",
  redirectAction: push,
})
