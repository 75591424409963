import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTransition, animated } from "react-spring"
import { JobDeclineFeedback } from "../job/job-decline-feedback-component"
import { JobCard } from "../job/job-card-component"
import { Button } from "../common/button-component"
import { JobDescription } from "../job/job-description-component"
import { Collapse } from "reactstrap"
import { JobInfo } from "../job/job-info-component"
import { JobApplicationControls } from "../job/job-application-controls-component"
import { getArrowClassName } from "../../lib/helpers"
import { isCreatingJobFeedbackSelector } from "../../selectors/job-selector"
import { candidateSelector } from "../../selectors/candidate-selector"
import { closeJobFeedback, createJobFeedback } from "../../actions/job-actions"

export const JobDashboardCardComponent = ({
  job,
  isCreatingJobFeedback,
  isOpen,
  closeJobFeedback,
  createJobFeedback,
  toggle,
  candidate,
}) => {
  const transition = useTransition(job.isFeedbackOpen, {
    enter: {
      transform: "scale(1)",
      opacity: 1,
      overflow: "hidden",
      maxHeight: 5000,
    },
    leave: { transform: "scale(0.5)", opacity: 0, maxHeight: 0 },
  })

  return transition((style, isFeedbackOpen) =>
    isFeedbackOpen ? (
      <animated.div style={style}>
        <JobDeclineFeedback
          jobId={job.id}
          jobTitle={job.title}
          isCreatingFeedback={isCreatingJobFeedback}
          jobSkills={job.skills}
          createFeedback={createJobFeedback}
          closeFeedback={() => closeJobFeedback({ jobId: job.id })}
          withFeedbackRequest
        />
      </animated.div>
    ) : (
      <animated.div style={style}>
        <JobCard
          id={job.id}
          companyLogo={job.company.logo}
          companyName={job.company.name}
          companyId={job.company.id}
          jobTitle={job.title}
          jobId={job.id}
          salaryMin={job.salaryMin}
          salaryMax={job.salaryMax}
          locations={job.locations}
          hiringRewardEuros={job.hiringRewardEuros}
          isRemote={job.remote}
          hasQuestions={job.hasQuestions}
          controls={
            <Button
              type="button"
              color="light"
              size="lg"
              pill
              className="btn-circle p-2"
              onClick={toggle}
            >
              <i className={getArrowClassName(isOpen)} />
            </Button>
          }
        />
        <Collapse isOpen={isOpen}>
          <JobApplicationControls jobId={job.id} />
          <JobInfo job={job} candidateSkills={candidate?.skills} />
          <JobDescription description={job.description} />
        </Collapse>
      </animated.div>
    ),
  )
}

JobDashboardCardComponent.propTypes = {
  job: PropTypes.object,
  isOpen: PropTypes.bool,
  isCreatingJobFeedback: PropTypes.bool,
  closeJobFeedback: PropTypes.func,
  createJobFeedback: PropTypes.func,
  toggle: PropTypes.func,
  candidate: PropTypes.object.isRequired,
}

JobDashboardCardComponent.defaultProps = {
  isCreatingJobFeedback: false,
  isOpen: false,
}

const mapStateToProps = state => ({
  isCreatingJobFeedback: isCreatingJobFeedbackSelector(state),
  candidate: candidateSelector(state),
})

const mapDispatchToProps = {
  closeJobFeedback,
  createJobFeedback,
}

export const JobDashboardCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDashboardCardComponent)
