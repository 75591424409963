import React, { Fragment } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { DashboardPage } from "./pages/dashboard-page"
import { JobPage } from "./pages/job-page"
import { CompanyPage } from "./pages/company-page"
import { ApplicationsPage } from "./pages/applications-page"
import { FeedbackSummaryPage } from "./pages/feedback-summary-page"
import { MessengerPage } from "./pages/messenger-page"
import { AccountPage } from "./pages/account-page"
import { AccountSocialPage } from "./pages/account-social-page"
import { AccountSettingsPage } from "./pages/account-settings-page"
import { AccountPrivacyPage } from "./pages/account-privacy-page"
import { ResendPage } from "./pages/resend-page"
import { ConfirmNewEmailPage } from "./pages/confirm-new-email-page"
import { ConfirmEmailPage } from "./pages/confirm-email-page"
import { ConfirmDeleteAccountPage } from "./pages/confirm-account-delete-page"
import { PasswordRecoveryPage } from "./pages/password-recovery-page"
import { PasswordResetPage } from "./pages/password-reset-page"
import { ConnectMoberriesBotPage } from "./pages/connect-moberries-bot-page"
import { AuthCallbackPage } from "./pages/auth-callback-page"
import { NotFoundPage } from "./pages/not-found-page"
import { MessengerThreadPage } from "./pages/messenger-thread-page"
import { SignupHiPage } from "./pages/signup-hi-page"
import { SignupInvitePage } from "./pages/signup-invite-page/signup-invite-page"
import { SignupApplyPage } from "./pages/signup-apply-page/signup-apply-page"
import { SignupEmailSentPage } from "./pages/signup-email-sent-page"
import { ConfirmMobotEmailPage } from "./pages/confirm-mobot-email-page"
import { SignupPage } from "./pages/signup-page/signup-page"
import { LoginPage } from "./pages/login-page/login-page"
import { RegistrationPersonalPage } from "./pages/registration-personal-page"
import { RegistrationProfessionalPage } from "./pages/registration-professional-page"
import { RegistrationPreferencePage } from "./pages/registration-preference-page"
import { RegistrationResumePage } from "./pages/registration-resume-page"
import { ProfileMyPreferencePage } from "./pages/profile-page/profile-my-preference-page"
import { ProfileProfessionalDetailsPage } from "./pages/profile-page/profile-professional-details-page"
import { ProfileEditPage } from "./pages/profile-page/profile-edit-page"
import { ProfilePage } from "./pages/profile-page/profile-page"
import { ProfileEditProfessionalDetailsPage } from "./pages/profile-page/profile-edit-professional-details-page"
import { ProfileEditMyPreferencePage } from "./pages/profile-page/profile-edit-my-preference-page"
import { ProfileEditMyResumePage } from "./pages/profile-page/profile-edit-resume-page"

export function Routes() {
  return (
    <Fragment>
      <Switch>
        <Redirect exact from="/reset" to="/password-recovery" />
        <Redirect from="/forgot-password" to="/password-recovery" />
        <Redirect from="/matches/:page?" to="/applications/:page?" />
        <Redirect exact from="/registration" to="/registration/personal" />

        <Route path="/" exact component={DashboardPage} />
        <Route path="/job/:id(\d+)" component={JobPage} />
        <Route path="/company/:id/:page?" component={CompanyPage} />
        <Route
          path="/applications/summary"
          exact
          component={FeedbackSummaryPage}
        />
        <Route path="/applications/:page?" component={ApplicationsPage} />
        <Route path="/messages" exact component={MessengerPage} />
        <Route path="/messages/:id" component={MessengerThreadPage} />
        <Route path="/signup" component={SignupPage} />

        <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/profile/edit" component={ProfileEditPage} />
        <Route
          exact
          path="/profile/personal"
          component={ProfileProfessionalDetailsPage}
        />
        <Route
          exact
          path="/profile/personal/edit"
          component={ProfileEditProfessionalDetailsPage}
        />
        <Route
          exact
          path="/profile/preferences"
          component={ProfileMyPreferencePage}
        />
        <Route
          exact
          path="/profile/preferences/edit"
          component={ProfileEditMyPreferencePage}
        />
        <Route
          exact
          path="/profile/resume/edit"
          component={ProfileEditMyResumePage}
        />
        <Route
          path="/registration/personal"
          component={RegistrationPersonalPage}
        />
        <Route
          path="/registration/professional"
          component={RegistrationProfessionalPage}
        />
        <Route
          path="/registration/preference"
          component={RegistrationPreferencePage}
        />
        <Route path="/registration/resume" component={RegistrationResumePage} />

        <Route path="/account" exact component={AccountPage} />
        <Route path="/account/social" component={AccountSocialPage} />
        <Route path="/account/settings" component={AccountSettingsPage} />
        <Route path="/account/privacy" component={AccountPrivacyPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/hi/:slugOrId" component={SignupHiPage} />
        <Route path="/invite" component={SignupInvitePage} />
        <Route path="/apply/:id(\d+)" component={SignupApplyPage} />
        <Route path="/signup-email-sent" component={SignupEmailSentPage} />
        <Route path="/resend" component={ResendPage} />
        <Route path="/confirm/email/:code" component={ConfirmNewEmailPage} />
        <Route
          path="/confirm/email-mobot/:code"
          component={ConfirmMobotEmailPage}
        />
        <Route
          path="/confirm/account/delete/:code"
          component={ConfirmDeleteAccountPage}
        />
        <Route path="/confirm/:code" component={ConfirmEmailPage} />
        <Route path="/password-recovery" component={PasswordRecoveryPage} />
        <Route path="/reset/:code" component={PasswordResetPage} />
        <Route path="/connect-mobot" component={ConnectMoberriesBotPage} />
        <Route path="/auth/callback" component={AuthCallbackPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Fragment>
  )
}
