import { getActionType } from "../lib/helpers"
import { GET, COMPANY, REQUEST, LIST, BY, SLUG } from "../constants"

export function getCompanyList({ params }) {
  return {
    type: getActionType(GET, COMPANY, LIST, REQUEST),
    payload: { params },
  }
}

export function getCompanyBySlug({ slug }) {
  return {
    type: getActionType(GET, COMPANY, BY, SLUG, REQUEST),
    payload: { slug },
  }
}

export function getCompanyRequest({ id }) {
  return {
    type: getActionType(GET, COMPANY, REQUEST),
    payload: { id },
  }
}
