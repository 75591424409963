import { all, put, call, takeLatest, select } from "redux-saga/effects"
import { api as topCvApi } from "../lib/top-cv-proxy-api"
import { getActionType } from "../lib/helpers"
import { stateSelector as topCvSelector } from "../selectors/top-cv-selector"
import {
  START,
  SUCCESS,
  FAIL,
  ACTIVATE,
  UPLOAD,
  CANDIDATE,
  INITIAL,
  TOPCV,
  REQUEST,
} from "../constants"
import {
  firstNameSelector,
  lastNameSelector,
  emailSelector,
  cvSelector,
} from "../selectors/candidate-selector"

export function* uploadToTopCv() {
  const topCvChecked = yield select(topCvSelector)

  if (!topCvChecked) {
    return
  }

  yield put({ type: getActionType(UPLOAD, TOPCV, START) })

  const cv = yield select(cvSelector)

  if (!cv) return null

  const { urls = {} } = cv

  const data = {
    firstName: yield select(firstNameSelector),
    lastName: yield select(lastNameSelector),
    email: yield select(emailSelector),
    resumeFileUrl: urls.original,
  }

  try {
    yield call(topCvApi.uploadToTopCv, data)

    yield put({
      type: getActionType(UPLOAD, TOPCV, SUCCESS),
    })
  } catch (err) {
    yield put({
      type: getActionType(UPLOAD, TOPCV, FAIL),
      payload: { err },
    })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(ACTIVATE, CANDIDATE, INITIAL, SUCCESS),
      uploadToTopCv,
    ),
    takeLatest(getActionType(UPLOAD, TOPCV, REQUEST), uploadToTopCv),
  ])
}
