import { getActionType } from "../lib/helpers"
import {
  GET,
  UPDATE,
  CANDIDATE,
  REQUEST,
  CREATE,
  SETTINGS,
  LOCALE,
  ACTIVATE,
  MATCHING,
  DEACTIVATE,
  CV,
  UPLOAD,
  INITIAL,
  TRACKING,
  ID,
  SET,
  ACCEPT,
  TERMS,
  DATA,
  EXPORT,
  ACTIVATION,
  CANCELLED,
  REFRESH,
  APPLICATION,
  APPLY,
  PREDICTION,
  NEWSLETTER,
  EMAIL,
  ON,
  MATCH,
  ACTIVE,
  INITIATE,
  SUBSCRIPTION,
  CANCEL,
  CLOSE,
  MODAL,
} from "../constants"

export function getCandidate({ id }) {
  return {
    type: getActionType(GET, CANDIDATE, REQUEST),
    payload: { id },
  }
}

export function refreshCandidate() {
  return {
    type: getActionType(REFRESH, CANDIDATE),
  }
}

export function exportCandidateData({ id }) {
  return {
    type: getActionType(EXPORT, CANDIDATE, DATA, REQUEST),
    payload: { id },
  }
}

export function createCandidate({
  firstName,
  lastName,
  email,
  password,
  settings,
}) {
  return {
    type: getActionType(CREATE, CANDIDATE, REQUEST),
    payload: { firstName, lastName, email, password, settings },
  }
}

export function updateCandidate({ id, candidate, formName, routing }) {
  return {
    type: getActionType(UPDATE, CANDIDATE, REQUEST),
    payload: { id, candidate, formName, routing },
  }
}

export function applyCandidatePrediction({ fields, formName }) {
  return {
    type: getActionType(APPLY, CANDIDATE, PREDICTION, REQUEST),
    payload: { fields, formName },
  }
}

export function updateCandidateLocale({ id, locale }) {
  return {
    type: getActionType(UPDATE, CANDIDATE, LOCALE, REQUEST),
    payload: { id, locale },
  }
}

export function activateCandidateInitial({ id, candidate, formName }) {
  return {
    type: getActionType(ACTIVATE, CANDIDATE, INITIAL, REQUEST),
    payload: { id, candidate, formName },
  }
}
export function setIsEmailOnMatchActive({ isEmailOnMatchActive }) {
  return {
    type: getActionType(SET, EMAIL, ON, MATCH, ACTIVE, REQUEST),
    payload: { isEmailOnMatchActive },
  }
}

export function setIsNewsletterActive({ isNewsletterActive }) {
  return {
    type: getActionType(SET, NEWSLETTER, ACTIVE, REQUEST),
    payload: { isNewsletterActive },
  }
}

export function initiateSubscriptionVerification({ messageProvider }) {
  return {
    type: getActionType(INITIATE, SUBSCRIPTION, REQUEST),
    payload: { messageProvider },
  }
}

export function cancelMessengerSubscription({ id, messageProvider }) {
  return {
    type: getActionType(CANCEL, SUBSCRIPTION, REQUEST),
    payload: { id, messageProvider },
  }
}

export function closeSubscriptionModal() {
  return {
    type: getActionType(CLOSE, SUBSCRIPTION, MODAL),
  }
}

export function activateCandidateTracking({ id }) {
  return {
    type: getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, REQUEST),
    payload: { id },
  }
}

export function deactivateCandidateTracking({ id }) {
  return {
    type: getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, REQUEST),
    payload: { id },
  }
}

export function activateMatching({ id }) {
  return {
    type: getActionType(ACTIVATE, CANDIDATE, MATCHING, REQUEST),
    payload: { id },
  }
}

export function deactivateMatching({ id }) {
  return {
    type: getActionType(DEACTIVATE, CANDIDATE, MATCHING, REQUEST),
    payload: { id },
  }
}

export function setMatchingActivationRequestCancelled({ newStatus }) {
  return {
    type: getActionType(SET, MATCHING, ACTIVATION, REQUEST, CANCELLED),
    payload: { newStatus },
  }
}

export function createCandidateCv({ data, cvType }) {
  return {
    type: getActionType(CREATE, CANDIDATE, CV, REQUEST),
    payload: { data, cvType },
  }
}

export function uploadCandidateCv({ data, cvType }) {
  return {
    type: getActionType(UPLOAD, CANDIDATE, CV, REQUEST),
    payload: { data, cvType },
  }
}

export function acceptTerms({ id, isNewsletterActive, termsVersion }) {
  return {
    type: getActionType(ACCEPT, TERMS, REQUEST),
    payload: { id, isNewsletterActive, termsVersion },
  }
}

export function setApplicationId({ id }) {
  return {
    type: getActionType(SET, APPLICATION, ID),
    payload: {
      id,
    },
  }
}

export function updateCandidateTracking({ tracking, formName }) {
  return {
    type: getActionType(UPDATE, CANDIDATE, TRACKING, REQUEST),
    payload: {
      tracking,
      formName,
    },
  }
}
