import { getActionType } from "../lib/helpers"
import {
  GET,
  REQUEST,
  MESSAGE,
  CREATE,
  INIT,
  THREAD,
  PAGE,
  MESSAGES,
  ADDITIONAL,
} from "../constants"

export function createMessage({ threadId, text }) {
  return {
    type: getActionType(CREATE, MESSAGE, REQUEST),
    payload: { threadId, text },
  }
}

export function initThreadPage({ threadId }) {
  return {
    type: getActionType(INIT, THREAD, PAGE, REQUEST),
    payload: {
      threadId,
    },
  }
}

export function getAdditionalThreadMessages({ threadId, page }) {
  return {
    type: getActionType(GET, ADDITIONAL, THREAD, MESSAGES, REQUEST),
    payload: { threadId, page },
  }
}
