import "react-toastify/dist/ReactToastify.css"
import "./styles/main.scss"
import "./lib/polyfills"
import "./lib/sentry"
import browserUpdate from "browser-update"
import React from "react"
import ReactDOM from "react-dom"
import { Root } from "./root"

browserUpdate({
  required: {
    c: -5,
    e: -5,
    f: -5,
    o: -5,
    v: -5,
    y: -5,
    i: 0,
    s: -1,
    samsung: -3,
  },
  insecure: true,
  no_permanent_hide: true,
  reminder: 14 * 24,
})

ReactDOM.render(<Root />, document.getElementById("root"))
