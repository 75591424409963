import {
  all,
  call,
  fork,
  put,
  select,
  takeLatest,
  take,
} from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType } from "../lib/helpers"
import { history } from "../history"
import humps from "humps"
import qs from "qs"
import {
  isCompleteSelector,
  isLoggedSelector,
} from "../selectors/candidate-selector"
import {
  BOT,
  CONNECTION,
  CODE,
  LIST,
  FAIL,
  GET,
  REQUEST,
  CREATE,
  START,
  SUCCESS,
  LOGIN,
  ACTIVATE,
  CANDIDATE,
  INITIAL,
} from "../constants"

export function* createBotConnectionCodeSaga() {
  yield put({ type: getActionType(CREATE, BOT, CONNECTION, CODE, START) })

  try {
    const {
      data: { code },
    } = yield call(moberriesApi.createBotConnectionCode)

    yield put({
      type: getActionType(CREATE, BOT, CONNECTION, CODE, SUCCESS),
      payload: { code },
    })
  } catch (err) {
    yield put({
      type: getActionType(CREATE, BOT, CONNECTION, CODE, FAIL),
      payload: { err },
    })
  }
}

export function* getBotConnectionListSaga() {
  yield put({ type: getActionType(GET, BOT, CONNECTION, LIST, START) })

  try {
    const {
      data: { results: connections, count },
    } = yield call(moberriesApi.getBotConnectionList)

    yield put({
      type: getActionType(GET, BOT, CONNECTION, LIST, SUCCESS),
      payload: { connections, count },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, BOT, CONNECTION, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* watchBotCodeSaga() {
  const query = humps.camelizeKeys(
    qs.parse(history.location.search, { ignoreQueryPrefix: true }),
  )

  if (!query.accountLinkingToken || !query.redirectUri) {
    return
  }

  const [isLogged, isComplete] = yield all([
    select(isCompleteSelector),
    select(isLoggedSelector),
  ])

  if (!isLogged || !isComplete) {
    return
  }

  yield fork(createBotConnectionCodeSaga)

  const {
    payload: { code },
  } = yield take(getActionType(CREATE, BOT, CONNECTION, CODE, SUCCESS))

  window.location.assign(`${query.redirectUri}&authorization_code=${code}`)
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(CREATE, BOT, CONNECTION, CODE, REQUEST),
      createBotConnectionCodeSaga,
    ),
    takeLatest(
      getActionType(GET, BOT, CONNECTION, LIST, REQUEST),
      getBotConnectionListSaga,
    ),
    takeLatest(
      [
        getActionType(LOGIN, SUCCESS),
        getActionType(ACTIVATE, CANDIDATE, INITIAL, SUCCESS),
      ],
      watchBotCodeSaga,
    ),
    watchBotCodeSaga(),
  ])
}
