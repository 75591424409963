import { BOT, CODE, REQUEST, GET, LIST, CREATE, CONNECTION } from "../constants"
import { getActionType } from "../lib/helpers"

export function createBotConnectionCode() {
  return {
    type: getActionType(CREATE, BOT, CONNECTION, CODE, REQUEST),
  }
}

export function getBotConnectionList() {
  return {
    type: getActionType(GET, BOT, CONNECTION, LIST, REQUEST),
  }
}
