import React, { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { compose } from "ramda"
import { authRequired } from "../decorators/auth-required"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import cn from "classnames"
import { Button } from "../components/common/button-component"
import enFlag from "../assets/img/en.svg"
import deFlag from "../assets/img/de.svg"
import { UncontrolledButtonDropdown } from "../components/common/uncontrolled-button-dropdown-component"
import { DropdownToggle } from "../components/common/dropdown-toggle-component"
import { deleteAccount } from "../actions/user-actions"
import { createFcmToken, deleteFcmToken, initFcm } from "../actions/fcm-actions"
import { Switch } from "../components/common/switch-component"
import { CandidateDeletionFeedback } from "../components/common/candidate-deletion-feedback-component"
import {
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TabPane,
} from "reactstrap"
import {
  activeSelector,
  botSubscriptionsSelector,
  cvStatusSelector,
  isCancelingMessageSubscriptionSelector,
  isEmailOnMatchActiveSelector,
  isEmailOnMatchUpdatingSelector,
  isNewsletterActiveSelector,
  isNewsletterUpdatingSelector,
  localeSelector,
  localeUpdatingSelector,
  matchingActivatingSelector,
  matchingDeactivatingErrSelector,
  matchingDeactivatingSelector,
} from "../selectors/candidate-selector"
import {
  activateMatching,
  cancelMessengerSubscription,
  deactivateMatching,
  setIsEmailOnMatchActive,
  setIsNewsletterActive,
  updateCandidateLocale,
} from "../actions/candidate-actions"
import {
  deletingErrSelector,
  deletingSelector,
} from "../selectors/user-selector"
import {
  loadingSelector,
  supportedSelector,
  tokenSelector,
} from "../selectors/fcm-selector"
import { usePrevious } from "react-hanger"
import { useOpenClose } from "../hooks/use-open-close"
import qs from "qs"
import { useForceUpdate } from "../hooks/use-force-update"
import { MatchSubscriptionModal } from "../components/modals/match-subscription-modal-component"
import {
  CV_STATUSES,
  FbMessageProvider,
  TelegramMessageProvider,
  WhatsappMessageProvider,
} from "../constants"

const messages = defineMessages({
  confirmTitle: { id: "app.account.confirm.title" },
  confirmDeactivate: { id: "app.account.confirm.deactivate" },
  confirm: { id: "app.common.confirm" },
  cancel: { id: "app.common.cancel" },
  notificationTitle: { id: "app.account.notification.title" },
  emailOnMatchDescription: {
    id: "app.account.notification.emailOnMatch.description",
  },
  messageSubscriptionJob: {
    id: "app.account.notification.messageSubscription.description",
  },
  newsletterDescription: {
    id: "app.account.notification.newsletter.description",
  },
  webPushDescription: { id: "app.account.notification.news.web.push" },
  yes: { id: "app.common.yes" },
  no: { id: "app.common.no" },
  germanLang: { id: "app.common.german" },
  englishLang: { id: "app.common.english" },
  languageHeader: { id: "app.common.language" },
  languageButtonText: { id: "app.account.language.switch.button" },
  languageLabelText: { id: "app.account.language.switch.label" },
  activate: { id: "app.account.activation.title.activate" },
  deactivate: { id: "app.account.activation.title.deactivate" },
  deactivateDescription: {
    id: "app.account.activation.description.deactivate",
  },
  activateDescription: { id: "app.account.activation.description.activate" },
  deleteTitle: { id: "app.account.delete.title" },
  deleteDescription: { id: "app.account.delete.description" },
  confirmDeactivateEmailOnMatch: {
    id: "app.account.confirm.deactivate.emailOnMatch",
  },
  confirmMessageUnsubscription: {
    id: "app.account.confirm.deactivate.messageSubscription",
  },
})

const AccountSettings = ({
  isEmailOnMatchActive,
  isNewsletterActive,
  botSubscriptions,

  locale,
  isCandidateActive,
  updateCandidateLocale,

  setIsNewsletterActive,
  setIsEmailOnMatchActive,
  cancelMessengerSubscription,
  activateMatching,
  deactivateMatching,
  deleteAccount,
  initFcm,

  isEmailOnMatchUpdating,
  isNewsletterUpdating,
  isCancelingMessageSubscription,

  isMatchingActivating,
  isMatchingDeactivating,
  matchingDeactivatingErr,

  isLocaleUpdating,

  isDeleting,
  deletingErr,

  createFcmToken,
  deleteFcmToken,
  fcmToken,
  isFcmLoading,
  isFcmSupported,

  location,

  cvStatus,
}) => {
  const messageSubscriptions = {
    [TelegramMessageProvider]:
      botSubscriptions.find(
        s => s.type === TelegramMessageProvider.toLowerCase(),
      ) || null,
    [WhatsappMessageProvider]:
      botSubscriptions.find(
        s => s.type === WhatsappMessageProvider.toLowerCase(),
      ) || null,
    [FbMessageProvider]:
      botSubscriptions.find(s => s.type === FbMessageProvider.toLowerCase()) ||
      null,
  }

  const prevMatchingDeactivating = usePrevious(isMatchingDeactivating)
  const prevIsDeleting = usePrevious(isDeleting)
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const { action } = query
  const {
    value: isDeactivateMatchingModalOpen,
    close: closeDeactivateMatchingModal,
    open: openDeactivateMatchingModal,
  } = useOpenClose({ initialValue: false })
  const {
    value: isDeleteModalOpen,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useOpenClose({ initialValue: false })
  const {
    value: isUnsubscribeEmailOnMatchModalOpen,
    close: closeEmailOnMatchModal,
    open: openEmailOnMatchModal,
  } = useOpenClose({
    initialValue: action === "unsubscribe_match" && isEmailOnMatchActive,
  })
  const {
    value: isMessageUnsubscriptionModalOpen,
    close: closeMessageUnsubscriptionModal,
    open: openMessageUnsubscriptionModal,
  } = useOpenClose({
    initialValue: action === "unsubscribe_match" && isEmailOnMatchActive,
  })
  const [unsubscriptionProvider, setUnsubscriptionProvider] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState(null)

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (
      prevMatchingDeactivating &&
      !isMatchingDeactivating &&
      !matchingDeactivatingErr
    ) {
      closeDeactivateMatchingModal()
    }

    if (prevIsDeleting && !isDeleting && !deletingErr) {
      closeDeleteModal()
    }
  }, [
    isMatchingDeactivating,
    matchingDeactivatingErr,
    closeDeactivateMatchingModal,
    closeDeleteModal,
    isDeleting,
    deletingErr,
    prevMatchingDeactivating,
    prevIsDeleting,
  ])

  const isWebPushChecked = () => {
    if (fcmToken) {
      if (isFcmLoading) return false
      return true
    } else {
      if (isFcmLoading) return true
      return false
    }
  }

  const startMessageUnsubscription = provider => {
    openMessageUnsubscriptionModal()
    setUnsubscriptionProvider(provider)
  }

  const cancelMessageUnsubscription = () => {
    closeMessageUnsubscriptionModal()
    setUnsubscriptionProvider(null)
  }

  const handleMessageSubscriptionChange = (e, provider) => {
    if (messageSubscriptions[provider]?.isSubscribed) {
      startMessageUnsubscription(provider)
    } else {
      setSelectedProvider(provider)
    }
  }

  const handleEmailOnMatchChange = () => {
    if (isEmailOnMatchActive) {
      openEmailOnMatchModal()
    } else {
      setIsEmailOnMatchActive({ isEmailOnMatchActive: true })
    }
  }

  const handleWebPushNotificationChange = e => {
    if (fcmToken) {
      deleteFcmToken()
    } else {
      createFcmToken()
    }
  }

  const handleMatchingToggle = () => {
    if (isCandidateActive) {
      openDeactivateMatchingModal()
    } else {
      activateMatching({})
    }
  }

  const handleAccountDelete = ({ reasons, comment }) => {
    deleteAccount({ reasons, comment })
  }

  return (
    <Fragment>
      <MatchSubscriptionModal
        toggle={() => setSelectedProvider(null)}
        isOpen={!!selectedProvider}
        selectedProvider={selectedProvider}
      />

      <Modal
        isOpen={isDeactivateMatchingModalOpen}
        toggle={closeDeactivateMatchingModal}
        centered
      >
        <ModalHeader toggle={closeDeactivateMatchingModal}>
          <FormattedMessage {...messages.confirmTitle} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage {...messages.confirmDeactivate} />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isMatchingDeactivating}
            color="light"
            onClick={closeDeactivateMatchingModal}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            disabled={isMatchingDeactivating}
            loading={isMatchingDeactivating}
            color="primary"
            onClick={deactivateMatching}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isDeleteModalOpen} toggle={closeDeleteModal} centered>
        <CandidateDeletionFeedback
          render={({ content, comment, selectedReason }) => {
            const isCommentMissing =
              selectedReason?.value === "other" && !comment
            return (
              <Fragment>
                <ModalHeader toggle={closeDeleteModal}>
                  <FormattedMessage {...messages.confirmTitle} />
                </ModalHeader>
                <ModalBody>{content}</ModalBody>
                <ModalFooter>
                  <Button
                    disabled={isDeleting}
                    color="light"
                    onClick={closeDeleteModal}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </Button>
                  <Button
                    loading={isDeleting}
                    disabled={isDeleting || !selectedReason || isCommentMissing}
                    color="primary"
                    onClick={() =>
                      handleAccountDelete({
                        comment,
                        reasons: [selectedReason].map(r => r.id),
                      })
                    }
                  >
                    <FormattedMessage {...messages.confirm} />
                  </Button>
                </ModalFooter>
              </Fragment>
            )
          }}
        />
      </Modal>

      <Modal
        isOpen={isUnsubscribeEmailOnMatchModalOpen}
        toggle={closeDeactivateMatchingModal}
        centered
      >
        <ModalHeader toggle={closeEmailOnMatchModal}>
          <FormattedMessage {...messages.confirmTitle} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage {...messages.confirmDeactivateEmailOnMatch} />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isEmailOnMatchUpdating}
            color="light"
            onClick={closeEmailOnMatchModal}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            disabled={isEmailOnMatchUpdating}
            loading={isEmailOnMatchUpdating}
            color="primary"
            onClick={async () => {
              await setIsEmailOnMatchActive({ isEmailOnMatchActive: false })
              closeEmailOnMatchModal()
            }}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isMessageUnsubscriptionModalOpen}
        toggle={closeDeactivateMatchingModal}
        centered
      >
        <ModalHeader toggle={cancelMessageUnsubscription}>
          <FormattedMessage {...messages.confirmTitle} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            {...messages.confirmMessageUnsubscription}
            values={{ provider: unsubscriptionProvider }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isCancelingMessageSubscription}
            color="light"
            onClick={cancelMessageUnsubscription}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            disabled={isCancelingMessageSubscription}
            loading={isCancelingMessageSubscription}
            color="primary"
            onClick={async () => {
              cancelMessengerSubscription({
                provider: unsubscriptionProvider,
                id: messageSubscriptions[unsubscriptionProvider].id,
              })
              cancelMessageUnsubscription()
            }}
          >
            <FormattedMessage {...messages.confirm} />
          </Button>
        </ModalFooter>
      </Modal>

      <AccountPageLayout>
        <TabPane>
          <FormGroup>
            <legend>
              <FormattedMessage {...messages.notificationTitle} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.emailOnMatchDescription} />
            </p>
            <Switch
              disabled={isEmailOnMatchUpdating}
              input={{
                checked:
                  (isEmailOnMatchActive && !isEmailOnMatchUpdating) ||
                  (!isEmailOnMatchActive && isEmailOnMatchUpdating),
                onChange: handleEmailOnMatchChange,
              }}
              id="emailOnMatch"
              meta={{}}
            />
            {messageSubscriptions[TelegramMessageProvider] && (
              <>
                <p className="text-muted">
                  <FormattedMessage
                    {...messages.messageSubscriptionJob}
                    values={{ provider: TelegramMessageProvider }}
                  />
                </p>
                <Switch
                  input={{
                    checked:
                      messageSubscriptions[TelegramMessageProvider]
                        .isSubscribed,
                    onChange: e =>
                      handleMessageSubscriptionChange(
                        e,
                        TelegramMessageProvider,
                      ),
                  }}
                  id="telegramSubscription"
                  meta={{}}
                />
              </>
            )}
            {messageSubscriptions[WhatsappMessageProvider] && (
              <>
                <p className="text-muted">
                  <FormattedMessage
                    {...messages.messageSubscriptionJob}
                    values={{ provider: WhatsappMessageProvider }}
                  />
                </p>
                <Switch
                  input={{
                    checked:
                      messageSubscriptions[WhatsappMessageProvider]
                        .isSubscribed,
                    onChange: e =>
                      handleMessageSubscriptionChange(
                        e,
                        WhatsappMessageProvider,
                      ),
                  }}
                  id="whatsappSubscription"
                  meta={{}}
                />
              </>
            )}
            {messageSubscriptions[FbMessageProvider] && (
              <>
                <p className="text-muted">
                  <FormattedMessage
                    {...messages.messageSubscriptionJob}
                    values={{ provider: FbMessageProvider }}
                  />
                </p>
                <Switch
                  input={{
                    checked:
                      messageSubscriptions[FbMessageProvider].isSubscribed,
                    onChange: e =>
                      handleMessageSubscriptionChange(e, FbMessageProvider),
                  }}
                  id="whatsappSubscription"
                  meta={{}}
                />
              </>
            )}
            <p className="text-muted">
              <FormattedMessage {...messages.newsletterDescription} />
            </p>
            <Switch
              disabled={isNewsletterUpdating}
              input={{
                checked:
                  (isNewsletterActive && !isNewsletterUpdating) ||
                  (!isNewsletterActive && isNewsletterUpdating),
                onChange: () =>
                  setIsNewsletterActive({
                    isNewsletterActive: !isNewsletterActive,
                  }),
              }}
              meta={{}}
              id="newsletter"
            />
            {isFcmSupported && (
              <Fragment>
                <p className="text-muted">
                  <FormattedMessage {...messages.webPushDescription} />
                </p>

                <Switch
                  disabled={
                    isFcmLoading || Notification.permission === "denied"
                  }
                  input={{
                    checked: isWebPushChecked(),
                    onChange: handleWebPushNotificationChange,
                  }}
                  meta={{}}
                  id="webPushChecked"
                  className="mb-1"
                />
                {isWebPushChecked() &&
                  Notification.permission === "default" &&
                  !isFcmLoading && (
                    <small className="text-muted">
                      <i className="far fa-exclamation-triangle mr-1" />
                      <FormattedMessage
                        id="app.account.notification.webpush.grantPermission"
                        values={{
                          a: (...children) => (
                            <span
                              className="span-link"
                              onClick={async () => {
                                await Notification.requestPermission()
                                forceUpdate()
                                initFcm()
                              }}
                            >
                              {children}{" "}
                            </span>
                          ),
                        }}
                      />
                    </small>
                  )}
                {Notification.permission === "denied" && (
                  <small className="text-muted">
                    <i className="far fa-exclamation-triangle mr-1" />
                    <FormattedMessage id="app.account.notification.webpush.permissionDenied" />
                  </small>
                )}
              </Fragment>
            )}
          </FormGroup>
          <FormGroup tag="fieldset">
            <legend>
              <FormattedMessage {...messages.languageHeader} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.languageLabelText} />
            </p>
            <UncontrolledButtonDropdown className="btn-block">
              <DropdownToggle
                loading={isLocaleUpdating}
                disabled={isLocaleUpdating}
                color="light"
                size="lg"
                block
                caret
              >
                {locale === "en" ? (
                  <Fragment>
                    <img
                      src={enFlag}
                      width="32"
                      height="32"
                      alt="enFlag"
                      className="mr-1"
                    />
                    <FormattedMessage {...messages.englishLang} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <img
                      src={deFlag}
                      width="32"
                      height="32"
                      alt="deFlag"
                      className="mr-1"
                    />
                    <FormattedMessage {...messages.germanLang} />
                  </Fragment>
                )}
              </DropdownToggle>
              <DropdownMenu className="btn-block">
                <DropdownItem
                  onClick={() => updateCandidateLocale({ locale: "en" })}
                >
                  <Fragment>
                    <img
                      src={enFlag}
                      width="32"
                      height="32"
                      alt="enFlag"
                      className="mr-1"
                    />
                    <FormattedMessage {...messages.englishLang} />
                  </Fragment>
                </DropdownItem>
                <DropdownItem
                  onClick={() => updateCandidateLocale({ locale: "de" })}
                >
                  <Fragment>
                    <img
                      src={deFlag}
                      width="32"
                      height="32"
                      alt="deFlag"
                      className="mr-1"
                    />
                    <FormattedMessage {...messages.germanLang} />
                  </Fragment>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </FormGroup>
          <FormGroup>
            <legend>
              {isCandidateActive ? (
                <FormattedMessage {...messages.deactivate} />
              ) : (
                <FormattedMessage {...messages.activate} />
              )}
            </legend>
            <p className="text-muted">
              {isCandidateActive ? (
                <FormattedMessage {...messages.deactivateDescription} />
              ) : (
                <FormattedMessage {...messages.activateDescription} />
              )}
            </p>
            <Button
              size="lg"
              disabled={
                isMatchingActivating ||
                isMatchingDeactivating ||
                cvStatus === CV_STATUSES.ERROR
              }
              loading={isMatchingActivating}
              block
              onClick={handleMatchingToggle}
              color={cn({
                danger: isCandidateActive,
                success: !isCandidateActive,
              })}
            >
              <i
                className={cn(
                  {
                    "fa-pause": isCandidateActive,
                    "fa-play": !isCandidateActive,
                  },
                  "fas pr-2",
                )}
              />
              {isCandidateActive ? (
                <FormattedMessage {...messages.deactivate} />
              ) : (
                <FormattedMessage {...messages.activate} />
              )}
            </Button>
          </FormGroup>
          <div>
            <legend>
              <FormattedMessage {...messages.deleteTitle} />
            </legend>
            <p className="text-muted">
              <FormattedMessage {...messages.deleteDescription} />
            </p>
            <Button
              onClick={openDeleteModal}
              disabled={isDeleting}
              color="danger"
              size="lg"
              block
            >
              <i className="fas fa-times pr-2" />
              <FormattedMessage {...messages.deleteTitle} />
            </Button>
          </div>
        </TabPane>
      </AccountPageLayout>
    </Fragment>
  )
}

const mapDispatchToProps = {
  updateCandidateLocale,
  activateMatching,
  deactivateMatching,
  deleteAccount,
  createFcmToken,
  deleteFcmToken,
  initFcm,
  setIsNewsletterActive,
  setIsEmailOnMatchActive,
  cancelMessengerSubscription,
}

const mapStateToProps = function (state) {
  return {
    isFcmLoading: loadingSelector(state),
    isFcmSupported: supportedSelector(state),
    fcmToken: tokenSelector(state),
    isCandidateActive: activeSelector(state),
    locale: localeSelector(state),
    isEmailOnMatchActive: isEmailOnMatchActiveSelector(state),
    isNewsletterActive: isNewsletterActiveSelector(state),
    botSubscriptions: botSubscriptionsSelector(state),
    isLocaleUpdating: localeUpdatingSelector(state),
    cvStatus: cvStatusSelector(state),

    isNewsletterUpdating: isNewsletterUpdatingSelector(state),
    isEmailOnMatchUpdating: isEmailOnMatchUpdatingSelector(state),
    isCancelingMessageSubscription: isCancelingMessageSubscriptionSelector(
      state,
    ),

    isMatchingActivating: matchingActivatingSelector(state),
    isMatchingDeactivating: matchingDeactivatingSelector(state),
    matchingDeactivatingErr: matchingDeactivatingErrSelector(state),
    isDeleting: deletingSelector(state),
    deletingErr: deletingErrSelector(state),
  }
}

AccountSettings.propTypes = {
  isEmailOnMatchActive: PropTypes.bool.isRequired,
  isNewsletterActive: PropTypes.bool.isRequired,
  botSubscriptions: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  isCandidateActive: PropTypes.bool.isRequired,
  updateCandidateLocale: PropTypes.func.isRequired,
  setIsNewsletterActive: PropTypes.func.isRequired,
  setIsEmailOnMatchActive: PropTypes.func.isRequired,
  cancelMessengerSubscription: PropTypes.func.isRequired,
  activateMatching: PropTypes.func.isRequired,
  deactivateMatching: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  initFcm: PropTypes.func.isRequired,

  isEmailOnMatchUpdating: PropTypes.bool.isRequired,

  isNewsletterUpdating: PropTypes.bool.isRequired,

  isCancelingMessageSubscription: PropTypes.bool.isRequired,

  isMatchingActivating: PropTypes.bool.isRequired,
  isMatchingDeactivating: PropTypes.bool.isRequired,
  matchingDeactivatingErr: PropTypes.object,

  isLocaleUpdating: PropTypes.bool.isRequired,

  isDeleting: PropTypes.bool.isRequired,
  deletingErr: PropTypes.object,

  createFcmToken: PropTypes.func.isRequired,
  deleteFcmToken: PropTypes.func.isRequired,
  fcmToken: PropTypes.string,
  isFcmLoading: PropTypes.bool.isRequired,
  isFcmSupported: PropTypes.bool.isRequired,

  location: PropTypes.object.isRequired,

  cvStatus: PropTypes.string,
}

AccountSettings.defaultProps = {
  isEmailOnMatchActive: false,
  isNewsletterActive: false,
  locale: "en",
  isCandidateActive: true,
  isFcmLoading: false,
  isFcmSupported: false,
}

export const AccountSettingsPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettings)
