import React from "react"
import PropTypes from "prop-types"
import { JobApplicationControls } from "./job-application-controls-component"
import { JobFeedbackControls } from "./job-feedback-controls-component"
import { isFeedbackAllowed, isApplyDiscardAllowed } from "../../lib/helpers"
import qs from "qs"
import { ACT } from "../../constants"

export const JobPageControls = ({
  job,
  isLoading,
  isLogged,
  isComplete,
  location,
  jobMatch,
}) => {
  if (!job || (isLoading && !job.match)) return null

  if (job.status !== ACT && !isLogged) {
    return null
  }

  if (!isComplete || isApplyDiscardAllowed({ job, match: job.match })) {
    const { apply, accept } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    return (
      <JobApplicationControls
        jobId={job.id}
        // After 07.08.2021 the accept fallback can be removed
        apply={apply === "" || accept === ""}
      />
    )
  }

  if (isFeedbackAllowed({ job, match: jobMatch })) {
    return <JobFeedbackControls matchId={job.match.id} />
  }

  return null
}

JobPageControls.propTypes = {
  job: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  jobMatch: PropTypes.object,
}
