import React from "react"
import PropTypes from "prop-types"
import { FormattedNumber } from "react-intl"

export const Price = ({ price }) => {
  return (
    <FormattedNumber
      style="currency" // eslint-disable-line react/style-prop-object
      minimumFractionDigits={0}
      maximumFractionDigits={2}
      value={price}
      currency="EUR"
    />
  )
}

Price.propTypes = {
  price: PropTypes.number.isRequired,
}

Price.defaultProps = {}
