import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { createFilter } from "react-select"
import { Select } from "./select-component"
import { Button } from "./button-component"

export class MultiSelect extends Component {
  static propTypes = {
    label: PropTypes.node,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    async: PropTypes.bool.isRequired,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    tagRenderer: PropTypes.func,
    underLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    getOptionValue: PropTypes.func.isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    maxItems: PropTypes.number.isRequired,
  }

  static defaultProps = {
    disabled: false,
    async: false,
    maxItems: 30,
  }

  render() {
    const {
      input,
      getOptionValue,
      getOptionLabel,
      disabled,
      tagRenderer,
      underLabel,
      maxItems,
      ...rest
    } = this.props

    return (
      <Fragment>
        <Select
          input={{
            onChange: this.addItem,
            onBlur: this.handleBlur,
            onFocus: input.onFocus,
            name: input.name,
            value: null,
          }}
          filterOption={this.filterOption}
          isDisabled={disabled || input.value.length >= maxItems}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          underLabel={
            underLabel
              ? typeof underLabel === "function"
                ? underLabel(this.props)
                : underLabel
              : undefined
          }
          underInput={
            <div className="mt-3">
              <div className="tags-wrapper">
                {input.value.map(v => (
                  <Button
                    onClick={this.removeItem(getOptionValue(v))}
                    key={getOptionValue(v)}
                    disabled={disabled}
                    color="success-light"
                    size="sm"
                  >
                    {tagRenderer ? tagRenderer(v) : getOptionLabel(v)}
                    <i className="fas fa-times pl-2 text-muted" />
                  </Button>
                ))}
              </div>
            </div>
          }
          {...rest}
        />
      </Fragment>
    )
  }

  addItem = value => {
    const { input } = this.props

    input.onChange(input.value.concat(value))
  }

  removeItem = value => () => {
    const { input, getOptionValue } = this.props

    input.onChange(input.value.filter(v => getOptionValue(v) !== value))
  }

  handleBlur = () => {
    this.props.input.onBlur(this.props.input.value)
  }

  filterOption = (option, textInput) => {
    const { input, getOptionValue } = this.props
    const selectedValues = input.value.map(v => getOptionValue(v))
    const defaultFilter = createFilter()
    if (option?.value) {
      return (
        !selectedValues.includes(option.value) &&
        defaultFilter(option, textInput)
      )
    }

    return false
  }
}
