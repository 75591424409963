import { getActionType } from "../lib/helpers"
import { TOPCV, TOGGLE, UPLOAD, SUCCESS, FAIL, LOGOUT } from "../constants"

export function reducer(state = false, action) {
  const { type } = action

  switch (type) {
    case getActionType(TOGGLE, TOPCV):
      return !state

    case getActionType(UPLOAD, TOPCV, SUCCESS):
    case getActionType(UPLOAD, TOPCV, FAIL):
      return false

    case getActionType(LOGOUT, SUCCESS):
      return false

    default:
      return state
  }
}
