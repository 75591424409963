import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Label, Input } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { SocialAuth } from "../social/social-auth-component"
import { EmailPasswordForm } from "../forms/email-password-form"
import { useOpenClose } from "../../hooks/use-open-close"
import { links } from "../../lib/external-links"
import { localeSelector } from "../../selectors/intl-selector"
import { useSelector, connect } from "react-redux"
import { signupWithLinkedin } from "../../actions/user-actions"
import { connectingLinkedinSelector } from "../../selectors/user-selector"
import { creatingSelector } from "../../selectors/candidate-selector"
import { emailPasswordValidator } from "../../validators/email-password-validator"
import FormFeedback from "../common/form-feedback"
import { createCandidate } from "../../actions/candidate-actions"

export const SignupSectionComponent = ({
  signupWithLinkedin,
  isConnectingLinkedin,
  isCreatingCandidate,
  createCandidate,
  setTracker,
}) => {
  const isConnecting = isConnectingLinkedin
  const {
    value: isNewsletterActive,
    toggle: toggleIsNewsletterActive,
  } = useOpenClose({ initialValue: false })

  const {
    value: areTermsAccepted,
    toggle: areTermsAcceptedToggle,
  } = useOpenClose({ initialValue: false })

  const { value: areTermsTouched, open: touchTerms } = useOpenClose({
    initialValue: false,
  })

  const locale = useSelector(localeSelector)

  const handleSubmit = ({ email, password }) => {
    let payload = {
      settings: {
        locale,
        isNewsletterActive,
      },
      email,
      password,
    }

    touchTerms()

    if (!areTermsAccepted) {
      return
    }

    setTracker && setTracker()

    createCandidate(payload)
  }

  return (
    <Fragment>
      <div className="mb-4">
        <div className="custom-control custom-checkbox">
          <Input
            id="terms"
            type="checkbox"
            className="custom-control-input"
            checked={areTermsAccepted}
            onChange={() => {
              touchTerms()
              areTermsAcceptedToggle()
            }}
            invalid={areTermsTouched && !areTermsAccepted}
          />
          <Label for="terms" className="custom-control-label">
            <FormattedMessage
              id="app.signup.agreement.text"
              values={{
                terms: (...children) => (
                  <a
                    href={links[locale].terms}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                ),
                privacy: (...children) => (
                  <a
                    href={links[locale].privacy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {children}
                  </a>
                ),
              }}
            />
          </Label>
          <FormFeedback
            error={<FormattedMessage id="app.signup.agreement.text.hint" />}
          />
        </div>
      </div>
      <div className="mb-4">
        <div className="custom-control custom-checkbox">
          <Input
            id="newsletter"
            type="checkbox"
            className="custom-control-input"
            checked={isNewsletterActive}
            onChange={toggleIsNewsletterActive}
          />
          <Label for="newsletter" className="custom-control-label">
            <FormattedMessage id="app.signup.agreement.checkbox" />
          </Label>
        </div>
      </div>
      <div className="mb-4">
        <SocialAuth
          withFacebook={false}
          isDisabled={isConnecting || isCreatingCandidate}
          onLinkedinClick={() => {
            if (areTermsAccepted) {
              signupWithLinkedin({
                isNewsletterActive,
              })
              setTracker && setTracker()
            } else {
              touchTerms()
            }
          }}
        />
      </div>
      <EmailPasswordForm
        form="SignupForm"
        disabled={isCreatingCandidate || isConnecting}
        onSubmit={handleSubmit}
        validate={emailPasswordValidator}
        buttonLabel={<FormattedMessage id="app.signup.register" />}
      />
    </Fragment>
  )
}

SignupSectionComponent.propTypes = {
  isConnectingLinkedin: PropTypes.bool.isRequired,
  isCreatingCandidate: PropTypes.bool.isRequired,
  createCandidate: PropTypes.func.isRequired,
  signupWithLinkedin: PropTypes.func,
  setTracker: PropTypes.func,
}

SignupSectionComponent.defaultProps = {
  isConnectingLinkedin: false,
  isCreatingCandidate: false,
}

const mapStateToProps = state => ({
  isConnectingLinkedin: connectingLinkedinSelector(state),
  isCreatingCandidate: creatingSelector(state),
})

const mapDispatchToProps = {
  createCandidate,
  signupWithLinkedin,
}

export const SignupSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupSectionComponent)
