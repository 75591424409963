import { arrayToObject, getActionType, N } from "../lib/helpers"
import { always, evolve, F, T, mergeLeft, without, prop, dec } from "ramda"
import {
  GET,
  SUCCESS,
  JOB,
  LIST,
  FAIL,
  START,
  EXTERNAL,
  HIDE,
  LOGOUT,
} from "../constants"

export const defaultState = {
  entities: {},
  isLoading: false,
  externalJobs: [],
  hidingExternalJobId: null,
  count: null,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, EXTERNAL, JOB, LIST, START):
      return evolve({ isLoading: T }, state)

    case getActionType(GET, EXTERNAL, JOB, LIST, SUCCESS):
      return evolve(
        {
          isLoading: F,
          err: N,
          entities: mergeLeft(arrayToObject(payload.jobs, "idStr")),
          externalJobs: always(payload.jobs.map(prop("idStr"))),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, EXTERNAL, JOB, LIST, FAIL):
      return evolve({ isLoading: F, err: always(payload.err) }, state)

    case getActionType(HIDE, EXTERNAL, JOB, START):
      return evolve(
        {
          hidingExternalJobId: always(payload.jobId),
        },
        state,
      )

    case getActionType(HIDE, EXTERNAL, JOB, SUCCESS):
      return evolve(
        {
          hidingExternalJobId: N,
          externalJobs: without([payload.jobId]),
          count: dec,
        },
        state,
      )

    case getActionType(HIDE, EXTERNAL, JOB, FAIL):
      return evolve(
        {
          hidingExternalJobId: N,
          err: always(payload.err),
          externalJobs: without([payload.jobId]),
          count: dec,
        },
        state,
      )

    case getActionType(LOGOUT, SUCCESS):
      return defaultState
    default:
      return state
  }
}
