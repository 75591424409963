import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Form } from "reactstrap"
import { compose } from "redux"
import { Field, reduxForm } from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"

import { EducationSection } from "./education-section/education-section"
import { KeySkillsSection } from "./key-skills-section/key-skills-section"
import { ReferencesSection } from "./references-section/references-section"
import { LanguagesSection } from "./languages-section/languages-section"
import { WorkExperienceSection } from "./work-experience-section/work-experience-section"

import styles from "./professional-details-form.module.scss"
import { CertificationsSection } from "./certifications-section/certifications-section"

export const ProfessionalDetails = ({
  handleSubmit,
  backRoute,
  isUpdating,
}) => {
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <Field name="languages" id="languages" component={LanguagesSection} />
          <Field name="skills" id="skills" component={KeySkillsSection} />
          <Field name="education" id="education" component={EducationSection} />
        </div>

        <div className={styles.rightSection}>
          <Field
            name="workExperience"
            id="workExperience"
            component={WorkExperienceSection}
          />
          <Field
            name="certificates"
            id="certificates"
            component={CertificationsSection}
          />
          <Field
            name="references"
            id="references"
            component={ReferencesSection}
          />
        </div>
      </div>

      <div className={styles.actions}>
        <Link to={backRoute}>
          <button
            className={styles.backButton}
            type="button"
            disabled={isUpdating}
          >
            <FormattedMessage id="app.common.back" />
          </button>
        </Link>
        <button
          className={styles.submitButton}
          type="submit"
          disabled={isUpdating}
        >
          {isUpdating && <i className="fas fa-circle-notch fa-spin mr-1" />}
          <FormattedMessage id="app.common.save" />
        </button>
      </div>
    </Form>
  )
}

export const ProfessionalDetailsForm = compose(
  injectIntl,
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
)(ProfessionalDetails)

ProfessionalDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  backRoute: PropTypes.string,
  isUpdating: PropTypes.bool,
}
