import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { PopoverBody, UncontrolledPopover } from "reactstrap"
import { Badge } from "./badge-component"
import { Price } from "./price-component"

export const HiringRewardTag = ({ id, hiringReward }) => (
  <Fragment>
    <Badge
      id={`hiring-reward-popover-${id}`}
      className="text-truncate align-middle"
      color="info-light"
    >
      <FormattedMessage
        id="app.job.hiringReward"
        values={{
          price: <Price price={parseFloat(hiringReward)} />,
        }}
      />
    </Badge>
    <UncontrolledPopover
      trigger="hover"
      placement="top"
      target={`hiring-reward-popover-${id}`}
    >
      <PopoverBody>
        <FormattedMessage
          id="app.job.hiringReward.popup"
          values={{
            price: <Price price={parseFloat(hiringReward)} />,
          }}
        />
      </PopoverBody>
    </UncontrolledPopover>
  </Fragment>
)

HiringRewardTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hiringReward: PropTypes.number.isRequired,
}

HiringRewardTag.defaultProps = {
  hiringReward: 0,
}
