import {
  OFF,
  ON,
  RELEASE,
  LOGROCKET,
  SET,
  LAST,
  SUPPORTED,
  APP,
  VERSIONS,
} from "../constants"
import { getActionType } from "../lib/helpers"
import { config } from "../config"
import { propOr } from "ramda"

export const defaultState = {
  release: false,
  logrocket: false,
  lastSupportedVersion: "0",
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(RELEASE, ON):
      return { ...state, release: true }

    case getActionType(RELEASE, OFF):
      return { ...state, release: false }

    case getActionType(LOGROCKET, ON):
      return { ...state, logrocket: true }

    case getActionType(LOGROCKET, OFF):
      return { ...state, logrocket: false }

    case getActionType(SET, LAST, SUPPORTED, APP, VERSIONS):
      return {
        ...state,
        lastSupportedVersion: propOr(
          state.lastSupportedVersion,
          config.package.name,
          payload,
        ),
      }

    default:
      return state
  }
}
