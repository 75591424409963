export const links = {
  en: {
    homePage: "https://www.moberries.com/candidates",
    guide: "https://www.moberries.com/page/candidateguide",
    support: "mailto:support@moberries.com",
    jobBot: "https://www.jobbot.me",
    about: "https://www.moberries.com/about",
    FAQ: "https://help.moberries.com/en/collections/2140782-for-candidates",
    terms: "https://www.moberries.com/terms",
    privacy: "https://www.moberries.com/privacy",
    blog: "https://www.moberries.com/blog",
  },
  de: {
    homePage: "https://www.moberries.com/de/candidates",
    guide: "https://www.moberries.com/de/page/candidateguide-de",
    support: "mailto:support@moberries.com",
    jobBot: "https://www.jobbot.me",
    about: "https://www.moberries.com/de/about",
    FAQ: "https://help.moberries.com/de/collections/2140782-fur-kandidaten",
    terms: "https://www.moberries.com/de/terms",
    privacy: "https://www.moberries.com/de/privacy",
    blog: "https://www.moberries.com/blog",
  },
}
