import { all, call } from "redux-saga/effects"
import {
  saga as userSaga,
  initAuthorizationTokenSaga,
} from "../sagas/user-saga"

import { saga as candidateSaga } from "../sagas/candidate-saga"
import { saga as matchSaga } from "../sagas/match-saga"
import { saga as jobSaga } from "../sagas/job-saga"
import { saga as companySaga } from "../sagas/company-saga"
import { saga as threadSaga } from "../sagas/thread-saga"
import { saga as messageSaga } from "../sagas/message-saga"
import { saga as botSaga } from "../sagas/bot-saga"
import { saga as intlSaga } from "../sagas/intl-saga"
import { saga as systemSaga } from "../sagas/system-saga"
import { saga as logRocketSaga } from "../sagas/logrocket-saga"
import { saga as errorSaga } from "../sagas/error-saga"
import { saga as trackingSaga } from "../sagas/tracking-saga"
import { saga as topCvSaga } from "../sagas/top-cv-saga"
import { saga as fcmSaga } from "../sagas/fcm-saga"
import { saga as surveySaga } from "../sagas/survey-saga"
import { saga as externalJobSaga } from "../sagas/external-job-saga"

export const saga = function* (ctx) {
  yield call(initAuthorizationTokenSaga)
  yield all([
    errorSaga(),
    systemSaga(),
    intlSaga(),
    userSaga(ctx),
    candidateSaga(),
    matchSaga(),
    jobSaga(),
    companySaga(),
    threadSaga(),
    messageSaga(),
    botSaga(),
    logRocketSaga(),
    trackingSaga(),
    topCvSaga(),
    fcmSaga(),
    surveySaga(),
    externalJobSaga(),
  ])
}
