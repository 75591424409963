import React, { Fragment } from "react"
import { push } from "connected-react-router"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage, defineMessages } from "react-intl"
import { JobControlsLayout } from "../layouts/job-controls-layout"
import { Button } from "../common/button-component"
import { jobSelector } from "../../selectors/job-selector"
import {
  setApplicationId,
  updateCandidateTracking,
} from "../../actions/candidate-actions"
import {
  cvIsUploadedSelector,
  isLoggedSelector,
} from "../../selectors/candidate-selector"
import { alertTypes, showAlert } from "../../lib/alerts"
import { ALERT_COLORS } from "../../constants"

const messages = defineMessages({
  applyButton: { id: "app.job.apply" },
  discardButton: { id: "app.job.discard" },
  applyText: { id: "app.job.apply.text" },
  discardText: { id: "app.job.discard.text" },
})

export const JobApplicationNonLoggedControlsComponent = ({
  job,
  isCvUploaded,
  push,
  setApplicationId,
  isLogged,
  updateCandidateTracking,
}) => {
  const redirectUrl = isCvUploaded ? "/registration/personal" : "/registration"
  return (
    <Fragment>
      <JobControlsLayout
        left={
          <Fragment>
            <Button
              onClick={() => {
                setApplicationId({ id: job.id })
                push(redirectUrl)
                if (isLogged) {
                  updateCandidateTracking({
                    tracking: {
                      job: job.id,
                    },
                  })
                  showAlert({
                    color: ALERT_COLORS.ERROR,
                    code: alertTypes.completeProfileError,
                  })
                }
              }}
              pill
              color="primary"
            >
              <i className="fas fa-heart pr-1" />
              <FormattedMessage {...messages.applyButton} />
            </Button>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.applyText} />
            </span>
          </Fragment>
        }
        right={
          <Fragment>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.discardText} />
            </span>
            <Button
              onClick={() => {
                setApplicationId({ id: null })
                push(redirectUrl)
              }}
              pill
              color="secondary"
            >
              <i className="fas fa-times pr-1" />
              <FormattedMessage {...messages.discardButton} />
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  )
}

JobApplicationNonLoggedControlsComponent.propTypes = {
  job: PropTypes.object,
  push: PropTypes.func.isRequired,
  setApplicationId: PropTypes.func.isRequired,
  isCvUploaded: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  updateCandidateTracking: PropTypes.func,
}

JobApplicationNonLoggedControlsComponent.defaultProps = {
  isCvUploaded: false,
  isLogged: false,
}

const mapDispatchToProps = {
  push,
  setApplicationId,
  updateCandidateTracking,
}

const mapStateToProps = (state, props) => ({
  job: jobSelector(state, { id: props.jobId }),
  isCvUploaded: cvIsUploadedSelector(state),
  isLogged: isLoggedSelector(state),
})

export const JobApplicationNonLoggedControls = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobApplicationNonLoggedControlsComponent)
