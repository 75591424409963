import { getActionType } from "../lib/helpers"
import {
  GET,
  MATCH,
  LIST,
  REQUEST,
  MARK,
  HIRED,
  NOT,
  FEEDBACK,
  SUMMARY,
  MATCHES,
  STATISTICS,
  VALIDATION,
  CLEAR,
} from "../constants"

export function getMatchList({ candidateId, params }) {
  return {
    type: getActionType(GET, MATCH, LIST, REQUEST),
    payload: { candidateId, params },
  }
}

export function getMatch({ matchId }) {
  return {
    type: getActionType(GET, MATCH, REQUEST),
    payload: { matchId },
  }
}

export function validateMatch({ jobId }) {
  return {
    type: getActionType(GET, MATCH, VALIDATION, REQUEST),
    payload: { jobId },
  }
}

export function clearValidation() {
  return {
    type: getActionType(CLEAR, MATCH, VALIDATION),
  }
}

export function markMatchHired({ matchId }) {
  return {
    type: getActionType(MARK, MATCH, HIRED, REQUEST),
    payload: { matchId },
  }
}

export function markMatchNotHired({ matchId, feedbackReasons, comment }) {
  return {
    type: getActionType(MARK, MATCH, NOT, HIRED, REQUEST),
    payload: { matchId, feedbackReasons, comment },
  }
}

export function getFeedbackSummary() {
  return {
    type: getActionType(GET, FEEDBACK, SUMMARY, REQUEST),
  }
}

export function getMatchesStatistics() {
  return {
    type: getActionType(GET, MATCHES, STATISTICS, REQUEST),
  }
}
