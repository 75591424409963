import React from "react"
import PropTypes from "prop-types"
import ReactCookieConsent from "react-cookie-consent"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import { acceptCookie } from "../../actions/user-actions"
import { isLoggedSelector } from "../../selectors/candidate-selector"
import { localeSelector } from "../../selectors/intl-selector"
import { config } from "../../config"
import { links } from "../../lib/external-links"

const messages = defineMessages({
  body: { id: "app.cookie.bar" },
  bodyLink: { id: "app.cookie.bar.details" },
  button: { id: "app.cookie.bar.button" },
})

const style = { backgroundColor: "#011627" }
const buttonStyle = {
  backgroundColor: "#2ec4b6", // "bluegreen"
  color: "#fff",
  right: "15px",
  cursor: "pointer",
  padding: undefined,
}

export function CookieConsentComponent(props) {
  if (props.isLogged) {
    return null
  }

  return (
    <ReactCookieConsent
      cookieName={config.cookie.keys.consent}
      buttonText={<FormattedMessage {...messages.button} />}
      onAccept={props.acceptCookie}
      style={style}
      buttonStyle={buttonStyle}
    >
      <FormattedMessage
        {...messages.body}
        values={{
          details: (
            <a
              href={links[props.locale].privacy}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage {...messages.bodyLink} />
            </a>
          ),
        }}
      />
    </ReactCookieConsent>
  )
}

CookieConsentComponent.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  acceptCookie: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
}

const mapDispatchToProps = { acceptCookie }

const mapStateToProps = function (state) {
  return {
    isLogged: isLoggedSelector(state),
    locale: localeSelector(state),
  }
}

export const CookieConsent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CookieConsentComponent)
