import React from "react"
import PropTypes from "prop-types"
import { BaseLayout } from "../layouts/base-layout"
import { JobsSkeleton } from "./jobs-skeleton"
import { CompanyViewPageLayout } from "../layouts/company-view-layout"

export function CompanyPageSkeleton({
  name,
  location,
  logo,
  about,
  website,
  content,
}) {
  return (
    <BaseLayout className="pb-5">
      <CompanyViewPageLayout
        name={name}
        location={location}
        logo={logo}
        about={about}
        website={website}
        content={<JobsSkeleton />}
      />
    </BaseLayout>
  )
}

CompanyPageSkeleton.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  website: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  content: PropTypes.node,
}

CompanyPageSkeleton.defaultProps = {
  name: "",
  logo: "",
  location: {},
  website: "",
  about: "",
}
