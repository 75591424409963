import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"
import { Form, InputGroup, Label } from "reactstrap"
import { emailValidator } from "../../validators/email-validator"

const messages = defineMessages({
  send: { id: "app.common.send" },
  sendEmail: { id: "app.job.modal.button.sendEmail" },
})

export function JobInvitationFormComponent({
  invalid,
  submitting,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="mt-2">
        <Label for="invitation-modal-send-button">
          <FormattedMessage {...messages.sendEmail} />
        </Label>
      </div>
      <InputGroup>
        <Field
          component={Input}
          className="m-0 flex-grow-1"
          name="email"
          type="email"
          id="email"
          placeholder="email@example.com"
          appendAddon={
            <Button
              disabled={submitting || invalid}
              loading={submitting}
              block
              color="secondary"
              type="submit"
              id="invitation-modal-send-button"
            >
              <FormattedMessage {...messages.send} />
            </Button>
          }
        />
      </InputGroup>
    </Form>
  )
}

JobInvitationFormComponent.propTypes = {
  ...propTypes,
  handleSubmit: PropTypes.func.isRequired,
}

export const JobInvitationForm = reduxForm({
  form: "JobInvitationForm",
  validate: emailValidator,
})(JobInvitationFormComponent)
