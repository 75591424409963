import { defineMessages } from "react-intl"
import { FEEDBACK_SETS } from "./constants"

const messages = defineMessages({
  englishLang: { id: "app.common.english" },
  germanLang: { id: "app.common.german" },

  jobTypeFullTime: { id: "app.common.job.type.fulltime" },
  jobTypePartTime: { id: "app.common.job.type.parttime" },
  jobTypeContract: { id: "app.common.job.type.contract" },
  jobTypeIntership: { id: "app.common.job.type.intership" },
  jobTypeTemporary: { id: "app.common.job.type.temporary" },

  jobExperienceLevelFirst: { id: "app.common.job.experience.level.first" },
  jobExperienceLevelSecond: { id: "app.common.job.experience.level.second" },
  jobExperienceLevelThird: { id: "app.common.job.experience.level.third" },
  jobExperienceLevelFourth: { id: "app.common.job.experience.level.fourth" },

  yes: { id: "app.common.yes" },
  no: { id: "app.common.no" },

  langLevelBeginner: { id: "app.common.language.level.beginner" },
  langLevelElementary: { id: "app.common.language.level.elementary" },
  langLevelIntermediate: { id: "app.common.language.level.intermediate" },
  langLevelUpperIntermediate: {
    id: "app.common.language.level.upperintermediate",
  },
  langLevelAdvanced: { id: "app.common.language.level.advanced" },
  langLevelMastery: { id: "app.common.language.level.mastery" },
  langLevelNative: { id: "app.common.language.level.native" },

  careerLevelStudent: { id: "app.common.career.level.student" },
  careerLevelEntry: { id: "app.common.career.level.entry" },
  careerLevelIntermediate: { id: "app.common.career.level.intermediate" },
  careerLevelSenior: { id: "app.common.career.level.senior" },
  careerLevelLead: { id: "app.common.career.level.lead" },
  careerLevelExecutive: { id: "app.common.career.level.executive" },

  availableImmediately: { id: "app.common.availability.period.immediately" },
  availableInThreeMonths: { id: "app.common.availability.period.threeMonths" },
  availableInMoreThenThreeMonths: {
    id: "app.common.availability.period.moreThenThreeMonths",
  },

  onGoing: { id: "app.messages.notHired.onGoing" },
  jobFit: { id: "app.messages.notHired.jobFit" },
  rejected: { id: "app.messages.notHired.rejected" },
  notResponding: { id: "app.messages.notHired.notResponding" },
  offerUnattractive: { id: "app.messages.notHired.offerUnattractive" },
  other: { id: "app.messages.notHired.other" },

  tooltipOnGoing: { id: "app.messages.notHired.onGoing.tooltip" },
  tooltipJobFit: { id: "app.messages.notHired.jobFit.tooltip" },
  tooltipRejected: { id: "app.messages.notHired.rejected.tooltip" },
  tooltipNotResponding: { id: "app.messages.notHired.notResponding.tooltip" },
  tooltipOfferUnattractive: {
    id: "app.messages.notHired.offerUnattractive.tooltip",
  },

  candidateDeleteFeedbackAlreadyHired: {
    id: "app.account.delete.confirmation.reasons.alreadyHired",
  },
  candidateDeleteFeedbackNoSuitableOffers: {
    id: "app.account.delete.confirmation.reasons.noSuitableOffers",
  },
  candidateDeleteFeedbackWrongLocation: {
    id: "app.account.delete.confirmation.reasons.wrongLocation",
  },
  candidateDeleteFeedbackNotLookingAnymore: {
    id: "app.account.delete.confirmation.reasons.notLookingAnymore",
  },
  candidateDeleteFeedbackAppFunctionality: {
    id: "app.account.delete.confirmation.reasons.appFunctionality",
  },
  candidateDeleteFeedbackCompaniesNotResponding: {
    id: "app.account.delete.confirmation.reasons.companiesNotResponding",
  },
  candidateDeleteFeedbackOther: {
    id: "app.account.delete.confirmation.reasons.other",
  },

  matchCanAccepted: { id: "app.job.tag.candidate.accepted.text" },
  matchComDeclined: { id: "app.job.tag.company.declined.text" },
  matchComAccepted: { id: "app.job.tag.company.accepted.text" },
  matchComExpired: { id: "app.job.tag.company.expired.text" },
  matchComRejected: { id: "app.job.tag.company.rejected.text" },
  matchComApproved: { id: "app.job.tag.company.approved.text" },
})

export const JOB_TYPES = [
  { id: 1, name: messages.jobTypeFullTime },
  { id: 2, name: messages.jobTypePartTime },
  { id: 3, name: messages.jobTypeContract },
  { id: 4, name: messages.jobTypeIntership },
  { id: 5, name: messages.jobTypeTemporary },
]

export const JOB_LEVELS = [
  { id: 0, name: messages.jobExperienceLevelFirst },
  { id: 1, name: messages.jobExperienceLevelSecond },
  { id: 2, name: messages.jobExperienceLevelThird },
  { id: 3, name: messages.jobExperienceLevelFourth },
]

export const RELOCATE_OPTIONS = [
  { value: 0, label: messages.yes },
  { value: 1, label: messages.no },
]

export const LANG_LEVELS = [
  { id: 0, name: messages.langLevelBeginner },
  { id: 1, name: messages.langLevelElementary },
  { id: 2, name: messages.langLevelIntermediate },
  { id: 3, name: messages.langLevelUpperIntermediate },
  { id: 4, name: messages.langLevelAdvanced },
  { id: 5, name: messages.langLevelMastery },
  { id: 6, name: messages.langLevelNative, native: true },
]

export const CAREER_LEVELS = [
  { id: 0, name: messages.careerLevelStudent },
  { id: 1, name: messages.careerLevelEntry },
  { id: 2, name: messages.careerLevelIntermediate },
  { id: 3, name: messages.careerLevelSenior },
  { id: 4, name: messages.careerLevelLead },
  { id: 5, name: messages.careerLevelExecutive },
]

export const CANDIDATE_AVAILABILITY = [
  { name: messages.availableImmediately, value: "immediately" },
  { name: messages.availableInThreeMonths, value: "in_three_months" },
  {
    name: messages.availableInMoreThenThreeMonths,
    value: "more_than_three_months",
  },
]

export const CANDIDATE_NOT_HIRED_REASONS = [
  { value: "other", name: messages.other },
  {
    value: "in_progress",
    name: messages.onGoing,
    popover: messages.tooltipOnGoing,
  },
  {
    value: "bad_job_fit",
    name: messages.jobFit,
    popover: messages.tooltipJobFit,
  },
  {
    value: "rejected",
    name: messages.rejected,
    popover: messages.tooltipRejected,
  },
  {
    value: "not_responding",
    name: messages.notResponding,
    popover: messages.tooltipNotResponding,
  },
  {
    value: "unattractive_offer",
    name: messages.offerUnattractive,
    popover: messages.tooltipOfferUnattractive,
  },
]

export const CANDIDATE_DELETION_REASONS = [
  { value: "other", name: messages.candidateDeleteFeedbackOther },
  {
    value: "app_functionality",
    name: messages.candidateDeleteFeedbackAppFunctionality,
  },
  {
    value: "not_looking_anymore",
    name: messages.candidateDeleteFeedbackNotLookingAnymore,
  },
  {
    value: "wrong_location",
    name: messages.candidateDeleteFeedbackWrongLocation,
  },
  {
    value: "no_suitable_offers",
    name: messages.candidateDeleteFeedbackNoSuitableOffers,
  },
  {
    value: "already_hired",
    name: messages.candidateDeleteFeedbackAlreadyHired,
  },
  {
    value: "companies_not_responding",
    name: messages.candidateDeleteFeedbackCompaniesNotResponding,
  },
]

export const FEEDBACK_REASONS = {
  [FEEDBACK_SETS.CANDIDATE_NOT_HIRED_REASONS]: CANDIDATE_NOT_HIRED_REASONS,
  [FEEDBACK_SETS.CANDIDATE_DELETION_REASONS]: CANDIDATE_DELETION_REASONS,
}

export const MATCH_LIST_FILTER_OPTIONS = [
  {
    name: messages.matchCanAccepted,
    value: "applicationInReview",
  },
  {
    name: messages.matchComDeclined,
    value: "applicationDeclined",
  },
  {
    name: messages.matchComAccepted,
    value: "applicationAccepted",
  },
  {
    name: messages.matchComExpired,
    value: "applicationExpired",
  },
  {
    name: messages.matchComRejected,
    value: "applicationRejected",
  },
  {
    name: messages.matchComApproved,
    value: "jobOffered",
  },
]
