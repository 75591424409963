import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { UncontrolledPopover, PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { Badge } from "./badge-component"

export const FeedbackOption = ({ isActive, id, name, popover, onClick }) => {
  return (
    <Fragment>
      <span className="lead">
        <Badge
          onClick={onClick}
          color={isActive ? "success-light" : "light"}
          border
          className="text-muted clickable"
          id={`feedback-option-popover-${id}`}
        >
          <span>
            <FormattedMessage id={name} />
          </span>
        </Badge>
      </span>
      {popover && (
        <UncontrolledPopover
          placement="top"
          target={`feedback-option-popover-${id}`}
          trigger="hover"
        >
          <PopoverBody>
            <FormattedMessage id={popover} />
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </Fragment>
  )
}

FeedbackOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  popover: PropTypes.string,
}
