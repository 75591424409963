import React from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import {
  applicationsAcceptedSelector,
  applicationsTotalSelector,
} from "../../selectors/candidate-selector"

const messages = defineMessages({
  statisticsTitle: { id: "app.sidebar.statistics.title" },
  statisticsTotal: { id: "app.common.applications" },
  statisticsAccepted: { id: "app.sidebar.statistics.accepted" },
})

export function MatchingStatisticsComponent({ total, accepted }) {
  return (
    <div className="bg-white rounded p-3 mb-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-chart-bar" />
        </div>
        <FormattedMessage {...messages.statisticsTitle} />
      </h5>
      <ul className="list-unstyled m-0 border-top">
        <li className="py-2 border-bottom d-flex justify-content-between ">
          <span>
            <FormattedMessage {...messages.statisticsTotal} />
          </span>
          <b>{total}</b>
        </li>
        <li className="py-2 border-bottom d-flex justify-content-between ">
          <span>
            <FormattedMessage {...messages.statisticsAccepted} />
          </span>
          <b>{accepted}</b>
        </li>
      </ul>
    </div>
  )
}

MatchingStatisticsComponent.propTypes = {
  total: PropTypes.number.isRequired,
  accepted: PropTypes.number.isRequired,
}

MatchingStatisticsComponent.defaultProps = {
  total: 0,
  accepted: 0,
}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {
    total: applicationsTotalSelector(state),
    accepted: applicationsAcceptedSelector(state),
  }
}

export const MatchingStatistics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchingStatisticsComponent)
