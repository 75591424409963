import { all, call, put, select, takeLatest, fork } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType, isDocumentVisible } from "../lib/helpers"
import { reverse } from "ramda"
import { createMatchSelector } from "connected-react-router"
import {
  READ,
  MESSAGE,
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  LIST,
  CREATE,
  NEW,
  THREAD,
  PAGE,
  INIT,
  ADDITIONAL,
  MESSAGES,
  SOUND,
  PLAY,
  UPDATE,
  ONLINE,
  QUEUE,
  DEQUEUE,
  VISIBILITY,
  CHANGE,
} from "../constants"
import {
  hasQueuedMessageFetchSelector,
  lastFetchedPageSelector,
  loadedThreadIdSelector,
  loadingPageSelector,
} from "../selectors/message-selector"
import { refreshCandidateSaga } from "./candidate-saga"
import { requestThreadSaga } from "./thread-saga"
import { alertTypes, showAlert } from "../lib/alerts"

export function* createMessageSaga(action) {
  const { threadId, text } = action.payload

  yield put({ type: getActionType(CREATE, MESSAGE, START) })

  try {
    const { data: message } = yield call(moberriesApi.createMessage, {
      threadId,
      text,
    })

    yield put({
      type: getActionType(CREATE, MESSAGE, SUCCESS),
      payload: { message, threadId },
    })

    yield fork(requestThreadSaga, action)
  } catch (err) {
    yield put({
      type: getActionType(CREATE, MESSAGE, FAIL),
      payload: { err },
    })
  }
}

export function* newMessageSaga(action) {
  const { threadId } = action.payload
  try {
    const messengerPageMatch = yield select(
      createMatchSelector({
        path: "/messages",
        exact: true,
      }),
    )

    const threadPageMatch = yield select(
      createMatchSelector("/messages/:threadId"),
    )

    const openedThreadId =
      threadPageMatch && parseInt(threadPageMatch.params.threadId, 10)

    if (openedThreadId === threadId) {
      if (!isDocumentVisible()) {
        yield put({ type: getActionType(QUEUE, NEW, MESSAGE) })
        yield call(refreshCandidateSaga)

        yield put({
          type: getActionType(PLAY, SOUND),
          meta: { sound: { play: "newMessage" } },
        })
        return
      }
      const {
        data: { results: messages, count },
      } = yield call(moberriesApi.getMessageList, {
        threadId,
      })
      yield put({
        type: getActionType(NEW, MESSAGE, SUCCESS),
        payload: {
          messages: reverse(messages),
          count,
          threadId,
        },
      })
    }

    if (openedThreadId || messengerPageMatch) {
      const {
        data: { results: threads, count },
      } = yield call(moberriesApi.getThreadList, {})

      yield put({
        type: getActionType(UPDATE, THREAD, LIST, SUCCESS),
        payload: { threads, count },
      })
    }

    if (!openedThreadId && !messengerPageMatch) {
      showAlert({ code: alertTypes.newMessage })
    }

    yield put({
      type: getActionType(PLAY, SOUND),
      meta: { sound: { play: "newMessage" } },
    })

    yield fork(refreshCandidateSaga)
  } catch (err) {
    yield put({
      type: getActionType(NEW, MESSAGE, FAIL),
      payload: { err },
    })
  }
}

export function* initThreadPageSaga(action) {
  const { threadId } = action.payload
  try {
    yield put({
      type: getActionType(GET, MESSAGE, LIST, START),
      payload: { threadId, page: 1 },
    })

    yield put({
      type: getActionType(GET, THREAD, LIST, START),
      payload: {
        page: 1,
      },
    })
    const {
      data: { results: threads, count: threadCount },
    } = yield call(moberriesApi.getThreadList, {})

    yield put({
      type: getActionType(GET, THREAD, LIST, SUCCESS),
      payload: {
        threads,
        count: threadCount,
        page: 1,
      },
    })

    yield put({
      type: getActionType(GET, THREAD, START),
    })

    const [
      {
        data: { results: messages, count: messageCount },
      },
      { data: thread },
    ] = yield all([
      call(moberriesApi.getMessageList, {
        threadId,
        params: { page: 1, limit: 30 },
      }),
      call(moberriesApi.getThread, { id: threadId }),
    ])

    yield put({
      type: getActionType(GET, MESSAGE, LIST, SUCCESS),
      payload: {
        messages: reverse(messages),
        threadId,
        page: 1,
        count: messageCount,
      },
    })
    yield put({
      type: getActionType(GET, THREAD, SUCCESS),
      payload: { thread },
    })

    yield fork(refreshCandidateSaga)
  } catch (err) {
    yield put({
      type: getActionType(GET, MESSAGE, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* getAdditionalThreadMessagesSaga(action) {
  const { threadId, page } = action.payload
  const loadedThreadId = yield select(loadedThreadIdSelector)
  const lastFetchedPage = yield select(lastFetchedPageSelector)
  const loadingPage = yield select(loadingPageSelector)

  const loadingOrLoadedLaterPages =
    threadId === loadedThreadId &&
    (lastFetchedPage >= page || loadingPage >= page)

  if (loadingOrLoadedLaterPages) {
    return
  }

  try {
    yield put({
      type: getActionType(GET, MESSAGE, LIST, START),
      payload: { threadId, page },
    })

    const {
      data: { results: messages, count },
    } = yield call(moberriesApi.getMessageList, {
      threadId,
      params: { page, limit: 30 },
    })

    yield put({
      type: getActionType(GET, MESSAGE, LIST, SUCCESS),
      payload: {
        messages: reverse(messages),
        threadId,
        page,
        count,
      },
    })

    yield fork(refreshCandidateSaga)
  } catch (err) {
    yield put({
      type: getActionType(GET, MESSAGE, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* readMessageSaga(action) {
  const {
    payload: { threadId, messageId },
  } = action

  try {
    const messengerPageMatch = yield select(
      createMatchSelector({
        path: "/messages",
        exact: true,
      }),
    )

    const threadPageMatch = yield select(
      createMatchSelector({
        path: "/messages/:id",
      }),
    )

    if (messengerPageMatch || threadPageMatch) {
      const { data: thread } = yield call(moberriesApi.getThread, {
        id: threadId,
      })
      yield put({
        type: getActionType(GET, THREAD, SUCCESS),
        payload: {
          thread,
          id: threadId,
        },
      })
    }

    const openedThreadId =
      threadPageMatch && parseInt(threadPageMatch.params.id)

    if (openedThreadId === threadId) {
      const { data: message } = yield call(moberriesApi.getMessage, {
        threadId,
        messageId,
      })

      yield put({
        type: getActionType(GET, MESSAGE, SUCCESS),
        payload: {
          message,
          messageId,
        },
      })
    }
  } catch (err) {
    yield put({
      type: getActionType(GET, THREAD, FAIL),
      payload: { err },
    })
  }
}

export function* updateMessengerOnOnlineSaga() {
  try {
    const messengerPageMatch = yield select(
      createMatchSelector({
        path: "/messages",
        exact: true,
      }),
    )

    const threadPageMatch = yield select(
      createMatchSelector("/messages/:threadId"),
    )

    if (messengerPageMatch || threadPageMatch) {
      const {
        data: { results: threads, count },
      } = yield call(moberriesApi.getThreadList, {})

      yield put({
        type: getActionType(UPDATE, THREAD, LIST, SUCCESS),
        payload: {
          threads,
          count,
        },
      })
    }

    if (threadPageMatch) {
      const threadId = parseInt(threadPageMatch.params.threadId, 10)
      const {
        data: { results: messages, count },
      } = yield call(moberriesApi.getMessageList, {
        threadId,
      })

      const { data: thread } = yield call(moberriesApi.getThread, {
        id: threadId,
      })

      yield put({
        type: getActionType(GET, THREAD, SUCCESS),
        payload: {
          thread,
        },
      })

      yield put({
        type: getActionType(UPDATE, MESSAGE, LIST, SUCCESS),
        payload: {
          messages: reverse(messages),
          count,
        },
      })
    }
    yield fork(refreshCandidateSaga)
  } catch (err) {
    yield put({
      type: getActionType(UPDATE, THREAD, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* updateMessengerOnVisibilityChangeSaga(action) {
  try {
    const { isVisible } = action.payload
    if (!isVisible) {
      return
    }
    const hasQueuedMessageFetch = yield select(hasQueuedMessageFetchSelector)
    if (hasQueuedMessageFetch) {
      const threadPageMatch = yield select(
        createMatchSelector({
          path: "/messages/:id",
        }),
      )

      const openedThreadId =
        threadPageMatch && parseInt(threadPageMatch.params.id)

      if (!openedThreadId) {
        yield put({
          type: getActionType(DEQUEUE, NEW, MESSAGE),
        })
        return
      }

      const {
        data: { results: messages, count },
      } = yield call(moberriesApi.getMessageList, {
        threadId: openedThreadId,
      })

      yield put({
        type: getActionType(UPDATE, MESSAGE, LIST, SUCCESS),
        payload: {
          messages: reverse(messages),
          count,
        },
      })

      const { data: thread } = yield call(moberriesApi.getThread, {
        id: openedThreadId,
      })
      yield put({
        type: getActionType(GET, THREAD, SUCCESS),
        payload: {
          thread,
          id: openedThreadId,
        },
      })
      yield put({
        type: getActionType(DEQUEUE, NEW, MESSAGE),
      })
      yield fork(refreshCandidateSaga)
    }
  } catch (err) {
    yield put({
      type: getActionType(NEW, MESSAGE, FAIL),
    })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, ADDITIONAL, THREAD, MESSAGES, REQUEST),
      getAdditionalThreadMessagesSaga,
    ),
    takeLatest(getActionType(CREATE, MESSAGE, REQUEST), createMessageSaga),
    takeLatest(getActionType(NEW, MESSAGE), newMessageSaga),
    takeLatest(getActionType(READ, MESSAGE), readMessageSaga),
    takeLatest(getActionType(INIT, THREAD, PAGE, REQUEST), initThreadPageSaga),
    takeLatest(getActionType(ONLINE), updateMessengerOnOnlineSaga),
    takeLatest(
      getActionType(VISIBILITY, CHANGE),
      updateMessengerOnVisibilityChangeSaga,
    ),
  ])
}
