import { all, takeLatest, call, put, select } from "redux-saga/effects"
import cookie from "js-cookie"
import { config } from "../config"
import { ensureLocale, getActionType, getMessages } from "../lib/helpers"
import {
  CANDIDATE,
  CONNECT,
  FACEBOOK,
  FAIL,
  GET,
  LINKEDIN,
  LOCALE,
  LOGIN,
  REQUEST,
  START,
  SUCCESS,
  UPDATE,
} from "../constants"
import { candidateSelector } from "../selectors/candidate-selector"
import { getLocale } from "../actions/intl-actions"
import { localeSelector } from "../selectors/intl-selector"

export function* initLocaleSaga() {
  const browserLang = navigator.language || navigator.browserLanguage

  let locale = "en"

  if (browserLang) {
    locale = browserLang.split("-")[0]
  }

  const cookieLocale = yield call(
    [cookie, cookie.get],
    config.cookie.keys.locale,
  )

  if (cookieLocale) {
    locale = cookieLocale
  }

  const candidate = yield select(candidateSelector)

  if (candidate && candidate.settings) {
    locale = candidate.settings.locale
  }

  locale = ensureLocale(locale)

  yield put(getLocale({ locale }))
}

export function* getLocaleSaga(action) {
  const { locale } = action.payload

  yield put({ type: getActionType(GET, LOCALE, START) })

  try {
    const messages = yield call(getMessages, locale)

    yield put({
      type: getActionType(GET, LOCALE, SUCCESS),
      payload: {
        locale,
        messages,
      },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, LOCALE, FAIL), payload: { err } })
  }
}

export function* updateCookieLocaleSaga(action) {
  const { locale } = action.payload
  yield call([cookie, cookie.set], config.cookie.keys.locale, locale, {
    domain: config.cookie.domain,
  })
}

export function* watchCandidateSaga(action) {
  const { candidate } = action.payload
  const locale = yield select(localeSelector)

  if (candidate) {
    if (candidate.settings.locale !== locale) {
      yield put(getLocale({ locale: candidate.settings.locale }))
    }
  }
}

export const saga = function* () {
  yield all([
    takeLatest(getActionType(GET, LOCALE, REQUEST), getLocaleSaga),
    takeLatest(getActionType(GET, LOCALE, SUCCESS), updateCookieLocaleSaga),
    takeLatest(getActionType(LOGIN, SUCCESS), watchCandidateSaga),
    takeLatest(getActionType(CONNECT, LINKEDIN, SUCCESS), watchCandidateSaga),
    takeLatest(getActionType(CONNECT, FACEBOOK, SUCCESS), watchCandidateSaga),
    takeLatest(getActionType(GET, CANDIDATE, SUCCESS), watchCandidateSaga),
    takeLatest(
      getActionType(UPDATE, CANDIDATE, LOCALE, SUCCESS),
      watchCandidateSaga,
    ),
    takeLatest(getActionType(UPDATE, CANDIDATE, SUCCESS), watchCandidateSaga),
    initLocaleSaga(),
  ])
}
