import React from "react"
import PropTypes from "prop-types"
import { FormText as BFormText } from "reactstrap"
import { FormattedMessage } from "react-intl"

export const FormText = props => {
  const { children, ...rest } = props
  let body = null

  if (typeof children === "object" && children.id) {
    body = <FormattedMessage {...children} />
  } else {
    body = children
  }

  return <BFormText {...rest}>{body}</BFormText>
}

FormText.propTypes = {
  children: PropTypes.any,
}
