import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { BaseLayout } from "../components/layouts/base-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout.js"
import { Container } from "reactstrap"
import { DoublePasswordForm } from "../components/forms/double-password-form"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { defineMessages, FormattedMessage } from "react-intl"
import { doublePasswordValidator } from "../validators/double-password-validator"
import { resetPassword } from "../actions/user-actions"
import {
  passwordResetErrSelector,
  passwordResetLoadingSelector,
} from "../selectors/user-selector"

const messages = defineMessages({
  header: { id: "app.password.change.title" },
  send: { id: "app.common.send" },
  label: { id: "app.password.change.label" },
})

class PasswordReset extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    err: PropTypes.object,
  }

  render() {
    return (
      <BaseLayout className="py-5">
        <Container>
          <h1 className="text-center mb-4">
            <FormattedMessage {...messages.header} />
          </h1>
        </Container>
        <SmallSingleColumnLayout>
          <div className="bg-white px-3 py-5">
            <SmallInnerFormLayout>
              <DoublePasswordForm
                form="ResetPasswordForm"
                onSubmit={this.handleSubmit}
                validate={doublePasswordValidator}
                firstLabel={
                  <span>
                    <FormattedMessage {...messages.label} />
                  </span>
                }
                buttonLabel={<FormattedMessage {...messages.send} />}
              />
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    )
  }

  handleSubmit = ({ password }) => {
    const { code } = this.props.match.params
    this.props.resetPassword({ code, password })
  }
}

const mapDispatchToProps = { resetPassword }

const mapStateToProps = function (state) {
  return {
    loading: passwordResetLoadingSelector(state),
    err: passwordResetErrSelector(state),
  }
}

export const PasswordResetPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PasswordReset)
