import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"

export function LabelWithHint(props) {
  if (!props.label && !props.hint) return null
  return (
    <div className="d-flex mb-2">
      {props.label && (
        <Label for={props.for} className="m-0">
          {props.label}
          {props.isRequired && "*"}
        </Label>
      )}
      {props.hint && <span>{props.hint}</span>}
    </div>
  )
}

LabelWithHint.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  for: PropTypes.string,
  isRequired: PropTypes.bool,
}

LabelWithHint.defaultProps = {
  isRequired: false,
}
