import { equals, head, map, pathSatisfies, pipe, pluck } from "ramda"
import { serverErrorsMap } from "../server-errors-map"

export function mapMoberriesApiValidationError(data) {
  let errors = {}

  if (data.nonFieldErrors) {
    errors._error = pipe(pluck("code"), head)(data.nonFieldErrors)
  }

  if (data.fieldErrors) {
    errors = {
      ...errors,
      ...map(pipe(pluck("code"), head), data.fieldErrors),
    }
  }

  if (!data.fieldErrors && !data.nonFieldErrors) {
    errors._error = data.code
  }

  errors = {
    ...errors,
    ...map(
      code =>
        serverErrorsMap.hasOwnProperty(code) ? serverErrorsMap[code] : code,
      errors,
    ),
  }

  return errors
}

export const isJobInactiveError = err =>
  pathSatisfies(
    equals("precondition_failed"),
    ["response", "data", "nonFieldErrors", "0", "code"],
    err,
  ) // https://github.com/moberries/backend/issues/4639

export const isJobMismatchError = err =>
  pathSatisfies(equals("rules_exception"), ["response", "data", "code"], err)
