import { times } from "ramda"
import { MessageLoader } from "./message-loader"
import React from "react"
import PropTypes from "prop-types"

export const MessageListLoader = ({ count }) =>
  times(
    i => (
      <div key={i} className="bg-white">
        <MessageLoader onTheRight={i % 2 === 0} />
      </div>
    ),
    count,
  )

MessageListLoader.propTypes = {
  count: PropTypes.number.isRequired,
}

MessageListLoader.defaultProps = {
  count: 6,
}
