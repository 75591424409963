import { createSelector } from "reselect"

export const stateSelector = state => state.fcm

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const tokenSelector = cs(state => state.token)
export const supportedSelector = cs(state => state.supported)
export const optoutSelector = cs(state => state.optout)
