import { all, call, put, takeLatest } from "redux-saga/effects"
import { api as typeformApi } from "../lib/typeform-api"
import { getActionType } from "../lib/helpers"
import {
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  SURVEY,
  STATUS,
} from "../constants"
import { config } from "../config"

export function* getSurveyStatusSaga(action) {
  const { candidateId } = action.payload
  yield put({ type: getActionType(GET, SURVEY, STATUS, START) })
  try {
    const [{ data: en }, { data: de }] = yield all([
      call(typeformApi.getSurveyStatus, {
        query: candidateId,
        code: config.typeform.codes.en,
      }),
      call(typeformApi.getSurveyStatus, {
        query: candidateId,
        code: config.typeform.codes.de,
      }),
    ])
    const isFormCompleted = data =>
      data.items.some(i => Number(i.hidden.candidate) === candidateId)
    yield put({
      type: getActionType(GET, SURVEY, STATUS, SUCCESS),
      payload: { isCompleted: isFormCompleted(en) || isFormCompleted(de) },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, SURVEY, STATUS, FAIL),
      payload: { err },
    })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, SURVEY, STATUS, REQUEST),
      getSurveyStatusSaga,
    ),
  ])
}
