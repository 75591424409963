import PropTypes from "prop-types"
import React, { Component } from "react"
import {
  Pagination as BPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import { Link } from "react-router-dom"

export class Pagination extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    count: PropTypes.number,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
    className: PropTypes.string,
    listClassName: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const {
      disabled,
      count,
      page,
      limit,
      className,
      listClassName,
    } = this.props

    if (count === null || count === 0) return null

    const totalPages = Math.floor((count - 1) / limit) + 1

    let pages = []

    if (totalPages < 6) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <PaginationItem key={i} disabled={disabled} active={page === i}>
            <PaginationLink tag={Link} to={this.getPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        )
      }
    }

    if (page <= 3 && totalPages >= 6) {
      for (let i = 1; i < 6; i++) {
        pages.push(
          <PaginationItem key={i} disabled={disabled} active={page === i}>
            <PaginationLink tag={Link} to={this.getPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        )
      }
      pages.push(
        <PaginationItem key={totalPages + 1} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      )
    }

    if (page > 3 && page <= totalPages - 3 && totalPages >= 6) {
      pages.push(
        <PaginationItem key={totalPages + 1} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      )

      for (let i = page - 2; i <= page + 2; i++) {
        pages.push(
          <PaginationItem key={i} disabled={disabled} active={page === i}>
            <PaginationLink tag={Link} to={this.getPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        )
      }

      pages.push(
        <PaginationItem key={totalPages + 2} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      )
    }

    if (page > totalPages - 3 && totalPages >= 6) {
      pages.push(
        <PaginationItem key={totalPages + 1} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      )

      for (let i = totalPages - 6 + 2; i <= totalPages; i++) {
        pages.push(
          <PaginationItem key={i} disabled={disabled} active={page === i}>
            <PaginationLink tag={Link} to={this.getPage(i)}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        )
      }
    }

    return (
      <BPagination className={className} listClassName={listClassName}>
        <PaginationItem disabled={page === 1 || disabled}>
          <PaginationLink tag={Link} to={this.getPage(1)} first />
        </PaginationItem>

        <PaginationItem disabled={page === 1 || disabled}>
          <PaginationLink tag={Link} to={this.getPage(page - 1)} previous />
        </PaginationItem>

        {pages}

        <PaginationItem disabled={page === totalPages || disabled}>
          <PaginationLink tag={Link} to={this.getPage(page + 1)} next />
        </PaginationItem>

        <PaginationItem disabled={page === totalPages || disabled}>
          <PaginationLink tag={Link} to={this.getPage(totalPages)} last />
        </PaginationItem>
      </BPagination>
    )
  }

  getPage = page =>
    page === 1 ? this.props.basePath : `${this.props.basePath}/${page}`
}
