import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { head } from "ramda"
import Visibility from "visibilityjs"
import favicon from "../../assets/img/favicon.ico"
import faviconWithMessage from "../../assets/img/favicon-with-badge.ico"
import { unreadThreadsSelector } from "../../selectors/thread-selector"
import { unreadCountSelector } from "../../selectors/candidate-selector"
import { getFullName } from "../../lib/helpers"
import { usePrevious } from "react-hanger"

const defaultTitle =
  "MoBerries - Hire The Best Talent. Candidates Matched to Your Needs."

export const HeadComponent = ({ unreadCount, unreadFrom }) => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(
    !Visibility.hidden(),
  )
  const [isNotificationVisible, setIsNotificationVisisble] = useState(false)
  const [tick, setTick] = useState(0)

  const prevUnreadCount = usePrevious(unreadCount)
  const prevIsDocumentVisible = usePrevious(isDocumentVisible)
  useEffect(() => {
    if (unreadCount > prevUnreadCount && !isDocumentVisible) {
      setIsNotificationVisisble(true)
    }
  }, [unreadCount, prevUnreadCount, isDocumentVisible])

  useEffect(() => {
    if (!prevIsDocumentVisible && isDocumentVisible) {
      setIsNotificationVisisble(false)
    }
  }, [isDocumentVisible, prevIsDocumentVisible])

  useEffect(() => {
    Visibility.every(999999, 1000, () => {
      setTick(tick => tick + 1)
    })
    Visibility.change((e, state) => {
      setIsDocumentVisible(state === "visible")
    })
  }, [])

  const getTitle = () => {
    if (tick % 2 === 0) {
      return `(${unreadCount}) ${defaultTitle}`
    } else {
      if (unreadFrom) {
        return `Message from ${unreadFrom}`
      } else {
        return "New message"
      }
    }
  }

  return (
    <Helmet defer={false}>
      <title>{isNotificationVisible ? getTitle() : defaultTitle}</title>
      <link
        rel="shortcut icon"
        href={isNotificationVisible ? faviconWithMessage : favicon}
      />
    </Helmet>
  )
}

const mapStateToProps = function (state) {
  const threads = unreadThreadsSelector(state)

  return {
    unreadFrom: getFullName(head(threads)?.candidate),
    unreadCount: unreadCountSelector(state),
  }
}

HeadComponent.propTypes = {
  unreadCount: PropTypes.number,
  unreadFrom: PropTypes.string,
}

export const Head = connect(mapStateToProps)(HeadComponent)
