import { getActionType } from "../lib/helpers"
import {
  GET,
  JOB,
  LIST,
  REQUEST,
  SUGGESTION,
  WITH,
  SIMILAR,
  COMPANY,
  DISCARD,
  INITIAL,
  APPLICATION,
  CREATE,
  FEEDBACK,
  CLOSE,
  MODAL,
  OPEN,
  SEND,
  INVITATION,
  COPY,
  LINK,
  APPLY,
  SUGGESTIONS,
  AND,
  EXTERNAL,
  JOBS,
} from "../constants"

export const openInitialApplicationModal = () => ({
  type: getActionType(OPEN, INITIAL, APPLICATION, MODAL),
})

export const closeInitialApplicationModal = () => ({
  type: getActionType(CLOSE, INITIAL, APPLICATION, MODAL),
})

export const getJobSuggestionList = () => ({
  type: getActionType(GET, JOB, SUGGESTION, LIST, REQUEST),
})

export const getJob = ({ jobId }) => ({
  type: getActionType(GET, JOB, REQUEST),
  payload: { jobId },
})

export const getJobWithSimilarJobList = ({ jobId }) => ({
  type: getActionType(GET, JOB, WITH, SIMILAR, JOB, LIST, REQUEST),
  payload: { jobId },
})

export const getCompanyJobListRequest = ({ id, params }) => ({
  type: getActionType(GET, COMPANY, JOB, LIST, REQUEST),
  payload: { id, params },
})

export const applyForJob = ({
  jobId,
  motivationalNote,
  answers,
  candidate,
}) => ({
  type: getActionType(APPLY, JOB, REQUEST),
  payload: {
    jobId,
    candidate,
    motivationalNote,
    answers,
  },
})

export const discardJob = ({ jobId }) => ({
  type: getActionType(DISCARD, JOB, REQUEST),
  payload: {
    jobId,
  },
})

export const createJobFeedback = ({ answers, comment, jobId, skills }) => ({
  type: getActionType(CREATE, JOB, FEEDBACK, REQUEST),
  payload: {
    answers,
    comment,
    jobId,
    skills,
  },
})

export const closeJobFeedback = ({ jobId }) => {
  return {
    type: getActionType(CLOSE, JOB, FEEDBACK),
    payload: {
      jobId,
    },
  }
}

export const sendJobInvitation = ({ email, jobId }) => {
  return {
    type: getActionType(SEND, JOB, INVITATION, REQUEST),
    payload: {
      email,
      jobId,
    },
  }
}

export const openJobInvitationModalTrack = ({ jobId }) => {
  return {
    type: getActionType(OPEN, JOB, INVITATION, MODAL),
    payload: {
      jobId,
    },
  }
}

export const copyJobInvitationLinkTrack = ({ jobId }) => {
  return {
    type: getActionType(COPY, JOB, INVITATION, LINK),
    payload: {
      jobId,
    },
  }
}

export const getJobSuggestionsAndExternalJobs = () => ({
  type: getActionType(GET, JOB, SUGGESTIONS, AND, EXTERNAL, JOBS, REQUEST),
})
