import React from "react"
import PropTypes from "prop-types"
import { FormGroup, PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { useSkillsLoader } from "../../../../hooks/use-skills-loader"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { MultiSelect } from "../../../common/multi-select-component"
import { InfoHint } from "../../../common/info-hint-component"

import styles from "./key-skills-section.module.scss"

export const KeySkillsSection = ({ input, meta }) => {
  const { loadSkills } = useSkillsLoader()
  const { error, dirty } = meta

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.skills.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.skills.text" />
          </span>
        }
        isRequired
        hint={
          <InfoHint
            id="keySkillsHint"
            popover={
              <PopoverBody>
                <FormattedMessage
                  id="app.registration.tabs.professional.input.skills.hint"
                  values={{
                    link: (
                      <a
                        href="https://moberries.typeform.com/to/hXBGOH"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="app.registration.tabs.professional.input.skills.hint.link" />
                      </a>
                    ),
                  }}
                />
              </PopoverBody>
            }
          />
        }
      />

      {error && dirty && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      <div style={{ marginTop: 10 }}>
        <MultiSelect
          async
          input={input}
          meta={meta}
          loadOptions={loadSkills}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          maxItems={20}
          placeholder={
            <FormattedMessage id="app.registration.tabs.professional.input.skills.placeholder" />
          }
          noOptionsMessage={() => (
            <FormattedMessage id="app.common.input.placeholder.noResults" />
          )}
          backspaceRemovesValue={false}
        />
      </div>
    </FormGroup>
  )
}

KeySkillsSection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}
