import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Link } from "react-router-dom"
import humps from "humps"

const messages = defineMessages({
  editProfile: { id: "app.job.modal.mismatch.editProfile" },
  cancel: { id: "app.job.modal.mismatch.cancel" },
  title: { id: "app.job.modal.mismatch.title" },
  description: { id: "app.job.modal.mismatch.description" },
  jobRoleTitle: { id: "app.job.modal.mismatch.jobRole.title" },
  jobRoleDescription: { id: "app.job.modal.mismatch.jobRole.description" },
  locationTitle: { id: "app.job.modal.mismatch.location.title" },
  locationDescription: { id: "app.job.modal.mismatch.location.description" },
  languageTitle: { id: "app.job.modal.mismatch.language.title" },
  languageDescription: { id: "app.job.modal.mismatch.language.description" },
  careerLevelTitle: { id: "app.job.modal.mismatch.careerLevel.title" },
  careerLevelDescription: {
    id: "app.job.modal.mismatch.careerLevel.description",
  },
  jobTypeTitle: { id: "app.job.modal.mismatch.jobType.title" },
  jobTypeDescription: { id: "app.job.modal.mismatch.jobType.description" },
  otherTitle: { id: "app.job.modal.mismatch.other.title" },
  otherDescription: { id: "app.job.modal.mismatch.other.description" },
})

export const JobMismatchModal = ({ isOpen, onClose, mismatchReasons }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        <FormattedMessage {...messages.title} />
      </ModalHeader>
      <ModalBody>
        <p className="text-secondary px-3">
          <FormattedMessage {...messages.description} />
        </p>
        {mismatchReasons?.map((reason, index) => (
          <div key={reason} className="p-2">
            <div className="font-weight-bolder">
              <i className="fas fa-exclamation-triangle text-warning mr-2" />
              <FormattedMessage
                {...messages[`${humps.camelize(reason)}Title`]}
              />
            </div>
            <div className="text-smaller text-secondary px-4">
              <FormattedMessage
                {...messages[`${humps.camelize(reason)}Description`]}
              />
              {index < mismatchReasons.length - 1 && <hr className="mb-0" />}
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Link to="/profile/preferences" className="btn btn-primary ">
          <FormattedMessage {...messages.editProfile} />
        </Link>
      </ModalFooter>
    </Modal>
  )
}

JobMismatchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mismatchReasons: PropTypes.array,
}

JobMismatchModal.defaultProps = {
  isOpen: false,
  isApplying: false,
  isDisabled: false,
}
