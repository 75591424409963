import React from "react"
import { BaseLayout } from "../components/layouts/base-layout"
import { Container } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { noAuthRequired } from "../decorators/no-auth-required"
import { AdzunaFrame } from "../components/common/adzuna/adzuna"

import MailImg from "../assets/img/mail.svg"

export const SignupEmailSentPageComponent = () => (
  <BaseLayout className="py-5">
    <Container>
      <h1 className="text-center mb-4">
        <FormattedMessage id="app.email.sent.title" />
      </h1>
    </Container>
    <SingleColumnLayout>
      <div className="bg-white rounded px-3 py-7 d-flex flex-column align-items-center">
        <img width={230} className="img-fluid mb-4" src={MailImg} alt="Mail" />
        <p className="w-75 text-center h4 mb-5">
          <FormattedMessage id="app.email.sent.linkSent" />
        </p>
        <p className="w-75 text-center h6">
          <FormattedMessage id="app.email.sent.subtitle" />
        </p>
      </div>
    </SingleColumnLayout>
    <AdzunaFrame />
  </BaseLayout>
)

export const SignupEmailSentPage = noAuthRequired(SignupEmailSentPageComponent)
