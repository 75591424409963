import React, { Fragment, useState, useEffect } from "react"
import classNames from "classnames"
import { usePrevious } from "react-hanger"
import { Input } from "../common/input-component"
import { Button } from "../common/button-component"
import { FormattedMessage, useIntl, defineMessages } from "react-intl"
import PropTypes from "prop-types"
import { KeyCodes } from "../../constants"

const messages = defineMessages({
  send: { id: "app.common.send" },
})

export const NewMessage = ({ onSend, threadId, creating, err }) => {
  const intl = useIntl()
  const [message, setMessage] = useState("")
  const prevCreating = usePrevious(creating)
  useEffect(() => {
    if (!creating && prevCreating && !err) {
      setMessage("")
    }
  }, [creating, err, prevCreating])
  useEffect(() => {
    setMessage("")
  }, [threadId])
  return (
    <Fragment>
      <Input
        type="textarea"
        name="message"
        id="message"
        className="m-0 p-half bg-white border-top _lr-hide"
        inputClassName={classNames("border-0 p-2 rounded-0", {
          "bg-white": !creating,
          "bg-light": creating,
        })}
        style={{
          resize: "none",
        }}
        meta={{}}
        input={{
          readOnly: creating,
          value: message,
          onChange: e => setMessage(e.target.value),
          onKeyDown: e => {
            if (e.which === KeyCodes.Enter && e.ctrlKey && message.length > 0) {
              onSend({ text: message, threadId })
            }
          },
        }}
        rows={4}
        placeholder={intl.formatMessage({
          id: "app.page.messages.textarea.placeholder",
        })}
      />
      <div className="text-right bg-light py-2 px-3 border-top">
        <Button
          color="primary"
          onClick={() => {
            if (message.length > 0) {
              onSend({ text: message, threadId })
            }
          }}
          disabled={creating}
          loading={creating}
        >
          <FormattedMessage {...messages.send} />
        </Button>
      </div>
    </Fragment>
  )
}

NewMessage.propTypes = {
  onSend: PropTypes.func.isRequired,
  threadId: PropTypes.number,
  creating: PropTypes.bool.isRequired,
  err: PropTypes.object,
}
