import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

export const MessageReadStatus = ({ isRead, className }) => {
  return isRead ? (
    <span className={classNames("message-read-icon mr-1", className)}>
      <i className="fas fa-check text-success" />
      <i className="fas fa-check text-success" />
    </span>
  ) : (
    <i className={classNames("fas fa-check text-success mr-1", className)} />
  )
}

MessageReadStatus.propTypes = {
  isRead: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

MessageReadStatus.defaultProps = {
  isRead: false,
}
