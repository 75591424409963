import React from "react"
import PropTypes from "prop-types"
import { defineMessages, useIntl } from "react-intl"
import { Field } from "redux-form"
import { APPLICATION_QUESTION_TYPES } from "../../constants"
import { Textarea } from "../common/textarea-component"
import { CheckboxGroup } from "../common/checkbox-group-component"
import { Input } from "../common/input-component"
import { Select } from "../common/select-component"
import { requiredValidator } from "../../validators/required-validator"
import { pluck } from "ramda"

const messages = defineMessages({
  inputPlaceholder: { id: "app.job.questions.input.placeholder" },
  yes: { id: "app.common.yes" },
  no: { id: "app.common.no" },
})

const toFieldName = ({ id }) => `question-${id}`

const getFieldComponentAndProps = ({
  predefinedAnswers,
  questionType,
  formatMessage,
}) =>
  ({
    [APPLICATION_QUESTION_TYPES.BOOLEAN]: {
      Component: Select,
      fieldProps: {
        getOptionValue: option => option.answer,
        getOptionLabel: option => option.answer,
        options: [
          { id: true, answer: formatMessage(messages.yes) },
          { id: false, answer: formatMessage(messages.no) },
        ],
      },
    },
    [APPLICATION_QUESTION_TYPES.LONG_TEXT]: {
      Component: Textarea,
      fieldProps: {
        minRows: 6,
        className: "w-100",
        placeholder: formatMessage(messages.inputPlaceholder),
      },
    },
    [APPLICATION_QUESTION_TYPES.SHORT_TEXT]: {
      Component: Input,
      fieldProps: {
        placeholder: formatMessage(messages.inputPlaceholder),
      },
    },
    [APPLICATION_QUESTION_TYPES.SINGLE_SELECTION]: {
      Component: Select,
      fieldProps: {
        getOptionValue: option => option.answer,
        getOptionLabel: option => option.answer,
        options: predefinedAnswers,
      },
    },

    [APPLICATION_QUESTION_TYPES.MULTIPLE_SELECTION]: {
      Component: CheckboxGroup,
      fieldProps: {
        normalize: value => value.map(id => ({ id })),
        format: pluck("id"),
        options: predefinedAnswers.map(({ id, answer }) => ({
          id,
          name: { defaultMessage: answer, id: answer },
        })),
      },
    },
  }[questionType])

export const JobApplicationQuestionsField = ({ currentQuestion }) => {
  const intl = useIntl()
  const { Component, fieldProps = {} } = getFieldComponentAndProps({
    predefinedAnswers: currentQuestion.predefinedAnswers,
    questionType: currentQuestion.questionType,
    formatMessage: intl.formatMessage,
  })

  return (
    <Field
      component={Component}
      name={toFieldName(currentQuestion)}
      id={String(currentQuestion.id)}
      validate={currentQuestion.isRequired ? [requiredValidator] : []}
      {...fieldProps}
    />
  )
}

JobApplicationQuestionsField.propTypes = {
  currentQuestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isRequired: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    predefinedAnswers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number, answer: PropTypes.string }),
    ),
    questionType: PropTypes.string,
  }).isRequired,
}
