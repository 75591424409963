import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, defineMessages } from "react-intl"
import { Button } from "../common/button-component"
import { FeedbackOption } from "../common/feedback-option-component"
import { Textarea } from "../common/textarea-component"
import { FEEDBACK_REASONS } from "../../default-options"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"
import { useList } from "../../hooks/use-list"

const messages = defineMessages({
  declineLabelComment: {
    id: "app.job.candidate.decline.comment",
  },
  confirm: { id: "app.common.confirm" },
  optionsLabel: { id: "app.job.candidate.declined.title" },
  cancel: { id: "app.common.cancel" },
})

export const ConfirmationFeedback = ({
  cancelButtonLabel,
  confirmButtonLabel,
  onConfirmClick,
  onCancelClick,
  header,
  isDisabled,
  isConfirmButtonLoading,
  feedbackSetType,
  reasonsRequired,
  commentLengthForOtherRequired,
}) => {
  const [comment, setComment] = useState("")
  const { feedbackChoices } = useFeedbackChoices({ feedbackSetType })
  const {
    has: isActive,
    toggle: toggleReason,
    value: selectedReasons,
  } = useList()

  const handleTextareaChange = e => {
    const { value } = e.target
    setComment(value)
  }

  const isButtonDisabled = () => {
    if (isDisabled) {
      return true
    }

    if (reasonsRequired && selectedReasons.length < reasonsRequired) {
      return true
    }

    const other = feedbackChoices.find(fc => fc.value === "other")

    if (
      commentLengthForOtherRequired &&
      other &&
      isActive(other.id) &&
      selectedReasons.length === 1 &&
      comment.length < commentLengthForOtherRequired
    ) {
      return true
    }

    return false
  }

  return (
    <div className="bg-white p-3 border-top border-bottom">
      {header}
      {FEEDBACK_REASONS[feedbackSetType] && (
        <Fragment>
          <label>
            <FormattedMessage {...messages.optionsLabel} />
          </label>
          <div className="tags-wrapper mb-5">
            {feedbackChoices.map(o => {
              const reason = FEEDBACK_REASONS[feedbackSetType].find(
                r => o.value === r.value,
              )

              if (!reason) return null

              return (
                <FeedbackOption
                  key={o.id}
                  id={o.value}
                  name={reason.name.id}
                  popover={reason.popover?.id}
                  isActive={isActive(o.id)}
                  onClick={() => toggleReason(o.id)}
                />
              )
            })}
          </div>
        </Fragment>
      )}
      <Textarea
        label={
          <span>
            <FormattedMessage {...messages.declineLabelComment} />
          </span>
        }
        type="textarea"
        name={`feedback-${feedbackSetType}-comment`}
        id={`feedback-${feedbackSetType}-comment`}
        meta={{}}
        input={{
          value: comment,
          onChange: handleTextareaChange,
        }}
        className="w-100"
        minRows={4}
        maxRows={15}
      />
      <div>
        <Button
          disabled={isDisabled || isConfirmButtonLoading}
          onClick={onCancelClick}
          className="mr-2"
        >
          {cancelButtonLabel}
        </Button>
        <Button
          disabled={isButtonDisabled()}
          loading={isConfirmButtonLoading}
          onClick={() => onConfirmClick({ comment, selectedReasons })}
          color="primary"
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  )
}

ConfirmationFeedback.propTypes = {
  cancelButtonLabel: PropTypes.node.isRequired,
  confirmButtonLabel: PropTypes.node.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isConfirmButtonLoading: PropTypes.bool.isRequired,
  feedbackChoices: PropTypes.array.isRequired,
  feedbackSetType: PropTypes.number.isRequired,
  reasonsRequired: PropTypes.number,
  commentLengthForOtherRequired: PropTypes.number,
}

ConfirmationFeedback.defaultProps = {
  isDisabled: false,
  comment: "",
  feedbackChoices: [],
  cancelButtonLabel: <FormattedMessage {...messages.cancel} />,
  confirmButtonLabel: <FormattedMessage {...messages.confirm} />,
  isConfirmButtonLoading: false,
}
