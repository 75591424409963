import { path, pathSatisfies } from "ramda"
import * as Sentry from "@sentry/react"
import { config } from "../../config"

export function isMethod(method, payload) {
  return pathSatisfies(
    m => m === method.toLowerCase(),
    ["err", "config", "method"],
    payload,
  )
}

export function isStatus(status, payload) {
  return pathSatisfies(
    s => s === status,
    ["err", "response", "status"],
    payload,
  )
}

export function isProblem(problem, payload) {
  return pathSatisfies(s => s === problem, ["err", "problem"], payload)
}

export function hasCode(payload) {
  return Boolean(path(["err", "code"], payload))
}

export function hasProblem(payload) {
  return Boolean(path(["err", "problem"], payload))
}

export async function sagaErrorHandler(err, { sagaStack }) {
  Sentry.captureException(err, { extra: { sagaStack } })
  if (config.env.isDev) {
    throw err
  }
}

export function getActionType(...args) {
  return args.join("_")
}
