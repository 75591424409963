import React from "react"
import PropTypes from "prop-types"
import { FormGroup } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { ToggleComponent } from "../../../common/toggle-component/toggle-component"

import styles from "./remote-section.module.scss"

export const RemoteSection = ({ input }) => {
  const { value: isRemote, onChange } = input

  const remoteChangeHandler = () => {
    onChange(!isRemote)
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.remote.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.remote.text" />
          </span>
        }
      />

      <ToggleComponent
        className={styles.toggle}
        checked={isRemote}
        onChange={remoteChangeHandler}
      />
    </FormGroup>
  )
}

RemoteSection.propTypes = {
  input: PropTypes.object,
}
