import { all, call, take, put, select, takeLatest } from "redux-saga/effects"
import { differenceInDays, parseISO } from "date-fns"
import { stopSubmit, startSubmit, reset } from "redux-form"
import { push, getLocation } from "connected-react-router"
import { values, complement, isNil, filter, pathSatisfies } from "ramda"
import { api as moberriesApi } from "../lib/moberries-api"
import { api as webpushApi } from "../lib/webpush-api"
import { api as authApi } from "../lib/auth-proxy-api"
import { api as matchingApi } from "../lib/matching-api"
import { api as typeformApi } from "../lib/typeform-api"
import { decamelizeKeys, camelizeKeys } from "humps"
import { history } from "../history"
import { logout } from "../actions/user-actions"
import { refreshCandidate } from "../actions/candidate-actions"
import { config } from "../config"
import cookie from "js-cookie"
import qs from "qs"
import { localeSelector } from "../selectors/intl-selector"
import {
  tokenSelector,
  tokenUpdatedAtSelector,
} from "../selectors/user-selector"
import {
  getActionType,
  isProblem,
  isStatus,
  mapMoberriesApiValidationError,
} from "../lib/helpers"
import {
  START,
  LOGIN,
  SUCCESS,
  FAIL,
  LOGOUT,
  REFRESH,
  TOKEN,
  CLIENT_ERROR,
  REQUEST,
  EMAIL,
  CONFIRM,
  CONNECT,
  FACEBOOK,
  LINKEDIN,
  REDIRECT,
  SOCIAL,
  PROVIDER,
  RESEND,
  CONFIRMATION,
  RECOVER,
  PASSWORD,
  RESET,
  NEW,
  ACCOUNT,
  DELETE,
  DISCONNECT,
  UPDATE,
  APPLICATION,
  DATA,
  REMOVE,
  SIGNUP,
  MOBOT,
  ALERT_COLORS,
} from "../constants"
import { getTrackingsSaga } from "./candidate-saga"
import { alertTypes, showAlert, showRequestErrorAlert } from "../lib/alerts"

export function* loginWithEmailAndPasswordSaga(action) {
  const { email, password } = action.payload

  yield put({ type: getActionType(LOGIN, START) })
  yield put(startSubmit("LoginForm"))

  try {
    const {
      data: { token, accountType },
    } = yield call(moberriesApi.loginWithEmailAndPassword, {
      email,
      password,
    })

    if (accountType !== "candidate") {
      const err = new Error("Invalid account type for candidate application")
      err.code = "wrong_account_type"
      throw err
    }

    const headers = {
      header: "Authorization",
      value: `JWT ${token}`,
    }

    moberriesApi.setDefaultHeader(headers)
    matchingApi.setDefaultHeader(headers)
    webpushApi.setDefaultHeader(headers)
    typeformApi.setDefaultHeader(headers)
    const { data: candidate } = yield call(moberriesApi.getCandidate, {
      id: "me",
    })

    yield put(stopSubmit("LoginForm"))

    yield put({
      type: getActionType(LOGIN, SUCCESS),
      payload: { token, accountType, candidate },
    })
  } catch (err) {
    const payload = { err, email }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "LoginForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("LoginForm"))
    }

    showRequestErrorAlert(payload)

    yield put({ type: getActionType(LOGIN, FAIL), payload })
  }
}

export function* confirmEmailWithCodeSaga(action) {
  const { code } = action.payload

  yield put({ type: getActionType(CONFIRM, EMAIL, START) })

  try {
    const {
      data: { token },
    } = yield call(moberriesApi.confirmEmailWithCode, {
      code,
    })

    const headers = {
      header: "Authorization",
      value: `JWT ${token}`,
    }

    moberriesApi.setDefaultHeader(headers)
    matchingApi.setDefaultHeader(headers)
    webpushApi.setDefaultHeader(headers)
    typeformApi.setDefaultHeader(headers)

    const { data: candidate } = yield call(moberriesApi.getCandidate, {
      id: "me",
    })

    showAlert({ code: alertTypes.emailConfirmSuccess })

    yield put({
      type: getActionType(CONFIRM, EMAIL, SUCCESS),
      payload: { token, accountType: "candidate", candidate },
    })
  } catch (err) {
    yield put(push("/resend"))
    yield put({ type: getActionType(CONFIRM, EMAIL, FAIL), payload: { err } })
  }
}

export function* confirmMobotEmailWithCodeSaga(action) {
  const { code } = action.payload

  yield put({ type: getActionType(CONFIRM, MOBOT, EMAIL, START) })

  try {
    yield call(moberriesApi.confirmEmailWithCode, {
      code,
    })

    showAlert({ code: alertTypes.mobotEmailConfirmSuccess })

    yield put({
      type: getActionType(CONFIRM, MOBOT, EMAIL, SUCCESS),
    })
  } catch (err) {
    showAlert({
      code: alertTypes.mobotEmailConfirmError,
      color: ALERT_COLORS.ERROR,
    })
    yield put({
      type: getActionType(CONFIRM, MOBOT, EMAIL, FAIL),
      payload: { err },
    })
  }
}

export function* confirmNewEmailWithCodeSaga(action) {
  const { code } = action.payload

  yield put({ type: getActionType(CONFIRM, NEW, EMAIL, START) })

  try {
    yield call(moberriesApi.confirmNewEmailWithCode, {
      code,
    })

    yield put(refreshCandidate())
    yield put(push("/account"))

    showAlert({ code: alertTypes.emailConfirmNewSuccess })

    yield put({
      type: getActionType(CONFIRM, NEW, EMAIL, SUCCESS),
    })
  } catch (err) {
    yield put(push("/account"))
    yield put({
      type: getActionType(CONFIRM, NEW, EMAIL, FAIL),
      payload: { err },
    })
  }
}

export function* facebookOauthSaga(state) {
  yield put({ type: getActionType(CONNECT, FACEBOOK, REDIRECT, START) })

  try {
    const stateEncoded = window.btoa(
      JSON.stringify({
        provider: FACEBOOK,
        ...state,
      }),
    )

    const query = qs.stringify(
      decamelizeKeys({
        clientId: config.facebook.id,
        redirectUri: config.oauth.redirectUrl,
        authType: config.facebook.authType,
        responseType: config.facebook.responseType,
        scope: config.facebook.scope.join(","),
        state: stateEncoded,
      }),
    )

    window.location.assign(`${config.facebook.oauthUrl}?${query}`)
  } catch (err) {
    yield put({
      type: getActionType(CONNECT, FACEBOOK, REDIRECT, FAIL),
      payload: { err },
    })
  }
}

export function* facebookLoginSaga() {
  yield call(facebookOauthSaga, {
    from: "/",
  })
}

export function* linkedinOauthSaga(state) {
  yield put({ type: getActionType(CONNECT, LINKEDIN, REDIRECT, START) })

  try {
    const stateEncoded = window.btoa(
      JSON.stringify({
        provider: LINKEDIN,
        ...state,
      }),
    )

    const query = qs.stringify(
      decamelizeKeys({
        clientId: config.linkedin.id,
        redirectUri: config.oauth.redirectUrl,
        scope: config.linkedin.scope.join(","),
        responseType: config.linkedin.responseType,
        state: stateEncoded,
      }),
    )

    window.location.assign(`${config.linkedin.oauthUrl}?${query}`)
  } catch (err) {
    yield put({
      type: getActionType(CONNECT, LINKEDIN, REDIRECT, FAIL),
      payload: { err },
    })
  }
}

export function* linkedinLoginSaga() {
  yield call(linkedinOauthSaga, {
    from: "/",
  })
}

export function* linkedinConnectSaga() {
  yield call(linkedinOauthSaga, {
    from: "/account/social",
  })
}

export function* linkedinSignupSaga(action) {
  const { isNewsletterActive } = action.payload
  const { tracking, invitationTracking } = yield call(getTrackingsSaga)
  const referral = cookie.get(config.cookie.keys.ref)

  const locale = yield select(localeSelector)

  yield call(linkedinOauthSaga, {
    from: "/registration/personal",
    settings: { isNewsletterActive, locale },
    tracking,
    invitationTracking,
    referral,
  })
}

function resolveQueryFromLocation(location) {
  let params

  if (location.hash && location.hash !== "#_=_") {
    params = decodeURIComponent(location.hash.slice(1))
  } else {
    params = decodeURIComponent(location.search.slice(1))
  }

  if (!params) return null

  const query = camelizeKeys(qs.parse(params))

  if (!query.state || (!query.code && !query.accessToken && !query.error)) {
    return null
  }

  return query
}

export function* resolveAccessTokenFromCodeSaga({ provider, code }) {
  switch (provider) {
    case LINKEDIN: {
      const {
        data: {
          data: { accessToken: token },
        },
      } = yield call(authApi.getLinkedinAccessToken, {
        redirectUri: config.oauth.redirectUrl,
        code,
      })

      return token
    }

    default:
      const err = new Error("No known providers for resolving access token")
      err.code = "unknown_provider"
      throw err
  }
}

export function* connectOauthProviderSaga() {
  try {
    const location = yield select(getLocation)
    const query = resolveQueryFromLocation(location)

    if (!query) {
      yield put(push("/"))
      return
    }

    const { accessToken, code, error } = query
    const state = JSON.parse(window.atob(query.state))

    const {
      settings,
      tracking,
      invitationTracking,
      from,
      provider,
      referral,
    } = state

    if (!provider) throw new Error("No oauth provider")

    yield put({ type: getActionType(CONNECT, provider, START) })
    yield put(push(from || "/login"))

    if (error) {
      const err = new Error(error)

      if (typeof error === "string") {
        // user_cancelled_login - linkedin
        // user_cancelled_authorize - linkedin
        // access_denied - facebook
        err.code = error
        err.provider = provider
      }

      throw err
    }

    const data = {
      platform: provider.toLowerCase(),
      accessToken,
      tracking,
      invitationTracking,
      settings,
      referral,
    }

    if (!accessToken && code) {
      data.accessToken = yield call(resolveAccessTokenFromCodeSaga, {
        provider,
        code,
      })
    }

    const {
      data: { token, platform },
    } = yield call(
      moberriesApi.connectSocialAccount,
      filter(complement(isNil), data),
    )

    const headers = {
      header: "Authorization",
      value: `JWT ${token}`,
    }

    moberriesApi.setDefaultHeader(headers)
    matchingApi.setDefaultHeader(headers)
    webpushApi.setDefaultHeader(headers)
    typeformApi.setDefaultHeader(headers)

    const { data: candidate } = yield call(moberriesApi.getCandidate, {
      id: "me",
    })

    yield put({
      type: getActionType(CONNECT, provider, SUCCESS),
      payload: {
        candidate,
        token,
        platform,
        accountType: "candidate",
      },
    })
  } catch (err) {
    yield put({
      type: getActionType(CONNECT, err.provider || SOCIAL, FAIL),
      payload: { err },
    })
  }
}

export function* disconnectFacebookSaga() {
  yield put({ type: getActionType(DISCONNECT, FACEBOOK, START) })

  try {
    yield call(moberriesApi.disconnectFacebook)

    showAlert({
      code: alertTypes.socialDisconnect,
      values: { platform: FACEBOOK.toLowerCase() },
    })

    yield put({
      type: getActionType(DISCONNECT, FACEBOOK, SUCCESS),
    })
  } catch (err) {
    yield put({
      type: getActionType(DISCONNECT, FACEBOOK, FAIL),
      payload: { err },
    })
  }
}

export function* disconnectLinkedinSaga() {
  yield put({ type: getActionType(DISCONNECT, LINKEDIN, START) })

  try {
    yield call(moberriesApi.disconnectLinkedin)

    showAlert({
      code: alertTypes.socialDisconnect,
      values: { platform: LINKEDIN.toLowerCase() },
    })

    yield put({ type: getActionType(DISCONNECT, LINKEDIN, SUCCESS) })
  } catch (err) {
    yield put({
      type: getActionType(DISCONNECT, LINKEDIN, FAIL),
      payload: { err },
    })
  }
}

export function* resendEmailConfirmationSaga(action) {
  const { email } = action.payload

  yield put({ type: getActionType(RESEND, EMAIL, CONFIRMATION, START) })
  yield put(startSubmit("ResendEmailConfirmationForm"))

  try {
    yield call(moberriesApi.resendConfirmationEmail, { email })

    yield put(stopSubmit("ResendEmailConfirmationForm"))

    showAlert({ code: alertTypes.resendConfirmationEmailSuccess })

    yield put({
      type: getActionType(RESEND, EMAIL, CONFIRMATION, SUCCESS),
    })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "ResendEmailConfirmationForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("ResendEmailConfirmationForm"))
    }

    yield put({
      type: getActionType(RESEND, EMAIL, CONFIRMATION, FAIL),
      payload,
    })
  }
}

export function* recoverPasswordSaga(action) {
  const { email } = action.payload

  yield put({ type: getActionType(RECOVER, PASSWORD, START) })
  yield put(startSubmit("RecoveryPasswordForm"))

  try {
    yield call(moberriesApi.recoverPassword, { email })

    yield put(stopSubmit("RecoveryPasswordForm"))

    showAlert({ code: alertTypes.sendResetPasswordEmailSuccess })

    yield put({
      type: getActionType(RECOVER, PASSWORD, SUCCESS),
    })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "RecoveryPasswordForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    }
    if (isProblem(CLIENT_ERROR, payload) && isStatus(404, payload)) {
      const error = {
        code: "validation_error",
        fieldErrors: {
          email: [
            {
              code: "email_not_found",
            },
          ],
        },
      }

      payload.err.response.data = error

      yield put(
        stopSubmit(
          "RecoveryPasswordForm",
          mapMoberriesApiValidationError(error),
        ),
      )
    } else {
      yield put(stopSubmit("RecoveryPasswordForm"))
    }

    if (
      pathSatisfies(
        code => code !== "email_not_found",
        ["err", "response", "data", "fieldErrors", "email", "0", "code"],
        payload,
      )
    ) {
      showRequestErrorAlert(payload)
    }

    yield put({
      type: getActionType(RECOVER, PASSWORD, FAIL),
      payload,
    })
  }
}

export function* resetPasswordSaga(action) {
  const { password, code } = action.payload

  yield put({ type: getActionType(RESET, PASSWORD, START) })
  yield put(startSubmit("ResetPasswordForm"))

  try {
    yield call(moberriesApi.resetPassword, { password, code })

    yield put(stopSubmit("ResetPasswordForm"))

    yield put(push("/login"))

    showAlert({ code: alertTypes.updateForgottenPasswordSuccess })

    yield put({
      type: getActionType(RESET, PASSWORD, SUCCESS),
    })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "ResetPasswordForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("ResetPasswordForm"))
    }

    yield put({
      type: getActionType(RESET, PASSWORD, FAIL),
      payload,
    })
  }
}

export function* updateEmailSaga(action) {
  const { id, email } = action.payload

  yield put({ type: getActionType(UPDATE, EMAIL, START) })
  yield put(startSubmit("UpdateEmailForm"))

  try {
    yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { email },
    })

    yield put(stopSubmit("UpdateEmailForm"))
    yield put(reset("UpdateEmailForm"))

    showAlert({ code: alertTypes.emailSentSuccess })

    yield put({
      type: getActionType(UPDATE, EMAIL, SUCCESS),
      payload: { email },
    })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "UpdateEmailForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("UpdateEmailForm"))
    }

    yield put({
      type: getActionType(UPDATE, EMAIL, FAIL),
      payload,
    })
  }
}

export function* updatePasswordSaga(action) {
  const { password, passwordNew } = action.payload

  yield put({ type: getActionType(UPDATE, PASSWORD, START) })
  yield put(startSubmit("UpdatePasswordForm"))

  try {
    yield call(moberriesApi.updatePassword, {
      password,
      passwordNew,
    })

    yield put(stopSubmit("UpdatePasswordForm"))
    yield put(reset("UpdatePasswordForm"))

    showAlert({ code: alertTypes.updatePasswordSuccess })

    yield put({
      type: getActionType(UPDATE, PASSWORD, SUCCESS),
    })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "UpdatePasswordForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("UpdatePasswordForm"))
    }

    yield put({ type: getActionType(UPDATE, PASSWORD, FAIL), payload })
  }
}

export function* deleteAccountSaga(action) {
  const { reasons, comment } = action.payload

  yield put({ type: getActionType(DELETE, ACCOUNT, START) })

  try {
    yield call(moberriesApi.deleteAccount)
    yield call(moberriesApi.createFeedback, { answers: reasons, comment })

    showAlert({ code: alertTypes.accountDeleteSuccess })

    yield put({ type: getActionType(DELETE, ACCOUNT, SUCCESS) })
  } catch (err) {
    yield put({ type: getActionType(DELETE, ACCOUNT, FAIL), payload: { err } })
  }
}

export function* confirmDeleteAccountSaga(action) {
  const { code } = action.payload

  yield put({ type: getActionType(CONFIRM, DELETE, ACCOUNT, START) })

  try {
    yield call(moberriesApi.confirmDeleteAccountWithCode, { code })
    yield put(logout())
    yield take(getActionType(LOGOUT, SUCCESS))
    yield put(push("/login"))

    showAlert({ code: alertTypes.confirmAccountDeleteSuccess })

    yield put({ type: getActionType(CONFIRM, DELETE, ACCOUNT, SUCCESS) })
  } catch (err) {
    yield put(push("/"))
    yield put({
      type: getActionType(CONFIRM, DELETE, ACCOUNT, FAIL),
      payload: { err },
    })
  }
}

export function* logoutSaga() {
  yield put({ type: getActionType(LOGOUT, START) })

  const headers = {
    header: "Authorization",
    value: "",
  }

  moberriesApi.setDefaultHeader(headers)
  matchingApi.setDefaultHeader(headers)
  webpushApi.setDefaultHeader(headers)
  typeformApi.setDefaultHeader(headers)

  yield put({ type: getActionType(LOGOUT, SUCCESS) })
}

export function* initAuthorizationTokenSaga() {
  const token = yield select(tokenSelector)

  if (token) {
    const headers = {
      header: "Authorization",
      value: `JWT ${token}`,
    }

    moberriesApi.setDefaultHeader(headers)
    matchingApi.setDefaultHeader(headers)
    webpushApi.setDefaultHeader(headers)
    typeformApi.setDefaultHeader(headers)
  }
}

export function* refreshTokenSaga() {
  const tokenUpdatedAt = yield select(tokenUpdatedAtSelector)
  const token = yield select(tokenSelector)
  if (!token || differenceInDays(new Date(), parseISO(tokenUpdatedAt)) < 1) {
    return
  }

  try {
    const { data } = yield call(moberriesApi.refreshToken, { token })

    yield put({
      type: getActionType(REFRESH, TOKEN, SUCCESS),
      payload: { token: data.token },
    })
  } catch (err) {
    yield put({
      type: getActionType(REFRESH, TOKEN, FAIL),
      payload: { err },
    })
  }
}

export function* initJwtFromQuerySaga() {
  const hadToken = Boolean(yield select(tokenSelector))

  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true })

  if (query.jwt) {
    const { jwt, ...nextQuery } = query

    const nextLocation = {
      ...history.location,
      search: qs.stringify(nextQuery, { addQueryPrefix: true }),
    }

    history.replace(history.createHref(nextLocation))

    if (hadToken) return

    const headers = {
      header: "Authorization",
      value: `JWT ${jwt}`,
    }

    moberriesApi.setDefaultHeader(headers)
    matchingApi.setDefaultHeader(headers)
    webpushApi.setDefaultHeader(headers)
    typeformApi.setDefaultHeader(headers)

    try {
      const { data: candidate } = yield call(moberriesApi.getCandidate, {
        id: "me",
      })

      yield put({
        type: getActionType(LOGIN, SUCCESS),
        payload: { accountType: "candidate", token: jwt, candidate },
      })
    } catch (err) {
      showRequestErrorAlert({ err })

      yield put({
        type: getActionType(LOGIN, FAIL),
        payload: { err },
      })
    }
  }
}

export function* removeLocalDataSaga(ctx) {
  const cookies = values(config.cookie)
  const cookieAttrs = {
    domain: config.cookie.domain,
  }

  yield put({ type: LOGOUT })

  for (const name of cookies) {
    yield call([cookie, cookie.remove], name, cookieAttrs)
  }

  yield call([ctx.persistor, ctx.persistor.purge])
}

export const saga = function* (ctx) {
  yield all([
    takeLatest(LOGOUT, logoutSaga),
    takeLatest(
      getActionType(REMOVE, APPLICATION, DATA),
      removeLocalDataSaga,
      ctx,
    ),
    takeLatest(getActionType(LOGIN, REQUEST), loginWithEmailAndPasswordSaga),
    takeLatest(
      getActionType(CONNECT, SOCIAL, PROVIDER, REQUEST),
      connectOauthProviderSaga,
    ),
    takeLatest(getActionType(LOGIN, FACEBOOK, REQUEST), facebookLoginSaga),
    takeLatest(getActionType(LOGIN, LINKEDIN, REQUEST), linkedinLoginSaga),
    takeLatest(getActionType(SIGNUP, LINKEDIN, REQUEST), linkedinSignupSaga),
    takeLatest(getActionType(CONNECT, LINKEDIN, REQUEST), linkedinConnectSaga),
    takeLatest(
      getActionType(DISCONNECT, FACEBOOK, REQUEST),
      disconnectFacebookSaga,
    ),
    takeLatest(
      getActionType(DISCONNECT, LINKEDIN, REQUEST),
      disconnectLinkedinSaga,
    ),
    takeLatest(
      getActionType(CONFIRM, EMAIL, REQUEST),
      confirmEmailWithCodeSaga,
    ),
    takeLatest(
      getActionType(CONFIRM, MOBOT, EMAIL, REQUEST),
      confirmMobotEmailWithCodeSaga,
    ),
    takeLatest(getActionType(UPDATE, EMAIL, REQUEST), updateEmailSaga),
    takeLatest(
      getActionType(CONFIRM, NEW, EMAIL, REQUEST),
      confirmNewEmailWithCodeSaga,
    ),
    takeLatest(
      getActionType(RESEND, EMAIL, CONFIRMATION, REQUEST),
      resendEmailConfirmationSaga,
    ),
    takeLatest(getActionType(UPDATE, PASSWORD, REQUEST), updatePasswordSaga),
    takeLatest(getActionType(RECOVER, PASSWORD, REQUEST), recoverPasswordSaga),
    takeLatest(getActionType(RESET, PASSWORD, REQUEST), resetPasswordSaga),
    takeLatest(getActionType(DELETE, ACCOUNT, REQUEST), deleteAccountSaga),
    takeLatest(
      getActionType(CONFIRM, DELETE, ACCOUNT, REQUEST),
      confirmDeleteAccountSaga,
    ),
    initJwtFromQuerySaga(),
    refreshTokenSaga(),
  ])
}
