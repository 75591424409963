import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { goBack } from "connected-react-router"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "./button-component"

export function NotFoundComponent(props) {
  return (
    <Fragment>
      <h1>Oh. Looks like you are lost.</h1>
      <p className="text-muted">
        For whatever reason, this page is either missing or no longer exists.
        Why not return <Link to="/">home</Link> or just go
        <Button onClick={props.goBack} color="link">
          ← back
        </Button>
      </p>
      <img
        className="img-fluid"
        src="//assets.moberries.com/images/404.svg"
        width="600"
        alt="Not Found"
      />
    </Fragment>
  )
}

NotFoundComponent.propTypes = {
  goBack: PropTypes.func.isRequired,
}

NotFoundComponent.defaultProps = {}

const mapDispatchToProps = { goBack }

const mapStateToProps = function (state) {
  return {}
}

export const NotFound = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotFoundComponent)
