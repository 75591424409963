import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { mergeLeft } from "ramda"
import { FormattedMessage } from "react-intl"
import { v4 as generateFakeId } from "uuid"

import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { InfoHint } from "../../../common/info-hint-component"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { InstitutionInputs } from "./institution-inputs/institution-inputs"
import { DetailsInputs } from "./details-inputs/details-inputs"
import { DatesInputs } from "./dates-inputs/dates-inputs"

import styles from "./education-section.module.scss"

export const EducationSection = ({ input, meta }) => {
  let { value: values, onChange } = input
  const { error, dirty } = meta

  const changePropHandler = (value, id, propName) => {
    console.log("changePropHandler", value, id, propName)

    const updatedValues = values.map(v => {
      const diff = { [propName]: value }

      if (propName === "startDate" && !v.endDate) {
        diff.endDate = null
      }

      return v.id === id ? mergeLeft(diff, v) : v
    })
    onChange(updatedValues)
  }

  const addItemHandler = () => {
    if (!values[0].institution || values.length >= 3) return

    const newValues = [{ id: generateFakeId() }, ...values]
    onChange(newValues)
  }

  const removeItemHandler = id => {
    const newValues =
      values.length === 1 ? [{ id: -1 }] : values.filter(v => v.id !== id)
    onChange(newValues)
  }

  const checkCurrentlyStudyHandler = id => {
    const updatedValues = values.map(v => {
      if (v.id !== id) return v

      if (v.endDate) {
        return mergeLeft({ endDate: null }, v)
      } else {
        return mergeLeft({ endDate: v.startDate }, v)
      }
    })
    onChange(updatedValues)
  }

  // For the first render when "education" is null
  if (!values?.length) values = [{ id: -1 }]

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.education.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.education.text" />
          </span>
        }
        hint={
          <InfoHint
            id="educationHint"
            popover={
              <PopoverBody>
                <FormattedMessage
                  id="app.registration.tabs.professional.input.education.hint"
                  values={{
                    email: (
                      <a href="mailto:support@moberries.com">
                        support@moberries.com
                      </a>
                    ),
                  }}
                />
              </PopoverBody>
            }
          />
        }
      />

      {error && dirty && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      {values.map((item, i) => (
        <div key={item.id} className={styles.formItem}>
          <div className={styles.main}>
            <div className={styles.inputs}>
              <InstitutionInputs
                className={styles.inputs__institution}
                item={item}
                changeProp={changePropHandler}
              />

              <DetailsInputs
                className={styles.inputs__details}
                item={item}
                changeProp={changePropHandler}
              />

              <DatesInputs item={item} changeProp={changePropHandler} />
            </div>

            <div className={styles.actions}>
              {i === 0 &&
              item.institution &&
              item.fieldOfStudy &&
              item.degree &&
              item.startDate &&
              values.length < 3 ? (
                <ButtonWithIcon iconName="plus" onClick={addItemHandler} />
              ) : (
                <ButtonWithIcon
                  iconName="cross"
                  onClick={() => removeItemHandler(item.id)}
                />
              )}
            </div>
          </div>

          {item.startDate && (
            <Label className={styles.checkbox}>
              <Input
                type="checkbox"
                checked={item.startDate && !item.endDate}
                onChange={() => checkCurrentlyStudyHandler(item.id)}
              />
              <FormattedMessage id="app.registration.tabs.professional.input.education.check" />
            </Label>
          )}
        </div>
      ))}
    </FormGroup>
  )
}

EducationSection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}
