import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { FormattedMessage } from "react-intl"

import styles from "./toggle-component.module.scss"

export const ToggleComponent = ({ className, checked, onChange }) => {
  return (
    <label className={cn([styles.toggleWrapper, className])}>
      <input
        className={styles.toggleInput}
        checked={checked}
        type="checkbox"
        onChange={onChange}
      />
      <span className={styles.toggleSlider}></span>
      <span className={cn([styles.text, styles.text_off])}>
        <FormattedMessage id="app.common.input.switch.no" />
      </span>
      <span className={cn([styles.text, styles.text_on])}>
        <FormattedMessage id="app.common.input.switch.yes" />
      </span>
    </label>
  )
}

ToggleComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}
