import React from "react"
import { NavLink as RLink } from "react-router-dom"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import {
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"

import { BaseLayout } from "../base-layout"
import { DoubleColumnLayoutProfile } from "./double-column-layout-profile"
import { ProfileBasicDetailsPanel } from "../../../pages/profile-page/profile-basic-details-panel"
import { MatchSubscriptionModal } from "../../modals/match-subscription-modal-component"
import { compose } from "ramda"
import { authRequired } from "../../../decorators/auth-required"
import { connect } from "react-redux"
import { haveJustFinishedRegistrationSelector } from "../../../selectors/candidate-selector"
import { useOpenClose } from "../../../hooks/use-open-close"

const messages = defineMessages({
  title: { id: "app.myProfile.title" },
  professional: { id: "app.myProfile.tabs.professional" },
  preferences: { id: "app.myProfile.tabs.preferences" },
  resume: { id: "app.myProfile.button.resume" },
})

export const ProfilePageLayoutComponent = props => {
  const { haveJustFinishedRegistration } = props
  const {
    value: isMatchSubscriptionModalOpen,
    close: closeMatchSubscriptionModal,
  } = useOpenClose({
    initialValue: haveJustFinishedRegistration,
  })

  return (
    <>
      {isMatchSubscriptionModalOpen && (
        <MatchSubscriptionModal
          isFromSignUpFlow={haveJustFinishedRegistration}
          toggle={closeMatchSubscriptionModal}
          isOpen={isMatchSubscriptionModalOpen}
        />
      )}
      <BaseLayout className="py-5">
        <Container>
          <h1 className="mb-4">
            <FormattedMessage {...messages.title} />
          </h1>
        </Container>

        <DoubleColumnLayoutProfile>
          <DoubleColumnLayoutProfile.Left>
            <ProfileBasicDetailsPanel />
          </DoubleColumnLayoutProfile.Left>

          <DoubleColumnLayoutProfile.Right>
            <Nav tabs justified className="profiles-tabs mt-5 mt-lg-0">
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/profile/personal"
                  exact
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage {...messages.professional} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/profile/preferences"
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage {...messages.preferences} />
                </NavLink>
              </NavItem>
              <div className="ml-5">
                <Button color="primary" tag="a" href="/profile/resume/edit">
                  <FormattedMessage {...messages.resume} />
                </Button>
              </div>
            </Nav>
            <div className="bg-white rounded-bottom px-3 py-3">
              <TabContent>{props.children}</TabContent>
            </div>
          </DoubleColumnLayoutProfile.Right>
        </DoubleColumnLayoutProfile>
      </BaseLayout>
    </>
  )
}

ProfilePageLayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  haveJustFinishedRegistration: PropTypes.bool,
}

const mapStateToProps = function (state) {
  return {
    haveJustFinishedRegistration: haveJustFinishedRegistrationSelector(state),
  }
}

export const ProfilePageLayout = compose(
  authRequired,
  connect(mapStateToProps),
)(ProfilePageLayoutComponent)
