import React, { useEffect } from "react"
import { addWeeks } from "date-fns"
import * as typeformEmbed from "@typeform/embed"
import { connect } from "react-redux"
import { config } from "../../config"
import PropTypes from "prop-types"
import { getSurveyStatus, closeSurvey } from "../../actions/survey-actions"
import {
  completedSelector,
  isClosedSelector,
  errorSelector,
} from "../../selectors/survey-selector"
import { candidateSelector } from "../../selectors/candidate-selector"
import { localeSelector } from "../../selectors/intl-selector"
import { Button } from "reactstrap"
import { range } from "ramda"
import { defineMessages, FormattedMessage } from "react-intl"
import { LOCALES } from "../../constants"

function createSurveyLink({ candidateId, rating, code }) {
  return `https://moberries-form.typeform.com/to/${code}?candidate=${candidateId}&rating=${rating}`
}

const messages = defineMessages({
  question: { id: "app.widget.survey.question" },
  notLikely: { id: "app.widget.survey.answer.notLikely" },
  likely: { id: "app.widget.survey.answer.likely" },
})

const TypeFormWidgetComponent = ({
  getSurveyStatus,
  candidate,
  isCompleted,
  locale,
  closeSurvey,
  isClosed,
  err,
}) => {
  const candidateId = candidate?.id
  const isAccountOlderThan4Weeks =
    Boolean(candidate?.completedAt) &&
    addWeeks(new Date(candidate.completedAt), 4) < new Date()

  useEffect(() => {
    if (!isClosed && candidateId && isAccountOlderThan4Weeks) {
      getSurveyStatus({ candidateId })
    }
  }, [candidateId, getSurveyStatus, isClosed, isAccountOlderThan4Weeks])

  const handleOnWidgetClick = e =>
    typeformEmbed
      .makePopup(
        createSurveyLink({
          candidateId,
          rating: e.target.value,
          code: config.typeform.codes[locale],
        }),
        {
          hideFooter: true,
          hideHeaders: true,
          opacity: 100,
          autoClose: 2,
          onSubmit: closeSurvey,
        },
      )
      .open()

  if (
    isCompleted ||
    isClosed ||
    err ||
    !candidateId ||
    !isAccountOlderThan4Weeks
  ) {
    return null
  }

  return (
    <div className="d-none d-sm-inline-flex flex-column bg-white typeform-widget rounded  p-1 shadow">
      <div className="d-flex flex-row justify-content-between border-bottom align-items-center m-2 pb-2">
        <span className="font-weight-bold">
          <FormattedMessage {...messages.question} />
        </span>
        <i
          className="fas fa-times fa-lg text-muted clickable"
          onClick={closeSurvey}
        />
      </div>
      <div className="d-flex flex-row align-items-center m-2">
        <span className="text-muted mr-1 font-weight-bold">
          <FormattedMessage {...messages.notLikely} />
        </span>
        <div className="survey-buttons d-flex flex-row">
          {range(1, 11).map(n => (
            <div key={n} className="pr-1 survey-button-wrapper">
              <Button
                onClick={handleOnWidgetClick}
                color="light"
                value={n}
                className="survey-button font-weight-bold p-0"
              >
                {n}
              </Button>
            </div>
          ))}
        </div>
        <span className="text-muted font-weight-bold">
          <FormattedMessage {...messages.likely} />
        </span>
      </div>
    </div>
  )
}

TypeFormWidgetComponent.propTypes = {
  candidate: PropTypes.object,
  locale: PropTypes.oneOf(LOCALES),
  getSurveyStatus: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  closeSurvey: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
  err: PropTypes.object,
}

TypeFormWidgetComponent.defaultProps = {
  locale: "en",
  isCompleted: true,
  isClosed: true,
}

const mapStateToProps = function (state) {
  return {
    candidate: candidateSelector(state),
    isCompleted: completedSelector(state),
    locale: localeSelector(state),
    isClosed: isClosedSelector(state),
    err: errorSelector(state),
  }
}

const mapDispatchToProps = {
  getSurveyStatus,
  closeSurvey,
}

export const TypeFormWidget = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypeFormWidgetComponent)
