import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"
import { compose } from "ramda"
import cn from "classnames"

import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { candidateSelector } from "../../selectors/candidate-selector"

import defaultLogo from "../../assets/img/default-logo.png"
import styles from "./profile-basic-details-panel.module.scss"

const icons = {
  mobile: "fas fa-mobile-alt",
  email: "fas fa-envelope",
  city: "fas fa-city",
  edit: "fas fa-edit",
}

export const ProfileBasicDetails = ({ candidate }) => {
  return (
    <div className={styles.container}>
      <div className={styles.basicInfo}>
        <img
          className={styles.image}
          src={candidate.picture || defaultLogo}
          alt="Profile"
        />
        <h1 className={styles.name}>
          {`${candidate.firstName} ${candidate.lastName}`}
        </h1>

        <div className={styles.contactInfo}>
          {candidate.phoneNumber && (
            <p className={styles.contactItem}>
              <i className={cn(icons.mobile, styles.icon)} />
              {candidate.phoneNumber}
            </p>
          )}
          <p className={styles.contactItem}>
            <i className={cn(icons.email, styles.icon)} />
            {candidate.email}
          </p>
          <p className={styles.contactItem}>
            <i className={cn(icons.city, styles.icon)} />
            {candidate.city.name}
          </p>
        </div>
      </div>

      <Button tag="a" className={styles.editButton} href="/profile/edit">
        <FormattedMessage id="app.job.modal.mismatch.editProfile" />{" "}
        <i className={cn(icons.edit, styles.icon)} />
      </Button>
    </div>
  )
}

ProfileBasicDetails.propTypes = {
  candidate: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

export const ProfileBasicDetailsPanel = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps),
)(ProfileBasicDetails)
