import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage, defineMessages } from "react-intl"
import { compose } from "ramda"
import {
  passwordRecoveryErrSelector,
  passwordRecoveryLoadingSelector,
} from "../selectors/user-selector"
import { BaseLayout } from "../components/layouts/base-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout.js"
import { noAuthRequired } from "../decorators/no-auth-required"
import { emailValidator } from "../validators/email-validator"
import { Container } from "reactstrap"
import { SmallInnerFormLayout } from "../components/layouts/small-inner-form-layout"
import { EmailForm } from "../components/forms/email-form"
import { recoverPassword } from "../actions/user-actions"
import { usePrevious } from "react-hanger"
import { useOpenClose } from "../hooks/use-open-close"

const messages = defineMessages({
  header: { id: "app.common.password.forgot" },
  subHeader: { id: "app.password.recovery.subtitle" },
  placeholder: { id: "app.common.email.placeholder" },
  send: { id: "app.common.send" },
  successTitle: { id: "app.email.sent.title" },
  successSubtitle: { id: "app.email.sent.subtitle" },
})

export const PasswordRecovery = ({
  location,
  recoverPassword,
  isLoading,
  err,
}) => {
  const { value: isOpen, open } = useOpenClose({ initialValue: false })
  const prevIsLoading = usePrevious(isLoading)
  useEffect(() => {
    if (prevIsLoading && !isLoading && !err) {
      open()
    }
  }, [prevIsLoading, isLoading, err, open])

  const handleSubmit = ({ email }) => {
    recoverPassword({ email })
  }

  const header = (
    <Container>
      <h1 className="text-center mb-4">
        <FormattedMessage {...messages.header} />
      </h1>
      <p className="text-center text-muted">
        <FormattedMessage {...messages.subHeader} />
      </p>
    </Container>
  )

  if (isOpen) {
    return (
      <BaseLayout className="py-5">
        {header}
        <SmallSingleColumnLayout>
          <div className="bg-white px-3 py-5">
            <SmallInnerFormLayout>
              <h3 className="text-center mb-4">
                <FormattedMessage {...messages.successTitle} />
              </h3>
              <h6 className="text-center">
                <FormattedMessage {...messages.successSubtitle} />
              </h6>
              <img
                className="img-fluid"
                src="//assets.moberries.com/images/mail.gif"
                alt="Mail"
              />
            </SmallInnerFormLayout>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout className="py-5">
      {header}
      <SmallSingleColumnLayout>
        <div className="bg-white px-3 py-5">
          <SmallInnerFormLayout>
            <div className="mb-4 text-center">
              <img
                className="img-fluid"
                alt="Omg"
                src="//assets.moberries.com/images/omg.gif"
              />
            </div>
            <EmailForm
              form="RecoveryPasswordForm"
              onSubmit={handleSubmit}
              initialValues={{
                email: (location.state && location.state.email) || "",
              }}
              validate={emailValidator}
              buttonLabel={<FormattedMessage {...messages.send} />}
            />
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

PasswordRecovery.propTypes = {
  location: PropTypes.object.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  err: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
}

const mapDispatchToProps = { recoverPassword }

const mapStateToProps = function (state) {
  return {
    isLoading: passwordRecoveryLoadingSelector(state),
    err: passwordRecoveryErrSelector(state),
  }
}

export const PasswordRecoveryPage = compose(
  noAuthRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(PasswordRecovery)
