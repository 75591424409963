import React, { Fragment } from "react"
import { BaseLayout } from "../layouts/base-layout"
import { DoubleColumnLayout } from "../layouts/double-column-layout"
import { FormattedMessage, defineMessages } from "react-intl"
import { Container, Row, Col } from "reactstrap"
import { CardLoader } from "../common/card-loader"
import { List } from "react-content-loader"
import { times } from "ramda"

const messages = defineMessages({
  subtitle: { id: "app.dashboard.suggestions.subtitle" },
  title: { id: "app.common.matches.loading" },
})

export function DashboardPageSkeleton(props) {
  return (
    <BaseLayout className="py-5">
      <Container>
        <Row>
          <Col>
            <h1>
              <FormattedMessage {...messages.title} />
            </h1>
            <p className="text-muted">
              <FormattedMessage {...messages.subtitle} />
            </p>
          </Col>
        </Row>
      </Container>
      <DoubleColumnLayout
        content={
          <Fragment>
            {times(
              i => (
                <div key={i} className="bg-white rounded p-3 mb-2">
                  <CardLoader />
                </div>
              ),
              4,
            )}
          </Fragment>
        }
        sidebar={
          <div className="bg-white rounded p-3">
            {times(
              i => (
                <List key={i} className="py-2" />
              ),
              5,
            )}
          </div>
        }
      />
    </BaseLayout>
  )
}
