import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Img } from "../common/img-component"
import { Container } from "reactstrap"
import { getLocationName, createWebsiteString } from "../../lib/helpers"
import { SmallSingleColumnLayout } from "./small-single-column-layout"

export function CompanyViewPageLayout({
  name,
  location,
  logo,
  about,
  website,
  content,
}) {
  return (
    <Fragment>
      <section className="present-company-hero present-hero bg-dark text-center text-white py-9 ">
        <Container>
          <h1 className="display-4 font-weight-light mb-2 mt-5">{name}</h1>
          <div className="mb-2">{getLocationName(location)}</div>
          <div className="mb-5">
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              {createWebsiteString(website)}
            </a>
          </div>
        </Container>
      </section>
      <div className="present-company-logo-wrapper text-center mb-5">
        <Img
          className="thumbnail-container d-inline-block rounded-circle"
          src={logo}
          width="128"
          height="128"
        />
      </div>
      <SmallSingleColumnLayout>
        <div className="mb-5">{about}</div>
        <div className="mb-5 border-bottom" />
        {content}
      </SmallSingleColumnLayout>
    </Fragment>
  )
}

CompanyViewPageLayout.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  website: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
}

CompanyViewPageLayout.defaultProps = {}
