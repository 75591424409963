import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { Input } from "../common/input-component"
import { Button } from "../common/button-component"
import { userIdSelector } from "../../selectors/candidate-selector"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { generateInviteURL } from "../../lib/helpers"

import { InputGroup, InputGroupAddon } from "reactstrap"
import { useTemporaryState } from "../../hooks/use-temporary-state"

const messages = defineMessages({
  inviteTitle: { id: "app.sidebar.invite.title" },
  inviteDescStart: { id: "app.sidebar.invite.description.start" },
  inviteDescEnd: { id: "app.sidebar.invite.description.end" },
  inviteShare: { id: "app.sidebar.invite.share" },
  inviteCopy: { id: "app.sidebar.invite.copy" },
})

export function FriendsReferralComponent({ userId }) {
  const value = generateInviteURL(userId)
  const [isCopied, setIsCopied] = useTemporaryState(false, 3000)

  return (
    <div className="bg-white rounded p-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-megaphone" />
        </div>
        <FormattedMessage {...messages.inviteTitle} />
      </h5>
      <div className="border-top pt-3">
        <p>
          <FormattedMessage {...messages.inviteDescStart} />
        </p>
        <p>
          <FormattedMessage {...messages.inviteDescEnd} />
        </p>
      </div>

      <InputGroup>
        <Input
          className="m-0 flex-grow-1"
          type="text"
          readOnly
          input={{
            defaultValue: value,
          }}
          meta={{}}
        />
        <InputGroupAddon addonType="append">
          <CopyToClipboard text={value}>
            <Button
              color="secondary"
              disabled={isCopied}
              onClick={() => {
                setIsCopied(true)
              }}
            >
              {isCopied ? (
                <i className="far fa-check-circle" />
              ) : (
                <i className="far fa-copy" />
              )}
            </Button>
          </CopyToClipboard>
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}

FriendsReferralComponent.propTypes = {
  userId: PropTypes.number,
}

const mapStateToProps = function (state) {
  return {
    userId: userIdSelector(state),
  }
}

export const FriendsReferral = connect(mapStateToProps)(
  FriendsReferralComponent,
)
