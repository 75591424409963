import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { authRequired } from "../decorators/auth-required"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { Button } from "../components/common/button-component"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { FormGroup, Label, TabPane } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import {
  cvIsUploadedSelector,
  facebookConnectedSelector,
  linkedinConnectedSelector,
} from "../selectors/candidate-selector"

import {
  connectingLinkedinSelector,
  disconnectingFacebookSelector,
  disconnectingLinkedinSelector,
} from "../selectors/user-selector"
import {
  disconnectLinkedin,
  disconnectFacebook,
  connectLinkedin,
} from "../actions/user-actions"

const messages = defineMessages({
  title: { id: "app.account.social.title" },
  description: { id: "app.account.social.description" },
  linkedinConnect: { id: "app.account.social.linkedin.connect" },
  linkedinDisconnect: { id: "app.account.social.linkedin.disconnect" },
  facebookDisconnect: { id: "app.account.social.facebook.disconnect" },
})

class AccountSocial extends Component {
  static propTypes = {
    connectLinkedin: PropTypes.func.isRequired,
    disconnectLinkedin: PropTypes.func.isRequired,
    disconnectFacebook: PropTypes.func.isRequired,
    connectingLinkedin: PropTypes.bool.isRequired,
    disconnectingLinkedin: PropTypes.bool.isRequired,
    disconnectingFacebook: PropTypes.bool.isRequired,
    facebookConnected: PropTypes.bool.isRequired,
    linkedinConnected: PropTypes.bool.isRequired,
    cvIsUploaded: PropTypes.bool.isRequired,
  }

  render() {
    const {
      connectingLinkedin,
      disconnectingLinkedin,
      disconnectingFacebook,
      linkedinConnected,
      cvIsUploaded,
      disconnectLinkedin,
      connectLinkedin,
      disconnectFacebook,
      facebookConnected,
    } = this.props

    const buttonsDisabled =
      connectingLinkedin || disconnectingFacebook || disconnectingLinkedin

    return (
      <AccountPageLayout>
        <TabPane>
          <legend>
            <FormattedMessage {...messages.title} />
          </legend>
          <p className="text-muted">
            <FormattedMessage {...messages.description} />
          </p>
          <FormGroup>
            <Label>Linkedin</Label>
            <Button
              disabled={buttonsDisabled || (linkedinConnected && !cvIsUploaded)}
              loading={connectingLinkedin || disconnectingLinkedin}
              onClick={() => {
                if (linkedinConnected) {
                  disconnectLinkedin()
                } else {
                  connectLinkedin({
                    state: {
                      from: "/account/social",
                    },
                  })
                }
              }}
              color="linkedin"
              size="lg"
              block
            >
              {this.getLinkedinIcon()}
              {linkedinConnected ? (
                <FormattedMessage {...messages.linkedinDisconnect} />
              ) : (
                <FormattedMessage {...messages.linkedinConnect} />
              )}
            </Button>
          </FormGroup>
          {facebookConnected && (
            <FormGroup>
              <Label>Facebook</Label>
              <Button
                disabled={buttonsDisabled}
                loading={disconnectingFacebook}
                onClick={disconnectFacebook}
                color="facebook"
                size="lg"
                block
              >
                {!disconnectingFacebook && <i className="fas fa-times pr-3" />}
                <FormattedMessage {...messages.facebookDisconnect} />
              </Button>
            </FormGroup>
          )}
        </TabPane>
      </AccountPageLayout>
    )
  }

  getLinkedinIcon = () => {
    const {
      connectingLinkedin,
      disconnectingLinkedin,
      linkedinConnected,
    } = this.props

    if (connectingLinkedin || disconnectingLinkedin) return null

    return linkedinConnected ? (
      <i className="fas fa-times pr-3" />
    ) : (
      <i className="fab fa-linkedin pr-3" />
    )
  }
}

const mapDispatchToProps = {
  connectLinkedin,
  disconnectLinkedin,
  disconnectFacebook,
}

const mapStateToProps = function (state) {
  return {
    cvIsUploaded: cvIsUploadedSelector(state),
    connectingLinkedin: connectingLinkedinSelector(state),
    disconnectingLinkedin: disconnectingLinkedinSelector(state),
    disconnectingFacebook: disconnectingFacebookSelector(state),
    facebookConnected: facebookConnectedSelector(state),
    linkedinConnected: linkedinConnectedSelector(state),
  }
}

export const AccountSocialPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSocial)
