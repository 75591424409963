import React, { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import Cookies from "universal-cookie"
import { FormattedMessage } from "react-intl"

import { Button } from "../button-component"

import wave from "../../../assets/img/wave-hand.png"
import styles from "./welcome-modal.module.scss"

export const WelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const cookies = new Cookies("welcome")

  useEffect(() => {
    if (cookies.get("welcome")) {
      setIsOpen(false)
    } else if (!cookies.get("welcome")) {
      cookies.set("welcome", "true", {
        path: "/",
      })
      setIsOpen(true)
    }
    // eslint-disable-next-line
  }, [])

  const close = () => setIsOpen(false)

  return (
    <Modal isOpen={isOpen} toggle={close} centered size="lg">
      <ModalBody className={styles.modalContainer}>
        <h1 className={styles.title}>
          <FormattedMessage id="app.registration.welcome.title" />
          <span>
            <img src={wave} alt="wave-hand" />
          </span>
        </h1>
        <p>
          <FormattedMessage id="app.registration.welcome.subtitle" />
        </p>
        <Button
          className={styles.button}
          color="primary"
          size="lg"
          onClick={close}
        >
          <FormattedMessage id="app.registration.welcome.button.start" />
        </Button>
      </ModalBody>
    </Modal>
  )
}
