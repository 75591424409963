import React from "react"
import { TabPane } from "reactstrap"
import { compose } from "ramda"

import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { ProfilePageLayout } from "../../components/layouts/profile-page/profile-page-layout"
import { ProfileMyPreferencePanel } from "./profile-my-preference-panel"
import { DownloadCVButton } from "./download-cv"

export const ProfileMyPreference = () => (
  <ProfilePageLayout downloadCvComponent={DownloadCVButton}>
    <TabPane>
      <ProfileMyPreferencePanel />
    </TabPane>
  </ProfilePageLayout>
)

export const ProfileMyPreferencePage = compose(
  authRequired,
  completedProfileRequired,
)(ProfileMyPreference)
