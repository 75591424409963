import { all, call, put, takeLatest } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType } from "../lib/helpers"
import {
  MATCH,
  LIST,
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  MARK,
  HIRED,
  NOT,
  FEEDBACK,
  SUMMARY,
  MATCHES,
  STATISTICS,
  VALIDATION,
} from "../constants"
import { alertTypes, showAlert } from "../lib/alerts"

export function* getMatchListSaga(action) {
  const { candidateId, params } = action.payload

  yield put({ type: getActionType(GET, MATCH, LIST, START) })

  try {
    const {
      data: { results: matches, count },
    } = yield call(moberriesApi.getMatchList, { candidateId, params })

    yield put({
      type: getActionType(GET, MATCH, LIST, SUCCESS),
      payload: { matches, count },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, MATCH, LIST, FAIL), payload: { err } })
  }
}

export function* getMatchSaga(action) {
  const { matchId } = action.payload

  yield put({ type: getActionType(GET, MATCH, START) })

  try {
    const { data: match } = yield call(moberriesApi.getMatch, { matchId })

    yield put({
      type: getActionType(GET, MATCH, SUCCESS),
      payload: { match },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, MATCH, FAIL), payload: { err } })
  }
}

export function* getMatchValidationSaga(action) {
  const { jobId } = action.payload

  yield put({ type: getActionType(GET, MATCH, VALIDATION, START) })

  try {
    const { data: validationData } = yield call(moberriesApi.validateMatch, {
      jobId,
    })

    yield put({
      type: getActionType(GET, MATCH, VALIDATION, SUCCESS),
      payload: { validationData, jobId },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, MATCH, VALIDATION, FAIL),
      payload: { err },
    })
  }
}

export function* markMatchHiredSaga(action) {
  const { matchId } = action.payload

  yield put({ type: getActionType(MARK, MATCH, HIRED, START) })

  try {
    const { data: match } = yield call(moberriesApi.markMatchHired, { matchId })

    showAlert({ code: alertTypes.markHired })

    yield put({
      type: getActionType(MARK, MATCH, HIRED, SUCCESS),
      payload: { match },
    })
  } catch (err) {
    yield put({
      type: getActionType(MARK, MATCH, HIRED, FAIL),
      payload: { err },
    })
  }
}

export function* markMatchNotHiredSaga(action) {
  const { matchId, feedbackReasons, comment } = action.payload

  yield put({ type: getActionType(MARK, MATCH, NOT, HIRED, START) })

  try {
    const { data: match } = yield call(moberriesApi.markMatchNotHired, {
      matchId,
    })

    const { data: feedback } = yield call(moberriesApi.createFeedback, {
      answers: feedbackReasons,
      comment,
    })

    yield call(moberriesApi.linkFeedbackWithMatch, {
      candidateId: "me",
      matchId,
      feedback: feedback.id,
    })

    showAlert({ code: alertTypes.markNotHired })

    yield put({
      type: getActionType(MARK, MATCH, NOT, HIRED, SUCCESS),
      payload: { match },
    })
  } catch (err) {
    yield put({
      type: getActionType(MARK, MATCH, NOT, HIRED, FAIL),
      payload: { err },
    })
  }
}

export function* getFeedbackSummarySaga() {
  yield put({
    type: getActionType(GET, FEEDBACK, SUMMARY, START),
  })

  try {
    const { data: feedbackSummary } = yield call(
      moberriesApi.getFeedbackSummary,
    )

    yield put({
      type: getActionType(GET, FEEDBACK, SUMMARY, SUCCESS),
      payload: { feedbackSummary },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, FEEDBACK, SUMMARY, FAIL),
      payload: { err },
    })
  }
}

export function* getMatchesStatisticsSaga() {
  yield put({
    type: getActionType(GET, MATCHES, STATISTICS, START),
  })

  try {
    const { data: matchesStatistics } = yield call(
      moberriesApi.getMatchesStatistics,
    )

    yield put({
      type: getActionType(GET, MATCHES, STATISTICS, SUCCESS),
      payload: { matchesStatistics },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, MATCHES, STATISTICS, FAIL),
      payload: { err },
    })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(getActionType(GET, MATCH, REQUEST), getMatchSaga),
    takeLatest(
      getActionType(GET, MATCH, VALIDATION, REQUEST),
      getMatchValidationSaga,
    ),
    takeLatest(getActionType(MARK, MATCH, HIRED, REQUEST), markMatchHiredSaga),
    takeLatest(
      getActionType(MARK, MATCH, NOT, HIRED, REQUEST),
      markMatchNotHiredSaga,
    ),
    takeLatest(getActionType(GET, MATCH, LIST, REQUEST), getMatchListSaga),
    takeLatest(
      getActionType(GET, FEEDBACK, SUMMARY, REQUEST),
      getFeedbackSummarySaga,
    ),
    takeLatest(
      getActionType(GET, MATCHES, STATISTICS, REQUEST),
      getMatchesStatisticsSaga,
    ),
  ])
}
