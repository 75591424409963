import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { compose } from "ramda"
import { connect } from "react-redux"

import { confirmMobotEmailWithCode } from "../actions/user-actions"
import { BaseLayout } from "../components/layouts/base-layout"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"

import check from "../assets/img/check.svg"

const messages = defineMessages({
  header: { id: "app.mobotEmail.header" },
  text: { id: "app.mobotEmail.text" },
  subtext: { id: "app.mobotEmail.subtext" },
})

export const ConfirmMobotEmail = ({ confirmMobotEmailWithCode, match }) => {
  const code = match.params.code
  useEffect(() => {
    confirmMobotEmailWithCode({ code })
  }, [confirmMobotEmailWithCode, code])
  return (
    <Fragment>
      <BaseLayout className="py-5">
        <Container>
          <h1 className="text-center mb-4">
            <FormattedMessage {...messages.header} />
          </h1>
        </Container>
        <SmallSingleColumnLayout>
          <div className="bg-white rounded px-3 py-5">
            <img
              className="mb-4 mx-auto d-block"
              src={check}
              width={100}
              height={100}
              alt="Check mark icon"
            />
            <p className="h2 text-center m-2">
              <FormattedMessage {...messages.text} />
            </p>
            <p className="h4 text-center text-muted">
              <FormattedMessage {...messages.subtext} />
            </p>
          </div>
        </SmallSingleColumnLayout>
      </BaseLayout>
    </Fragment>
  )
}

ConfirmMobotEmail.propTypes = {
  confirmMobotEmailWithCode: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

const mapDispatchToProps = { confirmMobotEmailWithCode }

export const ConfirmMobotEmailPage = compose(connect(null, mapDispatchToProps))(
  ConfirmMobotEmail,
)
