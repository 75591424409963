import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FormGroup } from "reactstrap"

import { useGoogleLocationsLoader } from "../../../../hooks/use-google-maps-places-loader"
import { getLocationName } from "../../../../lib/helpers"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { MultiSelect } from "../../../common/multi-select-component"
import { ToggleComponent } from "../../../common/toggle-component/toggle-component"

import styles from "./relocate-section.module.scss"

export const RelocateSection = ({
  relocate,
  locations,
  relocationCountries,
}) => {
  const { value: isRelocate, onChange: onChangeRelocate } = relocate.input
  const {
    value: locationsValues,
    onChange: onLocationsChange,
  } = locations.input
  const {
    value: relocationCountriesValues,
    onChange: onRelocationsLocationsChange,
  } = relocationCountries.input

  const { loadLocations } = useGoogleLocationsLoader({
    types: ["countries", "cities"],
  })

  const relocateChangeHandler = () => {
    onChangeRelocate(!isRelocate)
  }

  const onPlaceChange = value => {
    onRelocationsLocationsChange(value.filter(v => v.isCountry))
    onLocationsChange(value.filter(v => !v.isCountry))
  }

  const onPlaceBlur = value => {
    onRelocationsLocationsChange(value.filter(v => v.isCountry))
    onLocationsChange(value.filter(v => !v.isCountry))
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.relocation.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.relocation.text" />
          </span>
        }
      />

      <div className={styles.inputsWrapper}>
        <ToggleComponent
          className={styles.toggle}
          checked={isRelocate}
          onChange={relocateChangeHandler}
        />

        {isRelocate && (
          <div className={styles.place}>
            <MultiSelect
              input={{
                ...locations.input,
                onChange: onPlaceChange,
                onBlur: onPlaceBlur,
                value: [
                  ...locationsValues,
                  ...relocationCountriesValues.map(o => ({
                    ...o,
                    isCountry: true,
                  })),
                ],
              }}
              meta={locations.meta}
              name="locations"
              id="locations"
              placeholder={<FormattedMessage id="app.common.select" />}
              noOptionsMessage={() => (
                <FormattedMessage id="app.common.type.to.search" />
              )}
              controlShouldRenderValue={false}
              getOptionValue={option => option.placeId}
              getOptionLabel={option => getLocationName(option) || option.name}
              loadOptions={loadLocations}
              async
              withGoogleLogo
              backspaceRemovesValue={false}
              tagRenderer={val => getLocationName(val)}
            />
          </div>
        )}
      </div>
    </FormGroup>
  )
}

RelocateSection.propTypes = {
  relocate: PropTypes.object,
  locations: PropTypes.object,
  relocationCountries: PropTypes.object,
}
