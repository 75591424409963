import React from "react"
import PropTypes from "prop-types"
import styles from "./warning-message.module.scss"
import { FormattedMessage } from "react-intl"

export const WarningMessage = ({ id }) => (
  <div className={styles.card}>
    <i className="fas fa-exclamation-triangle text-warning" />
    <p className={styles.message}>
      <FormattedMessage
        id={id}
        values={{
          b: (...children) => <b>{children}</b>,
        }}
      />
    </p>
  </div>
)

WarningMessage.propTypes = {
  id: PropTypes.string,
}
