import React from "react"
import { formatUnreadCount, isMessageFromManager } from "../../lib/helpers"
import PropTypes from "prop-types"
import { MessageReadStatus } from "./message-read-status-component"

export const LastMessageStatus = ({ thread }) => {
  if (thread.unreadCount > 0) {
    return (
      <span className="thread-unread-count">
        {formatUnreadCount(thread.unreadCount)}
      </span>
    )
  } else if (!isMessageFromManager(thread.lastMessage)) {
    return <MessageReadStatus isRead={thread.lastMessage.isRead} />
  } else {
    return null
  }
}

LastMessageStatus.propTypes = {
  thread: PropTypes.object.isRequired,
}
