import React, { Component } from "react"
// import PropTypes from "prop-types"
import { connect } from "react-redux"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import baloonPic from "../assets/img/bear.png"
import { Img } from "../components/common/img-component"

export class Release extends Component {
  static propTypes = {}
  static defaultProps = {}

  render() {
    return (
      <SingleColumnLayout>
        <div className="mt-6 text-center">
          <div>
            <Img alt="User" width="400" src={baloonPic} />
          </div>
          <h1 className="text-uppercase font-weight-light my-5">
            We will be back soon!
          </h1>
          <p className="mb-5">
            We are busy upgrading your experience and will be back soon with
            amazing new features. Stay tuned!
          </p>
          {/* <div className="countdown d-flex justify-content-between">
            <div className="bloc-time hours" data-init-value="1">
              <span className="text-uppercase d-block mb-3">Hours</span>
              <div className="figure hours hours-1">
                <span className="top">2</span>
                <span className="top-back">
                  <span>2</span>
                </span>
                <span className="bottom">2</span>
                <span className="bottom-back">
                  <span>2</span>
                </span>
              </div>
              <div className="figure hours hours-2">
                <span className="top">4</span>
                <span className="top-back">
                  <span>4</span>
                </span>
                <span className="bottom">4</span>
                <span className="bottom-back">
                  <span>4</span>
                </span>
              </div>
            </div>
            <div className="bloc-time min" data-init-value="0">
              <span className="text-uppercase d-block mb-3">Minutes</span>
              <div className="figure min min-1">
                <span className="top">0</span>
                <span className="top-back">
                  <span>0</span>
                </span>
                <span className="bottom">0</span>
                <span className="bottom-back">
                  <span>0</span>
                </span>
              </div>
              <div className="figure min min-2">
                <span className="top">0</span>
                <span className="top-back">
                  <span>0</span>
                </span>
                <span className="bottom">0</span>
                <span className="bottom-back">
                  <span>0</span>
                </span>
              </div>
            </div>
            <div className="bloc-time sec" data-init-value="0">
              <span className="text-uppercase d-block mb-3">Seconds</span>
              <div className="figure sec sec-1">
                <span className="top">0</span>
                <span className="top-back">
                  <span>0</span>
                </span>
                <span className="bottom">0</span>
                <span className="bottom-back">
                  <span>0</span>
                </span>
              </div>
              <div className="figure sec sec-2">
                <span className="top">0</span>
                <span className="top-back">
                  <span>0</span>
                </span>
                <span className="bottom">0</span>
                <span className="bottom-back">
                  <span>0</span>
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </SingleColumnLayout>
    )
  }
}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {}
}

export const ReleasePage = connect(mapStateToProps, mapDispatchToProps)(Release)
