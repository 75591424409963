import { connectedReduxRedirect } from "redux-auth-wrapper/history4/redirect"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper"
import { push } from "connected-react-router"

const locationHelper = locationHelperBuilder({
  redirectQueryParamName: "redirectTo",
})

export const noAuthRequired = connectedReduxRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/",
  allowRedirectBack: false,
  authenticatedSelector: state => !state.candidate.entity,
  wrapperDisplayName: "noAuthRequired",
  redirectQueryParamName: "redirectTo",
  redirectAction: push,
})
