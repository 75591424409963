import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import { push } from "connected-react-router"
import { DesktopScreen, DesktopScreenHidden } from "react-responsive-redux"
import { connect } from "react-redux"
import { compose } from "ramda"
import { getThreadList } from "../actions/thread-actions"
import {
  threadsSelector,
  countSelector as threadCountSelector,
  lastFetchedPageSelector as lastFetchedThreadPageSelector,
  errorSelector,
} from "../selectors/thread-selector"
import { authRequired } from "../decorators/auth-required"
import { BaseLayout } from "../components/layouts/base-layout"
import { ThreadList } from "../components/messenger/thread-list-component"
import { SmallSingleColumnLayout } from "../components/layouts/small-single-column-layout"
import { MessengerPageSkeleton } from "../components/skeletons/messenger-page-skeleton"
import { hasMore } from "../lib/helpers"
import { MessengerPageEmpty } from "../components/empty-states/messenger-page-empty"
import { ErrorPage } from "./error-page"

export const MessengerPageComponent = ({
  getThreadList,
  threads,
  lastFetchedThreadPage,
  threadCount,
  err,
  push,
}) => {
  const loadMoreThreads = () => {
    getThreadList({ page: lastFetchedThreadPage + 1 })
  }

  useEffect(() => {
    getThreadList({})
  }, [getThreadList])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (threadCount === null) {
    return <MessengerPageSkeleton />
  }

  if (threadCount === 0) {
    return <MessengerPageEmpty />
  }

  return (
    <Fragment>
      <DesktopScreen>
        {threads.length ? (
          <Redirect to={`/messages/${threads[0].id}`} />
        ) : (
          <MessengerPageSkeleton />
        )}
      </DesktopScreen>
      <DesktopScreenHidden>
        <BaseLayout className="py-5">
          <SmallSingleColumnLayout>
            <ThreadList
              loadMore={loadMoreThreads}
              hasMore={hasMore(threadCount, lastFetchedThreadPage, 10)}
              threads={threads}
              useWindowScroll
              onClickThread={thread => {
                push(`/messages/${thread.id}`)
              }}
            />
          </SmallSingleColumnLayout>
        </BaseLayout>
      </DesktopScreenHidden>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  threads: threadsSelector(state),
  lastFetchedThreadPage: lastFetchedThreadPageSelector(state),
  threadCount: threadCountSelector(state),
  err: errorSelector(state),
})

const mapDispatchToProps = {
  getThreadList,
  push,
}

MessengerPageComponent.propTypes = {
  getThreadList: PropTypes.func.isRequired,
  threads: PropTypes.arrayOf(PropTypes.object).isRequired,
  lastFetchedThreadPage: PropTypes.number,
  threadCount: PropTypes.number,
  err: PropTypes.object,
  push: PropTypes.func.isRequired,
}

MessengerPageComponent.defaultProps = {
  threads: [],
}

export const MessengerPage = compose(
  authRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(MessengerPageComponent)
