import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"
import { Form } from "reactstrap"

const messages = defineMessages({
  email: { id: "app.common.email" },
  password: { id: "app.common.password" },
})

export function EmailPassword(props) {
  const { invalid, error, pristine, submitting, handleSubmit } = props
  const { buttonLabel, underPassword, disabled } = props

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={Input}
        label={
          <span>
            <FormattedMessage {...messages.email} />
          </span>
        }
        className="_lr-hide"
        name="email"
        type="email"
        id="email"
        placeholder="email@example.com"
      />
      <Field
        component={Input}
        label={
          <span>
            <FormattedMessage {...messages.password} />
          </span>
        }
        className="_lr-hide"
        name="password"
        type="password"
        id="password"
        placeholder="********"
        underFeedback={underPassword}
      />
      <Button
        disabled={disabled || submitting || pristine || (invalid && !error)}
        loading={submitting}
        block
        color="secondary"
        type="submit"
        size="lg"
      >
        {buttonLabel}
      </Button>
    </Form>
  )
}

EmailPassword.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.node.isRequired,
  underPassword: PropTypes.node,
}

EmailPassword.defaultProps = {
  buttonLabel: "Login",
  disabled: false,
  underPassword: null,
}

export const EmailPasswordForm = reduxForm({
  initialValues: {
    email: "",
    password: "",
  },
})(EmailPassword)
