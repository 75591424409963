import React, { Fragment, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import qs from "qs"
import { connect } from "react-redux"
import { prop } from "ramda"
import { ErrorPage } from "../pages/error-page"
import { BaseLayout } from "../components/layouts/base-layout"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import {
  getJobWithSimilarJobList,
  createJobFeedback,
  closeJobFeedback,
  discardJob,
  applyForJob,
} from "../actions/job-actions"
import { JobShare } from "../components/sidebar/job-share-component"
import { JobInfo } from "../components/job/job-info-component"
import { JobDescription } from "../components/job/job-description-component"
import { JobCard } from "../components/job/job-card-component"
import { JobCardStatus } from "../components/job/job-card-status-label-component"
import { List } from "react-content-loader"
import { CardLoader } from "../components/common/card-loader"
import { matchSelector } from "../selectors/match-selector"
import {
  errorSelector,
  jobSelector,
  jobSimilarSelector,
  loadingSelector,
  similarCountSelector,
  isCreatingJobFeedbackSelector,
  feedbackJobOpenIdSelector,
} from "../selectors/job-selector"
import { getHiringReward } from "../lib/helpers"
import {
  isCompleteSelector,
  isLoggedSelector,
  candidateSelector,
  userIdSelector,
} from "../selectors/candidate-selector"
import { ACT, MATCH_STATUSES } from "../constants"
import { FormattedMessage, defineMessages } from "react-intl"
import { Link } from "react-router-dom"
import { JobDeclineFeedback } from "../components/job/job-decline-feedback-component"
import { usePrevious } from "react-hanger"
import { JobPageControls } from "../components/job/job-page-controls"

const messages = defineMessages({
  inactiveJob: { id: "app.job.inactive.hint" },
  signup: { id: "app.job.signupLink" },
})

const appliedOrDiscardedStatuses = [
  MATCH_STATUSES.CANDIDATE_ACCEPTED,
  MATCH_STATUSES.CANDIDATE_DECLINED,
]

export const Job = ({
  getJobWithSimilarJobList,
  match,
  job,
  isLoading,
  jobMatch,
  isLogged,
  isComplete,
  location,
  similarJobs,
  similarJobsCount,
  err,
  createJobFeedback,
  isCreatingJobFeedback,
  feedbackJobOpenId,
  closeJobFeedback,
  candidate,
  discardJob,
  applyForJob,
}) => {
  const jobId = job?.id
  const jobMatchStatus = job?.match?.status
  const prevJob = usePrevious(job)
  const prevIsLoading = usePrevious(isLoading)

  const didJobGetAppliedOrDiscarded =
    appliedOrDiscardedStatuses.includes(job?.match?.status) &&
    !appliedOrDiscardedStatuses.includes(prevJob?.match?.status)

  const isJobFeedbackOpen = feedbackJobOpenId === jobId

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { apply, reject } = query

  useEffect(() => {
    getJobWithSimilarJobList({ jobId: match.params.id })
  }, [getJobWithSimilarJobList, match.params.id])

  const didFinishLoading = !isLoading && prevIsLoading
  const discardJobFromQuery = useCallback(() => {
    if (reject === "" && jobMatchStatus !== MATCH_STATUSES.CANDIDATE_DECLINED) {
      discardJob({ jobId })
    }
  }, [reject, jobId, discardJob, jobMatchStatus])

  const applyJobFromQuery = useCallback(() => {
    if (apply === "" && jobMatchStatus !== MATCH_STATUSES.CANDIDATE_ACCEPTED) {
      applyForJob({ jobId })
    }
  }, [apply, jobId, applyForJob, jobMatchStatus])

  useEffect(() => {
    if (didFinishLoading) {
      discardJobFromQuery()
      applyJobFromQuery()
    }

    if (didJobGetAppliedOrDiscarded) {
      getJobWithSimilarJobList({ jobId: match.params.id })
    }
  }, [
    didFinishLoading,
    discardJobFromQuery,
    applyJobFromQuery,
    didJobGetAppliedOrDiscarded,
    getJobWithSimilarJobList,
    match.params.id,
  ])

  if (err) {
    return <ErrorPage err={err} />
  }

  return (
    <BaseLayout className="py-5">
      <DoubleColumnLayout
        content={
          job ? (
            <Fragment>
              <JobCard
                id={job.id}
                companyLogo={job.company.logo}
                companyName={job.company.name}
                companyId={job.company.id}
                jobTitle={job.title}
                salaryMin={job.salaryMin}
                salaryMax={job.salaryMax}
                locations={job.locations}
                hiringRewardEuros={
                  job.match && jobMatch
                    ? getHiringReward(jobMatch)
                    : job.hiringRewardEuros
                }
                isRemote={job.remote}
                hasQuestions={job.hasQuestions}
                controls={
                  <JobCardStatus
                    matchId={prop("id", job.match)}
                    jobId={job.id}
                    matchStatus={prop("status", jobMatch)}
                    jobStatus={job.status}
                  />
                }
              />
              {shouldRenderInactiveBanner(job, isLogged) && (
                <div className="p-3 text-center bg-warning-light border-bottom">
                  <FormattedMessage
                    {...messages.inactiveJob}
                    values={{
                      link: (
                        <Link to="/signup">
                          <FormattedMessage {...messages.signup} />
                        </Link>
                      ),
                    }}
                  />
                </div>
              )}
              <JobPageControls
                job={job}
                isLoading={isLoading}
                isLogged={isLogged}
                isComplete={isComplete}
                location={location}
                jobMatch={jobMatch}
              />
              {isJobFeedbackOpen ? (
                <JobDeclineFeedback
                  jobId={job.id}
                  jobSkills={job.skills}
                  createFeedback={createJobFeedback}
                  closeFeedback={() => closeJobFeedback({ jobId: job.id })}
                  isCreatingFeedback={isCreatingJobFeedback}
                  matchId={jobMatch?.id}
                />
              ) : (
                <Fragment>
                  <JobInfo job={job} candidateSkills={candidate?.skills} />
                  <JobDescription description={job.description} initialIsOpen />
                </Fragment>
              )}
            </Fragment>
          ) : (
            <div className="bg-white p-3">
              <CardLoader />
              <div className="w-75 mx-auto mt-5">
                <List className="py-2" />
                <List className="py-2" />
              </div>
            </div>
          )
        }
        sidebar={
          <Fragment>
            <div className="mb-4">
              {job ? (
                <JobShare job={job} isLogged={isLogged} />
              ) : (
                <div className="bg-white p-3">
                  <List className="py-2" />
                </div>
              )}
            </div>
          </Fragment>
        }
      />
    </BaseLayout>
  )
}
Job.propTypes = {
  isComplete: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getJobWithSimilarJobList: PropTypes.func.isRequired,
  err: PropTypes.object,
  job: PropTypes.object,
  jobMatch: PropTypes.object,
  similarJobs: PropTypes.array.isRequired,
  similarJobsCount: PropTypes.number,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLogged: PropTypes.bool.isRequired,
  createJobFeedback: PropTypes.func.isRequired,
  isCreatingJobFeedback: PropTypes.bool.isRequired,
  feedbackJobOpenId: PropTypes.number,
  closeJobFeedback: PropTypes.func.isRequired,
  candidate: PropTypes.object,
  userId: PropTypes.number,
  discardJob: PropTypes.func,
  applyForJob: PropTypes.func,
}

Job.defaultProps = {
  similarJobs: [],
}
const mapDispatchToProps = {
  getJobWithSimilarJobList,
  createJobFeedback,
  closeJobFeedback,
  discardJob,
  applyForJob,
}

const mapStateToProps = function (state, props) {
  const job = jobSelector(state, { id: props.match.params.id })
  const matchId = job?.match?.id

  return {
    job,
    jobMatch: matchId && matchSelector(state, { id: matchId }),
    isComplete: isCompleteSelector(state),
    isLogged: isLoggedSelector(state),
    similarJobsCount: similarCountSelector(state),
    similarJobs: jobSimilarSelector(state, { jobId: props.match.params.id }),
    isLoading: loadingSelector(state),
    err: errorSelector(state),
    isCreatingJobFeedback: isCreatingJobFeedbackSelector(state),
    feedbackJobOpenId: feedbackJobOpenIdSelector(state),
    candidate: candidateSelector(state),
    userId: userIdSelector(state),
  }
}

function shouldRenderInactiveBanner(job, isLogged) {
  return job.status !== ACT && !isLogged
}

export const JobPage = connect(mapStateToProps, mapDispatchToProps)(Job)
