import { createSelector } from "reselect"

export const stateSelector = state => state.bot

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const errSelector = cs(state => state.err)
export const countSelector = cs(state => state.count)
export const connectionsSelector = cs(state => state.connections)
export const creatingSelector = cs(state => state.creating)
export const codeSelector = cs(state => state.code)
