import { arrayToObject, getActionType, N } from "../lib/helpers"
import {
  GET,
  SUCCESS,
  MATCH,
  LIST,
  FAIL,
  LOGOUT,
  START,
  HIRED,
  MARK,
  NOT,
  JOB,
  DISCARD,
  FEEDBACK,
  SUMMARY,
  APPLY,
  MATCHES,
  STATISTICS,
  VALIDATION,
  CLEAR,
} from "../constants"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  over,
  lensProp,
  prepend,
  pipe,
  uniq,
  prop,
  map,
  isEmpty,
  identity,
} from "ramda"

export const defaultState = {
  count: null,
  matches: [],
  entities: {},
  loading: false,
  markingHired: false,
  markingNotHired: false,
  err: null,
  feedbackSummary: null,
  isLoadingFeedbackSummary: false,
  matchCountByStatus: {},
  validationData: null,
  applicationJobId: null,
  validating: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MATCH, START):
    case getActionType(GET, MATCH, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(DISCARD, JOB, SUCCESS):
    case getActionType(APPLY, JOB, SUCCESS):
      return evolve(
        {
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
          matches: pipe(prepend(payload.match.id), uniq),
        },
        state,
      )

    case getActionType(GET, MATCH, SUCCESS):
      return evolve(
        {
          loading: F,
          err: N,
          entities: over(lensProp(payload.match.id), mergeLeft(payload.match)),
        },
        state,
      )

    case getActionType(GET, MATCH, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: N,
          matches: always(payload.matches.map(j => j.id)),
          count: always(payload.count),
          entities: mergeLeft(arrayToObject(payload.matches, "id")),
        },
        state,
      )

    case getActionType(GET, MATCH, VALIDATION, START):
      return evolve({ validating: T }, state)

    case getActionType(GET, MATCH, VALIDATION, SUCCESS):
      return evolve(
        {
          validating: F,
          validationData: isEmpty(payload.validationData)
            ? identity
            : always(payload.validationData),
          applicationJobId: always(payload.jobId),
        },
        state,
      )

    case getActionType(CLEAR, MATCH, VALIDATION):
      return evolve(
        {
          validationData: N,
          applicationJobId: N,
        },
        state,
      )

    case getActionType(GET, MATCH, FAIL):
    case getActionType(GET, MATCH, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(MARK, MATCH, HIRED, START):
      return evolve({ markingHired: T }, state)

    case getActionType(MARK, MATCH, HIRED, SUCCESS):
      return evolve(
        {
          markingHired: F,
          entities: {
            [payload.match.id]: always(payload.match),
          },
        },
        state,
      )

    case getActionType(MARK, MATCH, HIRED, FAIL):
      return evolve({ markingHired: F }, state)

    case getActionType(MARK, MATCH, NOT, HIRED, START):
      return evolve({ markingNotHired: T }, state)

    case getActionType(MARK, MATCH, NOT, HIRED, SUCCESS):
      return evolve(
        {
          markingNotHired: F,
          entities: {
            [payload.match.id]: always(payload.match),
          },
        },
        state,
      )

    case getActionType(MARK, MATCH, NOT, HIRED, FAIL):
      return evolve({ markingNotHired: F }, state)

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    case getActionType(GET, FEEDBACK, SUMMARY, SUCCESS):
      return evolve(
        {
          feedbackSummary: always(payload.feedbackSummary),
        },
        state,
      )
    case getActionType(GET, FEEDBACK, SUMMARY, FAIL):
      return evolve(
        {
          err: always(payload.err),
        },
        state,
      )
    case getActionType(GET, MATCHES, STATISTICS, SUCCESS):
      return evolve(
        {
          matchCountByStatus: always(
            map(
              prop("count"),
              arrayToObject(payload.matchesStatistics, "status"),
            ),
          ),
        },
        state,
      )
    default:
      return state
  }
}
