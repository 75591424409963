import axios from "axios"
import { config } from "../config"
import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  errorInterceptor,
  decamelizeInterceptor,
} from "./axios-interceptors"

export const http = axios.create({
  baseURL: config.typeform.api.url,
  timeout: 30000,
})

http.interceptors.response.use(response => response, errorInterceptor)
http.interceptors.response.use(saveOriginalInterceptor)
http.interceptors.response.use(camelizeInterceptor)

http.interceptors.request.use(decamelizeInterceptor)

export const api = {
  setDefaultHeader: ({ header, value }) => {
    http.defaults.headers[header] = value
  },

  getSurveyStatus: ({ query, code }) =>
    http(`/forms/${code}/responses?query=${query}`),
}
