import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

export const SmallInnerFormLayout = props => {
  return (
    <Row className="justify-content-center">
      <Col md="8" lg="6">
        {props.children}
      </Col>
    </Row>
  )
}

SmallInnerFormLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
