import isLength from "validator/lib/isLength"
import { defineMessages } from "react-intl"

const messages = defineMessages({
  valueRequired: { id: "app.validators.value.required" },
  passwordsEqual: { id: "app.validators.password.equal" },
  maxLen: { id: "app.validators.length.max" },
  minLen: { id: "app.validators.length.min" },
})

export const newPasswordValidator = values => {
  const errors = {}
  const minPasswordOptions = { min: 8 }
  const maxPasswordOptions = { max: 30 }

  if (!values.password) {
    errors.password = messages.valueRequired
  } else if (!isLength(values.password, minPasswordOptions)) {
    errors.password = { ...messages.minLen, values: minPasswordOptions }
  } else if (!isLength(values.password, maxPasswordOptions)) {
    errors.password = { ...messages.maxLen, values: maxPasswordOptions }
  }

  if (!values.password2) {
    errors.password2 = messages.valueRequired
  } else if (!isLength(values.password2, minPasswordOptions)) {
    errors.password2 = { ...messages.minLen, values: minPasswordOptions }
  } else if (!isLength(values.password2, maxPasswordOptions)) {
    errors.password2 = { ...messages.maxLen, values: maxPasswordOptions }
  }

  if (values.password === values.password2) {
    errors.password2 = "Passwords should not be equal"
  }

  return errors
}
