import React, { Component, Fragment } from "react"
import { push } from "connected-react-router"
import { defineMessages, FormattedMessage } from "react-intl"
import { ErrorPage } from "../pages/error-page"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { BaseLayout } from "../components/layouts/base-layout"
import { Pagination } from "../components/common/pagination-component"
import { CompanyViewPageLayout } from "../components/layouts/company-view-layout"
import { JobCard } from "../components/job/job-card-component"
import {
  companySelector,
  errorSelector,
  loadingSelector,
} from "../selectors/company-selector"
import {
  jobByCompanySelector,
  countSelector,
  errorSelector as jobErrorSelector,
} from "../selectors/job-selector"
import { getCompanyRequest } from "../actions/company-actions"
import { getCompanyJobListRequest } from "../actions/job-actions"
import { CompanyPageEmpty } from "../components/empty-states/company-page-empty"
import { CompanyPageSkeleton } from "../components/skeletons/company-page-skeleton"
import { JobsSkeleton } from "../components/skeletons/jobs-skeleton"

const messages = defineMessages({
  title: { id: "app.company.jobs.title" },
  subtitle: { id: "app.company.jobs.subtitle" },
  error: { id: "app.error.code.default_error" },
})

export class Company extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getCompanyRequest: PropTypes.func.isRequired,
    getCompanyJobListRequest: PropTypes.func.isRequired,
    getJobs: PropTypes.func,
    name: PropTypes.string,
    match: PropTypes.object,
    params: PropTypes.object,
    logo: PropTypes.string,
    location: PropTypes.object,
    website: PropTypes.string,
    about: PropTypes.string,
    company: PropTypes.object,
    jobs: PropTypes.array,
    salaryMax: PropTypes.number,
    salaryMin: PropTypes.number,
    count: PropTypes.number,
    limit: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    err: PropTypes.object,
  }

  static defaultProps = {
    jobs: [],
    page: 1,
    limit: 10,
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.loadJobs(this.props)
    }
  }

  componentDidMount() {
    this.props.getCompanyRequest({ id: this.props.match.params.id })
    this.loadJobs(this.props)
  }

  render() {
    const { company, err, jobs, count, limit, page, loading } = this.props

    if (err) {
      return <ErrorPage err={err} />
    }

    if (!company || count === null) {
      return <CompanyPageSkeleton />
    }

    if (count === 0) {
      return (
        <CompanyPageEmpty
          name={company.name}
          logo={company.logo}
          about={company.about}
          website={company.website}
          location={company.city}
        />
      )
    }

    return (
      <BaseLayout className="pb-5">
        <CompanyViewPageLayout
          name={company.name}
          logo={company.logo}
          about={company.about}
          location={company.city}
          website={company.website}
          content={
            <Fragment>
              <h2>
                <FormattedMessage {...messages.title} />
              </h2>
              {jobs.length === 0 && <JobsSkeleton />}
              {jobs.map(eachJob => (
                <JobCard
                  id={eachJob.id}
                  key={eachJob.id}
                  companyLogo={eachJob.company.logo}
                  companyName={eachJob.company.name}
                  companyId={eachJob.company.id}
                  jobTitle={eachJob.title}
                  jobId={eachJob.id}
                  salaryMin={eachJob.salaryMin}
                  salaryMax={eachJob.salaryMax}
                  locations={eachJob.locations}
                  isRemote={eachJob.remote}
                  hiringRewardEuros={eachJob.hiringRewardEuros}
                  hasQuestions={eachJob.hasQuestions}
                  className="mb-2"
                />
              ))}
              {count > limit && (
                <div className="mt-3">
                  <Pagination
                    className="justify-content-end"
                    page={page}
                    count={count}
                    disabled={loading}
                    limit={limit}
                    basePath={`/company/${company.id}`}
                  />
                </div>
              )}
            </Fragment>
          }
        />
      </BaseLayout>
    )
  }
  loadJobs = ({ page, limit }) => {
    this.props.getCompanyJobListRequest({
      id: this.props.match.params.id,
      params: {
        page,
        limit,
        status: "ACT",
      },
    })
  }
}

const mapDispatchToProps = {
  getCompanyRequest,
  getCompanyJobListRequest,
  push,
}

const mapStateToProps = function (state, props) {
  const company = companySelector(state, { id: props.match.params.id })
  const jobs = jobByCompanySelector(state, {
    id: parseInt(props.match.params.id, 10),
  })

  return {
    jobs,
    company: company,
    count: countSelector(state),
    loading: loadingSelector(state),
    err: errorSelector(state) || jobErrorSelector(state),
    page: props.match.params.page && parseInt(props.match.params.page, 10),
  }
}

export const CompanyPage = connect(mapStateToProps, mapDispatchToProps)(Company)
