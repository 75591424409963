import storage from "redux-persist/lib/storage"
import messageNotificationSound from "./assets/sounds/message-sound-bell.mp3"

export const JOB_SUGGESTIONS_ML_URL =
  "/api/v2/candidates/me/job_recommendations/"
export const JOB_SUGGESTIONS_RB_URL = "/api/v2/candidates/me/suggested_jobs/"

const NODE_ENV = process.env.NODE_ENV // test, development, production
const isTest = NODE_ENV === "test"
const isDev = NODE_ENV === "development"
const isProd = NODE_ENV === "production"

const BRANCH = process.env.REACT_APP_BRANCH || "unknown" // master, demo, release
const isMaster = BRANCH === "master"
const isDemo = BRANCH === "demo"
const isRelease = BRANCH === "release"
const isStaging = BRANCH === "staging"
const isDemo1 = BRANCH === "demo1"

const version = process.env.REACT_APP_VERSION || "unknown"
const packageName = process.env.REACT_APP_PACKAGE_NAME

const buildYear =
  parseInt(process.env.REACT_APP_BUILD_YEAR) || new Date().getFullYear()

let moberriesApiUrl = "https://api.dev.moberries.com"
let matchingApiUrl = "https://match.dev.moberries.com"
let webpushUrl = "https://webpush.dev.moberries.com"
let wsUrl = "https://ws.dev.moberries.com"
let catalogUrl = "https://www.dev.moberries.com"
let fbChatbotName = "MobotDevelopment"
let linkedinId = "8625b948d9x0f7"
let facebookId = "660782987434087"
let authProxyUrl = "https://proxy.dev.moberries.com"
let pdfServiceUrl = "https://pdf.dev.moberries.com"
let telegramBotName = "Moberries_jobbot"
let whatsappBotNumber = "4915735991707"

let firebasePublicVapidKey =
  "BKtM8VuTZzhCZ79ekS4nMatdT1OyqMk0dOZ1Con5DZrZFwckPIGQtXpNa3c0hYqcOXgwq0h3Dp-eRymwe-7DUbA"
let firebaseApiKey = "AIzaSyBsv0sKK0ubPvusUeji8xwQ3dehp6PvJc4"
let firebaseProjectId = "fcm-test-b9e8f"
let firebaseMessagingSenderId = "606432926407"
let firebaseAppId = "1:606432926407:web:6998c86c3008d8b589fce2"

const host = window.location.host || window.location.hostname || "unknown"

const getCookieDomain = branch => {
  switch (branch) {
    case "master":
    case "release":
      return ".moberries.com"
    default:
      return "localhost"
  }
}

if (isStaging) {
  moberriesApiUrl = "https://api.staging.moberries.com"
  catalogUrl = "https://www.staging.moberries.com"
  fbChatbotName = "MobotStaging"
  // linkedinId = "776vhfvunfwd47" TODO: Update
  // facebookId = "502997536545967"
  authProxyUrl = "https://proxy.staging.moberries.com"
  wsUrl = "https://ws.staging.moberries.com"
  webpushUrl = "https://webpush.staging.moberries.com"
  matchingApiUrl = "https://match.staging.moberries.com"
  pdfServiceUrl = "https://pdf.staging.moberries.com"
  telegramBotName = "Mobot_Staging_Bot"
  whatsappBotNumber = "4915735997611"
}

if (isDemo1) {
  moberriesApiUrl = "https://api.demo1.moberries.com"
  catalogUrl = "https://www.demo1.moberries.com"
  fbChatbotName = "MobotStaging"
  // linkedinId = "776vhfvunfwd47" TODO: Update
  // facebookId = "502997536545967"
  authProxyUrl = "https://proxy.demo1.moberries.com"
  wsUrl = "https://ws.demo1.moberries.com"
  webpushUrl = "https://webpush.demo1.moberries.com"
  matchingApiUrl = "https://match.demo1.moberries.com"
  pdfServiceUrl = "https://pdf.demo1.moberries.com"
}

if (isRelease) {
  moberriesApiUrl = "https://api.moberries.com"
  catalogUrl = "https://www.moberries.com"
  fbChatbotName = "moberries"
  linkedinId = "776vhfvunfwd47"
  facebookId = "502997536545967"
  authProxyUrl = "https://proxy.moberries.com"
  wsUrl = "https://ws.moberries.com"
  webpushUrl = "https://webpush.moberries.com"
  matchingApiUrl = "https://match.moberries.com"
  pdfServiceUrl = "https://pdf.moberries.com"
  telegramBotName = "MoBerries_Bot"
  whatsappBotNumber = "4915735996294"
}

if (process.env.REACT_APP_MOBERRIES_API_URL) {
  moberriesApiUrl = process.env.REACT_APP_MOBERRIES_API_URL
}

if (process.env.REACT_APP_AUTH_PROXY_URL) {
  authProxyUrl = process.env.REACT_APP_AUTH_PROXY_URL
}

if (process.env.REACT_APP_WS_URL) {
  wsUrl = process.env.REACT_APP_WS_URL
}

if (process.env.REACT_APP_WEBPUSH_URL) {
  webpushUrl = process.env.REACT_APP_WEBPUSH_URL
}

const baseUrl = window.location.protocol + "//" + window.location.hostname

export const config = {
  package: { version, name: packageName },

  baseUrl,

  env: { isTest, isDev, isProd },

  branch: { isMaster, isDemo, isRelease },

  buildYear,

  termsVersion: "20180525",

  persist: {
    root: {
      key: "root",
      storage,
      version: 1,
      whitelist: ["topCv"],
    },
    user: {
      key: "user",
      storage,
      version: 1,
      whitelist: [
        "token",
        "tokenUpdatedAt",
        "accountType",
        "socialAccountList",
        "cookieConsent",
      ],
    },
    candidate: {
      key: "candidate",
      storage,
      version: 1,
      whitelist: ["entity", "matchingActivationRequestCancelled"],
    },
    intl: {
      key: "intl",
      storage,
      version: 1,
      whitelist: ["locale", "messages"],
    },
    system: {
      key: "system",
      storage,
      version: 1,
      whitelist: ["release"],
    },
    survey: {
      key: "survey",
      storage,
      version: 1,
      whitelist: ["closingTimestamp"],
    },
    fcm: {
      key: "fcm",
      storage,
      version: 1,
      whitelist: ["token", "supported", "optout"],
    },
  },

  cookie: {
    keys: {
      ref: "ref",
      consent: "consent",
      jgClickId: "jg_clickid",
      locale: "user_lang",
      utmSource: "utm_source",
      utmMedium: "utm_medium",
      utmCampaign: "utm_campaign",
      utmTerm: "utm_term",
      utmContent: "utm_content",
    },

    domain: getCookieDomain(BRANCH),
  },

  moberries: {
    authProxy: {
      url: authProxyUrl,
    },
    api: {
      url: moberriesApiUrl,
      jobSuggestionsURL: JOB_SUGGESTIONS_ML_URL,
    },
    catalog: {
      url: catalogUrl,
    },
    ws: {
      url: wsUrl,
      options: {
        path: "/ws",
      },
    },
    webpush: {
      url: webpushUrl,
    },
    matching: {
      url: matchingApiUrl,
    },
    pdfService: {
      url: pdfServiceUrl,
    },
  },

  cv: {
    limit: 5242880,
    acceptedTypes:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },

  profilePicture: {
    limit: 1000000,
    acceptedTypes: "image/*",
  },
  typeform: {
    api: {
      url: `${authProxyUrl}/typeform`,
    },
    codes: { en: "cc6spoQ6", de: "NkpUHpdj" },
  },
  oauth: {
    redirectUrl: `${window.location.protocol}//${host}/auth/callback?utm_nooverride=1`,
  },

  facebook: {
    id: facebookId,
    scope: ["public_profile", "email"],
    signupPaths: ["/signup", "/hi/:slug", "/apply/:id", "/invite/:code"],
    authType: "rerequest",
    responseType: "code token granted_scopes",
    oauthUrl: "https://www.facebook.com/v2.10/dialog/oauth",
  },

  linkedin: {
    id: linkedinId,
    scope: ["r_liteprofile", "r_emailaddress"],
    responseType: "code",
    oauthUrl: "https://www.linkedin.com/oauth/v2/authorization",
  },

  google: {
    api: {
      places: {
        key: "AIzaSyDeZ5L1C1WEpUgsvQbibYEqAHJ7d3NHbwM",
      },
    },
  },

  fbChatBot: {
    name: fbChatbotName,
    baseShortLinkUrl: "http://m.me",
  },

  telegramBot: {
    name: telegramBotName,
    baseShortLinkUrl: "https://t.me",
  },

  whatsappBot: {
    name: whatsappBotNumber,
    baseShortLinkUrl: "https://api.whatsapp.com",
  },

  firebase: {
    publicVapidKey: firebasePublicVapidKey,
    apiKey: firebaseApiKey,
    projectId: firebaseProjectId,
    messagingSenderId: firebaseMessagingSenderId,
    appId: firebaseAppId,
  },

  logrocket: {
    url: "xeb6fu/candidate-app",
    options: {
      release: version,
      shouldCaptureIP: false,
    },
  },

  reduxSound: {
    newMessage: {
      src: [messageNotificationSound],
    },
  },

  hotjar: {
    hjsv: 6,
    hjid: 2869021,
  },

  adzuna: {
    pixel: {
      id: 10132,
      url: "https://www.adzuna.de/app_complete",
    },
  },
}
