import React from "react"
import { TabPane } from "reactstrap"
import { compose } from "ramda"

import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { ProfilePageLayout } from "../../components/layouts/profile-page/profile-page-layout"
import { ProfileProfessionalDetailsPanel } from "./profile-professional-details-panel"

export const ProfileProfessionalDetails = () => (
  <ProfilePageLayout>
    <TabPane>
      <ProfileProfessionalDetailsPanel />
    </TabPane>
  </ProfilePageLayout>
)

export const ProfileProfessionalDetailsPage = compose(
  authRequired,
  completedProfileRequired,
)(ProfileProfessionalDetails)
