import React from "react"
import PropTypes from "prop-types"
import { mergeLeft } from "ramda"
import { FormattedMessage, useIntl } from "react-intl"
import { FormGroup, Input, PopoverBody } from "reactstrap"
import { v4 as generateFakeId } from "uuid"

import { useJobRoles } from "../../../../hooks/use-job-roles"
import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { DatepickerComponent } from "../../../common/datepicker-component/datepicker-component"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { Select } from "../../../common/select-component"

import styles from "./certifications-section.module.scss"
import { InfoHint } from "../../../common/info-hint-component"

const emptyItem = {
  roleId: null,
  roleName: "",
  certificateName: "",
  issuingOrganization: "",
  issueDate: null,
}

export const CertificationsSection = ({ input, meta }) => {
  const intl = useIntl()
  const { loadJobRoles } = useJobRoles()
  let { value: values, onChange } = input
  const { error, dirty, touched } = meta

  const changeFieldHandler = (value, id) => {
    const updatedValues = values.map(v => {
      if (v.id !== id) return v

      return {
        ...v,
        roleId: value.id,
        roleName: value.name,
      }
    })
    onChange(updatedValues)
  }

  const changeCertificateNameHandler = (value, id) => {
    const updatedValues = values.map(v =>
      v.id === id ? mergeLeft({ certificateName: value }, v) : v,
    )
    onChange(updatedValues)
  }

  const changeIssuingOrganizationHandler = (value, id) => {
    const updatedValues = values.map(v =>
      v.id === id ? mergeLeft({ issuingOrganization: value }, v) : v,
    )
    onChange(updatedValues)
  }

  const changeIssueDateHandler = (value, id) => {
    const updatedValues = values.map(v =>
      v.id === id ? mergeLeft({ issueDate: value }, v) : v,
    )
    onChange(updatedValues)
  }

  const addItemHandler = () => {
    if (!values[0].roleId || values.length >= 2) return

    const newValues = [{ id: generateFakeId(), ...emptyItem }, ...values]
    onChange(newValues)
  }

  const removeItemHandler = id => {
    const newValues = values.filter(v => v.id !== id)
    onChange(newValues)
  }

  // For the first render when "certificates" is empty
  if (!values?.length)
    values = [
      {
        id: -1,
        ...emptyItem,
      },
    ]

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.certifications.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.certifications.text" />
          </span>
        }
        hint={
          <InfoHint
            id="certificatesHint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.registration.tabs.professional.input.certifications.hint" />
              </PopoverBody>
            }
          />
        }
      />

      {error && (dirty || touched) && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      {values.map((item, i) => (
        <div key={item.id} className={styles.formItem}>
          <div className={styles.formItem__main}>
            <div className={styles.formItem__inputs}>
              <div className={styles.info}>
                <div className={styles.jobRole}>
                  <Select
                    id="experience-job-roles"
                    async
                    input={{
                      onChange: newValue =>
                        changeFieldHandler(newValue, item.id),
                      value: !item.roleId
                        ? null
                        : { id: item.roleId, name: item.roleName },
                    }}
                    wrapper="div"
                    meta={{}}
                    loadOptions={loadJobRoles}
                    defaultOptions
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    placeholder={intl.formatMessage({
                      id:
                        "app.registration.tabs.professional.input.certifications.placeholder.field",
                    })}
                    noOptionsMessage={() => (
                      <FormattedMessage id="app.common.input.placeholder.noResults" />
                    )}
                    backspaceRemovesValue={false}
                  />
                </div>

                <div className={styles.certificateName}>
                  <Input
                    placeholder={intl.formatMessage({
                      id:
                        "app.registration.tabs.professional.input.certifications.placeholder.name",
                    })}
                    value={item.certificateName}
                    onChange={e =>
                      changeCertificateNameHandler(e.target.value, item.id)
                    }
                  />
                </div>
              </div>

              <div className={styles.issueInfo}>
                <div className={styles.issuingOrganization}>
                  <Input
                    placeholder={intl.formatMessage({
                      id:
                        "app.registration.tabs.professional.input.certifications.placeholder.organization",
                    })}
                    value={item.issuingOrganization}
                    onChange={e =>
                      changeIssuingOrganizationHandler(e.target.value, item.id)
                    }
                  />
                </div>
                <DatepickerComponent
                  className={styles.issueDate}
                  value={item.issueDate}
                  onChange={value => changeIssueDateHandler(value, item.id)}
                  placeholder="app.registration.tabs.professional.input.certifications.placeholder.date"
                />
              </div>
            </div>

            <div className={styles.formItem__action}>
              {i === 0 &&
              item.roleName &&
              item.issuingOrganization &&
              item.issueDate &&
              values.length < 2 ? (
                <ButtonWithIcon iconName="plus" onClick={addItemHandler} />
              ) : (
                <ButtonWithIcon
                  iconName="cross"
                  onClick={() => removeItemHandler(item.id)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </FormGroup>
  )
}

CertificationsSection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}
