import React from "react"
import ContentLoader from "react-content-loader"

export function CardLoader() {
  return (
    <ContentLoader viewBox="0 0 825 80">
      <rect x="85" y="15" rx="4" ry="4" width="600" height="10" />
      <rect x="85" y="35" rx="3" ry="3" width="700" height="10" />
      <rect x="85" y="55" rx="3" ry="3" width="650" height="10" />
      <circle cx="40" cy="40" r="40" />
    </ContentLoader>
  )
}
