import React from "react"
import { times } from "ramda"
import { CardLoader } from "./card-loader"

export const ApplicationsLoader = () => {
  return times(
    i => (
      <div key={i} className="bg-white p-3 mb-2">
        <CardLoader />
      </div>
    ),
    4,
  )
}
