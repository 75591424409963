import { useState, useEffect } from "react"
import { api } from "../lib/moberries-api"
import { pathOr } from "ramda"

export const useApplicationQuestions = ({ jobId }) => {
  const [applicationQuestions, setApplicationQuestions] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const res = await api.getApplicationQuestions({ params: { job: jobId } })
      const results = pathOr([], ["data", "results"], res)

      setApplicationQuestions(results)
    }
    if (jobId) {
      fetch()
    }
  }, [jobId])

  return { applicationQuestions }
}
