import React, { Fragment } from "react"
import { defineMessages, FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { BaseLayout } from "../layouts/base-layout"
import { CompanyViewPageLayout } from "../layouts/company-view-layout"
import defaultState from "../../assets/img/default-state.svg"
import { Img } from "../common/img-component"

const messages = defineMessages({
  title: { id: "app.messages.title.empty.noJobs" },
  subtitle: { id: "app.messages.subtitle.empty.noJobs" },
})

export function CompanyPageEmpty({
  name,
  location,
  logo,
  about,
  website,
  content,
}) {
  return (
    <BaseLayout>
      <CompanyViewPageLayout
        name={name}
        location={location}
        logo={logo}
        about={about}
        website={website}
        content={
          <Fragment>
            <h1>
              <FormattedMessage {...messages.title} />
            </h1>
            <p className="text-muted">
              <FormattedMessage {...messages.subtitle} />
            </p>
            <div className="p-3 text-center">
              <Img
                alt="Empty"
                width={600}
                className="img-fluid"
                src={defaultState}
              />
            </div>
          </Fragment>
        }
      />
    </BaseLayout>
  )
}

CompanyPageEmpty.propTypes = {
  name: PropTypes.string,
  logo: PropTypes.string,
  about: PropTypes.string,
  website: PropTypes.string,
  location: PropTypes.object,
  content: PropTypes.node,
}

CompanyPageEmpty.defaultProps = {
  name: "",
  logo: "",
  location: {},
  website: "",
  about: "",
}
