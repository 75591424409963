import { useState, useCallback } from "react"
import { api as googleMapsApi } from "../lib/google-maps-api"
import debounce from "debounce-promise"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"

export const useGoogleLocationsLoader = ({ types }) => {
  const [locations, setLocations] = useState([])
  const loadLocations = useCallback(
    debounce(async search => {
      const results = await googleMapsApi.loadLocations(search, types)
      setLocations(results)

      return results
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [],
  )

  return { locations, loadLocations }
}
