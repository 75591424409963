import React, { Fragment } from "react"
import { Form, ModalBody, ModalFooter } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import cn from "classnames"
import { Button } from "../common/button-component"
import { dec, inc } from "ramda"
import { reduxForm, propTypes } from "redux-form"
import { JobApplicationQuestionsField } from "../job/job-application-question-field"

const messages = defineMessages({
  confirm: { id: "app.common.confirm" },
  back: { id: "app.common.back" },
  next: { id: "app.common.next" },
  inputPlaceholder: { id: "app.job.questions.input.placeholder" },
})

const toFieldName = ({ id }) => `question-${id}`

export const JobApplicationQuestionsFormComponent = ({
  handleSubmit,
  applicationQuestions,
  touch,
  valid,
  form,
  step,
  setStep,
  initialValues,
}) => {
  const currentQuestion = applicationQuestions?.find(
    ({ position }) => position === step,
  )
  if (!currentQuestion) {
    return null
  }

  return (
    <Fragment>
      <Form noValidate onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-2">
            <strong>{currentQuestion.title}</strong>
          </div>
          <JobApplicationQuestionsField currentQuestion={currentQuestion} />
        </ModalBody>
        <ModalFooter className={cn({ "justify-content-between": step > 0 })}>
          {step > 0 && step < applicationQuestions?.length && (
            <Button
              color="light"
              onClick={() => {
                setStep(dec)
              }}
            >
              <FormattedMessage {...messages.back} />
            </Button>
          )}

          {step < applicationQuestions?.length - 1 && (
            <Button
              color="primary"
              type="button"
              onClick={() => {
                touch(form, toFieldName(currentQuestion))
                if (valid) {
                  setStep(inc)
                }
              }}
            >
              <FormattedMessage {...messages.next} />
            </Button>
          )}
          {step === applicationQuestions?.length - 1 && (
            <Button
              color="primary"
              type="button"
              onClick={() => {
                touch(form, toFieldName(currentQuestion))
                if (valid) {
                  setStep(inc)
                  handleSubmit()
                }
              }}
            >
              <FormattedMessage {...messages.next} />
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Fragment>
  )
}

JobApplicationQuestionsFormComponent.propTypes = {
  ...propTypes,
}

export const JobApplicationQuestionsForm = reduxForm({
  form: "JobApplicationQuestions",
})(JobApplicationQuestionsFormComponent)
