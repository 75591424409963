import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Input, FormGroup } from "reactstrap"
import browserImageSize from "browser-image-size"
import FormFeedback from "./form-feedback"
import AvatarEditor from "react-avatar-editor"
import Dropzone from "react-dropzone"
import defaultLogo from "../../assets/img/default-logo.png"
import { Button } from "../common/button-component"
import { config } from "../../config"
import { showAlert, alertTypes } from "../../lib/alerts"
import { Img } from "./img-component"
import { ALERT_COLORS } from "../../constants"

export class PictureInputComponent extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    rounded: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    borderRadius: PropTypes.number,
    buttons: PropTypes.shape({
      select: PropTypes.object,
      delete: PropTypes.object,
      crop: PropTypes.object,
      cancel: PropTypes.object,
    }),
  }

  static defaultProps = {
    rounded: "rounded-circle",
    width: "120",
    height: "120",
    borderRadius: 100,
    buttons: {
      select: {
        className: "",
        text: { id: "app.common.select" },
        size: "sm",
        style: {},
      },
      delete: {
        className: "",
        text: { id: "app.profile.picture.delete" },
        size: "sm",
        style: {},
      },
      crop: {
        className: "",
        text: { id: "app.common.crop" },
        size: "sm",
        style: {},
      },
      cancel: {
        className: "mr-1",
        text: { id: "app.common.cancel" },
        size: "sm",
        style: {},
      },
    },
  }

  state = {
    loadingError: false,
    picture: null,
  }

  render() {
    const {
      input,
      meta,
      rounded,
      width,
      height,
      borderRadius,
      buttons,
      ...rest
    } = this.props
    const { loadingError, picture } = this.state

    const { error, valid, invalid, touched } = meta

    let image = input.value

    if (!image || loadingError) {
      image = defaultLogo
    }

    return (
      <FormGroup className="_lr-hide">
        {picture ? (
          <Fragment>
            <div className="mb-2">
              <AvatarEditor
                ref={this.setEditorRef}
                image={picture}
                width={parseInt(width)}
                style={{ display: "block" }}
                height={parseInt(height)}
                border={0}
                borderRadius={borderRadius}
                color={[255, 255, 255, 1]}
                rotate={0}
                onLoadFailure={this.onLoadFailure}
                onLoadSuccess={this.onLoadSuccess}
                {...rest}
              />
            </div>

            <div className="d-flex">
              <Button
                onClick={this.handleCancel}
                size={buttons.cancel.size}
                type="button"
                className={buttons.cancel.className}
                style={buttons.cancel.style}
              >
                <FormattedMessage {...buttons.cancel.text} />
              </Button>
              <Button
                onClick={this.handleCrop}
                size={buttons.crop.size}
                type="button"
                className={buttons.crop.className}
                style={buttons.crop.style}
              >
                <FormattedMessage {...buttons.crop.text} />
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Dropzone
              onDrop={this.handleUpload}
              multiple={false}
              style={{}}
              maxSize={config.profilePicture.limit}
              accept={config.profilePicture.acceptedTypes}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div className="mb-2">
                    <input {...getInputProps()} />
                    <Img
                      className={rounded}
                      alt="User"
                      width={width}
                      height={height}
                      defaultImage={defaultLogo}
                      src={image}
                    />
                  </div>
                  {!input.value && (
                    <Button
                      className={buttons.select.className}
                      size={buttons.select.size}
                      type="button"
                      style={buttons.select.style}
                    >
                      <FormattedMessage {...buttons.select.text} />
                    </Button>
                  )}
                </div>
              )}
            </Dropzone>
            {input.value && (
              <Button
                className={buttons.delete.className}
                onClick={this.handleDelete}
                size={buttons.delete.size}
                type="button"
                style={buttons.delete.style}
              >
                <FormattedMessage {...buttons.delete.text} />
              </Button>
            )}
          </Fragment>
        )}

        <Input
          type="hidden"
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
        <FormFeedback error={error} />
      </FormGroup>
    )
  }

  setEditorRef = editor => (this.editor = editor)

  handleCrop = () => {
    const { onChange } = this.props.input
    if (this.editor) {
      const canvas = this.editor.getImage()

      onChange(canvas.toDataURL("image/jpeg"))
    }

    this.setState({ picture: null, loadingError: false })
  }

  handleCancel = () => {
    this.setState({ picture: null })
  }

  handleDelete = () => {
    const { onChange } = this.props.input
    this.setState({ picture: null })
    onChange(null)
  }
  onLoadSuccess = () => {
    const { onChange } = this.props.input
    if (this.editor) {
      const canvas = this.editor.getImage()
      onChange(canvas.toDataURL("image/jpeg"))
    }
  }

  onLoadFailure = () => {
    this.setState({ loadingError: true, picture: null })
  }

  handleUpload = async ([file], [reject]) => {
    let code = null

    if (reject) {
      code = alertTypes.defaultError

      if (reject.size >= config.profilePicture.limit) {
        code = alertTypes.pictureBigError
      }

      if (!reject.type.includes("image")) {
        code = alertTypes.pictureFormatError
      }
    }

    if (!code && !file) {
      code = alertTypes.defaultError
    }

    if (!code) {
      const size = await browserImageSize(file)

      if (size.width < 200 || size.height < 200) {
        code = alertTypes.pictureSmallError
      }
    }

    if (code) {
      return showAlert({ color: ALERT_COLORS.ERROR, code })
    }

    this.setState({ picture: file })
  }
}
