import PropTypes from "prop-types"
import { format } from "date-fns"

export const FormattedDate = ({ value }) => {
  return format(new Date(value), "dd.MM.yyyy")
}

FormattedDate.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}
