import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { push } from "connected-react-router"
import { Redirect } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { BaseLayout } from "../components/layouts/base-layout"
import { DesktopScreen } from "react-responsive-redux"
import { authRequired } from "../decorators/auth-required"
import { getThreadList } from "../actions/thread-actions"
import {
  countSelector as threadCountSelector,
  threadSelector,
  threadsSelector,
  lastFetchedPageSelector as lastFetchedThreadPageSelector,
} from "../selectors/thread-selector"
import {
  countSelector as messageCountSelector,
  messagesSelector,
  loadedThreadIdSelector,
  lastFetchedPageSelector as lastFetchedMessagePageSelector,
  loadingPageSelector as loadingMessagePageSelector,
  errorSelector,
  creatingSelector,
  creatingErrorSelector,
} from "../selectors/message-selector"
import { Thread } from "../components/messenger/thread-component"
import { ThreadList } from "../components/messenger/thread-list-component"
import {
  createMessage,
  getAdditionalThreadMessages,
  initThreadPage,
} from "../actions/message-actions"
import { candidateSelector } from "../selectors/candidate-selector"
import { ThreadListLoader } from "../components/common/thread-list-loader"
import { hasMore } from "../lib/helpers"
import { ErrorPage } from "./error-page"
import { prop, compose } from "ramda"

export const MessengerThreadPageComponent = ({
  match,
  threads,
  messages,
  getThreadList,
  threadCount,
  messageCount,
  getAdditionalThreadMessages,
  initThreadPage,
  createMessage,
  candidate,
  loadingMessagePage,
  lastFetchedThreadPage,
  lastFetchedMessagePage,
  loadedThreadId,
  err,
  creatingMessage,
  creatingMessageErr,
  push,
  thread,
}) => {
  const threadId = parseInt(match.params.id, 10)
  const reviewedAt = prop("reviewedAt", thread)

  const loadMoreMessages = () => {
    getAdditionalThreadMessages({ threadId, page: lastFetchedMessagePage + 1 })
  }

  const loadMoreThreads = () => {
    getThreadList({ page: lastFetchedThreadPage + 1 })
  }

  useEffect(() => {
    initThreadPage({ threadId })
  }, [initThreadPage, threadId])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (threadCount === 0) {
    return <Redirect to="/messages" />
  }

  return (
    <Fragment>
      <BaseLayout className="py-5">
        <Container>
          <div className="bg-white border-right">
            <Row noGutters>
              <DesktopScreen className="col-lg-5 d-flex flex-column messenger-height border-top">
                {!lastFetchedThreadPage ? (
                  <div className="p-2 bg-white h-100">
                    <ThreadListLoader />
                  </div>
                ) : (
                  <ThreadList
                    loadMore={loadMoreThreads}
                    threads={threads}
                    currentThreadId={threadId}
                    hasMore={hasMore(threadCount, lastFetchedThreadPage, 10)}
                    useWindowScroll={false}
                    onClickThread={t => {
                      if (!loadingMessagePage && t.id !== threadId) {
                        push(`/messages/${t.id}`)
                      }
                    }}
                  />
                )}
              </DesktopScreen>
              <Col lg="7" className="d-flex flex-column messenger-height">
                <Thread
                  loadMore={loadMoreMessages}
                  hasMore={
                    loadedThreadId === threadId
                      ? hasMore(messageCount, lastFetchedMessagePage, 30)
                      : false
                  }
                  job={thread?.job}
                  threadId={loadedThreadId}
                  messages={messages}
                  candidate={candidate}
                  loadingPage={loadingMessagePage}
                  createMessage={createMessage}
                  creatingMessage={creatingMessage}
                  creatingMessageErr={creatingMessageErr}
                  match={thread?.match}
                  reviewedAt={reviewedAt}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

MessengerThreadPageComponent.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  threads: PropTypes.arrayOf(PropTypes.object).isRequired,
  thread: PropTypes.object,
  match: PropTypes.object.isRequired,
  getThreadList: PropTypes.func.isRequired,
  messageCount: PropTypes.number,
  threadCount: PropTypes.number,
  getAdditionalThreadMessages: PropTypes.func.isRequired,
  initThreadPage: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  candidate: PropTypes.object,
  lastFetchedThreadPage: PropTypes.number,
  loadedThreadId: PropTypes.number,
  loadingMessagePage: PropTypes.number,
  lastFetchedMessagePage: PropTypes.number,
  err: PropTypes.object,
  creatingMessage: PropTypes.bool.isRequired,
  creatingMessageErr: PropTypes.object,
  push: PropTypes.func.isRequired,
}

MessengerThreadPageComponent.defaultProps = {
  messages: [],
  threads: [],
  creatingMessage: false,
}

const mapStateToProps = (state, ownProps) => {
  const thread = threadSelector(state, {
    id: parseInt(ownProps.match.params.id, 10),
  })
  return {
    thread,
    threads: threadsSelector(state),
    threadCount: threadCountSelector(state),
    messages: messagesSelector(state, { threadId: ownProps.match.params.id }),
    messageCount: messageCountSelector(state),
    candidate: thread
      ? candidateSelector(state, { id: thread.candidate.id })
      : null,
    lastFetchedThreadPage: lastFetchedThreadPageSelector(state),
    lastFetchedMessagePage: lastFetchedMessagePageSelector(state),
    loadingMessagePage: loadingMessagePageSelector(state),
    loadedThreadId: loadedThreadIdSelector(state),
    err: errorSelector(state),
    creatingMessage: creatingSelector(state),
    creatingMessageErr: creatingErrorSelector(state),
  }
}

const mapDispatchToProps = {
  getThreadList,
  initThreadPage,
  getAdditionalThreadMessages,
  createMessage,
  push,
}

export const MessengerThreadPage = compose(
  authRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(MessengerThreadPageComponent)
