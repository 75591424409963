import { useState, useEffect } from "react"
import { api } from "../lib/moberries-api"
import { prop } from "ramda"

export const useFeedbackChoices = ({ feedbackSetType }) => {
  const [feedbackChoices, setFeedbackChoices] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const res = await api.getFeedbackChoices({
        type: feedbackSetType,
      })
      const feedbackChoices = res?.data?.results ?? []
      setFeedbackChoices(feedbackChoices)
    }
    fetch()
  }, [feedbackSetType])

  return { feedbackChoices: feedbackChoices.filter(prop("isActive")) }
}
