import React from "react"
import { config } from "../../../config"

const frameProps = {
  src: `${config.adzuna.pixel.url}/${config.adzuna.pixel.id}`,
  scrolling: "no",
  frameBorder: "0",
  width: "1",
  height: "1",
}

export const AdzunaFrame = () => <iframe title="adzuna-frame" {...frameProps} />
