import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { confirmDeleteAccount } from "../actions/user-actions"

export class ConfirmDeleteAccount extends Component {
  static propTypes = {
    confirmDeleteAccount: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { match, confirmDeleteAccount } = this.props
    confirmDeleteAccount({ code: match.params.code })
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { confirmDeleteAccount }

const mapStateToProps = function (state) {
  return {}
}

export const ConfirmDeleteAccountPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeleteAccount)
