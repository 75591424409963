import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import { DatepickerComponent } from "../../../../common/datepicker-component/datepicker-component"
import styles from "./dates-inputs.module.scss"

export const DatesInputs = ({ className, item, changeProp }) => {
  return (
    <div className={cn(className, styles.wrapper)}>
      <DatepickerComponent
        className={styles.input}
        value={item.startDate}
        onChange={value => changeProp(value, item.id, "startDate")}
        placeholder="app.common.input.placeholder.startDate"
      />

      <DatepickerComponent
        className={styles.input}
        value={item.endDate}
        onChange={value => changeProp(value, item.id, "endDate")}
        placeholder={
          item.startDate && !item.endDate
            ? "app.common.input.placeholder.tillNow"
            : "app.common.input.placeholder.endDate"
        }
        disabled={item.startDate && !item.endDate}
      />
    </div>
  )
}

DatesInputs.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  changeProp: PropTypes.func.isRequired,
}
