import React from "react"
import PropTypes from "prop-types"
import { Media } from "reactstrap"
import { Img } from "../common/img-component"
import classNames from "classnames"
import { LastMessageStatus } from "./last-message-status-component"
import { FormattedDate } from "../common/formatted-date-component"

export function ThreadCard({ thread, isSelected, onClick }) {
  const hasUnreadMessages = thread.unreadCount > 0

  return (
    <Media
      onClick={onClick}
      className={classNames(
        "align-items-stretch",
        "p-3",
        "border-bottom",
        "text-decoration-none",
        "clickable",
        {
          "bg-white": !hasUnreadMessages && !isSelected,
          "bg-success-light": hasUnreadMessages && !isSelected,
          "bg-whitesmoke": isSelected,
        },
      )}
    >
      <Img
        width="48"
        height="48"
        alt="Author"
        src={thread.job.company.logo}
        className="rounded-circle mr-3 align-self-center _lr-hide"
      />
      <Media body className="text-truncate pr-1 _lr-hide">
        <h6 className="mb-1 text-truncate font-weight-bold">
          {thread.job.company.name}
        </h6>
        <strong className="font-weight-bold text-muted text-truncate">
          {thread.job.title}
        </strong>
        <div className="text-truncate">{thread.lastMessage.text}</div>
      </Media>
      <div className="d-flex flex-column text-right">
        <time className="text-muted text-smaller">
          <FormattedDate value={thread.lastMessageTimestamp} />
        </time>
        <div className="mt-auto">
          <LastMessageStatus thread={thread} />
        </div>
      </div>
    </Media>
  )
}
ThreadCard.propTypes = {
  thread: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}
