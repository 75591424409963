import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"

export const useInstitutions = (options = {}) => {
  const [institutions, setInstitutions] = useState([])
  const selected = (options.selected || []).filter(Boolean)

  const loadInstitutions = useCallback(
    debounce(async search => {
      const res = await moApi.getInstitutions({ params: { search, limit: 5 } })
      const institutions = pathOr([], ["data", "results"], res)
      const filteredInstitutions = institutions.filter(
        i => !selected.find(s => s.id === i.id),
      )
      setInstitutions(filteredInstitutions)

      return filteredInstitutions
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [selected.length],
  )

  return { institutions, loadInstitutions }
}
