// middleware for fixing redux-form integration with sagas
// rf dispatches SET_SUBMIT_SUCCEEDED action after calling submit
// so form becomes wring submitSucceeded flag set to true
import { SET_SUBMIT_SUCCEEDED } from "redux-form/es/actionTypes"

export function reduxFormFixMiddleware() {
  return next => action => {
    if (action.type !== SET_SUBMIT_SUCCEEDED) {
      return next(action)
    }
  }
}
