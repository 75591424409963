import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"

export class UncontrolledCarousel extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    indicators: PropTypes.bool,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    activeIndex: PropTypes.number,
    interval: PropTypes.number,
    next: PropTypes.func,
    previous: PropTypes.func,
    goToIndex: PropTypes.func,
  }

  static defaultProps = {
    controls: true,
    indicators: true,
    autoPlay: true,
    interval: 5000,
  }

  state = { activeIndex: 0 }

  next = () => {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.props.items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = newIndex => {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const {
      autoPlay,
      indicators,
      controls,
      items,
      goToIndex,
      interval,
      ...props
    } = this.props

    const { activeIndex } = this.state

    const slides = items.map(item => {
      return (
        <CarouselItem key={item.src}>
          <img src={item.src} alt={item.altText} className="img-fluid" />
          <CarouselCaption
            captionText={item.caption}
            captionHeader={item.caption}
          />
        </CarouselItem>
      )
    })

    return (
      <Carousel
        interval={interval}
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        ride={autoPlay ? "carousel" : undefined}
        {...props}
      >
        <CarouselIndicators
          items={items}
          activeIndex={props.activeIndex || activeIndex}
          onClickHandler={goToIndex || this.goToIndex}
        />
        {slides}
        {controls && (
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={props.previous || this.previous}
          />
        )}
        {controls && (
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={props.next || this.next}
          />
        )}
      </Carousel>
    )
  }
}
