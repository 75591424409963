import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"
export const useJobRoles = (options = {}) => {
  const [jobRoles, setJobRoles] = useState([])
  const selected = options.selected || []
  const loadJobRoles = useCallback(
    debounce(async search => {
      const res = await moApi.getJobRoles({
        params: {
          search,
          limit: 5,
          ordering: search && "name",
        },
      })
      const jobRoles = pathOr([], ["data", "results"], res)
      const selectedExcludedJobRoles = jobRoles.filter(
        l => !selected.map(s => s.id).includes(l.id),
      )

      setJobRoles(selectedExcludedJobRoles)

      return selectedExcludedJobRoles
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [selected.length],
  )

  return { jobRoles, loadJobRoles }
}
