import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { ProfileCompletionStatus } from "./profile-completion-status-component"
import { MatchingStatistics } from "./matching-statistics-component"
import { FriendsReferral } from "./friends-referral-component"
// import { WebPushSubscriptionWidget } from "./web-push-component"
import { ReportHire } from "./report-hire-component"
import { FeedbackSummarySidebar } from "./feedback-summary-component"
import { MessageSubscriptionWidget } from "./message-subscription"

export function BaseSidebar(props) {
  return (
    <Fragment>
      {props.displayReportHire && <ReportHire />}
      {props.displayFeedbackSummary && <FeedbackSummarySidebar />}
      {/*<WebPushSubscriptionWidget />*/}
      {props.showBotSubscription && <MessageSubscriptionWidget />}
      <ProfileCompletionStatus />
      <MatchingStatistics />
      <FriendsReferral />
    </Fragment>
  )
}

BaseSidebar.propTypes = {
  displayReportHire: PropTypes.bool.isRequired,
  displayFeedbackSummary: PropTypes.bool.isRequired,
  showBotSubscription: PropTypes.bool.isRequired,
}

BaseSidebar.defaultProps = {
  displayReportHire: false,
  displayFeedbackSummary: false,
  showBotSubscription: true,
}
