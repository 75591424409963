import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ThreadCard } from "./thread-card-component"
import { InfiniteScroll } from "../common/infinite-scroll-component"
import { CardLoader } from "../common/card-loader"

export const ThreadList = ({
  threads,
  currentThreadId,
  loadMore,
  hasMore,
  useWindowScroll,
  className,
  onClickThread,
}) => {
  return (
    <div
      className={classNames("h-25", className, {
        "flex-grow-1": !useWindowScroll,
        "overflow-auto": !useWindowScroll,
      })}
    >
      <InfiniteScroll
        onLoadMore={loadMore}
        hasMore={hasMore}
        rootMargin={200}
        loader={
          <div className="p-3 bg-white">
            <CardLoader />
          </div>
        }
        useWindowScroll={useWindowScroll}
      >
        {threads.map(t => (
          <ThreadCard
            key={t.id}
            thread={t}
            isSelected={t.id === currentThreadId}
            onClick={() => onClickThread(t)}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}

ThreadList.propTypes = {
  threads: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentThreadId: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  useWindowScroll: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClickThread: PropTypes.func,
}

ThreadList.defaultProps = {
  threads: [],
  hasMore: false,
}
