import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"

import styles from "./label-with-text-component.module.scss"

export const LabelWithText = ({ label, text, hint, forProp, isRequired }) => {
  if (!label && !hint) return null

  return (
    <div>
      {label && (
        <Label for={forProp} className={styles.label}>
          {label}
          {isRequired && "*"}
        </Label>
      )}
      {hint && <span>{hint}</span>}
      {text && <p className={styles.text}>{text}</p>}
    </div>
  )
}

LabelWithText.propTypes = {
  label: PropTypes.any,
  text: PropTypes.any,
  hint: PropTypes.node,
  forProp: PropTypes.string,
  isRequired: PropTypes.bool,
}
