import { createSelector } from "reselect"
import { prop } from "ramda"

export const stateSelector = state => state.system

const cs = (...args) => createSelector(stateSelector, ...args)

export const releaseSelector = cs(state => state.release)
export const logrocketSelector = cs(state => state.logrocket)
export const lastSupportedVersionSelector = cs(prop("lastSupportedVersion"))
