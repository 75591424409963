import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { Col, Row } from "reactstrap"
import emptyStateImage from "../../assets/img/default-state.svg"
import { Img } from "../common/img-component"

const messages = defineMessages({
  title: { id: "app.dashboard.matches.header.title.empty" },
  subtitle: { id: "app.dashboard.matches.header.subtitle.empty" },
})

export const DashboardJobsEmpty = () => (
  <Row className="bg-white mb-3 p-3 rounded mx-0 flex-wrap-reverse">
    <Col xs="12" md="2" className="text-center p-1">
      <Img src={emptyStateImage} width="300" className="img-fluid" />
    </Col>
    <Col>
      <h3>
        <FormattedMessage {...messages.title} />
      </h3>
      <p>
        <FormattedMessage {...messages.subtitle} />
      </p>
    </Col>
  </Row>
)
