import React from "react"

export function MbSvgLogo(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 22 29"
    >
      <path
        d="M17.1,16c0,2.9-2,5.4-4.8,5.9c-1.9,0.4-3.6-0.2-5-1.6c-2.6-2.6-2.4-6.9,0.5-9.2c3-2.4,7.4-1.2,8.9,2.5
                          C16.9,14.4,17.1,15.2,17.1,16L17.1,16z"
      />
      <path
        d="M3.4,21.9c0-1.4,0.4-2.5,1.2-3.4C5,18.2,5,18.2,5.1,18.6c0.9,2.2,2.5,3.6,4.8,4.1c0.5,0.1,1,0.1,1.5,0.1
                          c0.5,0,0.5,0,0.3,0.4c-0.6,1.8-2.3,2.9-4.1,2.9c-1.8,0-3.5-1.3-4-3C3.5,22.7,3.4,22.2,3.4,21.9L3.4,21.9z"
      />
      <path
        d="M19.9,6c-0.1,2.2-1.1,4-3.2,5.3c-0.3,0.2-0.4,0.2-0.6-0.1c-0.4-0.5-1-1-1.6-1.3c-0.2-0.1-0.2-0.2-0.1-0.4
                          c0.8-1.5,1.3-3,1.8-4.6c0.5-1.4,1.1-2.6,2.1-3.7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3,0.1C19.3,2.6,19.8,4.2,19.9,6L19.9,6z"
      />
      <path
        d="M18.4,0.9c-0.1,0.1-0.1,0.2-0.2,0.2c-1,0.8-1.6,1.8-2.2,2.9c-0.6,1.3-1.2,2.6-1.9,3.9
                          c-0.2,0.5-0.5,0.9-0.8,1.3c-0.1,0.2-0.2,0.2-0.5,0.2c-0.7-0.2-1.4-0.3-2.1-0.2c-0.3,0-0.3-0.1-0.4-0.3c-0.4-2.9,1.2-5.9,4.4-7.1
                          c1.1-0.4,2.2-0.6,3.3-0.8C18.2,0.9,18.3,0.9,18.4,0.9L18.4,0.9z"
      />
      <path
        d="M0.7,15.9c0.1-1.7,0.7-3.3,2.2-4.4c0.6-0.4,1.3-0.7,2-0.8c0.2,0,0.4,0,0.5,0.2c0.2,0.2,0.7,0.4,0.7,0.7
                          c0.1,0.3-0.3,0.6-0.5,0.9c-0.8,1.4-1.1,3-0.9,4.6c0,0.2,0,0.3-0.2,0.4C3.7,18.1,3.2,19,2.8,20c-0.1,0.4-0.1,0.4-0.4,0.1
                          C1.2,19,0.7,17.6,0.7,15.9L0.7,15.9z"
      />
      <path
        d="M21.5,16.4c-0.1,1.8-0.6,3.3-2.1,4.5c-0.6,0.5-0.7,0.5-0.9-0.2c-0.2-0.6-0.5-1.2-1-1.7
                          c-0.1-0.2-0.2-0.3-0.1-0.5c0.7-2.1,0.6-4.1-0.5-6c-0.1-0.2-0.1-0.3,0.1-0.4c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.1,0.3-0.2,0.5-0.1
                          c1.5,0.6,2.3,1.8,2.7,3.3C21.5,15.2,21.5,15.8,21.5,16.4L21.5,16.4z"
      />
      <path
        d="M18,22.4c0,1.5-1.2,3.1-3,3.4c-1.1,0.2-2-0.1-2.8-0.8c-0.1-0.1-0.2-0.2-0.1-0.4c0.3-0.5,0.6-1.1,0.7-1.7
                          c0-0.2,0.1-0.3,0.3-0.3c1.6-0.5,2.9-1.4,3.8-2.8c0.2-0.2,0.2-0.2,0.4,0C17.7,20.5,18,21.3,18,22.4L18,22.4z"
      />
      <path
        d="M6.3,2.7c0,1.4,0.6,2.5,1.2,3.6c0.5,0.9,1,1.8,1.4,2.8C9,9.4,9,9.6,8.7,9.7C8.1,9.9,7.5,10.3,7,10.8
                          c-0.2,0.2-0.3,0.2-0.5,0C4.7,9.5,4,7.4,4.8,5.4C5.1,4.4,5.6,3.6,6.3,2.7L6.3,2.7z"
      />
      <path
        d="M11.1,28.9c-1.1,0-2-0.6-2.3-1.4c-0.2-0.5-0.1-0.5,0.3-0.6c0.8-0.2,1.5-0.6,2.1-1.2c0.2-0.2,0.3-0.2,0.5,0
                          c0.5,0.4,1,0.7,1.7,0.9c0.3,0.1,0.3,0.2,0.3,0.4c0,0.6-0.3,1-0.8,1.3C12.3,28.7,11.8,28.9,11.1,28.9L11.1,28.9z"
      />
      <path
        d="M9.5,8.8C8.8,7.5,8,6.4,7.2,5.2C6.8,4.6,6.5,3.8,6.4,3c0-0.1-0.1-0.3,0-0.3c0.1-0.1,0.2,0,0.3,0.1
                          c1.1,0.5,2.2,1.1,3,2C10,5,10.1,5.3,9.9,5.6C9.6,6.4,9.5,7.3,9.6,8.2c0,0.1,0,0.3,0,0.4C9.6,8.7,9.6,8.7,9.5,8.8L9.5,8.8z"
      />
    </svg>
  )
}

MbSvgLogo.propTypes = {}
MbSvgLogo.defaultProps = {}
