import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FormGroup } from "reactstrap"

import { CANDIDATE_AVAILABILITY } from "../../../../default-options"
import { ButtonGroup } from "../../../common/button-group/button-group"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"

import styles from "./availability-section.module.scss"

export const AvailabilitySection = ({ input }) => {
  const { value: availability, onChange } = input

  useEffect(() => {
    if (!availability) {
      onChange("immediately")
    }
  }, [availability, onChange])

  const availabilityChangeHandler = value => {
    onChange(value)
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.availability.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.availability.text" />
          </span>
        }
      />

      <ButtonGroup
        className={styles.buttons}
        buttons={CANDIDATE_AVAILABILITY.map(({ name, value }) => ({
          name: <FormattedMessage {...name} />,
          value: value,
          active: availability === value,
        }))}
        onChange={availabilityChangeHandler}
      />
    </FormGroup>
  )
}

AvailabilitySection.propTypes = {
  input: PropTypes.object,
}
