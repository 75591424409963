import { getActionType } from "../lib/helpers"
import {
  FCM,
  TOKEN,
  INIT,
  SUCCESS,
  FAIL,
  START,
  CREATE,
  DELETE,
  NOT,
  SUPPORTED,
} from "../constants"

export const defaultState = {
  loading: false,
  token: null,
  optout: true,
  supported: null,
}

export const reducer = function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(FCM, SUPPORTED):
      return {
        ...state,
        supported: true,
      }

    case getActionType(FCM, NOT, SUPPORTED):
      return {
        ...state,
        supported: false,
      }

    case getActionType(INIT, FCM, TOKEN, SUCCESS):
      return {
        ...state,
        token: payload.token,
      }

    case getActionType(INIT, FCM, TOKEN, FAIL):
      return {
        ...state,
        token: null,
      }

    case getActionType(CREATE, FCM, TOKEN, START):
      return {
        ...state,
        loading: true,
      }

    case getActionType(CREATE, FCM, TOKEN, SUCCESS):
      return {
        ...state,
        loading: false,
        token: payload.token,
        optout: false,
      }

    case getActionType(CREATE, FCM, TOKEN, FAIL):
      return {
        ...state,
        loading: false,
        token: null,
      }

    case getActionType(DELETE, FCM, TOKEN, START):
      return {
        ...state,
        loading: true,
      }

    case getActionType(DELETE, FCM, TOKEN, SUCCESS):
      return {
        ...state,
        loading: false,
        token: null,
        optout: true,
      }

    case getActionType(DELETE, FCM, TOKEN, FAIL):
      return {
        ...state,
        loading: false,
        token: null,
      }

    default:
      return state
  }
}
