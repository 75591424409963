import React, { useEffect, Fragment, useCallback } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import { compose, head, isEmpty, times } from "ramda"
import { useTransition, animated } from "react-spring"
import { ErrorPage } from "./error-page"
import { BaseLayout } from "../components/layouts/base-layout"
import { DoubleColumnLayout } from "../components/layouts/double-column-layout"
import { authRequired } from "../decorators/auth-required"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { Row, Col } from "reactstrap"
import {
  firstNameSelector,
  candidateSelector,
  hasAnyActiveSubscriptionSelector,
} from "../selectors/candidate-selector"
import { DashboardPageSkeleton } from "../components/skeletons/dashboard-page-skeleton"
import { DashboardJobsEmpty } from "../components/empty-states/dashboard-jobs-empty"
import { BaseSidebar } from "../components/sidebar/base-sidebar"
import {
  getJobSuggestionList,
  getJobSuggestionsAndExternalJobs,
} from "../actions/job-actions"
import { getExternalJobList } from "../actions/external-job-actions"
import {
  suggestionsCountSelector,
  jobSuggestionsSelector,
  errorSelector as jobErrorSelector,
  loadingSelector as jobLoadingSelector,
  applyingJobIdSelector,
  applyingErrSelector,
  feedbackJobOpenIdSelector,
  discardingJobIdSelector,
} from "../selectors/job-selector"
import { usePrevious } from "react-hanger"
import { useList } from "../hooks/use-list"
import { JobDashboardCard } from "../components/dashboard/job-dashboard-card"
import {
  countSelector as externalJobsCountSelector,
  errorSelector as externalJobErrorSelector,
  externalJobsSelector,
  hidingExternalJobIdSelector,
  isLoadingSelector as areExternalJobsLoadingSelector,
} from "../selectors/external-job-selector"
import { ExternalJobDashboardCard } from "../components/dashboard/external-job-dashboard-card"
import { CardLoader } from "../components/common/card-loader"

const messages = defineMessages({
  title: { id: "app.dashboard.suggestions.title" },
  subtitle: { id: "app.dashboard.suggestions.subtitle" },
  externalJobsTitle: { id: "app.dashboard.externalJobs.title" },
  externalJobsSubtitle: { id: "app.dashboard.externalJobs.subtitle" },
  suggestionsHint: { id: "app.dashboard.suggestions.hint" },
  emptyTitle: { id: "app.dashboard.matches.header.title.empty" },
  emptySubtitle: { id: "app.dashboard.matches.header.subtitle.empty" },
})

const Dashboard = ({
  jobs,
  jobsCacheLeft,
  jobsCount,
  areJobSuggestionsLoading,
  err,
  applyingId,
  applyingErr,
  firstName,
  candidate,
  feedbackJobOpenId,
  discardingJobId,
  discardingErr,
  getExternalJobList,
  externalJobsCacheLeft,
  externalJobs,
  externalJobsCount,
  areExternalJobsLoading,
  hidingExternalJobId,
  externalJobError,
  getJobSuggestionsAndExternalJobs,
  hasAnyActiveSubscription,
}) => {
  const prevAreJobSuggestionsLoading = usePrevious(areJobSuggestionsLoading)
  const prevAreExternalJobsLoading = usePrevious(areExternalJobsLoading)
  const prevHidingExternalJobId = usePrevious(hidingExternalJobId)
  const prevApplyingId = usePrevious(applyingId)
  const prevDiscardingJobId = usePrevious(discardingJobId)

  const {
    has: isActive,
    toggle,
    value: activeItems,
    push: open,
    remove: close,
  } = useList()

  useEffect(() => {
    if (!applyingId && prevApplyingId && !applyingErr) {
      close(prevApplyingId)
    }

    if (!discardingJobId && prevDiscardingJobId && !discardingErr) {
      close(prevDiscardingJobId)
    }

    if (!hidingExternalJobId && prevHidingExternalJobId && !externalJobError) {
      close(prevHidingExternalJobId)
    }
  }, [
    discardingJobId,
    applyingId,
    applyingErr,
    prevApplyingId,
    discardingErr,
    prevDiscardingJobId,
    close,
    prevHidingExternalJobId,
    hidingExternalJobId,
    externalJobError,
  ])

  const openFirst = useCallback(() => {
    if (activeItems.length === 0) {
      const firstJobToReview = jobs.find(({ id }) => id !== feedbackJobOpenId)
      if (firstJobToReview) {
        open(firstJobToReview.id)
        return
      }
      const firstExternalJobToReview = head(externalJobs)
      if (firstExternalJobToReview) {
        open(firstExternalJobToReview.idStr)
      }
    }
  }, [activeItems.length, jobs, open, feedbackJobOpenId, externalJobs])

  useEffect(() => {
    if (
      (prevAreJobSuggestionsLoading && !areJobSuggestionsLoading && !err) ||
      (prevAreExternalJobsLoading &&
        !areExternalJobsLoading &&
        !externalJobError)
    ) {
      openFirst()
    }
  }, [
    prevAreJobSuggestionsLoading,
    areJobSuggestionsLoading,
    err,
    openFirst,
    prevAreExternalJobsLoading,
    areExternalJobsLoading,
    externalJobError,
  ])

  useEffect(() => {
    getJobSuggestionsAndExternalJobs()
  }, [getJobSuggestionsAndExternalJobs])

  const jobCardDidLeave = () => {
    openFirst()

    if (jobsCacheLeft <= 0) {
      getJobSuggestionsAndExternalJobs()
    }
  }

  const externalJobCardDidLeave = () => {
    openFirst()

    if (externalJobsCacheLeft <= 5) {
      getExternalJobList()
    }
  }

  const transitionStyles = {
    enter: {
      opacity: 1,
      transform: "scale(1)",
      padding: 4,
      margin: 4,
      maxHeight: 5000,
    },
    leave: {
      opacity: 0,
      transform: "scale(0.5)",
      padding: 0,
      maxHeight: 0,
    },
  }

  const jobTransitions = useTransition(
    jobs.map(job => ({
      ...job,
      isFeedbackOpen: job.id === feedbackJobOpenId,
    })),
    { ...transitionStyles, keys: job => job.id, onDestroyed: jobCardDidLeave },
  )

  const jobFragment = jobTransitions((style, job) => (
    <animated.div style={style}>
      <JobDashboardCard
        job={job}
        isOpen={isActive(job.id)}
        toggle={() => {
          if (!isCollapsingDisabled) {
            toggle(job.id)
          }
        }}
      />
    </animated.div>
  ))

  const externalJobTransitions = useTransition(externalJobs, {
    keys: job => job.idStr,
    ...transitionStyles,
    onDestroyed: externalJobCardDidLeave,
  })

  const externalJobFragment = externalJobTransitions((style, job) => (
    <animated.div style={style}>
      <ExternalJobDashboardCard
        job={job}
        isOpen={isActive(job.idStr)}
        toggle={() => {
          if (!isCollapsingDisabled) {
            toggle(job.idStr)
          }
        }}
      />
    </animated.div>
  ))

  const isCollapsingDisabled =
    areJobSuggestionsLoading ||
    areExternalJobsLoading ||
    Boolean(applyingId) ||
    Boolean(discardingJobId) ||
    Boolean(hidingExternalJobId)

  if (err) {
    return <ErrorPage err={err} />
  }

  if (jobsCount === null) {
    return <DashboardPageSkeleton />
  }

  return (
    <BaseLayout className="py-5">
      <DoubleColumnLayout
        content={
          <Fragment>
            {isEmpty(jobFragment.props.children) ? (
              <DashboardJobsEmpty />
            ) : (
              <Row>
                <Col>
                  <h1>
                    <FormattedMessage {...messages.title} />
                  </h1>
                  <p className="text-muted">
                    <FormattedMessage {...messages.subtitle} />
                  </p>
                </Col>
              </Row>
            )}
            {jobFragment}
            {jobs.length === 10 && (
              <div className="text-center p-3 rounded lead bg-light _lr-hide">
                <i className="fas fa-exclamation-circle pr-2 text-primary" />
                <FormattedMessage
                  {...messages.suggestionsHint}
                  values={{ firstName }}
                />
              </div>
            )}
            {(!isEmpty(externalJobFragment.props.childred) ||
              (externalJobsCount === null && areExternalJobsLoading)) && (
              <Row
                className={classNames({
                  "mt-4": jobs.length > 0,
                })}
              >
                <Col>
                  <h1>
                    <FormattedMessage {...messages.externalJobsTitle} />
                  </h1>
                  <p className="text-muted">
                    <FormattedMessage {...messages.externalJobsSubtitle} />
                  </p>
                </Col>
              </Row>
            )}
            {externalJobsCount === null &&
              areExternalJobsLoading &&
              times(
                i => (
                  <div key={i} className="bg-white rounded p-3 mb-2">
                    <CardLoader />
                  </div>
                ),
                4,
              )}
            {externalJobFragment}
          </Fragment>
        }
        sidebar={
          <BaseSidebar
            displayReportHire={
              candidate.active && candidate.statistics.matchesAccepted >= 1
            }
            displayFeedbackSummary={candidate.statistics.matchesDeclined > 3}
            showBotSubscription={!hasAnyActiveSubscription}
          />
        }
      />
    </BaseLayout>
  )
}

Dashboard.propTypes = {
  getJobSuggestionList: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
  jobsCacheLeft: PropTypes.number.isRequired,
  jobsCount: PropTypes.number,
  areJobSuggestionsLoading: PropTypes.bool.isRequired,
  err: PropTypes.object,
  isApplying: PropTypes.bool.isRequired,
  applyingId: PropTypes.number,
  applyingErr: PropTypes.object,
  firstName: PropTypes.string.isRequired,
  candidate: PropTypes.object.isRequired,
  isDiscarding: PropTypes.bool.isRequired,
  discardingJobId: PropTypes.number,
  discardingErr: PropTypes.object,
  feedbackJobOpenId: PropTypes.number,
  getExternalJobList: PropTypes.func.isRequired,
  externalJobsCacheLeft: PropTypes.number.isRequired,
  externalJobs: PropTypes.array.isRequired,
  externalJobsCount: PropTypes.number,
  areExternalJobsLoading: PropTypes.bool.isRequired,
  hidingExternalJobId: PropTypes.string,
  externalJobError: PropTypes.object,
  getJobSuggestionsAndExternalJobs: PropTypes.func,
  hasAnyActiveSubscription: PropTypes.bool.isRequired,
}

Dashboard.defaultProps = {
  isLoading: false,
  isApplying: false,
  isDiscarding: false,
  jobs: [],
  externalJobs: [],
}

const mapDispatchToProps = {
  getJobSuggestionsAndExternalJobs,
  getJobSuggestionList,
  getExternalJobList,
}

const mapStateToProps = function (state) {
  const jobsCount = suggestionsCountSelector(state)
  const jobs = jobSuggestionsSelector(state)
  const externalJobs = externalJobsSelector(state)
  return {
    firstName: firstNameSelector(state),
    areJobSuggestionsLoading: jobLoadingSelector(state),
    jobs: jobs.slice(0, 10),
    jobsCacheLeft: jobs.length - 10,
    applyingId: applyingJobIdSelector(state),
    err: jobErrorSelector(state),
    applyingErr: applyingErrSelector(state),
    jobsCount,
    candidate: candidateSelector(state),
    feedbackJobOpenId: feedbackJobOpenIdSelector(state),
    discardingJobId: discardingJobIdSelector(state),
    areExternalJobsLoading: areExternalJobsLoadingSelector(state),
    externalJobs: externalJobs.slice(0, 10),
    externalJobsCacheLeft: externalJobs.length - 10,
    externalJobsCount: externalJobsCountSelector(state),
    hidingExternalJobId: hidingExternalJobIdSelector(state),
    externalJobError: externalJobErrorSelector(state),
    hasAnyActiveSubscription: hasAnyActiveSubscriptionSelector(state),
  }
}

export const DashboardPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(Dashboard)
