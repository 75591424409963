import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Container, Alert } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { localeSelector } from "../../selectors/intl-selector"
import {
  cvIsUploadedSelector,
  isCompleteSelector,
} from "../../selectors/candidate-selector"

const messages = defineMessages({
  cvUploadForceText: { id: "app.common.cv.force.text" },
  cvUploadForceLink: { id: "app.common.cv.force.link" },
})

export function NoCvAlertComponent({ cvIsUploaded, isComplete }) {
  if (!isComplete || cvIsUploaded) {
    return null
  }

  return (
    <Alert
      className="mb-3 alert-floating"
      color="danger"
      isOpen={!cvIsUploaded}
      transition={{
        appear: false,
        enter: false,
        exit: false,
        timeout: 0,
      }}
    >
      <Container className="position-relative px-3">
        <div className="mr-3">
          <FormattedMessage
            {...messages.cvUploadForceText}
            values={{
              link: (
                <Link to="/profile">
                  <FormattedMessage {...messages.cvUploadForceLink} />
                </Link>
              ),
            }}
          />
        </div>
      </Container>
    </Alert>
  )
}

NoCvAlertComponent.propTypes = {
  cvIsUploaded: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
}
NoCvAlertComponent.defaultProps = {}

const mapStateToProps = function (state) {
  return {
    locale: localeSelector(state),
    cvIsUploaded: cvIsUploadedSelector(state),
    isComplete: isCompleteSelector(state),
  }
}

const mapDispatchToProps = {}

export const NoCvAlert = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoCvAlertComponent)
