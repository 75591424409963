import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { FormGroup, PopoverBody } from "reactstrap"
import { CAREER_LEVELS } from "../../../../default-options"
import { InfoHint } from "../../../common/info-hint-component"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { Select } from "../../../common/select-component"

import styles from "./career-level-section.module.scss"

export const CareerLevelSection = ({ input }) => {
  const { value: careerLevel, onChange } = input

  useEffect(() => {
    onChange(careerLevel || 0)
  }, [careerLevel, onChange])

  const levelChangeHandler = value => {
    onChange(value.id)
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.text" />
          </span>
        }
        isRequired
        hint={
          <InfoHint
            id="jobLevelHint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.title" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.student" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.student.desc" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.entry" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.entry.desc" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.intermediate" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.intermediate.desc" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.senior" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.senior.desc" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.lead" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.lead.desc" />
                <br />
                <b>
                  <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.executive" />
                </b>
                <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.hint.executive.desc" />
                <br />
              </PopoverBody>
            }
          />
        }
      />

      <div className={styles.inputWrapper}>
        <Select
          id="career-level"
          placeholder={
            <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.placeholder" />
          }
          options={CAREER_LEVELS}
          getOptionValue={option => option.id}
          getOptionLabel={option => <FormattedMessage {...option.name} />}
          backspaceRemovesValue={false}
          input={{
            onChange: newValue => {
              levelChangeHandler(newValue)
            },
            value: CAREER_LEVELS.find(l => l.id === careerLevel) || "",
          }}
          wrapper="div"
          isSearchable={false}
          withCaret
          meta={{}}
        />
      </div>
    </FormGroup>
  )
}

CareerLevelSection.propTypes = {
  input: PropTypes.object,
}
