import React from "react"
import PropTypes from "prop-types"
import { ButtonDropdown } from "./button-dropdown-component"
import { useOpenClose } from "../../hooks/use-open-close"

export const UncontrolledButtonDropdown = props => {
  const { toggle, value: isOpen } = useOpenClose({ initialValue: false })
  return <ButtonDropdown {...props} isOpen={isOpen} toggle={toggle} />
}

UncontrolledButtonDropdown.propTypes = {
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(["up", "down", "left", "right"]),
  group: PropTypes.bool,
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
}
