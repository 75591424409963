import { map } from "ramda"

import {
  anonymizeCandidate,
  anonymizeJob,
  anonymizeMatch,
  anonymizeCompany,
  anonymizeMessage,
  anonymizeThread,
} from "./anonymizer"

// const actions = [
//   "LOGIN",
//   "SIGNUP",
//   "REFRESH_TOKEN",
//   "PASSWORD",
//   "CONNECT",
//   "@@redux-form"
// ]

export function actionSanitizer(action) {
  const data = { type: action.type }

  if (action && action.payload && action.payload.err) {
    data.payload = {
      err: action.payload.err,
    }
  }

  return data
}

export function stateSanitizer(state) {
  return {
    ...state,
    form: undefined,
    candidate: {
      ...state.candidate,
      entity: anonymizeCandidate(state.candidate.entity),
    },
    user: {
      ...state.user,
      token: undefined,
    },
    messages: {
      ...state.messages,
      entities: map(anonymizeMessage, state.messages.entities),
    },
    threads: {
      ...state.threads,
      entities: map(anonymizeThread, state.threads.entities),
    },
    jobs: {
      ...state.jobs,
      entities: map(anonymizeJob, state.jobs.entities),
    },
    companies: {
      ...state.companies,
      entities: map(anonymizeCompany, state.companies.entities),
    },
    matches: {
      ...state.matches,
      entities: map(anonymizeMatch, state.matches.entities),
    },
    intl: {
      ...state.intl,
      messages: undefined,
    },
  }
}

export function requestSanitizer(request) {
  if (request.headers["Authorization"]) {
    request.headers["Authorization"] = ""
  }

  return request
}

export function responseSanitizer(response) {
  if (response.headers["Authorization"]) {
    response.headers["Authorization"] = ""
  }

  return response
}
