import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { head } from "ramda"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { usePrevious } from "react-hanger"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { isLoggedSelector } from "../../selectors/candidate-selector"
import { api } from "../../lib/moberries-api"
import { FormattedRelative } from "./formatted-relative"

import { Button } from "./button-component"

export const ProductUpdatesComponent = ({ isLogged }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [updates, setUpdates] = useState([])

  const markUpdatesAsRead = async ({ id }) => {
    await api.markProductUpdateAsRead({ id })
  }

  useEffect(() => {
    async function fetchProductUpdates() {
      if (isLogged) {
        const { data } = await api.getProductUpdates()
        setUpdates(data.results)
      }
    }
    fetchProductUpdates()
  }, [isLogged])

  const update = head(updates)

  const onDismiss = async id => {
    setIsOpen(false)
    await markUpdatesAsRead({ id })
  }

  const prevUpdate = usePrevious(update)
  // In order for the modal to not be empty as it is being dismissed,
  // We keep the `prevUpdate` and display it
  const { title, releasedAt, image, video, text, id } =
    update || prevUpdate || {}

  useEffect(() => {
    if (id) {
      setIsOpen(true)
    }
  }, [id, setIsOpen])

  return (
    <Modal isOpen={isOpen} toggle={() => onDismiss(id)} unmountOnClose>
      <ModalHeader toggle={() => onDismiss(id)}>{title}</ModalHeader>
      <ModalBody>
        <p className="text-muted">
          {releasedAt && <FormattedRelative timestamp={releasedAt} />}
        </p>
        {image && (
          <div className="mb-4 text-center">
            <img className="img-fluid" src={image} alt="Update" />
          </div>
        )}
        {video && (
          <div className="mb-4 embed-responsive embed-responsive-16by9">
            <video
              src={video}
              title="Update"
              className="embed-responsive-item"
              controls
              allowFullScreen
            />
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onDismiss(id)}>
          <FormattedMessage id="app.common.ok" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ProductUpdatesComponent.propTypes = {
  isLogged: PropTypes.bool,
}

const mapStateToProps = function (state) {
  return {
    isLogged: isLoggedSelector(state),
  }
}

export const ProductUpdates = connect(mapStateToProps)(ProductUpdatesComponent)
