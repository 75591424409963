import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

const Left = ({ children }) => children
const Right = ({ children }) => children

export const DoubleEqualColumnLayout = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col className="p-0" lg="6">
          {React.Children.toArray(children).find(c => c.type === Left)}
        </Col>
        <Col className="p-0" lg="6">
          {React.Children.toArray(children).find(c => c.type === Right)}
        </Col>
      </Row>
    </Container>
  )
}

DoubleEqualColumnLayout.Right = Right
DoubleEqualColumnLayout.Left = Left

DoubleEqualColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

Left.propTypes = {
  children: PropTypes.node.isRequired,
}

Right.propTypes = {
  children: PropTypes.node.isRequired,
}
