import isLength from "validator/lib/isLength"
import isEmail from "validator/lib/isEmail"
import { defineMessages } from "react-intl"

const messages = defineMessages({
  valueRequired: { id: "app.validators.value.required" },
  emailRequired: { id: "app.validators.email.required" },
  maxLen: { id: "app.validators.length.max" },
  minLen: { id: "app.validators.length.min" },
})

export const emailValidator = values => {
  const errors = {}
  const maxEmailOptions = { max: 50 }

  if (!values.email) {
    errors.email = messages.valueRequired
  } else if (!isEmail(values.email)) {
    errors.email = messages.emailRequired
  } else if (!isLength(values.email, maxEmailOptions)) {
    errors.email = { ...messages.maxLen, values: maxEmailOptions }
  }

  return errors
}
