import React from "react"
import PropTypes from "prop-types"
import { uniq, without } from "ramda"
import { FormattedMessage } from "react-intl"
import { FormGroup } from "reactstrap"
import { JOB_TYPES } from "../../../../default-options"
import { ButtonGroup } from "../../../common/button-group/button-group"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"

import styles from "./employment-type-section.module.scss"

export const EmploymentTypeSection = ({ input, meta }) => {
  const { value: values, onChange } = input
  const { error, dirty, touched } = meta

  const EmploymentChangeHandler = value => {
    let updatedValues

    if (!values.includes(value)) {
      updatedValues = uniq([...values, value])
    } else {
      updatedValues = without([value], values)
    }

    onChange(updatedValues)
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.employmentType.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.employmentType.text" />
          </span>
        }
        isRequired
      />

      {error && (dirty || touched) && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}
      <ButtonGroup
        className={styles.buttons}
        buttons={JOB_TYPES.map(({ id, name }) => ({
          name: <FormattedMessage {...name} />,
          value: id,
          active: values.includes(id),
        }))}
        onChange={EmploymentChangeHandler}
      />
    </FormGroup>
  )
}

EmploymentTypeSection.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
}
