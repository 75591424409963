import React from "react"
import PropTypes from "prop-types"

export const JobControlsLayout = ({ left, right }) => {
  return (
    <div className="d-flex bg-light border border-left-0 border-right-0">
      <div className="flex-grow-1 w-50 py-2 pr-2 border-right text-right">
        {right}
      </div>
      <div className="flex-grow-1 w-50 py-2 pl-2">{left}</div>
    </div>
  )
}

JobControlsLayout.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
}

JobControlsLayout.defaultProps = {}
