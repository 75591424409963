import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

import { PersonalDetailsPage } from "../personal-details-page"
import { BottomPanel } from "../../components/common/bottom-panel/bottom-panel"
import { PreferencesForm } from "../../components/forms/preferences-form/preferences-form"
import { candidateValidator } from "../../validators/candidate-validator"

const preferenceFormName = "PreferenceForm"

const bottomPanelTabs = [
  {
    name: <FormattedMessage id="app.registration.tabs.professional.title" />,
    active: false,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.preferences.title" />,
    active: true,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.resume.title" />,
    active: false,
  },
]

export const EditPreferences = ({
  candidate,
  location,
  updating: isCandidateUpdating,
  updateCandidate,
  backRoute,
  onSaveRoute,
}) => {
  const preferenceSubmitHandler = value => {
    // send only those properties that are specified in PreferencesForm with references key
    const preparedCandidate = {
      jobPosition: value.jobPosition.filter(item => item.id > 0),
      careerLevel: value.careerLevel,
      jobTypes: value.jobTypes,
      availability: value.availability,
      salaryMin: value.salaryMin || 0,
      relocate: value.relocate,
      locations: value.locations,
      relocationCountries: value.relocationCountries,
      remote: value.remote,
      references: value.references,
    }
    updateCandidate({
      candidate: preparedCandidate,
      formName: preferenceFormName,
      routing: {
        pathname: onSaveRoute,
        search: location.search,
      },
    })
  }

  return (
    <>
      <PersonalDetailsPage formName="PersonalDetailsForm" onSubmit={() => {}} />

      <BottomPanel tabs={bottomPanelTabs}>
        <PreferencesForm
          form={preferenceFormName}
          validate={candidateValidator}
          initialValues={candidate}
          onSubmit={preferenceSubmitHandler}
          isUpdating={isCandidateUpdating}
          backRoute={backRoute}
        />
      </BottomPanel>
    </>
  )
}

EditPreferences.propTypes = {
  candidate: PropTypes.object,
  location: PropTypes.object,
  updateCandidate: PropTypes.func,
  updating: PropTypes.bool,
  backRoute: PropTypes.string,
  onSaveRoute: PropTypes.string,
}
