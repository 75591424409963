import React, { useEffect, useState } from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { Button } from "../common/button-component"
import { messageProviders } from "../../constants"

import styles from "./match-subscription-modal-component.module.scss"
import { compose } from "ramda"
import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { connect } from "react-redux"
import {
  closeSubscriptionModal,
  initiateSubscriptionVerification,
} from "../../actions/candidate-actions"
import {
  botSubscriptionsSelector,
  isRequestingForSubscriptionVerificationSelector,
  messageProviderVerificationSelector,
} from "../../selectors/candidate-selector"
import { capitalize } from "../../lib/helpers"

export const MatchSubscription = ({
  size = "lg",
  isOpen,
  isFromSignUpFlow,
  toggle,
  selectedProvider,

  botSubscriptions,

  initiateSubscriptionVerification,
  closeSubscriptionModal,
  isRequestingForSubscriptionVerification,
  messageProviderVerification,
}) => {
  // hooks
  const [activeProvider, setActiveProvider] = useState(selectedProvider)
  const [requested, setRequested] = useState(false)

  const hasSubscriptions = botSubscriptions.length > 0
  const subscriptionType = botSubscriptions[0]?.type || null

  useEffect(() => {
    if (activeProvider !== selectedProvider) {
      setActiveProvider(selectedProvider)
    }
    // eslint-disable-next-line
  }, [selectedProvider])

  // data and flags
  const subscribedProviders = botSubscriptions
    .filter(s => s.active)
    .map(s => s.type)

  const isAlreadySubscribed =
    activeProvider &&
    botSubscriptions
      .map(s => s.active && s.type)
      .filter(Boolean)
      .includes(activeProvider.toLowerCase())

  const activeProviderData = activeProvider
    ? messageProviders.find(p => p.name === activeProvider)
    : {}

  // handlers
  const handleProviderChoice = newProviderName => {
    setActiveProvider(newProviderName)
    setRequested(false)
  }

  const handleSubscription = activeProvider => {
    initiateSubscriptionVerification({
      messageProvider: activeProvider,
    })
    setRequested(true)
  }

  const onClose = () => {
    setRequested(false)
    closeSubscriptionModal()
    toggle()
  }

  const getBotUrl = code =>
    !activeProviderData?.link || !code
      ? ""
      : activeProviderData.link.replace("<code>", code)

  if (messageProviderVerification?.verificationCode && requested) {
    onClose()
    window.open(
      getBotUrl(messageProviderVerification.verificationCode),
      "_blank",
    )
  }

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={onClose}
      contentClassName={styles.modalContent}
      size={size}
    >
      <ModalHeader className={styles.modalHeader} tag="div" toggle={onClose}>
        <h5>
          <FormattedMessage
            id={
              isFromSignUpFlow
                ? "app.settings.subscription.modal.title"
                : hasSubscriptions
                ? "app.settings.subscription.modal.subTitle.single"
                : "app.settings.subscription.modal.subTitle"
            }
            values={{
              botName: capitalize(subscriptionType),
            }}
          />
        </h5>
        {isFromSignUpFlow && (
          <p>
            <FormattedMessage id="app.settings.subscription.modal.subTitle" />
          </p>
        )}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center">
          {messageProviders
            .filter(provider => {
              if (!hasSubscriptions) return true

              return provider.name === capitalize(subscriptionType)
            })
            .map((provider, index) => (
              <Button
                key={provider.name}
                onClick={() => handleProviderChoice(provider.name)}
                color="light"
                className={cn({
                  "mr-2": index !== messageProviders.length - 1,
                })}
                active={provider.name === activeProvider}
              >
                <div className={cn(styles.provider, {})}>
                  <div className={styles.provider__icon}>
                    <img src={provider.icon} alt={provider.name} width={56} />
                  </div>
                  <h6>{provider.name}</h6>
                  {subscribedProviders.includes(
                    provider.name.toLowerCase(),
                  ) && (
                    <div className={styles.provider__label}>
                      <FormattedMessage id="app.common.subscribed" />
                    </div>
                  )}
                </div>
              </Button>
            ))}
        </div>
        <div className={styles.subscriptionContent}>
          {activeProvider && (
            <Button
              onClick={() => handleSubscription(activeProvider)}
              color="dark"
              disabled={
                isRequestingForSubscriptionVerification || isAlreadySubscribed
              }
              loading={isRequestingForSubscriptionVerification}
            >
              <FormattedMessage
                id={
                  isAlreadySubscribed
                    ? "app.common.subscribed"
                    : "app.common.subscribeTo"
                }
                values={{ provider: activeProvider }}
              />
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

MatchSubscription.propTypes = {
  isOpen: PropTypes.bool,
  isFromSignUpFlow: PropTypes.bool,
  toggle: PropTypes.func,
  size: PropTypes.string,
  selectedProvider: PropTypes.string,

  botSubscriptions: PropTypes.array.isRequired,

  initiateSubscriptionVerification: PropTypes.func.isRequired,
  closeSubscriptionModal: PropTypes.func.isRequired,
  isRequestingForSubscriptionVerification: PropTypes.bool,
  messageProviderVerification: PropTypes.object,
}

const mapDispatchToProps = {
  initiateSubscriptionVerification,
  closeSubscriptionModal,
}

const mapStateToProps = function (state) {
  return {
    isRequestingForSubscriptionVerification: isRequestingForSubscriptionVerificationSelector(
      state,
    ),
    messageProviderVerification: messageProviderVerificationSelector(state),
    botSubscriptions: botSubscriptionsSelector(state),
  }
}

export const MatchSubscriptionModal = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(MatchSubscription)
