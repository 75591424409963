import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { Button } from "../common/button-component"
import {
  connectingFacebookSelector,
  connectingLinkedinSelector,
} from "../../selectors/user-selector"
import { useSelector } from "react-redux"
import { creatingSelector } from "../../selectors/candidate-selector"

import styles from "./social-auth-component.module.scss"

export const SocialAuth = ({
  title,
  withFacebook,
  onFacebookClick,
  onLinkedinClick,
}) => {
  const isConnectingLinkedin = useSelector(connectingLinkedinSelector)
  const isConnectingFacebook = useSelector(connectingFacebookSelector)
  const isCreatingCandidate = useSelector(creatingSelector)
  const isDisabled =
    isConnectingFacebook || isConnectingLinkedin || isCreatingCandidate

  return (
    <div className={styles.container}>
      {title && <div className="pb-2">{title}</div>}

      {withFacebook && (
        <Button
          className={styles.btnFacebook}
          disabled={isDisabled}
          loading={isConnectingFacebook}
          block
          color="facebook"
          size="lg"
          onClick={onFacebookClick}
        >
          <i className={cn("fab fa-facebook-f", styles.iconFacebook)} />
          <span>Facebook</span>
        </Button>
      )}

      <Button
        className={styles.btnLinkedin}
        disabled={isDisabled}
        loading={isConnectingLinkedin}
        block
        color="linkedin"
        size="lg"
        onClick={onLinkedinClick}
      >
        <i className={cn("fab fa-linkedin-in", styles.iconLinkedin)} />
        <span>Linkedin</span>
      </Button>
    </div>
  )
}

SocialAuth.propTypes = {
  title: PropTypes.node,
  withFacebook: PropTypes.bool.isRequired,
  onFacebookClick: PropTypes.func,
  onLinkedinClick: PropTypes.func,
}

SocialAuth.defaultProps = {
  isDisabled: false,
  withFacebook: false,
}
