import React from "react"
import PropTypes from "prop-types"
import { ButtonDropdown as BButtonDropdown } from "reactstrap"
export function ButtonDropdown(props) {
  const { children, ...rest } = props

  return <BButtonDropdown {...rest}>{children}</BButtonDropdown>
}

ButtonDropdown.propTypes = {
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(["up", "down", "left", "right"]),
  group: PropTypes.bool,
  isOpen: PropTypes.bool,
  tag: PropTypes.string,
  toggle: PropTypes.func,
  children: PropTypes.node.isRequired,
}
