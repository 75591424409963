import React, { Component } from "react"
import PropTypes from "prop-types"
import defaultImage from "../../assets/img/default-logo.png"

export class Img extends Component {
  static propTypes = {
    src: PropTypes.string,
    defaultImage: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }

  static defaultProps = {
    defaultImage: defaultImage,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.prevSrc !== nextProps.src) {
      return { prevSrc: nextProps.src, loadingError: false }
    }

    return null
  }

  state = {
    loadingError: false,
    prevSrc: null,
  }

  render() {
    const {
      src,
      alt,
      className,
      width,
      height,
      defaultImage,
      ...rest
    } = this.props

    const { loadingError } = this.state

    let image = src

    if (!src || loadingError) {
      image = defaultImage
    }

    return (
      <img
        onError={this.onLoadFail}
        alt={alt}
        src={image}
        className={className}
        width={width}
        height={height}
        {...rest}
      />
    )
  }

  onLoadFail = () => {
    this.setState({ loadingError: true })
  }
}
