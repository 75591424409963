import React from "react"
import PropTypes from "prop-types"
import {
  Input as Binput,
  FormGroup,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"
import FormFeedback from "./form-feedback"
import { LabelWithHint } from "./label-with-hint-component"

export function Input(props) {
  const {
    input,
    meta,
    options,
    label,
    underInput,
    underFeedback,
    underLabel,
    hint,
    inputClassName,
    className,
    appendAddon,
    isRequired,
    ...rest
  } = props

  const { error, valid, invalid, touched } = meta
  return (
    <FormGroup className={className}>
      <LabelWithHint
        label={label}
        hint={hint}
        for={rest.id}
        isRequired={isRequired}
      />
      {underLabel}
      {appendAddon ? (
        <InputGroup>
          <Binput
            {...rest}
            {...input}
            className={inputClassName}
            valid={!touched || valid ? null : valid}
            invalid={!touched || valid ? null : invalid}
          />
          <InputGroupAddon addonType="append">{appendAddon}</InputGroupAddon>
          <FormFeedback error={error} />
        </InputGroup>
      ) : (
        <Binput
          {...rest}
          {...input}
          className={inputClassName}
          valid={!touched || valid ? null : valid}
          invalid={!touched || valid ? null : invalid}
        />
      )}
      {underInput}
      {!appendAddon && <FormFeedback error={error} />}
      {underFeedback}
    </FormGroup>
  )
}

Input.propTypes = {
  label: PropTypes.node,
  hint: PropTypes.node,
  underInput: PropTypes.node,
  underFeedback: PropTypes.node,
  underLabel: PropTypes.node,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  appendAddon: PropTypes.node,
  isRequired: PropTypes.bool,
}

Input.defaultProps = {
  type: "text",
  appendAddon: false,
  isRequired: false,
}
