import * as Sentry from "@sentry/react"
import { LOGOUT, SUCCESS } from "../constants"
import { getActionType, getAnonymousEmail } from "../lib/helpers"
import { idSelector } from "../selectors/candidate-selector"

let isUserSet = false
export const sentryUserMiddleware = ({ getState }) => next => action => {
  if (action.type === getActionType(LOGOUT, SUCCESS)) {
    Sentry.configureScope(scope => scope.setUser(null))
    isUserSet = false
    return next(action)
  }

  const candidateId = idSelector(getState())
  if (candidateId && !isUserSet) {
    Sentry.setUser({ email: getAnonymousEmail(candidateId), id: candidateId })
    isUserSet = true
  }

  next(action)
}
