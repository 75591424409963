import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { Button } from "../common/button-component"
import { Input } from "../common/input-component"
import { Form } from "reactstrap"

export function DoublePassword(props) {
  const { submitting, handleSubmit } = props
  const { buttonLabel, firstLabel, secondLabel, disabled, overInput } = props

  return (
    <Form onSubmit={handleSubmit}>
      {overInput}
      <Field
        component={Input}
        label={firstLabel}
        className="_lr-hide"
        name="password"
        type="password"
        id="password"
        placeholder="********"
        isRequired
      />
      <Field
        component={Input}
        label={secondLabel}
        className="_lr-hide"
        name="password2"
        type="password"
        id="password2"
        placeholder="********"
        isRequired
      />
      <Button
        disabled={disabled || submitting}
        loading={submitting}
        block
        color="primary"
        type="submit"
        size="lg"
      >
        {buttonLabel}
      </Button>
    </Form>
  )
}

DoublePassword.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.node.isRequired,
  firstLabel: PropTypes.node,
  secondLabel: PropTypes.node,
  overInput: PropTypes.node,
}

DoublePassword.defaultProps = {
  buttonLabel: "Send",
  disabled: false,
  overInput: null,
}

export const DoublePasswordForm = reduxForm({
  initialValues: {
    password: "",
    password2: "",
  },
})(DoublePassword)
