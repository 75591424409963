import qs from "qs"
import { config } from "../../config"
import cookie from "js-cookie"
import { always, curry, gte, ifElse, is, isNil, prop } from "ramda"

export function createWebsiteString(website) {
  if (!website) return ""
  try {
    const websiteName = new URL(website)
    return websiteName.hostname
  } catch (err) {
    return website
  }
}

export function updateQueryParams({ search, params = {} }) {
  const query = qs.parse(search, { ignoreQueryPrefix: true })

  Object.entries(params).forEach(([key, value]) => {
    if (value === null) {
      delete query[key]
    } else {
      query[key] = value
    }
  })

  return qs.stringify(query, { addQueryPrefix: true })
}

export function generateInviteURL(userId) {
  const value = `${config.baseUrl}${userId ? `?ref=u-${userId}` : ""}`
  return value
}

export function getAnonymousEmail(id) {
  return `${id}@moberries.com`
}

export function getParamsFromCookies() {
  const { keys } = config.cookie

  const paramNames = [
    keys.utmSource,
    keys.utmMedium,
    keys.utmCampaign,
    keys.utmTerm,
    keys.utmContent,
    keys.jgClickId,
  ]

  const params = paramNames.reduce((params, name) => {
    const param = cookie.get(name)
    if (param) {
      params[name] = param
    }

    return params
  }, {})

  if (Object.keys(params).length < 1) {
    return null
  }

  if (params[keys.utmSource] === "jobg8" && params[keys.jgClickId]) {
    params[keys.utmContent] = params[keys.jgClickId]
    delete params[keys.jgClickId]
  }

  return params
}

export const isWithin = curry((min, max, value) => {
  const isNumber = is(Number)
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  )
})

export const in200s = isWithin(200, 299)
export const in400s = isWithin(400, 499)
export const in500s = isWithin(500, 599)
export const statusNil = ifElse(isNil, always(undefined), prop("status"))

export const linebreakToBr = str => str?.replace(/\r\n|\r|\n/g, "<br />")
