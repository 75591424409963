import { all, select, call, takeLatest } from "redux-saga/effects"
import LogRocket from "logrocket"
import * as Sentry from "@sentry/react"
import { logrocketSelector } from "../selectors/system-selector"
import { config } from "../config"
import {
  candidateSelector,
  trackingEnabledSelector,
} from "../selectors/candidate-selector"
import {
  shouldInitTracker,
  getActionType,
  getAnonymousEmail,
} from "../lib/helpers"
import {
  responseSanitizer,
  requestSanitizer,
} from "../lib/logrocket-sanitizers"
import {
  CANDIDATE,
  CONFIRM,
  CONNECT,
  EMAIL,
  FACEBOOK,
  GET,
  LINKEDIN,
  LOGIN,
  SUCCESS,
  ACTIVATE,
  TRACKING,
  SETTINGS,
  LOGROCKET,
  ON,
} from "../constants"

let initialized = false

export function* logrocketSaga() {
  const candidate = yield select(candidateSelector)

  if (candidate && !initialized) {
    const trackingEnabled = yield select(trackingEnabledSelector)
    const logrocketEnabled = yield select(logrocketSelector)

    if (trackingEnabled && shouldInitTracker(initialized) && logrocketEnabled) {
      LogRocket.init(config.logrocket.url, {
        ...config.logrocket.options,
        network: {
          requestSanitizer,
          responseSanitizer,
        },
      })

      initialized = true

      LogRocket.identify(candidate.id, {
        email: getAnonymousEmail(candidate.id),
        id: candidate.id,
      })

      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setContext("logrocket", { sessionURL })
        })
      })
    }
  }
}

export const saga = function* () {
  if (config.branch.isRelease) {
    yield call(logrocketSaga)
    yield all([
      takeLatest(
        [
          getActionType(GET, CANDIDATE, SUCCESS),
          getActionType(LOGIN, SUCCESS),
          getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS),
          getActionType(CONFIRM, EMAIL, SUCCESS),
          getActionType(CONNECT, FACEBOOK, SUCCESS),
          getActionType(CONNECT, LINKEDIN, SUCCESS),
          getActionType(LOGROCKET, ON),
        ],
        logrocketSaga,
      ),
    ])
  }
}
