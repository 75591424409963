import React from "react"
import { defineMessages, FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { Button } from "../common/button-component"

const messages = defineMessages({
  reportHireDescription: { id: "app.sidebar.reportHire.description" },
  reportHire: { id: "app.sidebar.reportHire.button" },
})

export function ReportHire(props) {
  return (
    <div className="sidebar-widget bg-white mb-3 p-3 rounded d-flex align-items-center">
      <div className="mr-3">
        <span className="fa-stack fa-3x">
          <i className="fas fa-circle fa-stack-2x text-light" />
          <i className="fad fa-handshake fa-stack-1x" />
        </span>
      </div>
      <div>
        <p className="mb-2">
          <FormattedMessage {...messages.reportHireDescription} />
        </p>
        <Button tag={Link} color="light" to="/applications">
          <FormattedMessage {...messages.reportHire} />
        </Button>
      </div>
    </div>
  )
}

ReportHire.propTypes = {}

ReportHire.defaultProps = {}
