import React, { useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { useDropzone } from "react-dropzone"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"

import { uploadCandidateCv } from "../../../../actions/candidate-actions"
import { config } from "../../../../config"
import {
  candidateSelector,
  cvStatusSelector,
  cvTypeSelector,
  cvUpdatingSelector,
  cvUrlsSelector,
} from "../../../../selectors/candidate-selector"
import { LoaderLogo } from "../../../common/loader-logo"
import {
  ALERT_COLORS,
  CV_STATUSES,
  CV_TYPES,
  FA_ICONS,
} from "../../../../constants"
import { alertTypes, showAlert } from "../../../../lib/alerts"
import { useOpenClose } from "../../../../hooks/use-open-close"
import { ResumePreviewModal } from "../resume-preview-modal/resume-preview-modal"

import styles from "./resume-uploader.module.scss"
import { links } from "../../../../lib/external-links"
import { localeSelector } from "../../../../selectors/intl-selector"

const dropHandler = ([file], [reject], upload) => {
  let code = null

  if (reject) {
    code = alertTypes.defaultError

    if (reject.size >= config.cv.limit) {
      code = alertTypes.cvSizeError
    }

    if (!config.cv.acceptedTypes.includes(reject.type)) {
      code = alertTypes.cvFormatError
    }
  }

  if (!code && !file) {
    code = alertTypes.defaultError
  }

  if (code) {
    return showAlert({
      color: ALERT_COLORS.ERROR,
      code,
    })
  }

  upload({ data: file, cvType: CV_TYPES.UPLOAD })
}

const ResumeUploaderComponent = ({
  candidate,
  urls,
  cvStatus,
  isUploading,
  uploadCandidateCv,
  cvType,
  locale,
}) => {
  const [isInitUpload, setIsInitUpload] = useState(false)

  const {
    value: previewIsOpen,
    open: openPreview,
    close: closePreview,
  } = useOpenClose({ initialValue: false })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: ([file], [reject]) => {
      setIsInitUpload(true)
      dropHandler([file], [reject], uploadCandidateCv)
    },
    accept: config.cv.acceptedTypes,
    multiple: false,
    maxSize: config.cv.limit,
    disabled: isUploading,
  })

  const protectedCvType = cvType || candidate.candidateCv?.cvType
  const isCorrectCVType = protectedCvType === CV_TYPES.UPLOAD

  const shouldDisplayLoader = isInitUpload && isCorrectCVType && isUploading
  const shouldDisplaySuccessMessage =
    isInitUpload &&
    !isUploading &&
    isCorrectCVType &&
    cvStatus === CV_STATUSES.SUCCESS
  const shouldDisplayPreview =
    !isUploading && isCorrectCVType && cvStatus === CV_STATUSES.SUCCESS
  const shouldDisplayErrorMessage =
    isCorrectCVType && !isUploading && cvStatus === CV_STATUSES.ERROR
  const shouldDisplayProcessingMessage =
    !isUploading && isCorrectCVType && cvStatus === CV_STATUSES.PROCESSING

  return (
    <>
      <div {...getRootProps({ className: styles.control })}>
        <i className={cn(styles.control__icon, FA_ICONS.CLOUD_UPLOAD)} />
        <span className={styles.control__text}>
          <FormattedMessage id="app.registration.tabs.resume.upload.process.init" />
        </span>
        <input {...getInputProps()} />
      </div>
      <div className={styles.results}>
        {shouldDisplayLoader && <LoaderLogo />}
        {shouldDisplayProcessingMessage && (
          <p className={cn(styles.results__text, styles.results__text_success)}>
            <FormattedMessage id="app.registration.tabs.resume.upload.process.processing" />
          </p>
        )}
        {shouldDisplaySuccessMessage && (
          <p className={cn(styles.results__text, styles.results__text_success)}>
            <FormattedMessage id="app.registration.tabs.resume.upload.process.success" />
          </p>
        )}
        {shouldDisplayErrorMessage && (
          <p className={cn(styles.results__text, styles.results__text_error)}>
            <FormattedMessage
              id="app.registration.tabs.resume.upload.process.error"
              values={{
                a: (...children) => (
                  <a href={links[locale].support}>{children}</a>
                ),
              }}
            />
          </p>
        )}
        {shouldDisplayPreview && (
          <button className={styles.previewBtn} onClick={openPreview}>
            <i className={cn(styles.results__icon, FA_ICONS.FILE_PDF)} />
            <p className={styles.results__label}>
              <FormattedMessage id="app.registration.tabs.resume.file.preview" />
            </p>
          </button>
        )}
      </div>

      <ResumePreviewModal
        isOpen={previewIsOpen}
        close={closePreview}
        urls={urls}
      />
    </>
  )
}

ResumeUploaderComponent.propTypes = {
  candidate: PropTypes.object.isRequired,
  urls: PropTypes.array.isRequired,
  cvStatus: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired,
  uploadCandidateCv: PropTypes.func.isRequired,
  cvType: PropTypes.string,
  locale: PropTypes.string,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
  isUploading: cvUpdatingSelector(state),
  urls: cvUrlsSelector(state).map(u => ({ src: u, caption: "" })),
  cvStatus: cvStatusSelector(state),
  cvType: cvTypeSelector(state),
  locale: localeSelector(state),
})

const mapDispatchToProps = {
  uploadCandidateCv,
}

export const ResumeUploader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResumeUploaderComponent)
