import React from "react"
import PropTypes from "prop-types"
import { reduxForm, Field, propTypes } from "redux-form"
import { defineMessages, FormattedMessage } from "react-intl"
import { Form } from "reactstrap"

import { Button } from "../../components/common/button-component"
import { Input } from "../../components/common/input-component"

import styles from "./login-form.module.scss"

const messages = defineMessages({
  email: { id: "app.common.email" },
  password: { id: "app.common.password" },
})

export function Login({
  invalid,
  error,
  pristine,
  submitting,
  handleSubmit,
  buttonLabel,
  underPassword,
  disabled,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={Input}
        label={
          <span>
            <FormattedMessage {...messages.email} />
          </span>
        }
        className={styles.label}
        name="email"
        type="email"
        id="email"
      />
      <Field
        component={Input}
        label={
          <span>
            <FormattedMessage {...messages.password} />
          </span>
        }
        className={styles.label}
        name="password"
        type="password"
        id="password"
        underFeedback={underPassword}
      />
      <Button
        className={styles.submit}
        disabled={disabled || submitting || pristine || (invalid && !error)}
        loading={submitting}
        block
        color="secondary"
        type="submit"
        size="lg"
      >
        {buttonLabel}
      </Button>
    </Form>
  )
}

Login.propTypes = {
  ...propTypes,
  disabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.node.isRequired,
  underPassword: PropTypes.node,
}

Login.defaultProps = {
  buttonLabel: "Login",
  disabled: false,
  underPassword: null,
}

export const LoginForm = reduxForm({
  initialValues: {
    email: "",
    password: "",
  },
})(Login)
