import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Button } from "./button-component"
import {
  activeSelector,
  matchingActivatingSelector,
  matchingActivationRequestCancelledSelector,
  isLoggedSelector,
  isCompleteSelector,
  cvStatusSelector,
} from "../../selectors/candidate-selector"
import {
  activateMatching,
  setMatchingActivationRequestCancelled,
} from "../../actions/candidate-actions"
import { CV_STATUSES } from "../../constants"

const messages = defineMessages({
  modalTitle: { id: "app.common.inactive.matching.reminder.title" },
  modalBody: { id: "app.common.inactive.matching.reminder.body" },
  activateButton: {
    id: "app.common.inactive.matching.reminder.button.activate",
  },
  cancelButton: { id: "app.common.inactive.matching.reminder.button.cancel" },
})

class InactiveMatchingReminderComponent extends React.Component {
  static propTypes = {
    activateMatching: PropTypes.func.isRequired,
    setMatchingActivationRequestCancelled: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    matchingActivating: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
  }

  render() {
    const {
      activateMatching,
      setMatchingActivationRequestCancelled,
      isModalOpen,
      isLogged,
      isComplete,
      matchingActivating,
    } = this.props

    if (!isLogged || !isComplete) return null

    return (
      <Fragment>
        <Modal
          isOpen={isModalOpen}
          toggle={() =>
            setMatchingActivationRequestCancelled({ newStatus: true })
          }
          centered
        >
          <ModalHeader>
            <FormattedMessage {...messages.modalTitle} />
          </ModalHeader>
          <ModalBody>
            <FormattedMessage {...messages.modalBody} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              onClick={() =>
                setMatchingActivationRequestCancelled({ newStatus: true })
              }
            >
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              loading={matchingActivating}
              disabled={matchingActivating}
              onClick={activateMatching}
              color="primary"
            >
              <FormattedMessage {...messages.activateButton} />
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}
const mapDispatchToProps = {
  activateMatching,
  setMatchingActivationRequestCancelled,
}

const mapStateToProps = function (state) {
  const active = activeSelector(state)
  const isLogged = isLoggedSelector(state)
  const isComplete = isCompleteSelector(state)
  const matchingActivationRequestCancelled = matchingActivationRequestCancelledSelector(
    state,
  )
  const cvStatus = cvStatusSelector(state)

  return {
    isLogged,
    isComplete,
    active,
    matchingActivationRequestCancelled,
    isModalOpen:
      active === false &&
      cvStatus !== CV_STATUSES.ERROR &&
      matchingActivationRequestCancelled === false,
    matchingActivating: matchingActivatingSelector(state),
  }
}

export const InactiveMatchingReminder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InactiveMatchingReminderComponent)
