import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { lastSupportedVersionSelector } from "../../selectors/system-selector"
import { FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import semverCompare from "semver-compare"
import { config } from "../../config"
import { useCountdown } from "../../hooks/use-countdown"
import { randomInteger } from "../../lib/helpers"
import { useOpenClose } from "../../hooks/use-open-close"

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000

export const VersionUpdatePromptComponent = ({ lastSupportedVersion }) => {
  const [delayToReload] = useState(() => randomInteger(1, 12))
  const { value: isDismissed, open: dismiss, close: undismiss } = useOpenClose({
    initialValue: false,
  })
  const { secondsLeft, start, isRunning } = useCountdown({
    seconds: delayToReload,
    onFinish: () => {
      window.location.reload()
    },
  })
  const timeoutRef = useRef()
  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current)
    },
    [],
  )

  if (
    semverCompare(lastSupportedVersion, config.package.version) <= 0 ||
    isDismissed
  ) {
    return null
  }

  return (
    <div className="version-update-prompt">
      <div className="border-bottom border-light mb-1 p-2 d-flex justify-content-between">
        <h6 className="mb-0">
          <FormattedMessage id="app.updatePrompt.header" />
        </h6>
        <Button
          size="sm"
          className="text-right py-0 px-1 text-body miw-0"
          color="link"
          onClick={() => {
            dismiss()
            timeoutRef.current = setTimeout(undismiss, THIRTY_MINUTES_IN_MS)
          }}
        >
          <i className="fa fa-times" />
        </Button>
      </div>
      <div className="px-2 pb-2">
        {isRunning ? (
          <FormattedMessage
            id="app.updatePrompt.countdown"
            values={{ seconds: secondsLeft }}
          />
        ) : (
          <FormattedMessage id="app.updatePrompt.text" />
        )}
      </div>
      <div className="px-2 pb-2">
        <Button color="primary" disabled={isRunning} onClick={start}>
          <FormattedMessage id="app.updatePrompt.button" />
        </Button>
      </div>
    </div>
  )
}

VersionUpdatePromptComponent.propTypes = {
  lastSupportedVersion: PropTypes.string,
}

const mapStateToProps = state => ({
  lastSupportedVersion: lastSupportedVersionSelector(state),
})

export const VersionUpdatePrompt = connect(mapStateToProps)(
  VersionUpdatePromptComponent,
)
