import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormGroup, Input, PopoverBody } from "reactstrap"
import { FormattedMessage, useIntl } from "react-intl"

import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { v4 as generateFakeId } from "uuid"
import { InfoHint } from "../../../common/info-hint-component"

import styles from "./references-section.module.scss"

export const ReferencesSection = ({ input, meta }) => {
  const { value: values, onChange } = input
  const { error, dirty } = meta
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [localValues, setLocalValues] = useState(
    values && values.length
      ? values.map(v => ({ value: v, fakeId: generateFakeId() }))
      : [{ fakeId: generateFakeId(), value: "" }],
  )
  const [reference, setReference] = useState(null)
  const [referenceIdx, setReferenceIdx] = useState(null)
  const intl = useIntl()

  const addItemHandler = () => {
    onChange(["", ...values])
    setLocalValues([{ fakeId: generateFakeId(), value: "" }, ...localValues])
  }

  const removeItemHandler = i => {
    const references = [...values]
    const value = localValues.filter((_, idx) => idx !== i)

    if (value?.length) {
      setLocalValues(value)
      onChange(references.filter((_, idx) => idx !== i))
    }
  }

  const updateInput = value => {
    const references = [...values]
    references[referenceIdx] = value
    onChange(references)
    setLocalValues(
      references.map(v => ({ value: v, fakeId: generateFakeId() })),
    )
    setReference(null)
    setReferenceIdx(null)
    setButtonDisabled(false)
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.portfolio.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.portfolio.text" />
          </span>
        }
        hint={
          <InfoHint
            id="referencesHint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.registration.tabs.professional.input.portfolio.hint" />
              </PopoverBody>
            }
          />
        }
      />

      {error && dirty && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      {localValues.map((item, i) => {
        const hasValue = item.value || reference
        const isLtMaxLength = localValues.length < 3
        const isFirstInput = i === 0

        const canAdd = isFirstInput && hasValue && isLtMaxLength && !error

        return (
          <div key={item.fakeId} className={styles.formItem}>
            <Input
              className={styles.input}
              placeholder={intl.formatMessage({
                id:
                  "app.registration.tabs.professional.input.portfolio.placeholder",
              })}
              value={i === referenceIdx ? reference : item.value}
              onFocus={e => {
                setReference(e.target.value)
                setReferenceIdx(i)
                setButtonDisabled(true)
              }}
              onChange={e => setReference(e.target.value)}
              onBlur={e => updateInput(e.target.value, i)}
            />
            {canAdd ? (
              <ButtonWithIcon
                disabled={buttonDisabled}
                iconName="plus"
                onClick={addItemHandler}
              />
            ) : (
              <ButtonWithIcon
                disabled={buttonDisabled}
                iconName="cross"
                onClick={() => removeItemHandler(i)}
              />
            )}
          </div>
        )
      })}
    </FormGroup>
  )
}

ReferencesSection.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
}
