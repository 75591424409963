import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { noAuthRequired } from "../decorators/no-auth-required"
import { confirmEmailWithCode } from "../actions/user-actions"

export class ConfirmEmail extends Component {
  static propTypes = {
    confirmEmailWithCode: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { match, confirmEmailWithCode } = this.props
    confirmEmailWithCode({ code: match.params.code })
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { confirmEmailWithCode }

const mapStateToProps = function (state) {
  return {}
}

export const ConfirmEmailPage = compose(
  noAuthRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmEmail)
