import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"

export const SingleColumnLayout = props => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl="10">{props.children}</Col>
      </Row>
    </Container>
  )
}

SingleColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
