import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { SignupInfoSection } from "../../components/signup/signup-info-section-component/signup-info-section-component"

import clock from "../../assets/img/clock.svg"
import money from "../../assets/img/euro-money.png"
import feedback from "../../assets/img/goodnotes.svg"

export const SignupApplyInfoSection = ({ job }) => {
  const infoSectionProps = {
    header: {
      id: "app.apply.title",
    },
    subheader: {
      id: "app.signup.title.application",
      values: {
        job: <Link to={`/job/${job.id}`}>{job.title}</Link>,
        company: (
          <Link to={`/company/${job.company.id}`}>{job.company.name}</Link>
        ),
      },
    },
    benefits: {
      header: null,
      items: [
        {
          icon: {
            src: clock,
            alt: "Clock",
          },
          title: {
            id: "app.signup.infoSection.save.title",
          },
          subtitle: {
            id: "app.signup.infoSection.save.subtitle",
          },
        },
        {
          icon: {
            src: feedback,
            alt: "Feedback",
          },
          title: {
            id: "app.signup.infoSection.feedback.title",
          },
          subtitle: {
            id: "app.signup.infoSection.feedback.subtitle",
          },
        },
        {
          icon: {
            src: money,
            alt: "Money",
          },
          title: {
            id: "app.signup.infoSection.free.title",
          },
          subtitle: {
            id: "app.signup.infoSection.free.subtitle",
          },
        },
      ],
    },
  }

  return <SignupInfoSection {...infoSectionProps} />
}

SignupApplyInfoSection.propTypes = {
  job: PropTypes.object.isRequired,
}
