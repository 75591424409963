import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"
import { usePrevious } from "react-hanger"

const messages = defineMessages({
  confirmDiscardTitle: { id: "app.job.confirm.discard.title" },
  confirmDiscardBody: { id: "app.job.confirm.discard.body" },
  confirm: { id: "app.common.confirm" },
  cancel: { id: "app.common.cancel" },
})

export const JobDiscardConfirmationModal = ({
  isOpen,
  onClose,
  isDiscarding,
  isDisabled,
  onDiscard,
}) => {
  const prevIsDiscarding = usePrevious(isDiscarding)
  if (prevIsDiscarding && !isDiscarding && isOpen) {
    onClose()
  }
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        <FormattedMessage {...messages.confirmDiscardTitle} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...messages.confirmDiscardBody} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" disabled={isDisabled} onClick={onClose}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          color="primary"
          loading={isDiscarding}
          disabled={isDisabled}
          onClick={onDiscard}
        >
          <FormattedMessage {...messages.confirm} />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

JobDiscardConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDiscarding: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func.isRequired,
}

JobDiscardConfirmationModal.defaultProps = {
  isOpen: false,
  isDiscarding: false,
  isDisabled: false,
}
