import React from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import {
  shareJobOnLinkedin,
  shareJobOnFacebook,
  shareJobOnTwitter,
} from "../../lib/helpers"
import { Button } from "../common/button-component"
import { JobInvitationModal } from "../job/job-invitation-modal"
import { useOpenClose } from "../../hooks/use-open-close"

const messages = defineMessages({
  share: { id: "app.job.share" },
  invite: { id: "app.job.sidebar.button.invite" },
})

export const JobShare = ({ job, isLogged }) => {
  const {
    jobId,
    jobTitle,
    company: { name: companyName },
  } = job
  const {
    value: isJobInvitationModalOpen,
    open: openJobInvitationModal,
    close: closeJobInvitationModal,
  } = useOpenClose({ initialValue: false })
  return (
    <div className="p-3 bg-white rounded">
      <JobInvitationModal
        job={job}
        isOpen={isJobInvitationModalOpen}
        onClose={closeJobInvitationModal}
      />
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-share-alt" />
        </div>
        <FormattedMessage {...messages.share} />
      </h5>
      <div className="pt-3 border-top text-center">
        <Button
          className="rounded-circle p-3 btn-circle mr-2"
          onClick={() => shareJobOnLinkedin({ jobId })}
          color="linkedin"
        >
          <i className="fab fa-linkedin-in" />
        </Button>
        <Button
          className="rounded-circle p-3 btn-circle mr-2"
          onClick={() => shareJobOnFacebook({ jobId })}
          color="facebook"
        >
          <i className="fab fa-facebook-f" />
        </Button>
        <Button
          className="rounded-circle p-3 btn-circle"
          onClick={() => shareJobOnTwitter({ jobId, jobTitle, companyName })}
          color="twitter"
        >
          <i className="fab fa-twitter" />
        </Button>
      </div>
      {isLogged && (
        <Button
          className="rounded px-5 mt-2 w-100"
          onClick={openJobInvitationModal}
          color="light"
        >
          <FormattedMessage {...messages.invite} />
        </Button>
      )}
    </div>
  )
}

JobShare.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  job: PropTypes.object,
}
