import React from "react"
import { CardLoader } from "../common/card-loader"
import { times } from "ramda"

export function JobsSkeleton(props) {
  return (
    <div className="mt-6">
      {times(
        i => (
          <div key={i} className="bg-white p-3 mb-2">
            <CardLoader />
          </div>
        ),
        4,
      )}
    </div>
  )
}
