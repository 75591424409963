import React from "react"
import PropTypes from "prop-types"
import { push } from "connected-react-router"
import { compose } from "redux"
import { connect } from "react-redux"

import { updateCandidate } from "../../actions/candidate-actions"
import { authRequired } from "../../decorators/auth-required"
import { PersonalDetailsPage } from "../personal-details-page"

const personalDetailsFormName = "PersonalDetailsForm"

const ProfileEdit = ({ location, updateCandidate }) => {
  const backRoute = "/profile/personal"
  const afterSavePath = backRoute

  const personalDetailsSubmitHandler = value => {
    updateCandidate({
      candidate: value,
      formName: personalDetailsFormName,
      routing: {
        pathname: afterSavePath,
        search: location.search,
      },
    })
  }

  return (
    <PersonalDetailsPage
      formName={personalDetailsFormName}
      onSubmit={personalDetailsSubmitHandler}
      backRoute={backRoute}
    />
  )
}

ProfileEdit.propTypes = {
  location: PropTypes.object,
  updateCandidate: PropTypes.func,
}

const mapDispatchToProps = {
  updateCandidate,
  push,
}

export const ProfileEditPage = compose(
  authRequired,
  connect(null, mapDispatchToProps),
)(ProfileEdit)
