import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { path } from "ramda"
import { BaseLayout } from "../components/layouts/base-layout"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { NotFound } from "../components/common/not-found-component"
import errorImage from "../assets/img/error-page.svg"
import {
  CLIENT_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  CANCEL_ERROR,
  UNKNOWN_ERROR,
} from "../constants"
import { Button } from "../components/common/button-component"

const messages = defineMessages({
  serverErrorTitle: { id: "app.error.server.title" },
  networkErrorTitle: { id: "app.error.network.title" },
  clientErrrorTitle: { id: "app.error.client.title" },
  serverErrorSubtitle: { id: "app.error.server.subtitle" },
  networkErrorSubtitle: { id: "app.error.network.subtitle" },
  clientErrrorSubtitle: { id: "app.error.client.subtitle" },
})

export function ErrorPage(props) {
  const { err } = props
  let content

  switch (err.problem) {
    case SERVER_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage {...messages.serverErrorTitle} />}
          subTitle={<FormattedMessage {...messages.serverErrorSubtitle} />}
          content={
            <img
              alt={<FormattedMessage {...messages.serverErrorTitle} />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case NETWORK_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage {...messages.networkErrorTitle} />}
          subTitle={<FormattedMessage {...messages.networkErrorSubtitle} />}
          content={
            <img
              alt={<FormattedMessage {...messages.networkErrorTitle} />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      content = (
        <ErrorPageBody
          title={<FormattedMessage {...messages.clientErrrorTitle} />}
          subTitle={<FormattedMessage {...messages.clientErrrorSubtitle} />}
          content={
            <img
              alt={<FormattedMessage {...messages.clientErrrorTitle} />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
      break

    case CLIENT_ERROR:
      switch (path(["response", "status"], err)) {
        case 403:
          content = (
            <ErrorPageBody
              title={<FormattedMessage {...messages.clientErrrorTitle} />}
              subTitle={<FormattedMessage {...messages.clientErrrorSubtitle} />}
              content={
                <img
                  alt={<FormattedMessage {...messages.clientErrrorTitle} />}
                  height="400"
                  src={errorImage}
                />
              }
            />
          )
          break

        case 404:
          content = <NotFound />
          break

        default:
          content = (
            <ErrorPageBody
              title={<FormattedMessage {...messages.clientErrrorTitle} />}
              subTitle={<FormattedMessage {...messages.clientErrrorSubtitle} />}
              content={
                <img
                  alt={<FormattedMessage {...messages.clientErrrorTitle} />}
                  height="400"
                  src={errorImage}
                />
              }
            />
          )
      }

      break

    default:
      content = (
        <ErrorPageBody
          title={<FormattedMessage {...messages.clientErrrorTitle} />}
          subTitle={<FormattedMessage {...messages.clientErrrorSubtitle} />}
          content={
            <img
              alt={<FormattedMessage {...messages.clientErrrorTitle} />}
              height="400"
              src={errorImage}
            />
          }
        />
      )
  }

  return (
    <BaseLayout className="py-5">
      <SingleColumnLayout>
        <div className="mb-4 text-center">{content}</div>
      </SingleColumnLayout>
    </BaseLayout>
  )
}

ErrorPage.propTypes = {
  err: PropTypes.object.isRequired,
}

ErrorPage.defaultProps = {}

function ErrorPageBody(props) {
  return (
    <Fragment>
      <h1>{props.title}</h1>
      {props.subTitle && <p className="text-muted">{props.subTitle}</p>}
      <div className="d-flex flex-row justify-content-center">
        <Button
          onClick={() => {
            window.location.reload()
          }}
          color="primary"
        >
          Reload page
        </Button>
        <Button tag="a" className="ml-2" href="/">
          Home
        </Button>
      </div>
      {props.content}
    </Fragment>
  )
}

ErrorPageBody.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  content: PropTypes.node,
}

ErrorPageBody.defaultProps = {
  content: null,
}
