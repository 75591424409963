import {
  arrayToObject,
  getActionType,
  isJobInactiveError,
  N,
} from "../lib/helpers"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  over,
  lensProp,
  assoc,
  dec,
  without,
  uniq,
  prop,
  mergeDeepLeft,
  when,
  inc,
  pipe,
  identity,
} from "ramda"
import {
  GET,
  SUCCESS,
  JOB,
  SUGGESTION,
  LIST,
  FAIL,
  START,
  DISCARD,
  SIMILAR,
  COMPANY,
  FEEDBACK,
  CREATE,
  CLOSE,
  INITIAL,
  APPLICATION,
  MODAL,
  OPEN,
  APPLY,
  INVITATION,
  SEND,
  LOGOUT,
  PAU,
  DEL,
} from "../constants"

export const defaultState = {
  entities: {},
  byCompany: [],

  similarCount: null,
  similarLoading: false,
  similar: [],

  suggestions: [],
  suggestionsCount: null,
  discardingJobId: null,
  discardingErr: null,

  applyingJobId: null,
  applyingErr: null,
  feedbackJobOpenId: null,

  creatingJobFeedbackId: null,
  creatingJobFeedbackErr: null,

  loading: false,
  isInitialApplicationModalVisible: false,
  err: null,
  count: null,

  isSendingJobInvitation: null,
  sendJobInvitationErr: null,
}

const preserveFeedbackPosition = ({ nextSuggestions, state }) => {
  const feedbackIdx = state.suggestions.indexOf(state.feedbackJobOpenId)
  return always([
    ...nextSuggestions.slice(0, feedbackIdx),
    state.feedbackJobOpenId,
    ...nextSuggestions.slice(feedbackIdx),
  ])
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, JOB, START):
    case getActionType(GET, JOB, SUGGESTION, LIST, START):
    case getActionType(GET, JOB, SIMILAR, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, JOB, SUCCESS):
      return evolve(
        {
          loading: F,
          err: N,
          entities: over(lensProp(payload.job.id), mergeLeft(payload.job)),
        },
        state,
      )

    case getActionType(GET, JOB, SUGGESTION, LIST, SUCCESS):
      return evolve(
        {
          err: N,
          loading: F,
          suggestionsCount: pipe(
            always(payload.count),
            when(always(Boolean(state.feedbackJobOpenId)), inc),
          ),
          suggestions: state.feedbackJobOpenId
            ? preserveFeedbackPosition({
                nextSuggestions: payload.jobs.map(prop("id")),
                state,
              })
            : always(payload.jobs.map(prop("id"))),
          entities: mergeLeft(arrayToObject(payload.jobs, "id")),
        },
        state,
      )

    case getActionType(GET, JOB, SIMILAR, LIST, SUCCESS):
      return evolve(
        {
          err: N,
          similarCount: always(payload.count),
          similar: always(uniq(payload.jobs.map(j => j.id))),
          entities: mergeDeepLeft(arrayToObject(payload.jobs, "id")),
        },
        state,
      )

    case getActionType(GET, JOB, FAIL):
    case getActionType(GET, JOB, SUGGESTION, LIST, FAIL):
    case getActionType(GET, JOB, SIMILAR, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(DISCARD, JOB, START):
      return evolve(
        {
          discardingJobId: always(payload.jobId),
        },
        state,
      )

    case getActionType(DISCARD, JOB, SUCCESS):
      return evolve(
        {
          entities: {
            [payload.match.job.id]: assoc("match", payload.match),
          },
          feedbackJobOpenId: always(payload.match.job.id),
          suggestions: without([state.feedbackJobOpenId]),
          suggestionsCount: when(always(Boolean(state.feedbackJobOpenId)), dec),
          discardingJobId: N,
          discardingErr: N,
        },
        state,
      )

    case getActionType(DISCARD, JOB, FAIL):
      return evolve(
        {
          discardingErr: always(payload.err),
          discardingJobId: N,
          suggestions: when(
            always(isJobInactiveError(payload.err)),
            without([payload.jobId]),
          ),
          suggestionsCount: when(always(isJobInactiveError(payload.err)), dec),
          entities: isJobInactiveError(payload.err)
            ? over(lensProp(payload.jobId), mergeLeft({ status: DEL }))
            : identity,
        },
        state,
      )

    case getActionType(APPLY, JOB, START):
      return evolve(
        {
          applyingJobId: always(payload.jobId),
          applyingErr: N,
        },
        state,
      )

    case getActionType(APPLY, JOB, SUCCESS):
      return evolve(
        {
          entities: {
            [payload.match.job.id]: assoc("match", payload.match),
          },
          applyingJobId: N,
          applyingErr: N,
          suggestions: without([payload.match.job.id]),
          suggestionsCount: dec,
        },
        state,
      )

    case getActionType(APPLY, JOB, FAIL):
      return evolve(
        {
          applyingErr: always(payload.err),
          suggestions: when(
            always(isJobInactiveError(payload.err)),
            without([payload.jobId]),
          ),
          suggestionsCount: when(always(isJobInactiveError(payload.err)), dec),
          applyingJobId: N,
          entities: isJobInactiveError(payload.err)
            ? over(lensProp(payload.jobId), mergeLeft({ status: PAU }))
            : identity,
        },
        state,
      )

    case getActionType(GET, COMPANY, JOB, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, COMPANY, JOB, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: N,
          byCompany: always(payload.jobs.map(j => j.id)),
          entities: mergeLeft(arrayToObject(payload.jobs, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, COMPANY, JOB, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    case getActionType(CREATE, JOB, FEEDBACK, START):
      return evolve(
        {
          creatingJobFeedbackId: always(payload.jobId),
        },
        state,
      )
    case getActionType(CREATE, JOB, FEEDBACK, SUCCESS):
      return evolve(
        {
          creatingJobFeedbackId: N,
          creatingJobFeedbackErr: N,
          suggestions: without([payload.jobId]),
          suggestionsCount: dec,
          feedbackJobOpenId: N,
        },
        state,
      )
    case getActionType(CREATE, JOB, FEEDBACK, FAIL):
      return evolve(
        {
          creatingJobFeedbackId: N,
          creatingJobFeedbackErr: always(payload.err),
        },
        state,
      )
    case getActionType(CLOSE, JOB, FEEDBACK):
      return evolve(
        {
          suggestions: without([payload.jobId]),
          suggestionsCount: dec,
          feedbackJobOpenId: N,
        },
        state,
      )

    case getActionType(OPEN, INITIAL, APPLICATION, MODAL):
      return evolve({ isInitialApplicationModalVisible: T }, state)

    case getActionType(CLOSE, INITIAL, APPLICATION, MODAL):
      return evolve({ isInitialApplicationModalVisible: F }, state)

    case getActionType(SEND, JOB, INVITATION, START):
      return evolve({ isSendingJobInvitation: T }, state)

    case getActionType(SEND, JOB, INVITATION, SUCCESS):
      return evolve(
        { isSendingJobInvitation: F, sendJobInvitationErr: N },
        state,
      )

    case getActionType(SEND, JOB, INVITATION, FAIL):
      return evolve(
        {
          isSendingJobInvitation: F,
          sendJobInvitationErr: always(payload.err),
        },
        state,
      )
    case getActionType(LOGOUT, SUCCESS):
      return defaultState
    default:
      return state
  }
}
