import { all, call, put, takeLatest, select } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import {
  getActionType,
  randomInteger,
  sample,
  mapMoberriesApiValidationError,
  isJobInactiveError,
  isJobMismatchError,
} from "../lib/helpers"
import { pluck, pipe, propOr, prop, flatten, isEmpty } from "ramda"
import { getMatchSaga } from "./match-saga"
import {
  JOB,
  SUGGESTION,
  LIST,
  FAIL,
  GET,
  REQUEST,
  START,
  SUCCESS,
  SIMILAR,
  WITH,
  COMPANY,
  MATCH_STATUSES,
  DISCARD,
  UPDATE,
  CANDIDATE,
  CREATE,
  FEEDBACK,
  INVITATION,
  SEND,
  APPLY,
  JOB_SUGGESTIONS_URLS,
  SUGGESTIONS,
  AND,
  EXTERNAL,
  JOBS,
} from "../constants"
import {
  candidateSelector,
  trackingSelector,
} from "../selectors/candidate-selector"
import { matchByJobIdSelector } from "../selectors/match-selector"
import { startSubmit, stopSubmit } from "redux-form"
import { config } from "../config"
import { getExternalJobListSaga } from "./external-job-saga"
import { alertTypes, showAlert, showRequestErrorAlert } from "../lib/alerts"

export function* getJobSuggestionListSaga() {
  yield put({ type: getActionType(GET, JOB, SUGGESTION, LIST, START) })

  try {
    const { data } = yield call(moberriesApi.getJobSuggestionList)
    const tracking = yield select(trackingSelector)

    let jobs =
      config.moberries.api.jobSuggestionsURL === JOB_SUGGESTIONS_URLS.ML
        ? pluck("job", data)
        : data.results

    const trackingJobId = tracking?.job?.id
    jobs = jobs.filter(job => job.id !== trackingJobId)

    yield put({
      type: getActionType(GET, JOB, SUGGESTION, LIST, SUCCESS),
      payload: { jobs, count: jobs.length },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, JOB, SUGGESTION, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* getJobSaga(action) {
  const { jobId } = action.payload

  yield put({ type: getActionType(GET, JOB, START) })

  try {
    const { data: job } = yield call(moberriesApi.getJob, { jobId })

    yield put({
      type: getActionType(GET, JOB, SUCCESS),
      payload: { job },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, JOB, FAIL), payload: { err } })
  }
}

export function* getCompanyJobListSaga(action) {
  const { id, params } = action.payload

  yield put({ type: getActionType(GET, COMPANY, JOB, LIST, START) })

  try {
    const {
      data: { results: jobs, count },
    } = yield call(moberriesApi.getCompanyJobsList, {
      id,
      params: { ...params },
    })

    yield put({
      type: getActionType(GET, COMPANY, JOB, LIST, SUCCESS),
      payload: { jobs, count },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, COMPANY, JOB, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* getJobWithSimilarJobListSaga(action) {
  const { jobId } = action.payload

  yield put({ type: getActionType(GET, JOB, START) })

  try {
    const { data: job } = yield call(moberriesApi.getJob, { jobId })
    let matchEffect

    if (job && job.match && job.match.id) {
      matchEffect = call(getMatchSaga, { payload: { matchId: job.match.id } })
    }

    const [
      {
        data: { results: jobs, count },
      },
    ] = yield all([
      call(moberriesApi.getJobList, {
        limit: 6,
        offset: randomInteger(0, 20),
        job_roles__categories: pipe(
          pluck("categories"),
          flatten,
          sample,
          propOr("", "id"),
        )(job.jobRoles),
        status: "ACT",
      }),
      matchEffect,
    ])

    yield put({
      type: getActionType(GET, JOB, SIMILAR, LIST, SUCCESS),
      payload: { jobs, count },
    })

    yield put({
      type: getActionType(GET, JOB, SUCCESS),
      payload: { job },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, JOB, FAIL), payload: { err } })
  }
}

export function* discardJobSaga(action) {
  const { jobId } = action.payload

  try {
    yield put({
      type: getActionType(DISCARD, JOB, START),
      payload: { jobId },
    })
    const { data: match } = yield call(moberriesApi.createMatch, {
      jobId,
      status: MATCH_STATUSES.CANDIDATE_DECLINED,
    })
    yield put({
      type: getActionType(DISCARD, JOB, SUCCESS),
      payload: { match },
    })
  } catch (err) {
    if (!isJobInactiveError(err)) {
      showRequestErrorAlert({ err })
    }

    yield put({
      type: getActionType(DISCARD, JOB, FAIL),
      payload: {
        err,
        jobId,
      },
    })
  }
}

const formatAnswers = answers =>
  Object.entries(answers)
    .filter(([, answer]) => {
      return !isEmpty(answer)
    })
    .map(([question, answer]) => {
      if (Array.isArray(answer)) {
        answer = answer.map(i => i.id)
      } else if (typeof answer === "object") {
        answer = answer.id
      }

      const [, questionId] = question.split("-")

      return {
        question: questionId,
        answer,
      }
    })

export function* applyForJobSaga(action) {
  const { jobId, candidate, motivationalNote, answers } = action.payload

  try {
    const currentCandidate = yield select(candidateSelector)
    yield put({ type: getActionType(APPLY, JOB, START), payload: { jobId } })

    let newCandidate
    if (candidate) {
      const { data } = yield call(moberriesApi.updateCandidate, {
        id: currentCandidate.id,
        candidate,
      })

      newCandidate = data
    }

    const { data: match } = yield call(moberriesApi.createMatch, {
      jobId,
      status: MATCH_STATUSES.CANDIDATE_ACCEPTED,
      motivationalNote,
      answers: answers && formatAnswers(answers),
    })

    if (newCandidate) {
      newCandidate.statistics.offersAccepted =
        newCandidate.statistics.offersAccepted + 1
      yield put({
        type: getActionType(UPDATE, CANDIDATE, SUCCESS),
        payload: { candidate: newCandidate },
      })
    }
    yield put({
      type: getActionType(APPLY, JOB, SUCCESS),
      payload: { match },
    })
  } catch (err) {
    if (!isJobMismatchError(err)) {
      showRequestErrorAlert({ err })
    }

    yield put({
      type: getActionType(APPLY, JOB, FAIL),
      payload: { err, jobId },
    })
  }
}

export function* createJobFeedbackSaga(action) {
  const { jobId, answers, comment, skills } = action.payload

  try {
    yield put({
      type: getActionType(CREATE, JOB, FEEDBACK, START),
      payload: { jobId },
    })
    const { data: feedback } = yield call(moberriesApi.createFeedback, {
      answers,
      comment,
    })
    const match = yield select(state => matchByJobIdSelector(state, { jobId }))
    const matchId = prop("id", match)
    const { data: matchFeedback } = yield call(
      moberriesApi.linkFeedbackWithMatch,
      {
        candidateId: "me",
        matchId,
        feedback: feedback.id,
      },
    )
    yield call(moberriesApi.linkSkillsWithFeedback, {
      candidateId: "me",
      matchId,
      matchFeedbackId: matchFeedback.id,
      skills,
    })

    yield put({
      type: getActionType(CREATE, JOB, FEEDBACK, SUCCESS),
      payload: { jobId },
    })
  } catch (err) {
    yield put({
      type: getActionType(CREATE, JOB, FEEDBACK, FAIL),
      payload: { err },
    })
  }
}

export function* sendJobInvitationSaga(action) {
  const { email, jobId } = action.payload

  try {
    yield put(startSubmit("JobInvitationForm"))
    yield put({
      type: getActionType(SEND, JOB, INVITATION, START),
      payload: { email, jobId },
    })
    yield call(moberriesApi.sendJobInvitation, {
      email,
      jobId,
    })

    yield put(stopSubmit("JobInvitationForm"))

    showAlert({ code: alertTypes.jobInvitationSuccess })

    yield put({
      type: getActionType(SEND, JOB, INVITATION, SUCCESS),
      payload: { email, jobId },
    })
  } catch (err) {
    yield put(
      stopSubmit(
        "JobInvitationForm",
        mapMoberriesApiValidationError(err.response.data),
      ),
    )
    yield put({
      type: getActionType(SEND, JOB, INVITATION, FAIL),
      payload: { err },
    })
  }
}

export function* getJobSuggestionsAndExternalJobsSaga() {
  yield call(getJobSuggestionListSaga)
  // temporarily show always external jobs
  // const jobsCount = yield select(suggestionsCountSelector)
  // if (jobsCount < 5) {
  yield call(getExternalJobListSaga)
  // }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, JOB, SUGGESTION, LIST, REQUEST),
      getJobSuggestionListSaga,
    ),
    takeLatest(getActionType(GET, JOB, REQUEST), getJobSaga),
    takeLatest(
      getActionType(GET, COMPANY, JOB, LIST, REQUEST),
      getCompanyJobListSaga,
    ),
    takeLatest(
      getActionType(GET, JOB, WITH, SIMILAR, JOB, LIST, REQUEST),
      getJobWithSimilarJobListSaga,
    ),
    takeLatest(getActionType(DISCARD, JOB, REQUEST), discardJobSaga),
    takeLatest(getActionType(APPLY, JOB, REQUEST), applyForJobSaga),
    takeLatest(
      getActionType(CREATE, JOB, FEEDBACK, REQUEST),
      createJobFeedbackSaga,
    ),
    takeLatest(
      getActionType(SEND, JOB, INVITATION, REQUEST),
      sendJobInvitationSaga,
    ),
    takeLatest(
      getActionType(GET, JOB, SUGGESTIONS, AND, EXTERNAL, JOBS, REQUEST),
      getJobSuggestionsAndExternalJobsSaga,
    ),
  ])
}
