import { useState, useCallback } from "react"
import debounce from "debounce-promise"
import { api as moApi } from "../lib/moberries-api"
import { pathOr } from "ramda"
import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants"
export const useLanguagesLoader = (options = {}) => {
  const [languages, setLanguages] = useState([])
  const selected = options.selected || []

  const loadLanguages = useCallback(
    debounce(async search => {
      const res = await moApi.getLanguages({ params: { search } })
      const languages = pathOr([], ["data", "results"], res)
      const selectedExcludedLanguages = languages.filter(
        l => !selected.map(s => s.id).includes(l.id),
      )
      setLanguages(selectedExcludedLanguages)

      return selectedExcludedLanguages
    }, SEARCH_DEBOUNCE_TIMEOUT),
    [selected.length],
  )

  return { languages, loadLanguages }
}
