import { getActionType } from "../lib/helpers"
import { GET, JOB, LIST, REQUEST, EXTERNAL, HIDE } from "../constants"

export const getExternalJobList = () => ({
  type: getActionType(GET, EXTERNAL, JOB, LIST, REQUEST),
})

export const hideExternalJob = ({ jobId }) => ({
  type: getActionType(HIDE, EXTERNAL, JOB, REQUEST),
  payload: {
    jobId,
  },
})
