import React from "react"
import PropTypes from "prop-types"
import { Button as BButton } from "reactstrap"
import cn from "classnames"

export function Button(props) {
  const { loading, disabled, children, pill, className, ...rest } = props

  const css = cn({ "btn-pill": pill }, className)

  return (
    <BButton {...rest} className={css} disabled={disabled}>
      {loading && <i className="fas fa-circle-notch fa-spin mr-1" />}
      {children}
    </BButton>
  )
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  pill: PropTypes.bool.isRequired,
  block: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  tag: PropTypes.elementType,
  onClick: PropTypes.func,
  size: PropTypes.string,
}

Button.defaultProps = {
  pill: false,
  className: "",
}
