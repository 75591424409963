import React, { Fragment } from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { ApplicationsPageLayout } from "../layouts/applications-page-layout"
import { BaseSidebar } from "../sidebar/base-sidebar"
import defaultState from "../../assets/img/default-state.svg"
import { Img } from "../common/img-component"
import PropTypes from "prop-types"
import { compose } from "ramda"
import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { connect } from "react-redux"
import { hasAnyActiveSubscriptionSelector } from "../../selectors/candidate-selector"

const messages = defineMessages({
  title: { id: "app.applications.title.empty" },
  subtitle: { id: "app.applications.description.empty" },
})

export function ApplicationsEmpty({ hasAnyActiveSubscription }) {
  return (
    <ApplicationsPageLayout
      header={
        <Fragment>
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <p className="text-muted">
            <FormattedMessage {...messages.subtitle} />
          </p>
        </Fragment>
      }
      content={
        <div className="p-3 text-center bg-white">
          <Img
            src={defaultState}
            width="450"
            alt="Empty"
            className="img-fluid"
          />
        </div>
      }
      sidebar={<BaseSidebar showBotSubscription={!hasAnyActiveSubscription} />}
    />
  )
}

ApplicationsEmpty.propTypes = {
  hasAnyActiveSubscription: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  hasAnyActiveSubscription: hasAnyActiveSubscriptionSelector(state),
})

export const ApplicationsPageEmpty = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps),
)(ApplicationsEmpty)
