import React from "react"
import { BaseLayout } from "../components/layouts/base-layout"
import { SingleColumnLayout } from "../components/layouts/single-column-layout"
import { NotFound } from "../components/common/not-found-component"

export function NotFoundPage() {
  return (
    <BaseLayout className="py-5">
      <SingleColumnLayout>
        <div className="mb-4 text-center">
          <NotFound />
        </div>
      </SingleColumnLayout>
    </BaseLayout>
  )
}
