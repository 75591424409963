import { ACT, ALL_MATCH_STATUSES, MATCH_STATUSES } from "../../constants"
import { difference, differenceWith, uniq } from "ramda"
import { LANG_LEVELS } from "../../default-options"

export function getHiringReward(match) {
  switch (match.status) {
    case MATCH_STATUSES.CANDIDATE_PENDING:
    case MATCH_STATUSES.CANDIDATE_ACCEPTED:
      return match.job.hiringRewardEuros
    case MATCH_STATUSES.COMPANY_ACCEPTED:
    case MATCH_STATUSES.COMPANY_APPROVED:
    case MATCH_STATUSES.EXPORTED:
      return match.hiringRewardEuros
    default:
      return 0
  }
}

export function shouldRenderApplied(match) {
  if (!match) return false
  return [
    MATCH_STATUSES.CANDIDATE_ACCEPTED,
    MATCH_STATUSES.COMPANY_DECLINED,
    MATCH_STATUSES.COMPANY_ACCEPTED,
    MATCH_STATUSES.COMPANY_EXPIRED,
    MATCH_STATUSES.COMPANY_REJECTED,
    MATCH_STATUSES.COMPANY_APPROVED,
  ].includes(match.status)
}

export function wasJobAppliedOrDiscarded(jobMatch) {
  return [
    MATCH_STATUSES.CANDIDATE_ACCEPTED,
    MATCH_STATUSES.CANDIDATE_DECLINED,
  ].includes(jobMatch?.status)
}

export function wasMarkedAsHiredOrNotHired(hired) {
  return hired === true || hired === false
}

export function isMatchRejected(match) {
  return match?.status === MATCH_STATUSES.COMPANY_REJECTED
}

export function isApplyDiscardAllowed({ job, match }) {
  if (!job) return false
  if (job.status !== ACT) return false
  if (match && match.status !== MATCH_STATUSES.CANDIDATE_PENDING) return false
  return true
}

export function isFeedbackAllowed({ job, match }) {
  if (!(job && match)) return false
  if (
    match.status !== MATCH_STATUSES.COMPANY_ACCEPTED &&
    match.status !== MATCH_STATUSES.COMPANY_APPROVED &&
    match.status !== MATCH_STATUSES.EXPORTED
  ) {
    return false
  }

  const candidateAlreadyAnswered = match.canHired !== null
  if (candidateAlreadyAnswered) return false

  return true
}

export function matchStatusToQueryTransformer(status) {
  switch (status) {
    case "applicationInReview":
      return [MATCH_STATUSES.CANDIDATE_ACCEPTED, MATCH_STATUSES.EXPORTED]
    case "applicationDeclined":
      return [MATCH_STATUSES.COMPANY_DECLINED]
    case "applicationAccepted":
      return [MATCH_STATUSES.COMPANY_ACCEPTED]
    case "applicationExpired":
      return [MATCH_STATUSES.COMPANY_EXPIRED]
    case "applicationRejected":
      return [MATCH_STATUSES.COMPANY_REJECTED]
    case "jobOffered":
      return [MATCH_STATUSES.COMPANY_APPROVED]
    default:
      return ALL_MATCH_STATUSES
  }
}

export function getFullName(
  candidate,
  shortenLastName = false,
  shortenFirstName = false,
) {
  if (!candidate) return null

  let first = ""
  let last = ""

  if (candidate.firstName) {
    first = shortenFirstName
      ? candidate.firstName.split(" ")[0]
      : candidate.firstName
  }

  if (candidate.lastName) {
    last = shortenLastName
      ? ` ${candidate.lastName.slice(0, 1)}.`
      : ` ${candidate.lastName}`
  }

  return shortenFirstName ? first : first + last
}

export function getCandidateChangedFields(newCandidate, oldCandidate) {
  return uniq(
    Object.entries(newCandidate)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          if (Array.isArray(oldCandidate[key])) {
            if (difference(oldCandidate[key], value).length) {
              return key
            }

            if (difference(value, oldCandidate[key]).length) {
              return key
            }

            if (
              differenceWith((a, b) => a.id === b.id, value, oldCandidate[key])
                .length
            ) {
              return key
            }

            if (
              differenceWith((a, b) => a.id === b.id, oldCandidate[key], value)
                .length
            ) {
              return key
            }
          }

          return undefined
        }

        if (value !== oldCandidate[key]) {
          return key
        }

        return undefined
      })
      .filter(i => i !== undefined),
  )
}

export function getLocationName(city) {
  if (!city) return null

  let locationName = ""

  if (city.name) {
    locationName = city.name
  }

  if (city.country && city.country.name) {
    locationName = locationName + ", " + city.country.name
  }

  return locationName
}

export function isLanguageNative(language) {
  const nativeLang = LANG_LEVELS.find(l => l.native)
  return nativeLang && language.level === nativeLang.id
}

export function hasNativeLanguage(languages) {
  const nativeLanguage = languages.find(isLanguageNative)
  return Boolean(nativeLanguage) && Boolean(nativeLanguage.id)
}

export const isLinkedinLink = link => /linkedin\.com/.test(link)

export const isDocumentVisible = () => document.visibilityState === "visible"

export const getJobRoleLevelByYears = years => {
  if (years <= 1) {
    return 0
  } else if (years <= 3) {
    return 1
  } else if (years <= 6) {
    return 2
  }

  return 3
}
