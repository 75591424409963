import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { defineMessages, FormattedMessage } from "react-intl"
import { Row, Col, Collapse, PopoverBody } from "reactstrap"
import { CheckboxGroup } from "../common/checkbox-group-component"
import { useList } from "../../hooks/use-list"
import { useFeedbackChoices } from "../../hooks/use-feedback-choices"
import { FEEDBACK_SETS } from "../../constants"
import { pipe, map, groupBy, without, keys, prop, isEmpty, sort } from "ramda"
import humps from "humps"
import { Button } from "../common/button-component"
import { Textarea } from "../common/textarea-component"
import { useOpenClose } from "../../hooks/use-open-close"
import { arrayToObject } from "../../lib/helpers"
import { Link } from "react-router-dom"
import { InfoHint } from "../common/info-hint-component"

const messages = defineMessages({
  header: { id: "app.feedback.job.discard.header" },
  companyReasonsTitle: { id: "app.feedback.job.discard.companyReasons.title" },
  jobReasonsTitle: { id: "app.feedback.job.discard.jobReasons.title" },
  skillsReasonsTitle: { id: "app.feedback.job.discard.skillsReasons.title" },
  jobTitleDiscarded: { id: "app.feedback.job.discard.jobTitle" },
  whyButton: { id: "app.feedback.job.discard.button.why" },
  cancel: { id: "app.common.cancel" },
  confirm: { id: "app.common.confirm" },
  comment: { id: "app.feedback.job.discard.comment" },
  jobReasonsHint: { id: "app.feedback.job.decline.jobReasons.hint" },
  companyReasonsHint: { id: "app.feedback.job.decline.companyReasons.hint" },
  skillsReasonsHint: { id: "app.feedback.job.discard.skillsReasons.hint" },
})

export const JobDeclineFeedback = ({
  jobSkills,
  jobId,
  createFeedback,
  isCreatingFeedback,
  closeFeedback,
  withFeedbackRequest,
  jobTitle,
  matchId,
}) => {
  const { value: isFeedbackOpen, open: openFeedback } = useOpenClose({
    initialValue: !withFeedbackRequest,
  })
  const { feedbackChoices } = useFeedbackChoices({
    feedbackSetType: FEEDBACK_SETS.CANDIDATE_DECLINE_JOB_REASONS,
  })
  const feedbackChoicesGroups = groupFeedbackChoices(feedbackChoices)

  const {
    value: selectedFeedbackChoices,
    set: setSelectedFeedbackChoices,
  } = useList([])
  const { value: selectedSkills, push: addSelectedSkill, remove } = useList([])
  const [comment, setComment] = useState("")
  const jobSkillsById = arrayToObject(jobSkills, "id")
  const unselectedSkills = without(selectedSkills, keys(jobSkillsById))

  return (
    <Fragment>
      {withFeedbackRequest && (
        <div className="bg-whitesmoke rounded p-3 d-flex align-items-center">
          <div className="d-flex flex-row flex-grow-1 miw-0 flex-wrap flex-sm-nowrap">
            <div className="flex-shrink-sm-0">
              <FormattedMessage {...messages.jobTitleDiscarded} />
            </div>
            <Link
              className="text-decoration-none text-dark font-weight-bold text-truncate ml-sm-1"
              to={`/job/${jobId}`}
              target="_blank"
            >
              {jobTitle}
            </Link>
          </div>
          {!isFeedbackOpen && (
            <div className="text-right d-inline flex-shrink-0">
              <Button onClick={openFeedback} size="sm" color="primary">
                <FormattedMessage {...messages.whyButton} />
              </Button>
              <Button
                onClick={closeFeedback}
                color="whitesmoke"
                className="px-0 d-inline-flex align-items-center justify-content-end miw-0"
                size="sm"
              >
                <i className="fas fa-times pl-2 fa-lg" />
              </Button>
            </div>
          )}
        </div>
      )}
      <Collapse isOpen={isFeedbackOpen}>
        <div className="p-3 bg-light border-top border-bottom rounded-bottom">
          <h4>
            <FormattedMessage {...messages.header} />
          </h4>
          <Row className="no-gutters mt-4 lh-1">
            {keys(feedbackChoicesGroups).map((category, i) => (
              <Col
                xs="12"
                sm="6"
                key={category}
                className={classNames({
                  "pr-sm-3": i % 2 === 0,
                  "pl-sm-3": i % 2 !== 0,
                })}
              >
                <div className="text-smaller text-muted text-uppercase border-bottom pb-2 mb-2 d-flex justify-content-between">
                  <FormattedMessage {...messages[`${category}ReasonsTitle`]} />
                  <InfoHint
                    className="ml-2"
                    id={category}
                    popover={
                      <PopoverBody>
                        <FormattedMessage
                          {...messages[`${category}ReasonsHint`]}
                        />
                      </PopoverBody>
                    }
                  />
                </div>
                <CheckboxGroup
                  id={category}
                  input={{
                    value: selectedFeedbackChoices,
                    onChange: setSelectedFeedbackChoices,
                    onBlur: setSelectedFeedbackChoices,
                  }}
                  meta={{}}
                  options={feedbackChoicesGroups[category]}
                />
              </Col>
            ))}
            <Col xs="12" className="mb-3">
              <div className="text-smaller text-muted text-uppercase border-bottom pb-2 mb-2 d-flex justify-content-between">
                <FormattedMessage {...messages.skillsReasonsTitle} />
                <InfoHint
                  className="ml-2"
                  id="skills"
                  popover={
                    <PopoverBody>
                      <FormattedMessage {...messages.skillsReasonsHint} />
                    </PopoverBody>
                  }
                />
              </div>
              <div className="tags-wrapper mb-2">
                {unselectedSkills.map(jobSkillId => (
                  <Button
                    key={jobSkillId}
                    color="secondary-light"
                    onClick={() => addSelectedSkill(jobSkillId)}
                    size="sm"
                  >
                    {jobSkillsById[jobSkillId].name}
                    <i className="fas fa-plus pl-2" />
                  </Button>
                ))}
              </div>
              <div
                className={classNames({
                  "mt-3": Boolean(unselectedSkills.length),
                })}
              >
                <div className="tags-wrapper">
                  {selectedSkills.map(selectedSkill => (
                    <Button
                      onClick={() => remove(selectedSkill)}
                      key={selectedSkill}
                      disabled={isCreatingFeedback}
                      color="success"
                      size="sm"
                    >
                      {jobSkillsById[selectedSkill].name}
                      <i className="fas fa-times pl-2" />
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
            <Col xs="12">
              <Textarea
                label={
                  <div>
                    <FormattedMessage {...messages.comment} />
                  </div>
                }
                className="bg-white w-100"
                type="text"
                meta={{}}
                minRows={9}
                input={{
                  value: comment,
                  onChange: e => setComment(e.target.value),
                }}
              />
            </Col>
            <Col xs="12">
              <Button
                className="mr-1"
                color="primary"
                onClick={() => {
                  createFeedback({
                    answers: selectedFeedbackChoices,
                    comment,
                    jobId,
                    skills: selectedSkills,
                    matchId,
                  })
                }}
                loading={isCreatingFeedback}
                disabled={
                  isCreatingFeedback ||
                  (isEmpty(selectedFeedbackChoices) &&
                    isEmpty(selectedSkills)) ||
                  isCommentMissing({
                    selectedFeedbackChoices,
                    selectedSkills,
                    feedbackChoicesGroups,
                    comment,
                  })
                }
              >
                <FormattedMessage {...messages.confirm} />
              </Button>
              <Button
                color="secondary-light"
                disabled={isCreatingFeedback}
                onClick={closeFeedback}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </Col>
          </Row>
        </div>
      </Collapse>
    </Fragment>
  )
}

JobDeclineFeedback.propTypes = {
  jobSkills: PropTypes.array.isRequired,
  jobId: PropTypes.number.isRequired,
  createFeedback: PropTypes.func.isRequired,
  isCreatingFeedback: PropTypes.bool.isRequired,
  closeFeedback: PropTypes.func.isRequired,
  withFeedbackRequest: PropTypes.bool,
  jobTitle: PropTypes.string,
  matchId: PropTypes.number,
}

JobDeclineFeedback.defaultProps = {
  jobSkills: [],
  withFeedbackRequest: false,
}

function isCommentMissing({
  selectedFeedbackChoices,
  selectedSkills,
  feedbackChoicesGroups,
  comment,
}) {
  if (!feedbackChoicesGroups) return true

  if (selectedFeedbackChoices.length > 1) {
    return false
  }

  if (selectedFeedbackChoices.length === 1 && selectedSkills.length > 0) {
    return false
  }

  const otherOptionId = feedbackChoicesGroups.job.find(
    ({ value }) => value === "other",
  ).id
  const isOtherSelected = selectedFeedbackChoices.includes(otherOptionId)

  if (isOtherSelected && comment === "") {
    return true
  }

  return false
}

function groupFeedbackChoices(feedbackChoices) {
  return pipe(
    map(fc => ({
      id: fc.id,
      value: fc.value,
      name: { id: `app.feedback.job.discard.${humps.camelize(fc.value)}` },
      category: humps.camelize(fc?.category?.value),
    })),
    sort(({ value: a }, { value: b }) => {
      if (a === "other") {
        return 1
      } else if (b === "other") {
        return -1
      }
      return 0
    }),
    groupBy(prop("category")),
  )(feedbackChoices)
}
