import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Input, Label, PopoverBody } from "reactstrap"
import { mergeLeft } from "ramda"
import { v4 as generateFakeId } from "uuid"
import { FormattedMessage, useIntl } from "react-intl"

import { useJobRoles } from "../../../../hooks/use-job-roles"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { Select } from "../../../common/select-component"
import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { DatepickerComponent } from "../../../common/datepicker-component/datepicker-component"
import { InfoHint } from "../../../common/info-hint-component"

import styles from "./work-experience-section.module.scss"

const emptyItem = {
  companyName: "",
  name: "",
  startDate: null,
  endDate: null,
}

export const WorkExperienceSection = ({ input, meta }) => {
  const intl = useIntl()
  const { loadJobRoles } = useJobRoles()
  let { value: values, onChange } = input
  const { error, dirty, touched } = meta

  const changeJobTitleHandler = (value, dbId) => {
    const updatedValues = values.map(v => {
      if (v.dbId !== dbId) return v

      return {
        ...v,
        ...value,
      }
    })
    onChange(updatedValues)
  }

  const changeStartDateHandler = (value, dbId) => {
    const updatedValues = values.map(v => {
      if (v.dbId !== dbId) return v

      const diff = { startDate: value }

      if (!v.endDate) {
        diff.endDate = null
      }

      return mergeLeft(diff, v)
    })
    onChange(updatedValues)
  }

  const changeEndDateHandler = (value, dbId) => {
    const updatedValues = values.map(v => {
      if (v.dbId !== dbId) return v

      if (v.startDate) {
        const diff = {
          endDate: value,
        }
        return mergeLeft(diff, v)
      } else {
        return mergeLeft({ endDate: value }, v)
      }
    })
    onChange(updatedValues)
  }

  const changeCompanyNameHandler = (value, dbId) => {
    const updatedValues = values.map(v =>
      v.dbId === dbId ? mergeLeft({ companyName: value }, v) : v,
    )
    onChange(updatedValues)
  }

  const addItemHandler = () => {
    if (!values[0].id || values.length >= 5) return

    const newValues = [{ dbId: generateFakeId(), ...emptyItem }, ...values]
    onChange(newValues)
  }

  const removeItemHandler = dbId => {
    const newValues = values.filter(v => v.dbId !== dbId)
    onChange(newValues)
  }

  const checkCurrentlyStudyHandler = dbId => {
    const updatedValues = values.map(v => {
      if (v.dbId !== dbId) return v

      if (v.endDate) {
        return mergeLeft({ endDate: null }, v)
      } else {
        return mergeLeft({ endDate: v.startDate }, v)
      }
    })
    onChange(updatedValues)
  }

  // For the first render when "workExperience" is null
  if (!values?.length)
    values = [
      {
        dbId: -1,
        ...emptyItem,
      },
    ]

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.experience.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.experience.text" />
          </span>
        }
        isRequired
        hint={
          <InfoHint
            id="jobRolesHint"
            popover={
              <PopoverBody>
                <FormattedMessage
                  id="app.registration.tabs.professional.input.experience.hint"
                  values={{
                    link: (
                      <a
                        href="https://moberries.typeform.com/to/hXBGOH"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="app.registration.tabs.professional.input.experience.hint.link" />
                      </a>
                    ),
                  }}
                />
              </PopoverBody>
            }
          />
        }
      />

      {error && (dirty || touched) && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      {values.map((item, i) => (
        <div key={item.dbId} className={styles.formItem}>
          <div className={styles.formItem__main}>
            <div className={styles.formItem__inputs}>
              <div className={styles.info}>
                <div className={styles.jobRole}>
                  <Select
                    id="experience-job-roles"
                    async
                    input={{
                      onChange: newValue =>
                        changeJobTitleHandler(newValue, item.dbId),
                      value: !item.id ? null : item,
                    }}
                    wrapper="div"
                    meta={{}}
                    loadOptions={loadJobRoles}
                    defaultOptions
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    placeholder={
                      <FormattedMessage id="app.registration.tabs.professional.input.experience.placeholder.job" />
                    }
                    noOptionsMessage={() => (
                      <FormattedMessage id="app.common.input.placeholder.noResults" />
                    )}
                    backspaceRemovesValue={false}
                  />
                </div>

                <div className={styles.companyName}>
                  <Input
                    placeholder={intl.formatMessage({
                      id:
                        "app.registration.tabs.professional.input.experience.placeholder.company",
                    })}
                    value={item.companyName}
                    onChange={e =>
                      changeCompanyNameHandler(e.target.value, item.dbId)
                    }
                  />
                </div>
              </div>

              <div className={styles.dates}>
                <DatepickerComponent
                  className={styles.startDate}
                  value={item.startDate}
                  onChange={value => changeStartDateHandler(value, item.dbId)}
                  placeholder="app.common.input.placeholder.startDate"
                />
                <DatepickerComponent
                  className={styles.startDate}
                  value={item.endDate}
                  onChange={value => changeEndDateHandler(value, item.dbId)}
                  placeholder={
                    item.startDate && !item.endDate
                      ? "app.common.input.placeholder.tillNow"
                      : "app.common.input.placeholder.endDate"
                  }
                  disabled={item.startDate && !item.endDate}
                />
              </div>
            </div>

            <div className={styles.formItem__action}>
              {i === 0 &&
              item.name &&
              item.companyName &&
              item.startDate &&
              values.length < 5 ? (
                <ButtonWithIcon iconName="plus" onClick={addItemHandler} />
              ) : (
                <ButtonWithIcon
                  iconName="cross"
                  onClick={() => removeItemHandler(item.dbId)}
                />
              )}
            </div>
          </div>

          {item.startDate && (
            <Label className={styles.checkbox}>
              <Input
                type="checkbox"
                checked={item.startDate && !item.endDate}
                onChange={() => checkCurrentlyStudyHandler(item.dbId)}
              />
              <FormattedMessage id="app.registration.tabs.professional.input.experience.check" />
            </Label>
          )}
        </div>
      ))}
    </FormGroup>
  )
}

WorkExperienceSection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}
