import { createSelector } from "reselect"
import { reject, isNil, complement, values } from "ramda"

export const stateSelector = state => state.jobs

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const errorSelector = cs(state => state.err)
export const entitiesSelector = cs(state => state.entities)
export const suggestionsCountSelector = cs(state => state.suggestionsCount)
export const similarCountSelector = cs(state => state.similarCount)
export const countSelector = cs(state => state.count)
export const suggestionIdsSelector = cs(state => state.suggestions)
export const byCompanyIdsSelector = cs(state => state.byCompany)
export const similarIdsSelector = cs(state => state.similar)
export const applyingJobIdSelector = cs(state => state.applyingJobId)
export const isApplyingForJobSelector = createSelector(
  applyingJobIdSelector,
  complement(isNil),
)
export const discardingJobIdSelector = cs(state => state.discardingJobId)
export const isDiscardingJobSelector = createSelector(
  discardingJobIdSelector,
  complement(isNil),
)
export const applyingErrSelector = cs(state => state.applyingErr)
export const discardingErrSelector = cs(state => state.discardingErr)

export const jobSelector = cs(
  entitiesSelector,
  (state, { id }) => id,
  (_, entities, id) => entities[id],
)
export const jobsSelector = cs(entitiesSelector, (_, entities) =>
  values(entities),
)

export const jobSuggestionsSelector = cs(
  entitiesSelector,
  suggestionIdsSelector,
  (_, entities, ids) => ids.map(id => entities[id]),
)

export const jobByCompanySelector = cs(
  entitiesSelector,
  byCompanyIdsSelector,
  (state, { id }) => id,
  (_, entities, ids, companyId) =>
    reject(
      j => j.company.id !== companyId,
      ids.map(id => entities[id]),
    ),
)

export const jobSimilarSelector = cs(
  entitiesSelector,
  similarIdsSelector,
  (state, { jobId }) => jobId,
  (_, entities, ids, jobId) => {
    const results = ids.map(id => entities[id])

    if (jobId) {
      return results.filter(r => r.id !== jobId).slice(0, 5)
    } else {
      return results.slice(0, 5)
    }
  },
)

export const creatingJobFeedbackIdSelector = cs(
  state => state.creatingJobFeedbackId,
)
export const isCreatingJobFeedbackSelector = createSelector(
  creatingJobFeedbackIdSelector,
  complement(isNil),
)
export const creatingJobFeedbackErrSelector = cs(
  state => state.creatingJobFeedbackErr,
)
export const feedbackJobOpenIdSelector = cs(state => state.feedbackJobOpenId)

export const isInitialApplicationModalVisibleSelector = cs(
  state => state.isInitialApplicationModalVisible,
)

export const isSendingJobInvitationSelector = cs(
  state => state.isSendingJobInvitation,
)

export const sendJobInvitationErrSelector = cs(
  state => state.sendJobInvitationErr,
)
