import isLength from "validator/lib/isLength"
import isEmail from "validator/lib/isEmail"
import { defineMessages } from "react-intl"

const messages = defineMessages({
  firstNameRequired: { id: "app.validators.signup.firstName.required" },
  lastNameRequired: { id: "app.validators.signup.lastName.required" },
  emailRequired: { id: "app.validators.signup.email.required" },
  passwordRequired: { id: "app.validators.signup.password.required" },
  invalidEmail: { id: "app.validators.email.required" },
  maxLen: { id: "app.validators.length.max" },
  minLen: { id: "app.validators.length.min" },
})

export const signupFormValidator = values => {
  const errors = {}
  const maxEmailOptions = { max: 50 }
  const minPasswordOptions = { min: 8 }
  const maxPasswordOptions = { max: 30 }

  if (!values.firstName) {
    errors.firstName = messages.firstNameRequired
  }

  if (!values.lastName) {
    errors.lastName = messages.lastNameRequired
  }

  if (!values.email) {
    errors.email = messages.emailRequired
  } else if (!isEmail(values.email)) {
    errors.email = messages.invalidEmail
  } else if (!isLength(values.email, maxEmailOptions)) {
    errors.email = { ...messages.maxLen, values: maxEmailOptions }
  }

  if (!values.password) {
    errors.password = messages.passwordRequired
  } else if (!isLength(values.password, minPasswordOptions)) {
    errors.password = { ...messages.minLen, values: minPasswordOptions }
  } else if (!isLength(values.password, maxPasswordOptions)) {
    errors.password = { ...messages.maxLen, values: maxPasswordOptions }
  }

  return errors
}
