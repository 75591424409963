import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { ApplicationsPageLayout } from "../layouts/applications-page-layout"
import { FormattedMessage, defineMessages } from "react-intl"
import { List } from "react-content-loader"
import { times } from "ramda"
import { ApplicationsLoader } from "../common/applications-loader"

const messages = defineMessages({
  title: { id: "app.common.applications.loading" },
  subtitle: { id: "app.applications.description" },
})

export function ApplicationsPageSkeleton({ header }) {
  return (
    <ApplicationsPageLayout
      header={header}
      content={
        <Fragment>
          <ApplicationsLoader />
        </Fragment>
      }
      sidebar={
        <div className="bg-white p-3">
          {times(
            i => (
              <List key={i} className="py-2" />
            ),
            5,
          )}
        </div>
      }
    />
  )
}
ApplicationsPageSkeleton.propTypes = {
  header: PropTypes.node,
}

ApplicationsPageSkeleton.defaultProps = {
  header: (
    <Fragment>
      <h1>
        <FormattedMessage {...messages.title} />
      </h1>
      <p className="text-muted">
        <FormattedMessage {...messages.subtitle} />
      </p>
    </Fragment>
  ),
}
