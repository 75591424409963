import { getActionType, arrayToObject } from "../lib/helpers"
import {
  GET,
  SUCCESS,
  MESSAGE,
  LIST,
  FAIL,
  LOGOUT,
  START,
  CREATE,
  NEW,
  UPDATE,
  QUEUE,
  DEQUEUE,
} from "../constants"
import {
  always,
  evolve,
  F,
  T,
  mergeLeft,
  over,
  lensProp,
  inc,
  append,
  ifElse,
  equals,
  __,
  and,
  not,
  union,
} from "ramda"

export const defaultState = {
  count: null,
  messages: [],
  entities: {},
  creating: false,
  creatingErr: null,
  err: null,
  loadedThreadId: null,
  lastFetchedPage: null,
  loadingPage: null,
  hasQueuedMessageFetch: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MESSAGE, LIST, START):
      return evolve({ loadingPage: always(payload.page) }, state)

    case getActionType(GET, MESSAGE, LIST, SUCCESS):
      return evolve(
        {
          loadingPage: always(null),
          lastFetchedPage: always(payload.page),
          err: always(null),
          messages: ifElse(
            always(
              and(
                equals(payload.threadId, state.loadedThreadId),
                not(equals(payload.page, 1)),
              ),
            ),
            union(payload.messages.map(m => m.id)),
            always(payload.messages.map(m => m.id)),
          ),
          count: always(payload.count),
          entities: mergeLeft(arrayToObject(payload.messages, "id")),
          loadedThreadId: always(payload.threadId),
        },
        state,
      )

    case getActionType(NEW, MESSAGE, SUCCESS):
      return evolve(
        {
          messages: ifElse(
            always(equals(payload.threadId, state.loadedThreadId)),
            union(
              __,
              payload.messages.map(m => m.id),
            ),
            always(state.messages),
          ),
          entities: mergeLeft(arrayToObject(payload.messages, "id")),
          count: always(payload.count),
        },
        state,
      )

    case getActionType(GET, MESSAGE, LIST, FAIL):
      return evolve(
        {
          loadingPage: always(null),
          err: always(payload.err),
          lastFetchedPage: always(null),
          loadedThreadId: always(null),
        },
        state,
      )

    case getActionType(CREATE, MESSAGE, START):
      return evolve({ creating: T }, state)

    case getActionType(CREATE, MESSAGE, SUCCESS):
      return evolve(
        {
          creating: F,
          creatingErr: always(null),
          messages: append(payload.message.id),
          count: inc,
          entities: over(
            lensProp(payload.message.id),
            mergeLeft(payload.message),
          ),
        },
        state,
      )

    case getActionType(CREATE, MESSAGE, FAIL):
      return evolve({ creating: F, creatingErr: always(payload.err) }, state)

    case getActionType(GET, MESSAGE, SUCCESS):
      return evolve(
        {
          entities: over(
            lensProp(payload.message.id),
            mergeLeft(payload.message),
          ),
        },
        state,
      )

    case getActionType(UPDATE, MESSAGE, LIST, SUCCESS):
      return evolve(
        {
          count: always(payload.count),
          messages: union(
            __,
            payload.messages.map(m => m.id),
          ),
          entities: mergeLeft(arrayToObject(payload.messages, "id")),
        },
        state,
      )

    case getActionType(QUEUE, NEW, MESSAGE):
      return evolve({ hasQueuedMessageFetch: T }, state)

    case getActionType(DEQUEUE, NEW, MESSAGE):
      return evolve({ hasQueuedMessageFetch: F }, state)

    case getActionType(LOGOUT, SUCCESS):
      return defaultState

    default:
      return state
  }
}
