import {
  BOT,
  API,
  WS,
  SUCCESS,
  CONNECT,
  LOGIN,
  CONFIRM,
  EMAIL,
  LOGOUT,
  FACEBOOK,
  LINKEDIN,
  RELOAD,
  PAGE,
  ONLINE,
  VISIBILITY,
  CHANGE,
} from "../constants"
import { socket } from "../lib/ws-api"
import { eventChannel } from "redux-saga"
import { all, call, take, put, takeLatest, select } from "redux-saga/effects"
import { getActionType } from "../lib/helpers"
import { tokenSelector } from "../selectors/user-selector"

export function onActionChannel() {
  return eventChannel(emitter => {
    socket.on("action", emitter)
    return () => socket.off("action", emitter)
  })
}

export function onConnectChannel() {
  return eventChannel(emitter => {
    socket.on("connect", () =>
      emitter({ type: getActionType(BOT, WS, API, CONNECT, SUCCESS) }),
    )
    return () => socket.off("connect", emitter)
  })
}

export function onOnlineChannel() {
  return eventChannel(emitter => {
    window.ononline = () => {
      emitter({ type: getActionType(ONLINE) })
    }

    return () => (window.ononline = null)
  })
}

export function visibilityChangeChannel() {
  return eventChannel(emitter => {
    document.onvisibilitychange = () => {
      emitter({
        type: getActionType(VISIBILITY, CHANGE),
        payload: { isVisible: document.visibilityState === "visible" },
      })
    }

    return () => {
      document.onvisibilitychange = null
    }
  })
}

export function* actionsSaga() {
  const chan = yield call(onActionChannel)

  while (true) {
    const action = yield take(chan)
    yield put(action)
  }
}

export function* connectSaga() {
  const chan = yield call(onConnectChannel)

  while (true) {
    yield take(chan)
    const token = yield select(tokenSelector)

    yield call([socket, socket.emit], "authStateChange", { token })
  }
}

export function* onOnlineSaga() {
  const chan = yield call(onOnlineChannel)

  while (true) {
    const action = yield take(chan)
    yield put(action)
  }
}

export function* visibilityChangeSaga() {
  const chan = yield call(visibilityChangeChannel)

  while (true) {
    const action = yield take(chan)
    yield put(action)
  }
}

export function* authStateChangedSaga() {
  const token = yield select(tokenSelector)
  yield call([socket, socket.emit], "authStateChange", { token })
}

export function reloadPageSaga() {
  window.location.reload(true)
}

export const saga = function* () {
  yield all([
    takeLatest(getActionType(RELOAD, PAGE), reloadPageSaga),
    takeLatest(
      [
        getActionType(LOGIN, SUCCESS),
        getActionType(CONFIRM, EMAIL, SUCCESS),
        getActionType(CONNECT, FACEBOOK, SUCCESS),
        getActionType(CONNECT, LINKEDIN, SUCCESS),
        getActionType(LOGOUT, SUCCESS),
      ],
      authStateChangedSaga,
    ),
    connectSaga(),
    actionsSaga(),
    onOnlineSaga(),
    visibilityChangeSaga(),
  ])
}
