import { getActionType } from "../lib/helpers"
import { always, evolve, F, T } from "ramda"
import {
  GET,
  SUCCESS,
  LIST,
  FAIL,
  START,
  BOT,
  CONNECTION,
  CODE,
  CREATE,
} from "../constants"

export const defaultState = {
  loading: false,
  err: null,
  count: null,
  connections: [],

  creating: false,
  code: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(CREATE, BOT, CONNECTION, CODE, START):
      return evolve({ creating: T }, state)

    case getActionType(CREATE, BOT, CONNECTION, CODE, SUCCESS):
      return evolve({ creating: F, code: always(payload.code) }, state)

    case getActionType(CREATE, BOT, CONNECTION, CODE, FAIL):
      return evolve({ creating: F }, state)

    case getActionType(GET, BOT, CONNECTION, LIST, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, BOT, CONNECTION, LIST, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          count: always(payload.count),
          connections: always(payload.connections),
        },
        state,
      )

    case getActionType(GET, BOT, CONNECTION, LIST, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    default:
      return state
  }
}
