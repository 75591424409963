import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { FormattedMessage } from "react-intl"
import { Container } from "reactstrap"

import { candidateSelector } from "../selectors/candidate-selector"
import { candidateValidator } from "../validators/candidate-validator"
import { PersonalDetailsForm } from "../components/forms/personal-details-form"
import { BaseLayout } from "../components/layouts/base-layout"
import { WelcomeModal } from "../components/common/welcome-modal/welcome-modal"

const PersonalDetails = ({ candidate, onSubmit, formName, backRoute }) => {
  const { active } = candidate

  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="mb-4">
          <FormattedMessage id="app.myProfile.title" />
        </h1>
      </Container>

      <Container>
        {!active && <WelcomeModal />}

        <PersonalDetailsForm
          form={formName}
          initialValues={candidate}
          enableReinitialize
          validate={candidateValidator}
          onSubmit={onSubmit}
          backRoute={backRoute}
        />
      </Container>
    </BaseLayout>
  )
}

PersonalDetails.propTypes = {
  candidate: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  backRoute: PropTypes.string,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

export const PersonalDetailsPage = compose(connect(mapStateToProps))(
  PersonalDetails,
)
