import React from "react"
import { compose } from "redux"
import { push } from "connected-react-router"
import { connect } from "react-redux"

import { activateCandidateInitial } from "../actions/candidate-actions"
import { authRequired } from "../decorators/auth-required"
import { noCompletedProfileRequired } from "../decorators/no-completed-profile-required"
import { candidateSelector } from "../selectors/candidate-selector"
import { EditResume } from "./edit-profile-shared/edit-resume"

const RegistrationResume = props => (
  <EditResume backRoute="/registration/preference" {...props} />
)

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

const mapDispatchToProps = {
  activateCandidateInitial,
  push,
}

export const RegistrationResumePage = compose(
  noCompletedProfileRequired,
  authRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(RegistrationResume)
