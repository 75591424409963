import React from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { BaseLayout } from "./base-layout"
import { NavLink as RLink } from "react-router-dom"
import { Container, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import { SmallSingleColumnLayout } from "./small-single-column-layout"
import { SmallInnerFormLayout } from "./small-inner-form-layout"

const messages = defineMessages({
  title: { id: "app.common.account.capitalized" },
  credentials: { id: "app.account.tabs.credentials" },
  social: { id: "app.account.tabs.social" },
  settings: { id: "app.account.tabs.settings" },
  privacy: { id: "app.common.privacy" },
})

export const AccountPageLayout = props => {
  return (
    <BaseLayout className="py-5">
      <Container>
        <h1 className="text-center mb-4">
          <FormattedMessage {...messages.title} />
        </h1>
      </Container>
      <SmallSingleColumnLayout>
        <Nav tabs justified className="settings-tabs">
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account"
              exact
              className="font-weight-bold px-0"
            >
              <FormattedMessage {...messages.credentials} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account/social"
              className="font-weight-bold px-0"
            >
              <FormattedMessage {...messages.social} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account/settings"
              className="font-weight-bold px-0"
            >
              <FormattedMessage {...messages.settings} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={RLink}
              to="/account/privacy"
              className="font-weight-bold px-0"
            >
              <FormattedMessage {...messages.privacy} />
            </NavLink>
          </NavItem>
        </Nav>
        <div className="bg-white rounded-bottom px-3 py-5">
          <SmallInnerFormLayout>
            <TabContent>{props.children}</TabContent>
          </SmallInnerFormLayout>
        </div>
      </SmallSingleColumnLayout>
    </BaseLayout>
  )
}

AccountPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
