import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "react-intl"

import { PersonalDetailsPage } from "../personal-details-page"
import { BottomPanel } from "../../components/common/bottom-panel/bottom-panel"
import { ResumeForm } from "../../components/forms/resume-form/resume-form"
import { history } from "../../history"

const resumeFormName = "ResumeForm"

const bottomPanelTabs = [
  {
    name: <FormattedMessage id="app.registration.tabs.professional.title" />,
    active: false,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.preferences.title" />,
    active: false,
  },
  {
    name: <FormattedMessage id="app.registration.tabs.resume.title" />,
    active: true,
  },
]

export const EditResume = ({
  backRoute,
  candidate,
  activateCandidateInitial,
}) => {
  const finishRegistrationHandler = () => {
    activateCandidateInitial
      ? activateCandidateInitial({
          candidate: candidate,
          formName: resumeFormName,
        })
      : history.goBack()
  }
  return (
    <>
      <PersonalDetailsPage formName="PersonalDetailsForm" onSubmit={() => {}} />

      <BottomPanel tabs={bottomPanelTabs}>
        <ResumeForm
          backRoute={backRoute}
          form={resumeFormName}
          onFinish={finishRegistrationHandler}
          hasCv={!!candidate.candidateCv}
        />
      </BottomPanel>
    </>
  )
}

EditResume.propTypes = {
  backRoute: PropTypes.string,
  candidate: PropTypes.object,
  activateCandidateInitial: PropTypes.func,
}
