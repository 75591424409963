import isLength from "validator/lib/isLength"
import isURL from "validator/lib/isURL"
import { isNil, isEmpty } from "ramda"
import { defineMessages } from "react-intl"
import { hasNativeLanguage } from "../lib/helpers"

const messages = defineMessages({
  valueRequired: { id: "app.validators.value.required" },
  emailRequired: { id: "app.validators.email.required" },
  cityRequired: { id: "app.validators.city.required" },
  urlRequired: { id: "app.validators.url.required" },
  nativeRequired: { id: "app.validators.language.nativeRequired" },
  maxLen: { id: "app.validators.length.max" },
  minLen: { id: "app.validators.length.min" },
  minSalary: { id: "app.validators.salary.min" },
  minSalaryForm: { id: "app.validators.salary.minForm" },
  phoneFormat: { id: "app.validators.phone.format" },
  jobTypeRequired: { id: "app.validators.jobType.jobTypeRequired" },
})

export const candidateValidator = (values, formProps) => {
  const errors = {}
  const maxFirstNameOptions = { max: 30 }
  const maxLastNameOptions = { max: 30 }
  const minSalaryOptions = { min: 10000 }
  const phoneFormat = new RegExp(
    // /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    /^\+\d{8,15}$/g,
  )

  if (!values.firstName) {
    errors.firstName = messages.valueRequired
  } else if (!isLength(values.firstName, maxFirstNameOptions)) {
    errors.firstName = { ...messages.maxLen, values: maxFirstNameOptions }
  }

  if (!values.lastName) {
    errors.lastName = messages.valueRequired
  } else if (!isLength(values.lastName, maxLastNameOptions)) {
    errors.lastName = { ...messages.maxLen, values: maxLastNameOptions }
  }

  if (values.phoneNumber) {
    const isValid = phoneFormat.test(values.phoneNumber)
    if (!isValid) {
      errors.phoneNumber = messages.phoneFormat
    }
  }

  if (!values.city || isNil(values.city)) {
    errors.city = messages.cityRequired
  }

  if (isNaN(+values.salaryMin)) {
    errors.salaryMin = messages.minSalaryForm
  } else if (
    parseInt(values.salaryMin) < minSalaryOptions.min &&
    parseInt(values.salaryMin) !== 0
  ) {
    errors.salaryMin = {
      ...messages.minSalary,
      values: {
        min: formProps.intl.formatNumber(minSalaryOptions.min, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
      },
    }
  }

  if (isNil(values.careerLevel)) {
    errors.careerLevel = messages.valueRequired
  }

  if (isEmpty(values.jobTypes)) {
    errors.jobTypes = messages.jobTypeRequired
  }

  if (isEmpty(values.languages)) {
    errors.languages = messages.valueRequired
  }

  if (!isNil(values.languages) && !hasNativeLanguage(values.languages)) {
    errors.languages = messages.nativeRequired
  }

  if (!isNil(values.languages) && !isEmpty(values.references)) {
    errors.references = values.references.map(r =>
      !r || isURL(r, { allow_underscores: true }) ? null : messages.urlRequired,
    )

    if (isEmpty(errors.references)) {
      delete errors.references
    }
  }

  return errors
}
