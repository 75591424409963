import React from "react"
import PropTypes from "prop-types"
import { defineMessages, FormattedMessage } from "react-intl"
import { PopoverBody } from "reactstrap"
import { CardStatusTag } from "../common/card-status-tag"
import { MATCH_STATUSES, PAU, REV, DEL } from "../../constants"

const messages = defineMessages({
  tagInactive: { id: "app.job.tag.inactive.text" },
  tagCanDeclined: { id: "app.job.tag.candidate.declined.text" },
  tagCanAccepted: { id: "app.job.tag.candidate.accepted.text" },
  tagComDeclined: { id: "app.job.tag.company.declined.text" },
  tagComAccepted: { id: "app.job.tag.company.accepted.text" },
  tagComExpired: { id: "app.job.tag.company.expired.text" },
  tagComRejected: { id: "app.job.tag.company.rejected.text" },
  tagComApproved: { id: "app.job.tag.company.approved.text" },
  tagCanDeclinedTooltip: { id: "app.job.tag.candidate.declined.tooltip" },
  tagCanAcceptedTooltip: { id: "app.job.tag.candidate.accepted.tooltip" },
  tagComDeclinedTooltip: { id: "app.job.tag.company.declined.tooltip" },
  tagComAcceptedTooltip: { id: "app.job.tag.company.accepted.tooltip" },
  tagComExpiredTooltip: { id: "app.job.tag.company.expired.tooltip" },
  tagComRejectedTooltip: { id: "app.job.tag.company.rejected.tooltip" },
  tagComApprovedTooltip: { id: "app.job.tag.company.approved.tooltip" },
  tagInactiveTooltip: { id: "app.job.tag.inactive.tooltip" },
})

export function JobCardStatus({ matchId, matchStatus, jobStatus, jobId }) {
  if (matchStatus !== null && matchStatus !== undefined) {
    switch (matchStatus) {
      case MATCH_STATUSES.CANDIDATE_DECLINED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagCanDeclined} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagCanDeclinedTooltip} />
              </PopoverBody>
            }
            color="danger-light"
            icon="fa-times-circle text-danger"
          />
        )

      case MATCH_STATUSES.EXPORTED:
      case MATCH_STATUSES.CANDIDATE_ACCEPTED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagCanAccepted} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagCanAcceptedTooltip} />
              </PopoverBody>
            }
            color="primary-light"
            icon="fa-clock text-primary"
          />
        )
      case MATCH_STATUSES.COMPANY_DECLINED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagComDeclined} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagComDeclinedTooltip} />
              </PopoverBody>
            }
            color="danger-light"
            icon="fa-times-circle text-danger"
          />
        )

      case MATCH_STATUSES.COMPANY_ACCEPTED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagComAccepted} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagComAcceptedTooltip} />
              </PopoverBody>
            }
            color="success-light"
            icon="fa-check text-success"
          />
        )

      case MATCH_STATUSES.COMPANY_EXPIRED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagComExpired} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagComExpiredTooltip} />
              </PopoverBody>
            }
            color="warning-light"
            icon="fa-times-circle text-warning"
          />
        )

      case MATCH_STATUSES.COMPANY_REJECTED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagComRejected} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagComRejectedTooltip} />
              </PopoverBody>
            }
            color="danger-light"
            icon="fa-times-circle text-danger"
          />
        )

      case MATCH_STATUSES.COMPANY_APPROVED:
        return (
          <CardStatusTag
            id={matchId}
            text={<FormattedMessage {...messages.tagComApproved} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagComApprovedTooltip} />
              </PopoverBody>
            }
            color="info-light"
            icon="fa-check text-info"
          />
        )

      default:
        break
    }
  }

  if (jobStatus !== null && jobStatus !== undefined) {
    switch (jobStatus) {
      case PAU:
      case REV:
      case DEL:
        return (
          <CardStatusTag
            id={jobId}
            text={<FormattedMessage {...messages.tagInactive} />}
            popover={
              <PopoverBody>
                <FormattedMessage {...messages.tagInactiveTooltip} />
              </PopoverBody>
            }
            color="danger-light"
            icon="fa-times-circle text-danger"
          />
        )
      default:
        break
    }
  }

  return null
}

JobCardStatus.propTypes = {
  matchId: PropTypes.number,
  jobId: PropTypes.number,
  matchStatus: PropTypes.number,
  jobStatus: PropTypes.string.isRequired,
}
