import { createSelector } from "reselect"

export const stateSelector = state => state.messages

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const creatingSelector = cs(state => state.creating)
export const errorSelector = cs(state => state.err)
export const creatingErrorSelector = cs(state => state.creatingErr)
export const entitiesSelector = cs(state => state.entities)
export const countSelector = cs(state => state.count)
export const idsSelector = cs(state => state.messages)

export const messagesSelector = cs(
  entitiesSelector,
  idsSelector,
  (_, entities, ids) => ids.map(id => entities[id]),
)

export const lastFetchedPageSelector = cs(state => state.lastFetchedPage)

export const loadedThreadIdSelector = cs(state => state.loadedThreadId)

export const loadingPageSelector = cs(state => state.loadingPage)

export const hasQueuedMessageFetchSelector = cs(
  state => state.hasQueuedMessageFetch,
)
