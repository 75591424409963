import React from "react"
import { Link } from "react-router-dom"
import { defineMessages, FormattedMessage } from "react-intl"
import { path, values } from "ramda"
import { config } from "../config"

const messages = defineMessages({
  defaultError: { id: "app.error.code.default_error" },
  serverError: { id: "app.error.code.server_error" },
  networkError: { id: "app.error.code.network_error" },
  accountAlreadyConfirmed: { id: "app.error.code.account_already_confirmed" },
  invalid: { id: "app.error.code.invalid" }, // invalida data as well
  invalidData: { id: "app.error.code.invalid_data" }, // v1
  invalidFile: { id: "app.error.code.invalid_file" },
  invalidToken: { id: "app.error.code.invalid_token" },
  tokenInvalidText: { id: "app.error.code.token_invalid.text" },
  tokenInvalidLink: { id: "app.error.code.token_invalid.link" },
  emailNotFound: { id: "app.error.code.email_not_found" },
  emailNotUniq: { id: "app.error.code.email_not_unique" },
  emailPermissionMissing: { id: "app.error.code.email_permission_missing" },
  accountNotConfirmed: { id: "app.error.code.account_not_confirmed" },
  accountInactiveText: { id: "app.error.code.account_inactive.text" },
  accountInactiveLink: { id: "app.error.code.account_inactive.link" },
  wrongCredentialsText: { id: "app.error.code.wrong_credentials.text" },
  wrongCredentialsLink: { id: "app.error.code.wrong_credentials.link" },
  authFail: { id: "app.error.code.auth_failed" },
  accountExists: { id: "app.error.code.account_exists" },
  wrongFormat: { id: "app.error.code.wrong_format" },
  socialAccountExists: { id: "app.error.code.social_account_exists" },
  notConfirmedText: { id: "app.error.code.not_confirmed.text" },
  notConfirmedLink: { id: "app.error.code.not_confirmed.link" },
  candidateInactive: { id: "app.error.code.candidate_inactive" },
  candidateNotFound: { id: "app.error.code.candidate_not_found" },
  notCompleted: { id: "app.error.code.not_completed" },
  accountDeleted: { id: "app.error.code.account_deleted" },
  jobNotFound: { id: "app.error.code.job_not_found" },
  jobInactive: { id: "app.error.code.job_inactive" },
  wrongPassword: { id: "app.error.code.wrong_password" },
  authenticationFail: { id: "app.error.code.authentication_failed" },
  authorizationFail: { id: "app.error.code.authorization_failed" },
  userLoginCancel: { id: "app.error.code.user_cancelled_login" },
  accesDenied: { id: "app.error.code.access_denied" },
  clientError: { id: "app.alertbar.error.client" },
  webPushAlertPermissionDenied: { id: "app.webpush.alert.permission.denied" },
  notAuthenticated: { id: "app.error.code.notAuthenticated" },
  emailAlreadyInvited: { id: "app.error.code.emailAlreadyInvited" },
  emailInviteDailyLimitExceeded: {
    id: "app.error.code.emailInviteDailyLimitExceeded",
  },
  userInviteDailyLimitExceeded: {
    id: "app.error.code.userInviteDailyLimitExceeded",
  },
  preconditionFailed: { id: "app.error.code.preconditionFailed" },
  pendingInvite: { id: "app.error.code.pendingInvite" },
})

export const serverErrorsMap = {
  default_error: messages.defaultError,
  unknown_code: messages.defaultError,
  invalid_request: messages.defaultError,
  bad_request: messages.defaultError,
  server_error: messages.serverError,
  network_error: messages.networkError,
  account_already_confirmed: messages.accountAlreadyConfirmed,
  wrong_credentials: payload => {
    return (
      <FormattedMessage
        {...messages.wrongCredentialsText}
        values={{
          link: (
            <FormattedMessage {...messages.wrongCredentialsLink}>
              {txt => (
                <Link
                  className="u"
                  to={{
                    pathname: "/password-recovery",
                    state: { email: payload.email },
                  }}
                >
                  {txt}
                </Link>
              )}
            </FormattedMessage>
          ),
        }}
      />
    )
  },
  invalid: messages.invalid,
  invalid_data: messages.invalidData,
  invalid_file: messages.invalidFile,
  invalid_token: messages.invalidToken, // JWT fails
  // email confirmation token fails
  token_invalid: (
    <span>
      <FormattedMessage {...messages.tokenInvalidText} />
      <Link to="/resend">
        &nbsp;
        <FormattedMessage {...messages.tokenInvalidLink} />
      </Link>
    </span>
  ),
  email_not_found: messages.emailNotFound,
  email_not_unique: messages.emailNotUniq,
  email_permission_missing: messages.emailPermissionMissing,
  account_not_confirmed: messages.accountNotConfirmed,
  account_inactive: (
    <span>
      <FormattedMessage {...messages.accountInactiveText} />
      <Link to="/resend">
        &nbsp;
        <FormattedMessage {...messages.accountInactiveLink} />
      </Link>
    </span>
  ),
  auth_failed: messages.authFail,
  account_exists: messages.accountExists,
  wrong_format: messages.wrongFormat,
  social_account_exists: messages.socialAccountExists,
  not_confirmed: (
    <FormattedMessage
      {...messages.notConfirmedText}
      values={{
        link: (
          <FormattedMessage {...messages.notConfirmedLink}>
            {txt => <Link to="/resend">{txt}</Link>}
          </FormattedMessage>
        ),
      }}
    />
  ),
  "messaging/permission-blocked": messages.webPushAlertPermissionDenied,
  candidate_inactive: messages.candidateInactive,
  candidate_not_found: messages.candidateNotFound,
  not_completed: messages.notCompleted,
  account_deleted: messages.accountDeleted,
  job_not_found: messages.jobNotFound,
  job_inactive: messages.jobInactive,
  wrong_password: messages.wrongPassword,
  authentication_failed: messages.authenticationFail,
  authorization_failed: messages.authorizationFail,
  user_cancelled_login: messages.userLoginCancel,
  user_cancelled_authorize: messages.userLoginCancel,
  access_denied: messages.accesDenied,
  not_found: messages.defaultError,
  not_authenticated: messages.notAuthenticated,
  email_already_invited: messages.emailAlreadyInvited,
  email_invite_daily_limit_exceeded: messages.emailInviteDailyLimitExceeded,
  user_invite_daily_limit_exceeded: messages.userInviteDailyLimitExceeded,
  precondition_failed: messages.preconditionFailed,
  pending_invite: (
    <FormattedMessage
      {...messages.pendingInvite}
      values={{
        br: (
          <>
            <br />
            <br />
          </>
        ),
        a: (...children) => <a href={`mailto:${children}`}>{children}</a>,
      }}
    />
  ),
}

export function getErrorMessage(payload) {
  let errorCode = null
  let message = messages.clientError

  errorCode = path(["err", "response", "data", "code"], payload)
  if (!errorCode) {
    errorCode = path(["err", "code"], payload)
  }

  if (
    errorCode &&
    errorCode === "validation_error" &&
    path(["err", "response", "data", "fieldErrors"], payload)
  ) {
    values(payload.err.response.data.fieldErrors).forEach(value => {
      if (value && value.length) {
        errorCode = value[0].code
      }
    })
  }

  if (
    path(["err", "response", "data", "nonFieldErrors"], payload) &&
    payload.err.response.data.nonFieldErrors.length
  ) {
    errorCode = payload.err.response.data.nonFieldErrors[0].code
  }

  if (!errorCode && path(["err", "response", "data"], payload)) {
    if (typeof payload.err.response.data === "object") {
      try {
        const error = JSON.stringify(payload.err.response.data)
        message = `Backend error: ${error}`
      } catch (err) {
        message = `Backend error: ${payload.err.response.data}`
      }
    } else {
      message = `Backend error: ${payload.err.response.data}`
    }
  }

  if (errorCode && serverErrorsMap[errorCode]) {
    message = serverErrorsMap[errorCode]
  }
  if (config.env.isDev && errorCode && !serverErrorsMap[errorCode]) {
    message = `Missing error code handling for: '${errorCode}'. Add correct message to serverErrorsMap.js`
  }

  if (!config.env.isDev && (!errorCode || !serverErrorsMap[errorCode])) {
    message = messages.defaultError
  }

  if (typeof message === "function") {
    message = message(payload)
  }

  return message
}
