import { UPDATE } from "react-intl-redux"
import { getActionType } from "../lib/helpers"
import { GET, LOCALE, SUCCESS, FAIL, START } from "../constants"

export const initialState = {
  locale: "en",
  loading: false,
  loaded: false,
  messages: {},
}

export function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case UPDATE:
      return { ...state, ...payload }

    case getActionType(GET, LOCALE, START):
      return { ...state, loading: true }

    case getActionType(GET, LOCALE, SUCCESS):
      return { ...state, ...payload, loading: false, loaded: true }

    case getActionType(GET, LOCALE, FAIL):
      return { ...state, loading: false, loaded: true }

    default:
      return state
  }
}
