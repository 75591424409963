import { all, put, takeEvery } from "redux-saga/effects"
import { CLIENT_ERROR, FAIL } from "../constants"
import { logout } from "../actions/user-actions"

export function* errorSaga(action) {
  const { payload } = action

  if (
    payload &&
    payload.err &&
    payload.err.problem === CLIENT_ERROR &&
    payload.err.response.status === 401
  ) {
    yield put(logout())
  }
}

export const saga = function* () {
  yield all([takeEvery(action => action.type.includes(FAIL), errorSaga)])
}
