import { history } from "../history"
import { connectRouter } from "connected-react-router"
import { reducer as formReducer } from "redux-form"
import { reducer as responsiveReducer } from "react-responsive-redux"
import { reducer as systemReducer } from "../reducers/system-reducer"
import { reducer as userReducer } from "../reducers/user-reducer"
import { reducer as candidateReducer } from "../reducers/candidate-reducer"
import { reducer as matchesReducer } from "../reducers/match-reducer"
import { reducer as jobsReducer } from "../reducers/job-reducer"
import { reducer as companyReducer } from "../reducers/company-reducer"
import { reducer as threadReducer } from "../reducers/thread-reducer"
import { reducer as messageReducer } from "../reducers/message-reducer"
import { reducer as botReducer } from "../reducers/bot-reducer"
import { reducer as intlReducer } from "../reducers/intl-reducer"
import { reducer as topCvReducer } from "../reducers/top-cv-reducer"
import { reducer as fcmReducer } from "../reducers/fcm-reducer"
import { reducer as surveyReducer } from "../reducers/survey-reducer"
import { reducer as externalJobsReducer } from "../reducers/external-job-reducer"

export const reducers = {
  system: systemReducer,
  user: userReducer,
  candidate: candidateReducer,
  matches: matchesReducer,
  jobs: jobsReducer,
  companies: companyReducer,
  threads: threadReducer,
  messages: messageReducer,
  bot: botReducer,
  router: connectRouter(history),
  form: formReducer,
  responsive: responsiveReducer,
  intl: intlReducer,
  topCv: topCvReducer,
  fcm: fcmReducer,
  survey: surveyReducer,
  externalJobs: externalJobsReducer,
}
