import React, { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Modal, Progress } from "reactstrap"
import { JobApplicationQuestionsForm } from "../forms/job-application-questions-form"
import { APPLICATION_QUESTION_TYPES } from "../../constants"
import { useApplicationQuestions } from "../../hooks/use-application-questions"
import { JobApplicationModalHeader } from "./job-application-modal-header"
import { MotivationalNoteComponent } from "./motivational-note-component"
import { FormattedMessage, defineMessages } from "react-intl"
import { applyForJob } from "../../actions/job-actions"

const messages = defineMessages({
  progress: { id: "app.job.questions.questionsCompletion" },
})
const toFieldName = ({ id }) => `question-${id}`
const INITIAL_FIELD_VALUE_MAP = {
  [APPLICATION_QUESTION_TYPES.MULTIPLE_SELECTION]: [],
}

const getInitialValues = applicationQuestions => {
  return applicationQuestions.reduce((initialValues, question) => {
    initialValues[toFieldName(question)] =
      INITIAL_FIELD_VALUE_MAP[question.questionType] ?? ""
    return initialValues
  }, {})
}
export const JobFinalizeApplicationModal = ({
  isOpen,
  onClose,
  jobId,
  hasQuestions,
  isApplying,
}) => {
  const { applicationQuestions } = useApplicationQuestions({ jobId })
  const [step, setStep] = useState(0)
  const [applicationAnswers, setApplicationAnswers] = useState({})

  const dispatch = useDispatch()

  const handleSubmit = coverNote => {
    if (coverNote) {
      dispatch(
        applyForJob({
          jobId,
          motivationalNote: coverNote,
          answers: applicationAnswers,
        }),
      )
    } else {
      dispatch(
        applyForJob({
          jobId,
          answers: applicationAnswers,
        }),
      )
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <JobApplicationModalHeader
        onClose={onClose}
        jobId={jobId}
        step={step}
        hasQuestions={hasQuestions}
      />
      {hasQuestions && (
        <Fragment>
          <Progress
            value={step + 1}
            max={applicationQuestions?.length + 1}
            className="application-questions-progress"
          />
          {step < applicationQuestions?.length && (
            <h5 className="mt-3 mb-0 ml-3">
              <FormattedMessage
                {...messages.progress}
                values={{
                  totalQuestions: applicationQuestions?.length,
                  currentQuestion: step + 1,
                }}
              />
            </h5>
          )}
        </Fragment>
      )}

      {hasQuestions && applicationQuestions && (
        <JobApplicationQuestionsForm
          applicationQuestions={applicationQuestions}
          initialValues={getInitialValues(applicationQuestions)}
          step={step}
          setStep={setStep}
          onSubmit={answers => {
            setApplicationAnswers(answers)
          }}
          jobId={jobId}
        />
      )}
      {(!hasQuestions || step === applicationQuestions?.length) && (
        <MotivationalNoteComponent
          handleSubmit={handleSubmit}
          isApplying={isApplying}
        />
      )}
    </Modal>
  )
}

JobFinalizeApplicationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  hasQuestions: PropTypes.bool.isRequired,
  isApplying: PropTypes.bool.isRequired,
}

JobFinalizeApplicationModal.defaultProps = {
  isOpen: false,
}
