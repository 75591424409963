import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { PopoverBody, UncontrolledPopover } from "reactstrap"
import { Badge } from "./badge-component"

export const PrescreenQuestionsTag = ({ id }) => (
  <Fragment>
    <Badge
      id={`prescreen-questions-popover-${id}`}
      color="light"
      className="text-truncate align-middle"
    >
      <FormattedMessage id="app.job.questions.tag" />
    </Badge>
    <UncontrolledPopover
      trigger="hover"
      placement="top"
      target={`prescreen-questions-popover-${id}`}
    >
      <PopoverBody>
        <FormattedMessage id="app.job.questions.popover" />
      </PopoverBody>
    </UncontrolledPopover>
  </Fragment>
)

PrescreenQuestionsTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
