import React from "react"
import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import { UncontrolledCarousel } from "../../../common/carousel-component"

export const ResumePreviewModal = ({ isOpen, close, urls }) => {
  const activeControls = Boolean(urls && urls.length > 1)

  return (
    <Modal
      contentClassName="cv-preview-modal"
      isOpen={isOpen}
      toggle={close}
      size="lg"
      centered
    >
      <UncontrolledCarousel
        className="_lr-hide"
        items={urls}
        autoPlay={false}
        interval={86400000}
        indicators={activeControls}
        controls={activeControls}
      />
    </Modal>
  )
}

ResumePreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  urls: PropTypes.array.isRequired,
}
