import React from "react"
import PropTypes from "prop-types"
import { push } from "connected-react-router"
import { compose } from "redux"
import { connect } from "react-redux"

import { updateCandidate } from "../actions/candidate-actions"
import { authRequired } from "../decorators/auth-required"
import { noCompletedProfileRequired } from "../decorators/no-completed-profile-required"
import { PersonalDetailsPage } from "./personal-details-page"

const personalDetailsFormName = "PersonalDetailsForm"

const RegistrationPersonal = ({ location, updateCandidate, push }) => {
  const personalDetailsSubmitHandler = value => {
    updateCandidate({
      candidate: value,
      formName: personalDetailsFormName,
      routing: {
        pathname: "/registration/professional",
        search: location.search,
      },
    })
  }

  return (
    <>
      <PersonalDetailsPage
        formName={personalDetailsFormName}
        onSubmit={personalDetailsSubmitHandler}
      />
      <img
        src="https://www.talent.com/tracker/img-pixel.php?tracker=moeberries"
        alt="pixel tracker"
      />
    </>
  )
}

RegistrationPersonal.propTypes = {
  location: PropTypes.object,
  push: PropTypes.func,
  updateCandidate: PropTypes.func,
}

const mapDispatchToProps = {
  updateCandidate,
  push,
}

export const RegistrationPersonalPage = compose(
  noCompletedProfileRequired,
  authRequired,
  connect(null, mapDispatchToProps),
)(RegistrationPersonal)
