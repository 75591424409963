import React, { useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import {
  compose,
  pipe,
  map,
  groupBy,
  prop,
  keys,
  isEmpty,
  isNil,
  pick,
} from "ramda"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { connect } from "react-redux"
import { ErrorPage } from "./error-page"
import { authRequired } from "../decorators/auth-required"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { getFeedbackSummary } from "../actions/match-actions"
import { ApplicationsPageLayout } from "../components/layouts/applications-page-layout"
import { BaseSidebar } from "../components/sidebar/base-sidebar"
import { feedbackSummarySelector } from "../selectors/match-selector"
import { Badge } from "../components/common/badge-component"
import humps from "humps"
import { ApplicationsPageSkeleton } from "../components/skeletons/applications-page-skeleton"
import emptyStateImg from "../assets/img/default-state.svg"
import { Img } from "../components/common/img-component"

const messages = defineMessages({
  loadingTitle: { id: "app.applications.feedbackSummary.loading.title" },
  title: { id: "app.applications.feedbackSummary.title" },
  subtitle: { id: "app.applications.feedbackSummary.subtitle" },
  emptyMessage: { id: "app.applications.feedbackSummary.emptyMessage" },

  frequentCareerChanges: {
    id: "app.applications.feedbackSummary.frequentCareerChanges",
  },
  frequentCareerChangesTip: {
    id: "app.applications.feedbackSummary.frequentCareerChanges.tip",
  },
  wrongIndustry: { id: "app.applications.feedbackSummary.wrongIndustry" },
  wrongIndustryTip: {
    id: "app.applications.feedbackSummary.wrongIndustry.tip",
  },
  notQualified: { id: "app.applications.feedbackSummary.notQualified" },
  notQualifiedTip: { id: "app.applications.feedbackSummary.notQualified.tip" },
  overqualified: { id: "app.applications.feedbackSummary.overqualified" },
  overqualifiedTip: {
    id: "app.applications.feedbackSummary.overqualified.tip",
  },
  missing: { id: "app.applications.feedbackSummary.missing" },
  missingTip: { id: "app.applications.feedbackSummary.missing.tip" },
  missingLinkHref: {
    id: "app.applications.feedbackSummary.missing.tip.linkHref",
  },
  languageSkillsMismatch: {
    id: "app.applications.feedbackSummary.languageSkillsMismatch",
  },
  languageSkillsMismatchTip: {
    id: "app.applications.feedbackSummary.languageSkillsMismatch.tip",
  },
  languageSkillsMismatchLinkHref: {
    id: "app.applications.feedbackSummary.languageSkillsMismatch.tip.linkHref",
  },
  careerMismatch: { id: "app.applications.feedbackSummary.careerMismatch" },
  careerMismatchTip: {
    id: "app.applications.feedbackSummary.careerMismatch.tip",
  },
  careerMismatchLinkHref: {
    id: "app.applications.feedbackSummary.careerMismatch.tip.linkHref",
  },
  highExpectations: { id: "app.applications.feedbackSummary.highExpectations" },
  highExpectationsTip: {
    id: "app.applications.feedbackSummary.highExpectations.tip",
  },
  highExpectationsLinkHref: {
    id: "app.applications.feedbackSummary.highExpectations.tip.linkHref",
  },
  mismatch: { id: "app.applications.feedbackSummary.mismatch" },
  mismatchTip: { id: "app.applications.feedbackSummary.mismatch.tip" },
  mismatchLinkHref: {
    id: "app.applications.feedbackSummary.mismatch.tip.linkHref",
  },
  differsFromCv: { id: "app.applications.feedbackSummary.differsFromCv" },
  differsFromCvTip: {
    id: "app.applications.feedbackSummary.differsFromCv.tip",
  },
  differsFromCvLinkHref: {
    id: "app.applications.feedbackSummary.differsFromCv.tip.linkHref",
  },
  differsFromJobLocation: {
    id: "app.applications.feedbackSummary.differsFromJobLocation",
  },
  differsFromJobLocationTip: {
    id: "app.applications.feedbackSummary.differsFromJobLocation.tip",
  },
  differsFromJobLocationLinkHref: {
    id: "app.applications.feedbackSummary.differsFromJobLocation.tip.linkHref",
  },
  noRelocate: { id: "app.applications.feedbackSummary.noRelocate" },
  noRelocateTip: { id: "app.applications.feedbackSummary.noRelocate.tip" },
  noRelocateLinkHref: {
    id: "app.applications.feedbackSummary.noRelocate.tip.linkHref",
  },
  noVisa: { id: "app.applications.feedbackSummary.noVisa" },
  noVisaTip: { id: "app.applications.feedbackSummary.noVisa.tip" },
  missingInfo: { id: "app.applications.feedbackSummary.missingInfo" },
  missingInfoTip: { id: "app.applications.feedbackSummary.missingInfo.tip" },
  mistakes: { id: "app.applications.feedbackSummary.mistakes" },
  mistakesTip: { id: "app.applications.feedbackSummary.mistakes.tip" },
  poorStructure: { id: "app.applications.feedbackSummary.poorStructure" },
  poorStructureTip: {
    id: "app.applications.feedbackSummary.poorStructure.tip",
  },
  poorStructureLinkHref: {
    id: "app.applications.feedbackSummary.poorStructure.tip.linkHref",
  },
  notRelevantEducation: {
    id: "app.applications.feedbackSummary.notRelevantEducation",
  },
  notRelevantEducationTip: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip",
  },
  notRelevantEducationLinkHref: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip.linkHref",
  },
  notRelevantEducationLinkBHref: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip.linkBHref",
  },
  notRelevantExperience: {
    id: "app.applications.feedbackSummary.notRelevantEducation",
  },
  notRelevantExperienceTip: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip",
  },
  notRelevantExperienceLinkHref: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip.linkHref",
  },
  notRelevantExperienceLinkBHref: {
    id: "app.applications.feedbackSummary.notRelevantEducation.tip.linkBHref",
  },
  noDegree: { id: "app.applications.feedbackSummary.noDegree" },
  noDegreeTip: { id: "app.applications.feedbackSummary.noDegree.tip" },
  missingSkillsTip: {
    id: "app.applications.feedbackSummary.missingSkills.tip",
  },
  missingSkillsLink: {
    id: "app.applications.feedbackSummary.missingSkills.tip.link",
  },
  missingSkillsHref: {
    id: "app.applications.feedbackSummary.missingSkills.tip.linkHref",
  },

  jobClaims: { id: "app.applications.feedbackSummary.jobClaims" },
  location: { id: "app.applications.feedbackSummary.location" },
  experience: { id: "app.applications.feedbackSummary.experience" },
  language: { id: "app.applications.feedbackSummary.language" },
  education: { id: "app.applications.feedbackSummary.education" },
  cv: { id: "app.applications.feedbackSummary.cv" },
  missingSkills: { id: "app.applications.feedbackSummary.missingSkills" },
})

const ICON_MAP = {
  experience: "fas fa-briefcase",
  location: "fas fa-map-marker-alt",
  language: "fas fa-language",
  jobClaims: "fas fa-hammer",
  education: "fas fa-graduation-cap",
  cv: "fas fa-file-alt",
  missingSkills: "fas fa-tags",
}

export const FeedbackSummaryPageComponent = ({
  err,
  feedbackSummary,
  getFeedbackSummary,
}) => {
  const intl = useIntl()

  useEffect(() => {
    getFeedbackSummary()
  }, [getFeedbackSummary])

  if (err) {
    return <ErrorPage err={err} />
  }

  if (isNil(feedbackSummary)) {
    return (
      <ApplicationsPageSkeleton
        header={
          <Fragment>
            <h1>
              <FormattedMessage {...messages.loadingTitle} />
            </h1>
            <p className="text-muted">
              <FormattedMessage {...messages.subtitle} />
            </p>
          </Fragment>
        }
      />
    )
  }
  const feedbackChoicesGroups =
    feedbackSummary.feedbackChoices &&
    groupFeedbackChoices(feedbackSummary.feedbackChoices)
  return (
    <ApplicationsPageLayout
      header={
        <Fragment>
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <p className="text-muted">
            <FormattedMessage {...messages.subtitle} />
          </p>
        </Fragment>
      }
      content={
        <Fragment>
          <div className="bg-white p-3 rounded">
            {isEmpty(feedbackSummary) ? (
              <div className="p-2 text-center">
                <FormattedMessage {...messages.emptyMessage} />
                <Img
                  src={emptyStateImg}
                  width="450"
                  alt="Empty"
                  className="img-fluid mt-2"
                />
              </div>
            ) : (
              <Fragment>
                {keys(feedbackChoicesGroups).map(category => (
                  <div key={category}>
                    <div className="mb-3">
                      <h5>
                        <div className="icon-circled mr-2">
                          <i className={ICON_MAP[category]} />
                        </div>
                        <FormattedMessage {...messages[category]} />
                      </h5>
                    </div>
                    {feedbackChoicesGroups[category].map(reason => (
                      <div key={reason.value}>
                        <Badge className="mb-3" color="danger-light">
                          <span className="font-weight-bold">
                            <FormattedMessage {...messages[reason.value]} />
                          </span>
                          <Badge color="danger" pill className="ml-2 px-2">
                            {reason.count}
                          </Badge>
                        </Badge>
                        <p className="mb-3">
                          <FormattedMessage
                            {...messages[`${reason.value}Tip`]}
                            values={{
                              link_a: (...text) => (
                                <a
                                  href={intl.formatMessage(
                                    messages[`${reason.value}LinkHref`],
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {text}
                                </a>
                              ),
                              link_b: (...text) => (
                                <a
                                  href={intl.formatMessage(
                                    messages[`${reason.value}LinkBHref`],
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {text}
                                </a>
                              ),
                            }}
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
                {!isEmpty(feedbackSummary.missingSkills) && (
                  <div>
                    <div className="mb-3">
                      <h5>
                        <div className="icon-circled mr-2">
                          <i className={ICON_MAP.missingSkills} />
                        </div>
                        <FormattedMessage {...messages.missingSkills} />
                      </h5>
                    </div>
                    {feedbackSummary.missingSkills.map(skill => (
                      <Badge
                        key={skill.name}
                        className="mr-2 mb-3"
                        color="danger-light"
                      >
                        <span className="font-weight-bold">{skill.name}</span>
                        <Badge color="danger" pill className="ml-2 px-2">
                          {skill.count}
                        </Badge>
                      </Badge>
                    ))}
                    <p>
                      <FormattedMessage
                        {...messages.missingSkillsTip}
                        values={{
                          link_a: (...text) => (
                            <a
                              href={intl.formatMessage(
                                messages.missingSkillsHref,
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {text}
                            </a>
                          ),
                        }}
                      />
                    </p>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </Fragment>
      }
      sidebar={<BaseSidebar />}
    />
  )
}

FeedbackSummaryPageComponent.propTypes = {
  getFeedbackSummary: PropTypes.func.isRequired,
  feedbackSummary: PropTypes.object,
  err: PropTypes.object,
}

FeedbackSummaryPageComponent.defaultProps = {}

const mapDispatchToProps = { getFeedbackSummary }

const mapStateToProps = state => ({
  feedbackSummary: feedbackSummarySelector(state),
})

function groupFeedbackChoices(feedbackChoices) {
  return pipe(
    map(fc => ({
      id: fc.id,
      value: humps.camelize(fc.value),
      category: fc?.category && humps.camelize(fc.category.value),
      count: fc.count,
    })),
    groupBy(prop("category")),
    pick([
      "education",
      "location",
      "jobClaims",
      "cv",
      "experience",
      "language",
    ]),
  )(feedbackChoices)
}

export const FeedbackSummaryPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(FeedbackSummaryPageComponent)
