import React from "react"
import ContentLoader from "react-content-loader"
import PropTypes from "prop-types"

const WIDTH = 320
const HEIGHT = 45
const PADDING = 10
const MESSAGE_WIDTH = 200
const MESSAGE_HEIGHT = 35

export function MessageLoader({ onTheRight }) {
  const rightMessageX = WIDTH - PADDING - MESSAGE_WIDTH
  return (
    <ContentLoader viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
      <rect
        x={onTheRight ? rightMessageX : PADDING}
        y={PADDING / 2}
        rx="4"
        ry="4"
        width={MESSAGE_WIDTH}
        height={MESSAGE_HEIGHT}
      />
    </ContentLoader>
  )
}

MessageLoader.propTypes = {
  onTheRight: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
}

MessageLoader.defaultProps = {
  onTheRight: false,
}
