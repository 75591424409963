import { getActionType } from "../lib/helpers"
import {
  REQUEST,
  LOGIN,
  LOGOUT,
  SIGNUP,
  CONNECT,
  FACEBOOK,
  LINKEDIN,
  CONFIRM,
  EMAIL,
  SOCIAL,
  PROVIDER,
  RESEND,
  CONFIRMATION,
  RECOVER,
  PASSWORD,
  RESET,
  NEW,
  UPDATE,
  ACCOUNT,
  DELETE,
  DISCONNECT,
  APPLICATION,
  DATA,
  REMOVE,
  ACCEPT,
  COOKIE,
  USAGE,
  MOBOT,
} from "../constants"

export function loginWithEmailAndPassword({ email, password }) {
  return {
    type: getActionType(LOGIN, REQUEST),
    payload: { email, password },
  }
}

export function loginWithFacebook() {
  return {
    type: getActionType(LOGIN, FACEBOOK, REQUEST),
  }
}

export function loginWithLinkedin() {
  return {
    type: getActionType(LOGIN, LINKEDIN, REQUEST),
  }
}

export function signupWithLinkedin({ isNewsletterActive }) {
  return {
    type: getActionType(SIGNUP, LINKEDIN, REQUEST),
    payload: {
      isNewsletterActive,
    },
  }
}

export function connectLinkedin() {
  return {
    type: getActionType(CONNECT, LINKEDIN, REQUEST),
  }
}

export function disconnectFacebook() {
  return {
    type: getActionType(DISCONNECT, FACEBOOK, REQUEST),
  }
}

export function disconnectLinkedin() {
  return {
    type: getActionType(DISCONNECT, LINKEDIN, REQUEST),
  }
}

export function signupWithEmailAndPassword({ email, password }) {
  return {
    type: getActionType(SIGNUP, REQUEST),
    payload: { email, password },
  }
}

export function confirmEmailWithCode({ code }) {
  return {
    type: getActionType(CONFIRM, EMAIL, REQUEST),
    payload: { code },
  }
}

export function confirmMobotEmailWithCode({ code }) {
  return {
    type: getActionType(CONFIRM, MOBOT, EMAIL, REQUEST),
    payload: { code },
  }
}

export function confirmNewEmailWithCode({ code }) {
  return {
    type: getActionType(CONFIRM, NEW, EMAIL, REQUEST),
    payload: { code },
  }
}

export function connectSocialProvider() {
  return {
    type: getActionType(CONNECT, SOCIAL, PROVIDER, REQUEST),
  }
}

export function resendEmailConfirmation({ email }) {
  return {
    type: getActionType(RESEND, EMAIL, CONFIRMATION, REQUEST),
    payload: { email },
  }
}

export function updateEmail({ id, email }) {
  return {
    type: getActionType(UPDATE, EMAIL, REQUEST),
    payload: { id, email },
  }
}

export function updatePassword({ password, passwordNew }) {
  return {
    type: getActionType(UPDATE, PASSWORD, REQUEST),
    payload: { password, passwordNew },
  }
}

export function recoverPassword({ email }) {
  return {
    type: getActionType(RECOVER, PASSWORD, REQUEST),
    payload: { email },
  }
}

export function resetPassword({ password, code }) {
  return {
    type: getActionType(RESET, PASSWORD, REQUEST),
    payload: { password, code },
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}

export function deleteAccount({ reasons, comment }) {
  return {
    type: getActionType(DELETE, ACCOUNT, REQUEST),
    payload: { reasons, comment },
  }
}

export function confirmDeleteAccount({ code }) {
  return {
    type: getActionType(CONFIRM, DELETE, ACCOUNT, REQUEST),
    payload: { code },
  }
}

export function removeLocalData() {
  return {
    type: getActionType(REMOVE, APPLICATION, DATA),
  }
}

export function acceptCookie() {
  return {
    type: getActionType(ACCEPT, COOKIE, USAGE),
  }
}
