import {
  REFRESH,
  TOKEN,
  SUCCESS,
  FAIL,
  LOGIN,
  LOGOUT,
  START,
  CONFIRM,
  EMAIL,
  FACEBOOK,
  LINKEDIN,
  CONNECT,
  SOCIAL,
  RESEND,
  CONFIRMATION,
  RECOVER,
  PASSWORD,
  RESET,
  ACCOUNT,
  DELETE,
  DISCONNECT,
  REDIRECT,
  ACCEPT,
  COOKIE,
  USAGE,
  MOBOT,
} from "../constants"

import { always, evolve, F, T } from "ramda"
import { getActionType } from "../lib/helpers"

export const defaultState = {
  token: null,
  tokenUpdatedAt: null,
  accountType: null,
  loading: false,
  loaded: false,
  passwordRecoveryLoading: false,
  passwordRecoveryErr: null,
  passwordResetLoading: false,
  passwordResetErr: null,
  confirmationEmailResending: false,
  confirmationEmailResendingErr: null,
  connectingFacebook: false,
  connectingLinkedin: false,
  disconnectingFacebook: false,
  disconnectingLinkedin: false,
  deleting: false,
  deletingErr: null,
  err: null,
  cookieConsent: false,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(LOGIN, START):
      return evolve({ loading: T }, state)

    case getActionType(CONFIRM, MOBOT, EMAIL, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: always(null),
        },
        state,
      )

    case getActionType(CONFIRM, EMAIL, SUCCESS):
    case getActionType(LOGIN, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: always(null),
          accountType: always(payload.accountType),
          token: always(payload.token),
          tokenUpdatedAt: always(new Date()),
        },
        state,
      )

    case getActionType(CONNECT, FACEBOOK, REDIRECT, START):
    case getActionType(CONNECT, FACEBOOK, START):
      return evolve({ connectingFacebook: T }, state)

    case getActionType(CONNECT, LINKEDIN, REDIRECT, START):
    case getActionType(CONNECT, LINKEDIN, START):
      return evolve({ connectingLinkedin: T }, state)

    case getActionType(CONNECT, FACEBOOK, REDIRECT, SUCCESS):
      return evolve({ connectingFacebook: F }, state)

    case getActionType(CONNECT, FACEBOOK, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: always(null),
          connectingFacebook: F,
          accountType: always(payload.accountType),
          token: always(payload.token),
          tokenUpdatedAt: always(new Date()),
        },
        state,
      )

    case getActionType(CONNECT, LINKEDIN, REDIRECT, SUCCESS):
      return evolve({ connectingLinkedin: F }, state)

    case getActionType(CONNECT, LINKEDIN, SUCCESS):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: always(null),
          connectingLinkedin: F,
          accountType: always(payload.accountType),
          token: always(payload.token),
          tokenUpdatedAt: always(new Date()),
        },
        state,
      )

    case getActionType(CONNECT, FACEBOOK, REDIRECT, FAIL):
    case getActionType(CONNECT, FACEBOOK, FAIL):
      return evolve({ connectingFacebook: F }, state)

    case getActionType(CONNECT, LINKEDIN, REDIRECT, FAIL):
    case getActionType(CONNECT, LINKEDIN, FAIL):
      return evolve({ connectingLinkedin: F }, state)

    case getActionType(CONNECT, SOCIAL, FAIL):
      return evolve({ connectingFacebook: F, connectingLinkedin: F }, state)

    case getActionType(LOGIN, FAIL):
    case getActionType(REFRESH, TOKEN, FAIL):
      return evolve(
        {
          loading: F,
          loaded: T,
          err: always(payload.err),
          accountType: always(null),
          token: always(null),
          tokenUpdatedAt: always(null),
        },
        state,
      )

    case getActionType(DISCONNECT, FACEBOOK, START):
      return evolve({ disconnectingFacebook: T }, state)

    case getActionType(DISCONNECT, FACEBOOK, SUCCESS):
      return evolve(
        {
          disconnectingFacebook: F,
        },
        state,
      )

    case getActionType(DISCONNECT, FACEBOOK, FAIL):
      return evolve({ disconnectingFacebook: F }, state)

    case getActionType(DISCONNECT, LINKEDIN, START):
      return evolve({ disconnectingLinkedin: T }, state)

    case getActionType(DISCONNECT, LINKEDIN, SUCCESS):
      return evolve(
        {
          disconnectingLinkedin: F,
        },
        state,
      )

    case getActionType(DISCONNECT, LINKEDIN, FAIL):
      return evolve({ disconnectingLinkedin: F }, state)

    case getActionType(REFRESH, TOKEN, SUCCESS):
      return evolve(
        {
          token: always(payload.token),
          tokenUpdatedAt: always(new Date()),
        },
        state,
      )

    case getActionType(RESEND, EMAIL, CONFIRMATION, START):
      return evolve({ confirmationEmailResending: T }, state)

    case getActionType(RESEND, EMAIL, CONFIRMATION, SUCCESS):
      return evolve(
        {
          confirmationEmailResending: F,
          confirmationEmailResendingErr: always(null),
        },
        state,
      )

    case getActionType(RESEND, EMAIL, CONFIRMATION, FAIL):
      return evolve(
        {
          confirmationEmailResending: F,
          confirmationEmailResendingErr: always(payload.err),
        },
        state,
      )

    case getActionType(RECOVER, PASSWORD, START):
      return evolve({ passwordRecoveryLoading: T }, state)

    case getActionType(RECOVER, PASSWORD, SUCCESS):
      return evolve(
        {
          passwordRecoveryLoading: F,
          passwordRecoveryErr: always(null),
        },
        state,
      )

    case getActionType(RECOVER, PASSWORD, FAIL):
      return evolve(
        {
          passwordRecoveryLoading: F,
          passwordRecoveryErr: always(payload.err),
        },
        state,
      )

    case getActionType(RESET, PASSWORD, START):
      return evolve({ passwordResetLoading: T }, state)

    case getActionType(RESET, PASSWORD, SUCCESS):
      return evolve(
        {
          passwordResetLoading: F,
          passwordResetErr: always(null),
        },
        state,
      )

    case getActionType(RESET, PASSWORD, FAIL):
      return evolve(
        {
          passwordResetLoading: F,
          passwordResetErr: always(payload.err),
        },
        state,
      )

    case getActionType(DELETE, ACCOUNT, START):
      return evolve({ deleting: T }, state)

    case getActionType(DELETE, ACCOUNT, SUCCESS):
      return evolve({ deleting: F, deletingErr: always(null) }, state)

    case getActionType(DELETE, ACCOUNT, FAIL):
      return evolve({ deleting: F, deletingErr: always(payload.err) }, state)

    case getActionType(LOGOUT, SUCCESS):
      return evolve(
        {
          err: always(null),
          accountType: always(null),
          token: always(null),
          tokenUpdatedAt: always(null),
        },
        state,
      )

    case getActionType(ACCEPT, COOKIE, USAGE):
      return evolve({ cookieConsent: T }, state)

    default:
      return state
  }
}
