import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"

import { confirmNewEmailWithCode } from "../actions/user-actions"

export class ConfirmNewEmail extends Component {
  static propTypes = {
    confirmNewEmailWithCode: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { match, confirmNewEmailWithCode } = this.props
    confirmNewEmailWithCode({ code: match.params.code })
  }

  render() {
    return null
  }
}

const mapDispatchToProps = { confirmNewEmailWithCode }

const mapStateToProps = function (state) {
  return {}
}

export const ConfirmNewEmailPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ConfirmNewEmail)
