import React from "react"
import { defineMessages, FormattedMessage } from "react-intl"
import { Button } from "../common/button-component"

const messages = defineMessages({
  feedbackDescription: { id: "app.sidebar.feedback.description" },
  feedbackOpen: { id: "app.sidebar.feedback.open" },
})

export function FeedbackSummarySidebar() {
  return (
    <div className="sidebar-widget bg-primary-light mb-3 p-3 rounded d-flex align-items-center">
      <div className="mr-3">
        <span className="fa-stack fa-3x">
          <i className="fas fa-circle fa-stack-2x text-light" />
          <i className="fad fa-comment-alt-exclamation fa-stack-1x"></i>
        </span>
      </div>
      <div>
        <p className="mb-2">
          <FormattedMessage {...messages.feedbackDescription} />
        </p>
        <Button color="primary" tag="a" href="/applications/summary">
          <FormattedMessage {...messages.feedbackOpen} />
        </Button>
      </div>
    </div>
  )
}
