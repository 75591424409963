import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "reactstrap"
import { FormattedMessage } from "react-intl"
import { compose, includes } from "ramda"
import cn from "classnames"

import { authRequired } from "../../decorators/auth-required"
import { completedProfileRequired } from "../../decorators/completed-profile-required"
import { candidateSelector } from "../../selectors/candidate-selector"

import styles from "./profile-my-preference-panel.module.scss"
import { getMissingPositionIds } from "../../lib/helpers"

export const JOB_LEVELS = [
  { id: 0, name: "Student" },
  { id: 1, name: "Entry" },
  { id: 2, name: "Intermediate" },
  { id: 3, name: "Senior" },
  { id: 4, name: "Team Lead" },
  { id: 5, name: "Executive" },
]

export const JOB_TYPES = [
  { id: 1, name: "Full time" },
  { id: 2, name: "Part time" },
  { id: 3, name: "Contract" },
  { id: 4, name: "Internship" },
  { id: 5, name: "Temporary" },
]

export const CANDIDATE_AVAILABILITY = [
  { value: "immediately", name: "Immediately" },
  { value: "in_three_months", name: "In a period of 3 months" },
  {
    value: "more_than_three_months",
    name: "More than 3 months",
  },
]

export const locationsMapper = location => {
  if (location.country) {
    return `${location.name}, ${location.country.name}`
  }

  return location.name
}

export const ProfileMyPreference = ({ candidate }) => {
  const jobLevel = JOB_LEVELS.find(l => l.id === candidate.careerLevel)?.name
  const employmentType = candidate.jobTypes.map(
    j => JOB_TYPES.find(t => t.id === j)?.name,
  )
  const missingPositionIds = getMissingPositionIds({
    experience: candidate.workExperience,
    positions: candidate.jobPosition,
    certificates: candidate.certificates,
  })

  const remote = candidate.remote ? "Yes" : "No"
  const relocate = candidate.relocate ? "Yes" : "No"
  const relocationPreferences = [
    ...(candidate.relocationCountries || []),
    ...(candidate.locations || []),
  ].map(locationsMapper)
  const availability =
    CANDIDATE_AVAILABILITY.find(a => a.value === candidate.availability)
      ?.name || "N/A"

  return (
    <div className={styles.container}>
      <Button
        className={styles.editButton}
        onClick={() => window.location.replace("/profile/preferences/edit")}
      >
        <FormattedMessage id="app.common.edit" />{" "}
        <i className={cn("fas fa-edit", styles.icon)} />
      </Button>
      {candidate.jobPosition.length > 0 && (
        <div className={styles.section}>
          <h2 className={styles.label}>
            <FormattedMessage id="app.registration.tabs.preferences.input.jobPosition.label" />
          </h2>
          <div className={styles.containerTags}>
            {candidate.jobPosition.map((p, i) => (
              <div key={i} className={styles.tagWrapper}>
                {includes(p.id, missingPositionIds) && (
                  <div className={styles.warningIcon}>
                    <i
                      id={`positions-warning-popover-${p.id}`}
                      className="fas fa-exclamation-triangle text-warning"
                    />
                  </div>
                )}
                <p className={styles.tag}>{p.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.preferences.input.careerLevel.label" />
        </h2>
        <div className={styles.containerTags}>
          <p className={styles.tag}>{jobLevel}</p>
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.preferences.input.employmentType.label" />
        </h2>
        <div className={styles.containerTags}>
          {employmentType.map((e, i) => (
            <p className={styles.tag} key={i}>
              {e}
            </p>
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.preferences.input.remote.label" />
        </h2>
        <div className={styles.containerTags}>
          <p className={styles.tag}>{remote}</p>
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.preferences.input.relocation.label" />
        </h2>
        <div className={styles.containerTags}>
          <p className={styles.tag}>{relocate}</p>
          {relocationPreferences.map(location => (
            <p className={styles.relocateOption} key={location}>
              {location}
            </p>
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <h2 className={styles.label}>
          <FormattedMessage id="app.registration.tabs.preferences.input.availability.label" />
        </h2>
        <div className={styles.containerTags}>
          <p className={styles.tag}>{availability}</p>
        </div>
      </div>

      {!!candidate.salaryMin && (
        <div className={styles.section}>
          <h2 className={styles.label}>
            <FormattedMessage id="app.registration.tabs.preferences.input.salary.label" />
          </h2>
          <p className={styles.tag}>{`${candidate.salaryMin} €`}</p>
        </div>
      )}
    </div>
  )
}

ProfileMyPreference.propTypes = {
  candidate: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

export const ProfileMyPreferencePanel = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps),
)(ProfileMyPreference)
