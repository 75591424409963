import { useState, useCallback } from "react"
import { last } from "ramda"

export const useList = (initialValue = []) => {
  const [value, set] = useState(initialValue)

  const remove = useCallback(val => set(value.filter(v => v !== val)), [value])
  const push = useCallback(
    val => {
      set(value.concat(val))
      return value.length + 1
    },
    [value],
  )

  const pop = useCallback(() => {
    set(value.slice(0, -1))
    return last(value)
  }, [value])

  const filter = fn => {
    const newValue = value.filter(fn)
    set(newValue)
    return newValue
  }
  const has = useCallback(item => value.includes(item), [value])
  const toggle = useCallback(
    item => {
      if (has(item)) {
        remove(item)
      } else {
        push(item)
      }
    },
    [has, remove, push],
  )

  const reset = useCallback(() => set(initialValue), [initialValue])
  return {
    value,
    set,
    remove,
    push,
    pop,
    filter,
    has,
    toggle,
    reset,
  }
}
