import { connectedReduxRedirect } from "redux-auth-wrapper/history4/redirect"
import { push } from "connected-react-router"
import { isCompleteSelector } from "../selectors/candidate-selector"

export const completedProfileRequired = connectedReduxRedirect({
  redirectPath: "/registration/personal",
  authenticatedSelector: state => isCompleteSelector(state),
  wrapperDisplayName: "completedProfileRequired",
  redirectQueryParamName: "redirectTo",
  redirectAction: push,
})
