import React from "react"
import PropTypes from "prop-types"
import { FormGroup, PopoverBody } from "reactstrap"
import { FormattedMessage } from "react-intl"

import { InfoHint } from "../../../common/info-hint-component"
import { Input } from "../../../common/input-component"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"

import styles from "./salary-section.module.scss"

const maxSalary = 1000000

export const SalarySection = ({ input, meta }) => {
  const { onChange } = input
  const { error, dirty } = meta

  const salaryChangeHandler = event => {
    let value = event.target.value

    if (value <= maxSalary) {
      onChange(value)
    }
  }

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.salary.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.preferences.input.salary.text" />
          </span>
        }
        hint={
          <InfoHint
            id="salaryHint"
            popover={
              <PopoverBody>
                <FormattedMessage id="app.registration.tabs.preferences.input.salary.hint" />
              </PopoverBody>
            }
          />
        }
      />
      {error && dirty && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}
      <div className={styles.inputWrapper}>
        <Input
          id="salary-value"
          placeholder="45000"
          input={{
            ...input,
            value: input.value || "",
            onChange: salaryChangeHandler,
          }}
          meta={{}}
        />
      </div>
    </FormGroup>
  )
}

SalarySection.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
}
