import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  delay,
} from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { api as pdfServiceApi } from "../lib/pdf-service-api"
import {
  candidateSelector,
  isNewsletterActiveSelector,
  loadingSelector,
  applicationIdSelector,
  predictionSelector,
  createdCvSelector,
} from "../selectors/candidate-selector"
import { tokenSelector } from "../selectors/user-selector"
import { startSubmit, stopSubmit, change, touch } from "redux-form"
import {
  createMatchSelector,
  getLocation,
  LOCATION_CHANGE,
  push,
} from "connected-react-router"
import FileSaver from "file-saver"
import { isNil, pick, union, uniq, is, mergeDeepLeft, path } from "ramda"
import cookie from "js-cookie"
import { config } from "../config"
import { setMatchingActivationRequestCancelled } from "../actions/candidate-actions"
import qs from "qs"
import {
  getActionType,
  getParamsFromCookies,
  mapMoberriesApiValidationError,
  getBase64,
  isStatus,
  isProblem,
  getCandidateChangedFields,
  isLinkedinLink,
} from "../lib/helpers"
import {
  ACTIVATE,
  CANDIDATE,
  CREATE,
  DEACTIVATE,
  FAIL,
  GET,
  LOCALE,
  MATCHING,
  REQUEST,
  SETTINGS,
  START,
  SUCCESS,
  UPDATE,
  UPLOAD,
  CV,
  CLIENT_ERROR,
  INITIAL,
  TRACKING,
  RELOAD,
  PAGE,
  TERMS,
  ACCEPT,
  DATA,
  EXPORT,
  REFRESH,
  PREDICTION,
  APPLY,
  SET,
  NEWSLETTER,
  EMAIL,
  ON,
  MATCH,
  ACTIVE,
  MODAL,
  APPLICATION,
  OPEN,
  CV_TYPES,
  ALERT_COLORS,
  INITIATE,
  SUBSCRIPTION,
  CANCEL,
  CV_STATUSES,
} from "../constants"
import { alertTypes, showRequestErrorAlert, showAlert } from "../lib/alerts"

export function* getTrackingsSaga() {
  const cookieParams = getParamsFromCookies()
  const applicationId = yield select(applicationIdSelector)

  let trackings = {}

  if (applicationId) {
    trackings = mergeDeepLeft(trackings, { tracking: { job: applicationId } })
  }
  if (cookieParams) {
    trackings = mergeDeepLeft(trackings, {
      tracking: cookieParams,
      invitationTracking: cookieParams,
    })
  }

  return trackings
}

export function* requestCandidateSaga({ id }) {
  try {
    let { data: candidate } = yield call(moberriesApi.getCandidate, { id })

    yield put({
      type: getActionType(GET, CANDIDATE, SUCCESS),
      payload: { candidate },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, CANDIDATE, FAIL), payload: { err } })
  }
}

export function* getCandidateSaga(action) {
  const { id } = action.payload

  yield put({ type: getActionType(GET, CANDIDATE, START) })

  yield call(requestCandidateSaga, { id })
}

export function* exportCandidateDataSaga(action) {
  const { id } = action.payload

  yield put({ type: getActionType(EXPORT, CANDIDATE, DATA, START) })

  try {
    const { data } = yield call(moberriesApi.exportCandidateData, {
      id,
    })

    yield call([FileSaver, FileSaver.saveAs], data, "data.json")
    yield put({ type: getActionType(EXPORT, CANDIDATE, DATA, SUCCESS) })
  } catch (err) {
    showRequestErrorAlert({ err })

    yield put({
      type: getActionType(EXPORT, CANDIDATE, DATA, FAIL),
      payload: { err },
    })
  }
}

export function* createCandidateSaga(action) {
  let { firstName, lastName, email, password, settings } = action.payload

  yield put({ type: getActionType(CREATE, CANDIDATE, START) })
  yield put(startSubmit("SignupForm"))
  let { tracking, invitationTracking } = yield call(getTrackingsSaga)
  const referral = cookie.get(config.cookie.keys.ref)

  try {
    const { data } = yield call(moberriesApi.createCandidate, {
      firstName,
      lastName,
      email,
      password,
      settings,
      tracking,
      invitationTracking,
      referral,
    })

    yield put(stopSubmit("SignupForm"))

    yield put({
      type: getActionType(CREATE, CANDIDATE, SUCCESS),
      payload: { ...data },
    })
    yield put(push("/signup-email-sent"))
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(
          "SignupForm",
          mapMoberriesApiValidationError(err.response.data),
        ),
      )
    } else {
      yield put(stopSubmit("SignupForm"))
    }

    yield put({
      type: getActionType(CREATE, CANDIDATE, FAIL),
      payload: { err },
    })
  }
}

export function* updateCandidateSaga(action) {
  let { id, candidate, formName, routing } = action.payload

  const shouldNotifyToReCreateCv =
    (candidate.candidateCv || {}).cvType === CV_TYPES.CREATE &&
    (formName === "ProfessionalDetailsForm" ||
      formName === "PersonalDetailsForm")

  yield put({ type: getActionType(UPDATE, CANDIDATE, START) })
  yield put(startSubmit(formName))

  candidate = {
    ...candidate,
    references: uniq(candidate.references.filter(Boolean)),
  }

  const fields = getCandidateChangedFields(
    candidate,
    yield select(candidateSelector),
  )

  const data = pick(fields, candidate)

  try {
    const { data: candidate } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: data,
    })

    yield put(stopSubmit(formName))

    yield put({
      type: getActionType(UPDATE, CANDIDATE, SUCCESS),
      payload: { candidate },
    })
    if (routing) yield put(push(routing))
    if (shouldNotifyToReCreateCv) {
      showAlert({
        code: alertTypes.cvReCreationNotification,
        color: ALERT_COLORS.WARNING,
      })
    }
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      yield put(
        stopSubmit(formName, mapMoberriesApiValidationError(err.response.data)),
      )
      showAlert({
        color: "error",
        code: alertTypes.formSomethingWrongError,
      })
    } else {
      yield put(stopSubmit(formName))
    }

    yield put({
      type: getActionType(UPDATE, CANDIDATE, FAIL),
      payload: { err },
    })
  }
}

function* applyPredictionFieldSaga(formName, field, value) {
  const candidate = yield select(candidateSelector)

  switch (field) {
    default:
      return
    case "references": {
      const joinedReferences = [""]
        .concat(union(candidate.references, value))
        .filter(l => !isLinkedinLink(l))
      const predictionLinkedinLink = value.find(isLinkedinLink)
      const existingLinkedinLink = candidate.references.find(isLinkedinLink)
      const chosenLinkedinLink = existingLinkedinLink || predictionLinkedinLink

      if (chosenLinkedinLink) {
        joinedReferences.push(chosenLinkedinLink)
      }

      yield put(change(formName, field, joinedReferences))
      break
    }

    case "careerLevel":
      yield put(change(formName, field, value.level))
      break

    case "languages": {
      const languagesWithLevels = value.filter(language =>
        is(Number, language.level),
      )
      if (languagesWithLevels.length > 0) {
        yield put(change(formName, field, languagesWithLevels))
        yield put(touch(formName, field))
      }
      break
    }

    case "jobRoles":
    case "skills":
      yield put(change(formName, field, value))
      break
  }
}

export function* applyCandidatePredictionSaga(action) {
  const { fields, formName } = action.payload

  const prediction = yield select(predictionSelector)
  if (!prediction) return

  let appliedFields = []

  yield all(
    fields.map(field => {
      if (!isNil(prediction[field])) {
        appliedFields.push(field)
        return call(
          applyPredictionFieldSaga,
          formName,
          field,
          prediction[field],
        )
      }
      return null
    }),
  )

  if (appliedFields.length > 0) {
    yield put({
      type: getActionType(APPLY, CANDIDATE, PREDICTION, SUCCESS),
      payload: {
        fields: appliedFields,
      },
    })
  }
}

export function* activateCandidateInitialSaga(action) {
  const { id, candidate, formName } = action.payload

  yield put({ type: getActionType(ACTIVATE, CANDIDATE, INITIAL, START) })
  yield put(startSubmit(formName))

  const fields = getCandidateChangedFields(
    candidate,
    yield select(candidateSelector),
  )

  const data = pick(fields, candidate)
  const cvStatus = path(["candidateCv", "status"], candidate)

  try {
    yield call(moberriesApi.updateCandidate, {
      id,
      candidate: data,
    })

    const { data: candidate } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { initial: true, active: cvStatus !== CV_STATUSES.ERROR },
    })

    yield put(stopSubmit(formName))

    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, INITIAL, SUCCESS),
      payload: { candidate },
    })

    yield put({ type: getActionType(OPEN, INITIAL, APPLICATION, MODAL) })
  } catch (err) {
    const payload = { err }

    if (isProblem(CLIENT_ERROR, payload) && isStatus(400, payload)) {
      const mappedError = mapMoberriesApiValidationError(err.response.data)
      yield put(stopSubmit(formName, mappedError))
      if (mappedError.active) {
        showAlert({
          color: "error",
          code: alertTypes.signUpFormNotCompletedError,
        })
      }
    } else {
      yield put(stopSubmit(formName))
    }

    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, INITIAL, FAIL),
      payload: { err },
    })
  }
}

export function* createCandidateCvSaga(action) {
  const { data, cvType } = action.payload
  const token = yield select(tokenSelector)

  yield put({
    type: getActionType(CREATE, CANDIDATE, CV, START),
    payload: { cvType },
  })

  try {
    let { data: cv } = yield call(pdfServiceApi.createCandidateCv, {
      data,
      token,
    })

    yield put({
      type: getActionType(CREATE, CANDIDATE, CV, SUCCESS),
      payload: { cv },
    })
    showAlert({ code: alertTypes.cvCreateSuccess })
  } catch (err) {
    yield put({
      type: getActionType(CREATE, CANDIDATE, CV, FAIL),
      payload: { err },
    })
  }

  const createdCv = yield select(createdCvSelector)

  yield call(uploadCandidateCvSaga, {
    payload: {
      data: createdCv,
      cvType,
    },
  })
}

export function* uploadCandidateCvSaga(action) {
  const { data, cvType } = action.payload

  yield put({
    type: getActionType(UPLOAD, CANDIDATE, CV, START),
    payload: { cvType },
  })

  try {
    const result = yield call(getBase64, data)

    if (!result) throw new Error()

    let { data: cv } = yield call(moberriesApi.uploadCandidateCv, {
      data: result,
      cvType,
    })

    const RETRY_COUNT = 3
    const RETRY_DELAY = 3000

    for (let i = 0; i < RETRY_COUNT; i++) {
      yield delay(RETRY_DELAY)

      try {
        const { data: candidate } = yield call(moberriesApi.getCandidate, {})
        const status = path(["candidateCv", "status"], candidate)

        if (status !== CV_STATUSES.PROCESSING) {
          cv = path(["candidateCv"], candidate)
          break
        }
      } catch (err) {
        if (i >= RETRY_COUNT - 1) {
          throw new Error()
        }
      }
    }

    yield put({
      type: getActionType(UPLOAD, CANDIDATE, CV, SUCCESS),
      payload: { cv },
    })
  } catch (err) {
    yield put({
      type: getActionType(UPLOAD, CANDIDATE, CV, FAIL),
      payload: { err },
    })
  }
}

export function* updateCandidateLocaleSaga(action) {
  const { id, locale } = action.payload

  yield put({ type: getActionType(UPDATE, CANDIDATE, LOCALE, START) })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { settings: { locale } },
    })

    yield put({
      type: getActionType(UPDATE, CANDIDATE, LOCALE, SUCCESS),
      payload: { candidate: data },
    })
  } catch (err) {
    yield put({
      type: getActionType(UPDATE, CANDIDATE, LOCALE, FAIL),
      payload: { err },
    })
  }
}

export function* setIsEmailOnMatchActiveSaga(action) {
  const { isEmailOnMatchActive } = action.payload
  yield put({
    type: getActionType(SET, EMAIL, ON, MATCH, ACTIVE, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      candidate: { settings: { notifyAlerts: isEmailOnMatchActive } },
    })

    yield put({
      type: getActionType(SET, EMAIL, ON, MATCH, ACTIVE, SUCCESS),
      payload: { candidate: data },
    })
  } catch (err) {
    yield put({
      type: getActionType(SET, EMAIL, ON, MATCH, ACTIVE, FAIL),
      payload: { err },
    })
  }
}

export function* setIsNewsletterActiveSaga(action) {
  const { isNewsletterActive } = action.payload

  yield put({
    type: getActionType(SET, NEWSLETTER, ACTIVE, START),
    payload: { isNewsletterActive },
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      candidate: { settings: { notifyNews: isNewsletterActive } },
    })

    yield put({
      type: getActionType(SET, NEWSLETTER, ACTIVE, SUCCESS),
      payload: { candidate: data },
    })
  } catch (err) {
    yield put({
      type: getActionType(SET, NEWSLETTER, ACTIVE, FAIL),
      payload: { err },
    })
  }
}

export function* initiateSubscriptionVerificationSaga(action) {
  const { messageProvider } = action.payload

  yield put({
    type: getActionType(INITIATE, SUBSCRIPTION, REQUEST, START),
    payload: { messageProvider },
  })

  try {
    const { data } = yield call(moberriesApi.getMessageProviderVerification, {
      provider: messageProvider,
    })

    yield put({
      type: getActionType(INITIATE, SUBSCRIPTION, REQUEST, SUCCESS),
      payload: {
        provider: messageProvider,
        verificationCode: data.code,
      },
    })
  } catch (err) {
    yield put({
      type: getActionType(INITIATE, SUBSCRIPTION, REQUEST, FAIL),
      payload: { err },
    })
  }
}

export function* cancelMessengerSubscriptionSaga(action) {
  const { id, messageProvider } = action.payload

  yield put({
    type: getActionType(CANCEL, SUBSCRIPTION, REQUEST, START),
    payload: { id, messageProvider },
  })

  try {
    yield call(moberriesApi.cancelMessengerSubscription, {
      id,
    })

    const { data: candidate } = yield call(moberriesApi.getCandidate, {})

    yield put({
      type: getActionType(CANCEL, SUBSCRIPTION, REQUEST, SUCCESS),
      payload: {
        candidate,
      },
    })
  } catch (err) {
    yield put({
      type: getActionType(CANCEL, SUBSCRIPTION, REQUEST, FAIL),
      payload: { err },
    })
  }
}

export function* activateCandidateTrackingSaga(action) {
  const { id } = action.payload

  yield put({
    type: getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { settings: { useExternalTracking: true } },
    })

    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS),
      payload: { candidate: data },
    })
  } catch (err) {
    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, FAIL),
      payload: { err },
    })
  }
}

export function* deactivateCandidateTrackingSaga(action) {
  const { id } = action.payload

  yield put({
    type: getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { settings: { useExternalTracking: false } },
    })

    yield put({
      type: getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, SUCCESS),
      payload: { candidate: data },
    })

    yield put({ type: getActionType(RELOAD, PAGE) })
  } catch (err) {
    yield put({
      type: getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, FAIL),
      payload: { err },
    })
  }
}

export function* activateMatchingSaga(action) {
  const { id } = action.payload

  yield put({
    type: getActionType(ACTIVATE, CANDIDATE, MATCHING, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { active: true },
    })

    showAlert({ code: alertTypes.settingsSaveSuccess })

    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, MATCHING, SUCCESS),
      payload: { candidate: data },
    })

    yield put(setMatchingActivationRequestCancelled({ newStatus: false }))
  } catch (err) {
    yield put({
      type: getActionType(ACTIVATE, CANDIDATE, MATCHING, FAIL),
      payload: { err },
    })
  }
}

export function* deactivateMatchingSaga(action) {
  const { id } = action.payload

  yield put({
    type: getActionType(DEACTIVATE, CANDIDATE, MATCHING, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { active: false },
    })

    showAlert({ code: alertTypes.settingsSaveSuccess })

    yield put({
      type: getActionType(DEACTIVATE, CANDIDATE, MATCHING, SUCCESS),
      payload: { candidate: data },
    })

    yield put(setMatchingActivationRequestCancelled({ newStatus: true }))
  } catch (err) {
    yield put({
      type: getActionType(DEACTIVATE, CANDIDATE, MATCHING, FAIL),
      payload: { err },
    })
  }
}

export function* refreshCandidateSaga() {
  const candidate = yield select(candidateSelector)
  if (!candidate) return

  yield call(requestCandidateSaga, { id: "me" })
}

export function* autoActivateCandidateNewsNotificationSaga() {
  yield take(LOCATION_CHANGE)

  const accountSettingsPagePageMatch = yield select(
    createMatchSelector("/account/settings"),
  )

  if (!accountSettingsPagePageMatch) {
    return
  }

  const location = yield select(getLocation)

  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  if (query && query.subscribe === "newsletter") {
    const isNewsletterActive = yield select(isNewsletterActiveSelector)

    if (!isNewsletterActive) {
      const loading = yield select(loadingSelector)

      if (loading) {
        yield take(getActionType(GET, CANDIDATE, SUCCESS))
      }

      yield call(setIsNewsletterActiveSaga, {
        payload: { isNewsletterActive: true },
      })

      showAlert({ code: alertTypes.updateNewsSuccess })
    }
  }
}

export function* acceptTermsSaga(action) {
  const { id, isNewsletterActive, termsVersion } = action.payload

  yield put({
    type: getActionType(ACCEPT, TERMS, START),
  })

  try {
    const { data } = yield call(moberriesApi.updateCandidate, {
      id,
      candidate: { settings: { notifyNews: isNewsletterActive, termsVersion } },
    })

    yield put({
      type: getActionType(ACCEPT, TERMS, SUCCESS),
      payload: { candidate: data },
    })
  } catch (err) {
    yield put({
      type: getActionType(ACCEPT, TERMS, FAIL),
      payload: { err },
    })
  }
}

export function* updateCandidateTrackingSaga(action) {
  const { formName, tracking } = action.payload
  try {
    yield put({ type: getActionType(UPDATE, CANDIDATE, TRACKING, START) })
    yield put(startSubmit(formName))
    const candidate = yield select(candidateSelector)
    const currentTracking = candidate.tracking
    let newTracking = null

    if (!currentTracking) {
      const res = yield call(moberriesApi.createCandidateTracking, {
        tracking: tracking,
      })
      newTracking = res.data
    }

    yield put({
      type: getActionType(UPDATE, CANDIDATE, TRACKING, SUCCESS),
      payload: {
        tracking: newTracking,
      },
    })
    yield put(stopSubmit(formName))
  } catch (err) {
    yield put({
      type: getActionType(UPDATE, CANDIDATE, TRACKING, FAIL),
      payload: { err },
    })

    if (formName) {
      if (
        isProblem(CLIENT_ERROR, action.payload) &&
        isStatus(400, action.payload)
      ) {
        yield put(
          stopSubmit(
            formName,
            mapMoberriesApiValidationError(err.response.data),
          ),
        )
      } else {
        yield put(stopSubmit(formName))
      }
    }
  }
}

export const saga = function* () {
  yield all([
    takeLatest(getActionType(GET, CANDIDATE, REQUEST), getCandidateSaga),
    takeLatest(
      getActionType(EXPORT, CANDIDATE, DATA, REQUEST),
      exportCandidateDataSaga,
    ),
    takeLatest(getActionType(CREATE, CANDIDATE, REQUEST), createCandidateSaga),
    takeLatest(getActionType(UPDATE, CANDIDATE, REQUEST), updateCandidateSaga),
    takeLatest(
      getActionType(ACTIVATE, CANDIDATE, INITIAL, REQUEST),
      activateCandidateInitialSaga,
    ),
    takeLatest(
      getActionType(UPDATE, CANDIDATE, LOCALE, REQUEST),
      updateCandidateLocaleSaga,
    ),
    takeLatest(
      getActionType(CREATE, CANDIDATE, CV, REQUEST),
      createCandidateCvSaga,
    ),
    takeLatest(
      getActionType(UPLOAD, CANDIDATE, CV, REQUEST),
      uploadCandidateCvSaga,
    ),
    takeLatest(
      getActionType(SET, EMAIL, ON, MATCH, ACTIVE, REQUEST),
      setIsEmailOnMatchActiveSaga,
    ),
    takeLatest(
      getActionType(SET, NEWSLETTER, ACTIVE, REQUEST),
      setIsNewsletterActiveSaga,
    ),
    takeLatest(
      getActionType(INITIATE, SUBSCRIPTION, REQUEST),
      initiateSubscriptionVerificationSaga,
    ),
    takeLatest(
      getActionType(CANCEL, SUBSCRIPTION, REQUEST),
      cancelMessengerSubscriptionSaga,
    ),
    takeLatest(
      getActionType(ACTIVATE, CANDIDATE, TRACKING, SETTINGS, REQUEST),
      activateCandidateTrackingSaga,
    ),
    takeLatest(
      getActionType(DEACTIVATE, CANDIDATE, TRACKING, SETTINGS, REQUEST),
      deactivateCandidateTrackingSaga,
    ),
    takeLatest(
      getActionType(ACTIVATE, CANDIDATE, MATCHING, REQUEST),
      activateMatchingSaga,
    ),
    takeLatest(
      getActionType(DEACTIVATE, CANDIDATE, MATCHING, REQUEST),
      deactivateMatchingSaga,
    ),
    takeLatest(getActionType(ACCEPT, TERMS, REQUEST), acceptTermsSaga),
    takeLatest(getActionType(REFRESH, CANDIDATE), refreshCandidateSaga),
    refreshCandidateSaga(),
    autoActivateCandidateNewsNotificationSaga(),
    takeLatest(
      getActionType(APPLY, CANDIDATE, PREDICTION, REQUEST),
      applyCandidatePredictionSaga,
    ),
    takeLatest(
      getActionType(UPDATE, CANDIDATE, TRACKING, REQUEST),
      updateCandidateTrackingSaga,
    ),
  ])
}
