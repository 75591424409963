import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Input } from "reactstrap"
import BTextarea from "react-textarea-autosize"
import FormFeedback from "./form-feedback"
import { LabelWithHint } from "./label-with-hint-component"
import cn from "classnames"

export function Textarea(props) {
  const {
    input,
    meta,
    options,
    label,
    underInput,
    underFeedback,
    underLabel,
    hint,
    className,
    ...rest
  } = props

  const { error, valid, invalid, touched } = meta
  return (
    <FormGroup>
      <LabelWithHint label={label} hint={hint} for={rest.id} />
      {underLabel}
      <BTextarea
        {...rest}
        {...input}
        className={cn(className, {
          "border-danger": touched && invalid,
        })}
      />
      {underInput}
      <Input
        type="hidden"
        valid={!touched || valid ? null : valid}
        invalid={!touched || valid ? null : invalid}
      />
      <FormFeedback error={error} />
      {underFeedback}
    </FormGroup>
  )
}

Textarea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.node,
  underInput: PropTypes.node,
  underFeedback: PropTypes.node,
  underLabel: PropTypes.node,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
}

Textarea.defaultProps = {
  type: "textarea",
}
