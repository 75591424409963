import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { BaseLayout } from "./base-layout"
import { Container, Nav, NavItem, NavLink } from "reactstrap"
import { DoubleColumnLayout } from "./double-column-layout"
import { NavLink as RLink } from "react-router-dom"
import { defineMessages, FormattedMessage } from "react-intl"

const messages = defineMessages({
  applications: { id: "app.common.applications" },
  feedbackSummary: { id: "app.applications.feedbackSummary" },
})

export const ApplicationsPageLayout = ({ header, content, sidebar }) => {
  return (
    <BaseLayout className="py-5">
      <Container>{header}</Container>
      <DoubleColumnLayout
        content={
          <Fragment>
            <Nav tabs justified className="settings-tabs">
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/applications"
                  exact
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage {...messages.applications} />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={RLink}
                  to="/applications/summary"
                  className="font-weight-bold px-0"
                >
                  <FormattedMessage {...messages.feedbackSummary} />
                </NavLink>
              </NavItem>
            </Nav>
            {content}
          </Fragment>
        }
        sidebar={sidebar}
      />
    </BaseLayout>
  )
}

ApplicationsPageLayout.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
}
