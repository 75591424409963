import React, { Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FormattedMessage, defineMessages } from "react-intl"
import { getHiringReward } from "../../lib/helpers"
import { InfoHint } from "../common/info-hint-component"
import { Price } from "../common/price-component"
import { JobControlsLayout } from "../layouts/job-controls-layout"
import { Button } from "../common/button-component"
import { markMatchHired, markMatchNotHired } from "../../actions/match-actions"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PopoverBody,
} from "reactstrap"
import { ConfirmationFeedback } from "./confirmation-feedback-component"
import {
  loadingSelector as matchLoadingSelector,
  markingHiredSelector,
  markingNotHiredSelector,
  matchSelector,
} from "../../selectors/match-selector"
import {
  jobSelector,
  loadingSelector as jobLoadingSelector,
} from "../../selectors/job-selector"
import { MATCH_STATUSES, NOT_HIRED, FEEDBACK_SETS } from "../../constants"
import { useOpenClose } from "../../hooks/use-open-close"
import { useList } from "../../hooks/use-list"

const messages = defineMessages({
  hiredButton: { id: "app.job.mark.hired" },
  notHiredButton: { id: "app.job.mark.nohired" },
  hiredText: { id: "app.applications.hired.text" },
  notHiredText: { id: "app.applications.nohired.text" },
  declineHeader: { id: "app.job.candidate.decline.header" },
  modalBody: { id: "app.job.mark.hired.modal.body" },
  confirmHiredButton: { id: "app.common.confirm" },
  cancelConfirmHiredButton: { id: "app.common.cancel" },
  confirmHiredTitle: { id: "app.job.mark.hired.modal.title" },
  hiringRewardInfo: { id: "app.job.alert.info" },
  hiringRewardAlert: { id: "app.job.alert" },
})

export const JobFeedbackControlsComponent = ({
  match,
  isDisabled,
  isMarkingHired,
  isMarkingNotHired,
  markMatchHired,
  markMatchNotHired,
}) => {
  const {
    value: isModalActive,
    close: closeModal,
    open: openModal,
  } = useOpenClose({ initialValue: false })

  const {
    has: isFeedbackStepSelected,
    push: addFeedbackType,
    remove: removeFeedbackType,
  } = useList()

  const handleMarkHiredConfirm = () => {
    markMatchHired({ matchId: match.id })
  }

  const handleMarkHired = () => {
    openModal(isModalActive)
  }

  const handleMarkNotHired = () => {
    addFeedbackType(NOT_HIRED)
  }

  const handleMarkNotHiredConfirm = ({ comment, selectedReasons }) => {
    markMatchNotHired({
      matchId: match.id,
      comment: comment,
      feedbackReasons: selectedReasons,
    })
  }

  const onCancelClick = () => {
    removeFeedbackType(NOT_HIRED)
  }

  if (isFeedbackStepSelected(NOT_HIRED)) {
    return (
      <ConfirmationFeedback
        feedbackSetType={FEEDBACK_SETS.CANDIDATE_NOT_HIRED_REASONS}
        header={
          <div className="mb-5">
            <FormattedMessage {...messages.declineHeader} />
          </div>
        }
        onCancelClick={onCancelClick}
        onConfirmClick={handleMarkNotHiredConfirm}
        isDisabled={isDisabled}
        isConfirmButtonLoading={isMarkingNotHired}
        reasonsRequired={1}
        commentLengthForOtherRequired={4}
      />
    )
  }

  return (
    <Fragment>
      <Modal isOpen={isModalActive} toggle={closeModal} centered>
        <ModalHeader toggle={closeModal}>
          <FormattedMessage {...messages.confirmHiredTitle} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage {...messages.modalBody} />
        </ModalBody>
        <ModalFooter>
          <Button disabled={isDisabled} color="light" onClick={closeModal}>
            <FormattedMessage {...messages.cancelConfirmHiredButton} />
          </Button>
          <Button
            onClick={handleMarkHiredConfirm}
            loading={isMarkingHired}
            disabled={isDisabled}
            color="primary"
          >
            <FormattedMessage {...messages.confirmHiredButton} />
          </Button>
        </ModalFooter>
      </Modal>
      {Boolean(getHiringReward(match)) && (
        <div className="d-flex bg-warning-light border-warning border-top">
          <div className="col py-2 text-center">
            <small>
              <FormattedMessage
                {...messages.hiringRewardAlert}
                values={{
                  price: (
                    <strong>
                      <Price price={parseFloat(match.hiringRewardEuros)} />
                    </strong>
                  ),
                }}
              />
            </small>
            <InfoHint
              id="hiringRewardHint"
              popover={
                <PopoverBody>
                  <FormattedMessage
                    {...messages.hiringRewardInfo}
                    values={{
                      price: (
                        <Price price={parseFloat(match.hiringRewardEuros)} />
                      ),
                    }}
                  />
                </PopoverBody>
              }
            />
          </div>
        </div>
      )}
      <JobControlsLayout
        left={
          <Fragment>
            <Button
              onClick={handleMarkHired}
              disabled={isDisabled}
              loading={isMarkingHired}
              pill
              color="primary"
            >
              <i className="fas fa-heart pr-1" />
              <FormattedMessage {...messages.hiredButton} />
            </Button>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.hiredText} />
            </span>
          </Fragment>
        }
        right={
          <Fragment>
            <span className="d-none d-md-inline px-2">
              <FormattedMessage {...messages.notHiredText} />
            </span>
            <Button
              onClick={handleMarkNotHired}
              disabled={isDisabled}
              loading={isMarkingNotHired}
              pill
              color="secondary"
            >
              <i className="fas fa-times pr-1" />
              <FormattedMessage {...messages.notHiredButton} />
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  )
}

const mapDispatchToProps = { markMatchHired, markMatchNotHired }

const mapStateToProps = function (state, props) {
  let match = null

  if (props.matchId) {
    match = matchSelector(state, { id: props.matchId })
  }

  if (!match && props.jobId) {
    const job = jobSelector(state, { id: props.jobId })

    if (job && job.match) {
      match = job.match
    }
  }

  const isMarkingHired = markingHiredSelector(state)
  const isMarkingNotHired = markingNotHiredSelector(state)

  const isDisabled =
    isMarkingHired ||
    isMarkingNotHired ||
    jobLoadingSelector(state) ||
    matchLoadingSelector(state) ||
    !match ||
    (match.status !== MATCH_STATUSES.COMPANY_ACCEPTED &&
      match.status !== MATCH_STATUSES.COMPANY_APPROVED &&
      match.status !== MATCH_STATUSES.EXPORTED)

  return {
    match,
    isDisabled,
    isMarkingHired,
    isMarkingNotHired,
  }
}
JobFeedbackControlsComponent.propTypes = {
  match: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired,
  isMarkingHired: PropTypes.bool.isRequired,
  isMarkingNotHired: PropTypes.bool.isRequired,
  markMatchHired: PropTypes.func.isRequired,
  markMatchNotHired: PropTypes.func.isRequired,
}
JobFeedbackControlsComponent.defaultProps = {
  isDisabled: false,
}
export const JobFeedbackControls = connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobFeedbackControlsComponent)
