import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { components } from "react-select"
import { Img } from "../common/img-component"
import poweredByGoogle from "../../assets/img/powered-by-google-on-white-hdpi.png"

export const MenuList = props => (
  <Fragment>
    <components.MenuList {...props} />
    {props.options.length > 0 && (
      <div className="d-flex flex-row-reverse px-4 py-2">
        <Img height="20" src={poweredByGoogle} />
      </div>
    )}
  </Fragment>
)

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
}

MenuList.defaultProps = {
  options: [],
}
