import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { FormattedMessage, defineMessages } from "react-intl"
import { Link } from "react-router-dom"
import { WidgetProgressBar } from "./widget-progress-bar"
import cn from "classnames"
import {
  activeSelector,
  profileCompletionSelector,
} from "../../selectors/candidate-selector"
import { MatchingWidgetJobPositions } from "../common/matching-widget-job-positions/matching-widget-job-positions"

const messages = defineMessages({
  completionTitle: { id: "app.sidebar.completion.title" },
  completionProgress: { id: "app.sidebar.completion.progress" },
  completionCompleteText: { id: "app.sidebar.completion.advice.complete" },
  completionIncompleteText: { id: "app.sidebar.completion.advice.incomplete" },
  completionStatusTitle: { id: "app.sidebar.completion.matching.status.title" },
  completionStatusActive: {
    id: "app.sidebar.completion.matching.status.active",
  },
  completionStatusInactive: {
    id: "app.sidebar.completion.matching.status.inactive",
  },
})

export function ProfileCompletionStatusComponent({
  completion,
  matchingActive,
}) {
  const completed = completion > 90

  const text = completed
    ? messages.completionCompleteText
    : messages.completionIncompleteText

  const statusText = matchingActive
    ? messages.completionStatusActive
    : messages.completionStatusInactive

  return (
    <div className="bg-white rounded p-3 mb-3">
      <h5 className="mb-3">
        <div className="icon-circled mr-2">
          <i className="fas fa-user" />
        </div>
        <FormattedMessage {...messages.completionTitle} />
      </h5>
      <ul className="list-unstyled border-top mb-2">
        <li>
          <div className="d-flex py-1 border-bottom border-white justify-content-between align-items-center position-relative">
            <span>
              <FormattedMessage {...messages.completionProgress} />
            </span>
            <div>
              <b
                className={cn("mr-2", {
                  "text-success": completed,
                  "text-warning": !completed,
                })}
              >
                {completion} %
              </b>
              <Link
                className="btn btn-circle btn-pill p-2 btn-light btn-sm"
                to="/profile/personal"
              >
                <i className="fas fa-pen" />
              </Link>
            </div>
            <WidgetProgressBar value={completion} />
          </div>
          <p className="mt-1 mb-2 pb-2 border-bottom">
            <small>
              <FormattedMessage {...text} />
            </small>
          </p>
        </li>
        <li>
          <div className="py-1 mb-3 d-flex border-bottom justify-content-between align-items-center">
            <span>
              <FormattedMessage {...messages.completionStatusTitle} />
            </span>
            <div>
              <b
                className={
                  (matchingActive ? "text-success" : "text-danger") + " mr-2"
                }
              >
                <FormattedMessage {...statusText} />
              </b>
              <Link
                className="btn btn-circle btn-pill p-2 btn-light btn-sm"
                to="/account/settings"
              >
                <i className="fas fa-pen" />
              </Link>
            </div>
          </div>
          {matchingActive && <MatchingWidgetJobPositions />}
        </li>
      </ul>
    </div>
  )
}

ProfileCompletionStatusComponent.propTypes = {
  completion: PropTypes.number.isRequired,
  matchingActive: PropTypes.bool.isRequired,
}

ProfileCompletionStatusComponent.defaultProps = {
  matchingActive: false,
  completion: 0,
}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {
    matchingActive: activeSelector(state),
    completion: profileCompletionSelector(state),
  }
}

export const ProfileCompletionStatus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileCompletionStatusComponent)
