import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { candidateSelector } from "../../../selectors/candidate-selector"

import styles from "./matching-widget-job-positions.module.scss"
import { getMissingPositionIds } from "../../../lib/helpers"
import { includes } from "ramda"
import { FormattedMessage } from "react-intl"

const MatchingWidgetJobPositionsComponent = ({ candidate }) => {
  let matchingJobRoles = []

  if (candidate.jobPosition.length) {
    matchingJobRoles = candidate.jobPosition
  } else if (candidate.workExperience.length) {
    matchingJobRoles = candidate.jobRoles
  }

  const missingPositionIds = getMissingPositionIds({
    experience: candidate.workExperience,
    positions: candidate.jobPosition,
    certificates: candidate.certificates,
  })

  const activeRoles = matchingJobRoles.filter(
    jr => !includes(jr.id, missingPositionIds),
  )
  const missingRoles = matchingJobRoles.filter(jr =>
    includes(jr.id, missingPositionIds),
  )

  if (matchingJobRoles.length === 0) {
    return null
  }

  return (
    <div className={styles.card}>
      {activeRoles.length > 0 && (
        <>
          <p className={styles.title}>
            <span className={styles.titleText}>
              <FormattedMessage id="app.sidebar.completion.mathcing.roles.title" />
            </span>
          </p>
          <div className={styles.tagsWrapper}>
            {activeRoles.map(jr => (
              <p key={jr.id} className={styles.tag}>
                <span className={styles.tagText}>{jr.name}</span>
              </p>
            ))}
          </div>
        </>
      )}

      {missingRoles.length > 0 && (
        <>
          <p className={styles.title}>
            <i className="fas fa-exclamation-triangle text-warning" />
            <span className={styles.titleText}>
              <FormattedMessage id="app.sidebar.completion.mathcing.roles.warning" />
            </span>
          </p>
          <div className={styles.tagsWrapper}>
            {missingRoles.map(jr => (
              <p key={jr.id} className={cn([styles.tag, styles.tag_warning])}>
                <i className="fas fa-exclamation-triangle text-warning" />
                <span className={styles.tagText}>{jr.name}</span>
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

MatchingWidgetJobPositionsComponent.propTypes = {
  candidate: PropTypes.object,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
})

const mapDispatchToProps = {}

export const MatchingWidgetJobPositions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatchingWidgetJobPositionsComponent)
