import React from "react"
import PropTypes from "prop-types"
import { Badge as BBadge } from "reactstrap"
import { head } from "ramda"
import cn from "classnames"

export function Badge(props) {
  const { color, className, border, ...rest } = props

  const css = cn(
    {
      border,
      [`border-${head(color.split("-"))}`]: border,
    },
    className,
  )

  return <BBadge color={color} className={css} {...rest} />
}

Badge.propTypes = {
  color: PropTypes.string.isRequired,
  border: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
}

Badge.defaultProps = {
  color: "primary",
  border: false,
  className: "",
}
