import { all, call, put, takeLatest } from "redux-saga/effects"
import { api as moberriesApi } from "../lib/moberries-api"
import { getActionType } from "../lib/helpers"
import { THREAD, FAIL, GET, REQUEST, START, SUCCESS, LIST } from "../constants"

export function* requestThreadListSaga(action) {
  const { page = 1 } = action.payload

  yield put({
    type: getActionType(GET, THREAD, LIST, START),
    payload: { page },
  })

  try {
    const {
      data: { results: threads, count },
    } = yield call(moberriesApi.getThreadList, { page })

    yield put({
      type: getActionType(GET, THREAD, LIST, SUCCESS),
      payload: { threads, count, page },
    })
  } catch (err) {
    yield put({
      type: getActionType(GET, THREAD, LIST, FAIL),
      payload: { err },
    })
  }
}

export function* requestThreadSaga(action) {
  const { threadId } = action.payload

  try {
    yield put({ type: getActionType(GET, THREAD, START) })
    const { data: thread } = yield call(moberriesApi.getThread, {
      id: threadId,
    })
    yield put({
      type: getActionType(GET, THREAD, SUCCESS),
      payload: { thread },
    })
  } catch (err) {
    yield put({ type: getActionType(GET, THREAD, FAIL), payload: { err } })
  }
}

export const saga = function* () {
  yield all([
    takeLatest(
      getActionType(GET, THREAD, LIST, REQUEST),
      requestThreadListSaga,
    ),
  ])
}
