import { FCM, TOKEN, REQUEST, CREATE, DELETE, INIT } from "../constants"
import { getActionType } from "../lib/helpers"

export function createFcmToken() {
  return {
    type: getActionType(CREATE, FCM, TOKEN, REQUEST),
  }
}

export function deleteFcmToken() {
  return {
    type: getActionType(DELETE, FCM, TOKEN, REQUEST),
  }
}

export const initFcm = () => ({
  type: getActionType(INIT, FCM),
})
