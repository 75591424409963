import React from "react"

import styles from "./login-info-section.module.scss"
import { FormattedMessage } from "react-intl"

export const LoginInfoSection = () => (
  <div className={styles.outerContainer}>
    <div className={styles.innerContainer}>
      <div className={styles.container}>
        <div>
          <h4 className={styles.header}>
            <FormattedMessage id="app.login.infoSection.title" />
          </h4>
          <p className={styles.subheader}>
            <FormattedMessage id="app.login.infoSection.subtitle" />
          </p>
        </div>
      </div>
    </div>
  </div>
)
