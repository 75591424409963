import { createSelector } from "reselect"
import { values } from "ramda"

export const stateSelector = state => state.matches

const cs = (...args) => createSelector(stateSelector, ...args)

export const loadingSelector = cs(state => state.loading)
export const markingHiredSelector = cs(state => state.markingHired)
export const markingNotHiredSelector = cs(state => state.markingNotHired)
export const errorSelector = cs(state => state.err)
export const entitiesSelector = cs(state => state.entities)
export const countSelector = cs(state => state.count)
export const matchesIdsSelector = cs(state => state.matches)

export const matchesSelector = cs(
  entitiesSelector,
  matchesIdsSelector,
  (_, entities, ids) => ids.map(id => entities[id]),
)

export const matchSelector = cs(
  entitiesSelector,
  (state, { id }) => id,
  (_, entities, id) => entities[id],
)

export const matchByJobIdSelector = cs(
  entitiesSelector,
  (state, { jobId }) => ({ jobId }),
  (_, entities, { jobId }) => {
    return values(entities).find(t => t.job.id === Number(jobId))
  },
)

export const feedbackSummarySelector = cs(state => state.feedbackSummary)

export const validationDataSelector = cs(state => state.validationData)
export const applicationJobIdSelector = cs(state => state.applicationJobId)
export const validatingSelector = cs(state => state.validating)

export const matchCountByStatusSelector = cs(state => state.matchCountByStatus)
