import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import styles from "./button-group.module.scss"

export const ButtonGroup = ({ buttons, onChange, className }) => {
  return (
    <div className={cn([styles.wrapper, className])}>
      {buttons.map(({ name, value, active }) => (
        <button
          className={cn([styles.button, { [styles.button_active]: active }])}
          key={value}
          type="button"
          onClick={() => onChange(value)}
        >
          {name}
        </button>
      ))}
    </div>
  )
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
}
