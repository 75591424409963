import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Media } from "reactstrap"
import cn from "classnames"
import { HiringRewardTag } from "../common/hiring-reward-tag"
import { PrescreenQuestionsTag } from "../common/prescreen-questions-tag"
import { Img } from "../common/img-component"
import { getLocationName } from "../../lib/helpers"
import { Badge } from "../common/badge-component"
import { FormattedMessage, defineMessages } from "react-intl"

const messages = defineMessages({
  remote: { id: "app.common.remote" },
})

export function JobCard({
  id,
  companyLogo,
  companyName,
  companyId,
  jobTitle,
  jobId,
  salaryMin,
  salaryMax,
  locations,
  isRemote,
  controls,
  style,
  className,
  hiringRewardEuros,
  hasQuestions,
}) {
  const cardProps = {}

  if (style) {
    cardProps.style = style
  }

  return (
    <Media className={cn("bg-white rounded p-3", className)} {...cardProps}>
      <Media left>
        <Link to={`/company/${companyId}`}>
          <Img
            src={companyLogo}
            alt={companyName}
            width="80"
            height="80"
            className="mr-3 d-none d-md-inline clickable"
          />
        </Link>
      </Media>
      <Media body>
        <Link to={`/company/${companyId}`}>
          <small>{companyName}</small>
        </Link>
        {jobId ? (
          <h4 className="mb-1">
            <Link
              className="text-decoration-none d-block text-dark"
              to={`/job/${jobId}`}
            >
              {jobTitle}
            </Link>
          </h4>
        ) : (
          <h4 className="mb-1">{jobTitle}</h4>
        )}
        <div className="mb-2">
          {isRemote && (
            <Badge color="info-light mr-2">
              <i className="fas fa-globe-americas" />
              <FormattedMessage {...messages.remote} />
            </Badge>
          )}
          {locations.length > 0 &&
            locations.map(l => (
              <span
                key={l.placeId}
                className="d-inline-block text-muted text-smaller mr-2 align-middle"
              >
                <i className="fas fa-map-marker-alt mr-1" />
                {getLocationName(l)}
              </span>
            ))}
        </div>
        <div className="tags-wrapper">
          {salaryMin > 0 && salaryMax > 0 && (
            <Badge color="light" className="text-truncate align-middle">
              {salaryMin / 1000} - {salaryMax / 1000}K €
            </Badge>
          )}
          {hiringRewardEuros > 0 && (
            <HiringRewardTag id={id} hiringReward={hiringRewardEuros} />
          )}
          {hasQuestions && <PrescreenQuestionsTag id={id} />}
        </div>
      </Media>
      {controls && <div className="pl-2 text-right">{controls}</div>}
    </Media>
  )
}

JobCard.propTypes = {
  id: PropTypes.number.isRequired,
  companyLogo: PropTypes.string,
  className: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  salaryMin: PropTypes.number.isRequired,
  salaryMax: PropTypes.number.isRequired,
  locations: PropTypes.array.isRequired,
  controls: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  companyId: PropTypes.number,
  hiringRewardEuros: PropTypes.number,
  jobId: PropTypes.number,
  isRemote: PropTypes.bool,
  hasQuestions: PropTypes.bool,
}

JobCard.defaultProps = {
  companyLogo: "",
  companyName: "",
  jobTitle: "",
  salaryMin: "",
  salaryMax: "",
  locations: [],
  className: "",
  isRemote: false,
}
