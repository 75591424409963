import React from "react"

import { noAuthRequired } from "../../decorators/no-auth-required"
import { BaseLayout } from "../../components/layouts/base-layout"
import { DoubleEqualColumnLayout } from "../../components/layouts/double-equal-column-layout"
import { SignupInfoSection } from "../../components/signup/signup-info-section-component/signup-info-section-component"
import { SignupFormSection } from "../../components/signup/signup-form-section-component/signup-form-section-component"

import clock from "../../assets/img/clock.svg"
import feedback from "../../assets/img/goodnotes.svg"
import money from "../../assets/img/euro-money.png"

const infoSectionProps = {
  header: {
    id: "app.signup.infoSection.title",
  },
  subheader: {
    id: "app.signup.infoSection.subtitle",
  },
  benefits: {
    header: null,
    items: [
      {
        icon: {
          src: clock,
          alt: "Clock",
        },
        title: {
          id: "app.signup.infoSection.save.title",
        },
        subtitle: {
          id: "app.signup.infoSection.save.subtitle",
        },
      },
      {
        icon: {
          src: feedback,
          alt: "Feedback",
        },
        title: {
          id: "app.signup.infoSection.feedback.title",
        },
        subtitle: {
          id: "app.signup.infoSection.feedback.subtitle",
        },
      },
      {
        icon: {
          src: money,
          alt: "Money",
        },
        title: {
          id: "app.signup.infoSection.free.title",
        },
        subtitle: {
          id: "app.signup.infoSection.free.subtitle",
        },
      },
    ],
  },
}

export const SignupPageComponent = () => (
  <BaseLayout className="py-5">
    <DoubleEqualColumnLayout>
      <DoubleEqualColumnLayout.Left>
        <SignupInfoSection {...infoSectionProps} />
      </DoubleEqualColumnLayout.Left>

      <DoubleEqualColumnLayout.Right>
        <SignupFormSection />
      </DoubleEqualColumnLayout.Right>
    </DoubleEqualColumnLayout>
  </BaseLayout>
)

export const SignupPage = noAuthRequired(SignupPageComponent)
