import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "ramda"
import { FormattedMessage, defineMessages } from "react-intl"
import { authRequired } from "../decorators/auth-required"
import { AccountPageLayout } from "../components/layouts/account-page-layout"
import { completedProfileRequired } from "../decorators/completed-profile-required"
import { TabPane } from "reactstrap"
import { EmailForm } from "../components/forms/email-form"
import { emailValidator } from "../validators/email-validator"
import { Input } from "../components/common/input-component"
import { emailSelector } from "../selectors/candidate-selector"
import { DoublePasswordForm } from "../components/forms/double-password-form"
import { newPasswordValidator } from "../validators/new-password-validator"
import { updateEmail, updatePassword } from "../actions/user-actions"

const messages = defineMessages({
  emailLegend: { id: "app.account.email.title" },
  newEmail: { id: "app.account.email.new.email" },
  save: { id: "app.common.save" },
  passwordLegend: { id: "app.account.password.title" },
  oldPassword: { id: "app.account.password.old.password" },
  newPassword: { id: "app.common.password.new" },
})

class Account extends Component {
  static propTypes = {
    updateEmail: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
  }

  render() {
    const { email } = this.props

    return (
      <AccountPageLayout>
        <TabPane>
          <EmailForm
            className="mb-5"
            overInput={
              <Fragment>
                <legend>
                  <FormattedMessage {...messages.emailLegend} />
                </legend>
                <Input
                  input={{ value: email }}
                  meta={{}}
                  type="email"
                  readOnly
                />
              </Fragment>
            }
            emailLabel={
              <span>
                <FormattedMessage {...messages.newEmail} />
              </span>
            }
            form="UpdateEmailForm"
            onSubmit={this.handleEmailFormSubmit}
            validate={emailValidator}
            buttonLabel={<FormattedMessage {...messages.save} />}
          />

          <DoublePasswordForm
            overInput={
              <legend>
                <FormattedMessage {...messages.passwordLegend} />
              </legend>
            }
            firstLabel={
              <span>
                <FormattedMessage {...messages.oldPassword} />
              </span>
            }
            secondLabel={
              <span>
                <FormattedMessage {...messages.newPassword} />
              </span>
            }
            form="UpdatePasswordForm"
            onSubmit={this.handlePasswordFormSubmit}
            validate={newPasswordValidator}
            buttonLabel={<FormattedMessage {...messages.save} />}
          />
        </TabPane>
      </AccountPageLayout>
    )
  }

  handleEmailFormSubmit = ({ email }) => {
    this.props.updateEmail({ email })
  }
  handlePasswordFormSubmit = ({ password, password2 }) => {
    this.props.updatePassword({ password, passwordNew: password2 })
  }
}

const mapDispatchToProps = { updateEmail, updatePassword }

const mapStateToProps = function (state) {
  return {
    email: emailSelector(state),
  }
}

export const AccountPage = compose(
  authRequired,
  completedProfileRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(Account)
