import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Redirect } from "react-router"
import { compose } from "ramda"
import qs from "qs"

import { CLIENT_ERROR } from "../../constants"
import { noAuthRequired } from "../../decorators/no-auth-required"
import { jobSelector, errorSelector } from "../../selectors/job-selector"
import { isLoggedSelector } from "../../selectors/candidate-selector"
import { getJob } from "../../actions/job-actions"
import { setApplicationId } from "../../actions/candidate-actions"

import { ErrorPage } from "../error-page"
import { BaseLayout } from "../../components/layouts/base-layout"
import { DoubleEqualColumnLayout } from "../../components/layouts/double-equal-column-layout"
import { SignupFormSection } from "../../components/signup/signup-form-section-component/signup-form-section-component"
import { SignupApplyInfoSection } from "./signup-apply-info-section"

export const SignupApplyPageComponent = ({
  match,
  isLogged,
  job,
  getJob,
  setApplicationId,
  err,
  location,
}) => {
  const jobId = Number(match.params.id)

  const { jl_tid: trackerId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const setJobliftFinishTracker = () => {
    if (trackerId) {
      ;(function (j, o, b, l, i, f, t) {
        j.jlq = [
          [
            "init",
            { cid: "557", tid_param: "jl_tid", inital_page_view: false },
          ],
        ]
        l = o.createElement("script")
        l.async = true
        l.src = b
        i = o.getElementsByTagName("head")[0]
        i.appendChild(l)
      })(window, document, "https://assets.joblift.com/tr/de.js")
      window.jlq.push(["application_finished"])
    }
  }

  useEffect(() => {
    getJob({ jobId })
    setApplicationId({ id: jobId })
  }, [jobId, getJob, setApplicationId])

  if (isLogged) {
    return (
      <Redirect
        to={{ pathname: `/job/${match.params.id}`, search: "?apply" }}
      />
    )
  }

  if (err) {
    if (err.problem === CLIENT_ERROR && err.response.status === 404) {
      return <Redirect to="/signup" />
    } else {
      return <ErrorPage err={err} />
    }
  }

  if (!job) {
    return null
  }

  return (
    <BaseLayout className="py-5">
      <DoubleEqualColumnLayout>
        <DoubleEqualColumnLayout.Left>
          <SignupApplyInfoSection job={job} />
        </DoubleEqualColumnLayout.Left>

        <DoubleEqualColumnLayout.Right>
          <SignupFormSection setTracker={setJobliftFinishTracker} />
        </DoubleEqualColumnLayout.Right>
      </DoubleEqualColumnLayout>
    </BaseLayout>
  )
}

SignupApplyPageComponent.propTypes = {
  match: PropTypes.object,
  isLogged: PropTypes.bool.isRequired,
  job: PropTypes.object,
  getJob: PropTypes.func.isRequired,
  setApplicationId: PropTypes.func.isRequired,
  err: PropTypes.object,
  location: PropTypes.object.isRequired,
}

SignupApplyPageComponent.defaultProps = {
  isLogged: false,
}

const mapStateToProps = (state, ownProps) => ({
  job: jobSelector(state, { id: ownProps.match.params.id }),
  err: errorSelector(state),
  isLogged: isLoggedSelector(state),
})

const mapDispatchToProps = {
  getJob,
  setApplicationId,
}

export const SignupApplyPage = compose(
  noAuthRequired,
  connect(mapStateToProps, mapDispatchToProps),
)(SignupApplyPageComponent)
