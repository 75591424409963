import React from "react"
import { FormattedMessage, defineMessages } from "react-intl"
import { Media, Badge } from "reactstrap"

const messages = defineMessages({
  reportHireBlockDescription: {
    id: "app.applications.reportHireBlock.description",
  },
  reportHireBlockTitle: { id: "app.applications.reportHireBlock.title" },
  reportHireBlockTip: { id: "app.common.tip" },
})

export function ReportHireBlock(props) {
  return (
    <Media className="sidebar-widget bg-white rounded p-3 mb-2">
      <Media left>
        <div className="mr-3">
          <span className="fa-stack fa-3x">
            <i className="fas fa-circle fa-stack-2x text-light" />
            <i className="fad fa-handshake fa-stack-1x" />
          </span>
        </div>
      </Media>
      <Media body>
        <Media heading>
          <Badge color="success-light">
            <FormattedMessage {...messages.reportHireBlockTip} />
          </Badge>{" "}
          <FormattedMessage {...messages.reportHireBlockTitle} />
        </Media>
        <div className="tags-wrapper">
          <FormattedMessage {...messages.reportHireBlockDescription} />
        </div>
      </Media>
    </Media>
  )
}
