import { all, call, put, take, select, takeLatest } from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import { getActionType } from "../lib/helpers"
import { messaging } from "../lib/firebase"
import { tokenSelector, optoutSelector } from "../selectors/fcm-selector"
import { api as webpushApi } from "../lib/webpush-api"
import {
  FCM,
  CREATE,
  TOKEN,
  INIT,
  REFRESH,
  REQUEST,
  DELETE,
  SUCCESS,
  FAIL,
  START,
  SUPPORTED,
  NOT,
  LOGIN,
  CONFIRM,
  EMAIL,
  FACEBOOK,
  CONNECT,
  LINKEDIN,
  LOGOUT,
} from "../constants"
import { isLoggedSelector } from "../selectors/candidate-selector"
import { alertTypes, showAlert } from "../lib/alerts"

export function* initFcmSaga() {
  const isLogged = yield select(isLoggedSelector)
  const optout = yield select(optoutSelector)
  const isPermissionGranted = Notification.permission === "granted"

  if (optout || !isLogged || !isPermissionGranted) {
    return
  }
  const prevToken = yield select(tokenSelector)

  try {
    const token = yield call([messaging, messaging.getToken])
    yield put({
      type: getActionType(INIT, FCM, TOKEN, SUCCESS),
      payload: { token },
    })

    if (token !== prevToken) {
      if (prevToken) {
        yield call(webpushApi.deleteFcmToken, { token: prevToken })
      }
      yield call(webpushApi.putFcmToken, { token })
    }
  } catch (err) {
    if (err.code === "messaging/notifications-blocked" && prevToken) {
      yield call(webpushApi.deleteFcmToken, { token: prevToken })
    }
    yield put({
      type: getActionType(INIT, FCM, TOKEN, FAIL),
    })
  }
  yield call(refreshFcmTokenSaga)
}

export function* createFcmTokenSaga() {
  const prevToken = yield select(tokenSelector)
  yield put({ type: getActionType(CREATE, FCM, TOKEN, START) })

  try {
    let token = yield call([messaging, messaging.getToken])

    if (prevToken) {
      yield call(webpushApi.deleteFcmToken, { token: prevToken })
    }

    yield call(webpushApi.putFcmToken, { token })

    showAlert({ code: alertTypes.webPushAlertSuccess })

    yield put({
      type: getActionType(CREATE, FCM, TOKEN, SUCCESS),
      payload: { token },
    })
  } catch (err) {
    yield put({
      type: getActionType(CREATE, FCM, TOKEN, FAIL),
      payload: { err },
    })
  }
}

export function* deleteFcmTokenSaga() {
  yield put({ type: getActionType(DELETE, FCM, TOKEN, START) })

  try {
    let token = null
    if (Notification.permission === "granted") {
      token = yield call([messaging, messaging.getToken])
    } else {
      token = yield select(tokenSelector)
    }
    yield call([messaging, messaging.deleteToken], token)

    yield call(webpushApi.deleteFcmToken, { token })

    yield put({
      type: getActionType(DELETE, FCM, TOKEN, SUCCESS),
    })
  } catch (err) {
    yield put({
      type: getActionType(DELETE, FCM, TOKEN, FAIL),
    })
  }
}

let unsubscribeFromTokenRefresh
export function fcmTokenRefreshChannel() {
  return eventChannel(emitter => {
    unsubscribeFromTokenRefresh = messaging.onTokenRefresh(() =>
      emitter({
        type: getActionType(REFRESH, FCM, TOKEN),
      }),
    )
    return unsubscribeFromTokenRefresh
  })
}

function unsubscribeFromTokenRefreshSaga() {
  if (unsubscribeFromTokenRefresh) {
    unsubscribeFromTokenRefresh()
  }
}

export function* refreshFcmTokenSaga() {
  const chan = yield call(fcmTokenRefreshChannel)

  while (true) {
    yield take(chan)
    yield call(initFcmSaga)
  }
}

export const saga = function* () {
  if (messaging) {
    yield put({ type: getActionType(FCM, SUPPORTED) })
  } else {
    yield put({ type: getActionType(FCM, NOT, SUPPORTED) })
    return
  }

  yield all([
    initFcmSaga(),
    refreshFcmTokenSaga(),
    takeLatest(
      [
        getActionType(LOGIN, SUCCESS),
        getActionType(CONFIRM, EMAIL, SUCCESS),
        getActionType(CONNECT, FACEBOOK, SUCCESS),
        getActionType(CONNECT, LINKEDIN, SUCCESS),
        getActionType(INIT, FCM),
      ],
      initFcmSaga,
    ),
    takeLatest(getActionType(LOGOUT, SUCCESS), unsubscribeFromTokenRefreshSaga),
    takeLatest(getActionType(CREATE, FCM, TOKEN, REQUEST), createFcmTokenSaga),
    takeLatest(getActionType(DELETE, FCM, TOKEN, REQUEST), deleteFcmTokenSaga),
  ])
}
