import React from "react"
import { path } from "ramda"
import { toast } from "react-toastify"
import { FormattedMessage } from "react-intl"
import {
  ALERT_COLORS,
  CLIENT_ERROR,
  SERVER_ERROR,
  CONNECTION_ERROR,
  TIMEOUT_ERROR,
  NETWORK_ERROR,
  CANCEL_ERROR,
  UNKNOWN_ERROR,
} from "../constants"
import { getErrorMessage } from "./server-errors-map"
import { hasCode, hasProblem } from "./helpers"

export const alertTypes = Object.freeze({
  markHired: "markHired",
  markNotHired: "markNotHired",
  emailSentSuccess: "emailSentSuccess",
  resendConfirmationEmailSuccess: "resendConfirmationEmailSuccess",
  sendResetPasswordEmailSuccess: "sendResetPasswordEmailSuccess",
  updateForgottenPasswordSuccess: "updateForgottenPasswordSuccess",
  updatePasswordSuccess: "updatePasswordSuccess",
  accountDeleteSuccess: "accountDeleteSuccess",
  confirmAccountDeleteSuccess: "confirmAccountDeleteSuccess",
  emailConfirmSuccess: "emailConfirmSuccess",
  emailConfirmNewSuccess: "emailConfirmNewSuccess",
  mobotEmailConfirmSuccess: "mobotEmailConfirmSuccess",
  mobotEmailConfirmError: "mobotEmailConfirmError",
  settingsSaveSuccess: "settingsSaveSuccess",
  webPushAlertSuccess: "webPushAlertSuccess",
  jobInvitationSuccess: "jobInvitationSuccess",
  socialDisconnect: "socialDisconnect",
  defaultError: "pictureDefaultError",
  pictureBigError: "pictureBigError",
  pictureSmallError: "pictureSmallError",
  pictureFormatError: "pictureFormatError",
  completeProfileError: "completeProfileError",
  newMessage: "newMessage",
  cvCreateSuccess: "cvCreateSuccess",
  cvReCreationNotification: "cvReCreationNotification",
  cvUploadSuccess: "cvUploadSuccess",
  cvSizeError: "cvSizeError",
  cvFormatError: "cvFormatError",
  cvUploadFrozenError: "cvUploadFrozenError",
  updateNewsSuccess: "updateNewsSuccess",
  redirectError: "redirectError",
  signUpFormNotCompletedError: "signUpFormNotCompletedError",
  formSomethingWrongError: "formSomethingWrongError",
})

const alertsMapping = {
  [alertTypes.markHired]: () => (
    <FormattedMessage id="app.alertbar.match.mark.hired" />
  ),
  [alertTypes.apply]: () => <FormattedMessage id="app.dashboard.alert.apply" />,
  [alertTypes.applyAgain]: () => (
    <FormattedMessage id="app.dashboard.alert.reapply" />
  ),
  [alertTypes.markNotHired]: () => (
    <FormattedMessage id="app.alertbar.match.mark.not.hired" />
  ),
  [alertTypes.socialDisconnect]: values => (
    <FormattedMessage id="app.alertbar.social.disconnect" values={values} />
  ),
  [alertTypes.webPushAlertSuccess]: () => (
    <FormattedMessage id="app.webpush.alert.success" />
  ),
  [alertTypes.resendConfirmationEmailSuccess]: () => (
    <FormattedMessage id="app.alertbar.resend.confirmation.email.success" />
  ),
  [alertTypes.sendResetPasswordEmailSuccess]: () => (
    <FormattedMessage id="app.alertbar.send.reset.password.email.success" />
  ),
  [alertTypes.updateForgottenPasswordSuccess]: () => (
    <FormattedMessage id="app.alertbar.recovery.password.success" />
  ),
  [alertTypes.updatePasswordSuccess]: () => (
    <FormattedMessage id="app.alertbar.update.password.success" />
  ),
  [alertTypes.settingsSaveSuccess]: () => (
    <FormattedMessage id="app.alertbar.settings.save.success" />
  ),
  [alertTypes.accountDeleteSuccess]: () => (
    <FormattedMessage id="app.alertbar.account.delete.success" />
  ),
  [alertTypes.confirmAccountDeleteSuccess]: () => (
    <FormattedMessage id="app.alertbar.confirm.account.delete.success" />
  ),
  [alertTypes.emailConfirmSuccess]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.success" />
  ),
  [alertTypes.emailConfirmNewSuccess]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.new.success" />
  ),
  [alertTypes.mobotEmailConfirmSuccess]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.mobot.success" />
  ),
  [alertTypes.mobotEmailConfirmError]: () => (
    <FormattedMessage id="app.alertbar.email.confirm.mobot.error" />
  ),
  [alertTypes.emailSentSuccess]: () => (
    <FormattedMessage id="app.email.sent.title" />
  ),
  [alertTypes.serverError]: () => (
    <FormattedMessage id="app.alertbar.error.server" />
  ),
  [alertTypes.networkError]: () => (
    <FormattedMessage id="app.alertbar.error.network" />
  ),
  [alertTypes.clientError]: () => (
    <FormattedMessage id="app.alertbar.error.client" />
  ),
  [alertTypes.jobInvitationSuccess]: () => (
    <FormattedMessage id="app.alertbar.jobInvitation.success" />
  ),
  [alertTypes.defaultError]: () => (
    <FormattedMessage id="app.error.code.default_error" />
  ),
  [alertTypes.pictureBigError]: () => (
    <FormattedMessage id="app.alertbar.picture.bad.size" />
  ),
  [alertTypes.pictureSmallError]: () => (
    <FormattedMessage id="app.alertbar.picture.bad.resolution" />
  ),
  [alertTypes.pictureFormatError]: () => (
    <FormattedMessage id="app.alertbar.picture.unknown.format" />
  ),
  [alertTypes.completeProfileError]: () => (
    <FormattedMessage id="app.alertbar.error.completeProfile" />
  ),
  [alertTypes.newMessage]: () => (
    <FormattedMessage id="app.alertbar.messages.new" />
  ),
  [alertTypes.cvCreateSuccess]: () => (
    <FormattedMessage id="app.alertbar.cv.create.success" />
  ),
  [alertTypes.cvReCreationNotification]: () => (
    <FormattedMessage id="app.alertbar.cv.reCreate" />
  ),
  [alertTypes.cvUploadSuccess]: () => (
    <FormattedMessage id="app.alertbar.cv.upload.success" />
  ),
  [alertTypes.cvSizeError]: () => (
    <FormattedMessage id="app.alertbar.cv.bad.size" />
  ),
  [alertTypes.cvFormatError]: () => (
    <FormattedMessage id="app.alertbar.cv.bad.format" />
  ),
  [alertTypes.updateNewsSuccess]: () => (
    <FormattedMessage id="app.alertbar.update.news.success" />
  ),
  [alertTypes.redirectError]: () => (
    <FormattedMessage id="app.error.code.redirectError" />
  ),
  [alertTypes.cvUploadFrozenError]: () => (
    <FormattedMessage id="app.error.client.cvUploadFrozenError" />
  ),
  [alertTypes.signUpFormNotCompletedError]: () => (
    <FormattedMessage id="app.error.code.signUpFormNotCompletedError" />
  ),
  [alertTypes.formSomethingWrongError]: () => (
    <FormattedMessage id="app.error.code.formSomethingWrongError" />
  ),
}

export function showAlert({
  code,
  color = ALERT_COLORS.SUCCESS,
  values = {},
  options = {},
}) {
  const Text = alertsMapping[code]
  toast[color](<Text {...values} />, options)
}

export function showRequestErrorAlert(payload) {
  if (hasProblem(payload)) {
    return handleProblem(payload)
  }

  if (hasCode(payload)) {
    return showServerErrorAlert(payload)
  }
}

function showServerErrorAlert(payload) {
  const message = getErrorMessage(payload)
  toast[ALERT_COLORS.ERROR](message)
}

function handleProblem(payload) {
  switch (path(["err", "problem"], payload)) {
    case SERVER_ERROR:
      return showAlert({
        color: ALERT_COLORS.ERROR,
        code: alertTypes.serverError,
      })

    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case NETWORK_ERROR:
      return showAlert({
        color: ALERT_COLORS.ERROR,
        code: alertTypes.networkError,
      })

    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      return showAlert({
        color: ALERT_COLORS.ERROR,
        code: alertTypes.clientError,
      })

    case CLIENT_ERROR:
      return showServerErrorAlert(payload)

    default:
      return
  }
}
