import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"
import { ConnectedRouter as Router } from "connected-react-router"
import { IntlProvider } from "react-intl-redux"
import { configureStore } from "./redux/store"
import { history } from "./history"
import { App } from "./app"
const { persistor, store, runSaga } = configureStore()

export function Root() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} onBeforeLift={runSaga}>
        <IntlProvider textComponent="span">
          <Router history={history}>
            <App />
          </Router>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}
