import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"

import {
  saveOriginalInterceptor,
  camelizeInterceptor,
  decamelizeInterceptor,
  errorInterceptor,
  pageToOffsetInterceptor,
} from "./axios-interceptors"

export const http = axios.create({
  baseURL: config.moberries.matching.url,
  timeout: 30000,
  headers: {}, // https://github.com/axios/axios/pull/1391
})

axiosRetry(http, { retries: 3 })

http.interceptors.response.use(response => response, errorInterceptor)
http.interceptors.response.use(saveOriginalInterceptor)
http.interceptors.response.use(camelizeInterceptor)

http.interceptors.request.use(pageToOffsetInterceptor)
http.interceptors.request.use(decamelizeInterceptor)

export const api = {
  setDefaultHeader: ({ header, value }) => {
    http.defaults.headers[header] = value
  },

  getPrediction({ candidateId }) {
    return http({
      url: "/get_candidate_prediction",
      params: {
        candidateId,
      },
    })
  },
}
