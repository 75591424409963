import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { FormattedMessage, defineMessages } from "react-intl"
import { usePrevious } from "react-hanger"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

import { useOpenClose } from "../../hooks/use-open-close"
import {
  loadingSelector as userLoadingSelector,
  errSelector,
  connectingFacebookSelector,
  connectingLinkedinSelector,
} from "../../selectors/user-selector"
import { loadingSelector as candidateLoadingSelector } from "../../selectors/candidate-selector"
import { emailPasswordValidator } from "../../validators/email-password-validator"
import {
  loginWithEmailAndPassword,
  loginWithFacebook,
  loginWithLinkedin,
} from "../../actions/user-actions"
import { SocialAuth } from "../../components/social/social-auth-component"
import { Button } from "../../components/common/button-component"
import { FormText } from "../../components/common/form-text"
import { Img } from "../../components/common//img-component"
import { LoginForm } from "./login-form"

import moberriesLogo from "../../assets/img/mb-logo-footer.svg"
import styles from "./login-form-section.module.scss"

const messages = defineMessages({
  loginHeader: { id: "app.login.header" },
  login: { id: "app.login.login" },
  signupLink: { id: "app.login.teaser.link" },
  teaser: { id: "app.login.teaser.body" },
  forgotPassword: { id: "app.common.password.forgot" },
  modalTitle: { id: "app.login.alert.title" },
  wrongAccountTypeText: { id: "app.login.wrongAccountType.text" },
  wrongAccountTypeLink: { id: "app.login.wrongAccountType.link" },
  confirm: { id: "app.common.confirm" },
})

export const LoginFormSectionComponent = ({
  loginWithEmailAndPassword,
  location,
  isLoading,
  authError,
  loginWithFacebook,
  loginWithLinkedin,
}) => {
  const prevIsLoading = usePrevious(isLoading)
  const {
    value: isModalOpen,
    close: closeModal,
    open: openModal,
  } = useOpenClose({ initialValue: false })
  useEffect(() => {
    if (
      prevIsLoading &&
      !isLoading &&
      authError?.code === "wrong_account_type"
    ) {
      openModal()
    }
  }, [isLoading, prevIsLoading, authError, openModal])

  const handleSubmit = ({ email, password }) => {
    loginWithEmailAndPassword({ email, password })
  }

  return (
    <>
      <Modal isOpen={isModalOpen} toggle={closeModal} centered>
        <ModalHeader toggle={closeModal}>
          <FormattedMessage {...messages.modalTitle} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            {...messages.wrongAccountTypeText}
            values={{
              link: (
                <a href="https://my.moberries.com/login">
                  <FormattedMessage {...messages.wrongAccountTypeLink} />
                </a>
              ),
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>
            <FormattedMessage {...messages.confirm} />
          </Button>
        </ModalFooter>
      </Modal>

      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Img width="100" src={moberriesLogo} alt="Moberries Logo" />
        </div>

        <LoginForm
          disabled={isLoading}
          form="LoginForm"
          onSubmit={handleSubmit}
          validate={emailPasswordValidator}
          buttonLabel={<FormattedMessage {...messages.login} />}
          underPassword={
            <FormText className={styles.forgotPassword}>
              <Link to="/password-recovery">
                <FormattedMessage {...messages.forgotPassword} />
              </Link>
            </FormText>
          }
        />

        <div className={styles.orContainer}>
          <hr className={styles.horizontalLine} />
          <div className={styles.boxOR}>
            <FormattedMessage id="app.common.or.caps" />
          </div>
          <hr className={styles.horizontalLine} />
        </div>

        <SocialAuth
          isDisabled={isLoading}
          onFacebookClick={loginWithFacebook}
          onLinkedinClick={loginWithLinkedin}
          withFacebook
        />

        <div className={styles.footer}>
          <FormattedMessage
            {...messages.teaser}
            values={{
              link: (
                <Link
                  to={{
                    pathname: "/signup",
                    search: location.search,
                  }}
                >
                  <FormattedMessage {...messages.signupLink} />
                </Link>
              ),
            }}
          />
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  loginWithFacebook,
  loginWithLinkedin,
  loginWithEmailAndPassword,
}

const mapStateToProps = function (state) {
  const isConnectingLinkedin = connectingLinkedinSelector(state)
  const isConnectingFacebook = connectingFacebookSelector(state)

  return {
    isLoading:
      candidateLoadingSelector(state) ||
      userLoadingSelector(state) ||
      isConnectingFacebook ||
      isConnectingLinkedin,
    authError: errSelector(state),
    isConnectingFacebook,
    isConnectingLinkedin,
  }
}

LoginFormSectionComponent.propTypes = {
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  authError: PropTypes.object,
  loginWithLinkedin: PropTypes.func.isRequired,
  loginWithFacebook: PropTypes.func.isRequired,
}

export const LoginFormSection = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginFormSectionComponent)
