import { getActionType } from "../lib/helpers"
import { always, evolve, F, lensProp, mergeLeft, over, T } from "ramda"
import { GET, SUCCESS, FAIL, START, COMPANY, SLUG, BY } from "../constants"

export const defaultState = {
  count: null,
  companies: [],
  entities: {},
  loading: false,
  loaded: false,
  err: null,
}

export function reducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, COMPANY, BY, SLUG, START):
    case getActionType(GET, COMPANY, START):
      return evolve({ loading: T }, state)

    case getActionType(GET, COMPANY, BY, SLUG, SUCCESS):
    case getActionType(GET, COMPANY, SUCCESS):
      return evolve(
        {
          loading: F,
          err: always(null),
          entities: over(
            lensProp(payload.company.id),
            mergeLeft(payload.company),
          ),
        },
        state,
      )

    case getActionType(GET, COMPANY, BY, SLUG, FAIL):
    case getActionType(GET, COMPANY, FAIL):
      return evolve({ loading: F, err: always(payload.err) }, state)

    default:
      return state
  }
}
