import { compareDesc, parseISO } from "date-fns"
import { defineMessages } from "react-intl"
import isURL from "validator/lib/isURL"

import { noneOrEmpty, xorObjectValues } from "../lib/helpers"

const messages = defineMessages({
  valueRequired: { id: "app.validators.value.required" },
  noNativeLanguage: {
    id: "app.validators.registration.professional.noNativeLanguage",
  },
  noLanguage: {
    id: "app.validators.registration.professional.noLanguage",
  },
  noLanguageLevel: {
    id: "app.validators.registration.professional.noLanguageLevel",
  },
  tooMuchSkills: {
    id: "app.validators.registration.professional.tooMuchSkills",
  },
  noEducationDetails: {
    id: "app.validators.registration.professional.noEducationDetails",
  },
  noWorkExperienceDetails: {
    id: "app.validators.registration.professional.noWorkExperienceDetails",
  },
  startDaterLaterThanEndDate: {
    id: "app.validators.registration.professional.startDaterLaterThanEndDate",
  },
  startDateInFuture: {
    id: "app.validators.registration.professional.startDateInFuture",
  },
  startDateEqualEndDate: {
    id: "app.validators.registration.professional.startDateEqualEndDate",
  },
  urlRequired: { id: "app.validators.url.required" },
})

const validateLanguages = values => {
  if (noneOrEmpty(values)) {
    return messages.valueRequired
  }

  const noLanguageItem = values.find(({ name }) => !name)
  if (noLanguageItem) {
    return messages.noLanguage
  }

  const noLevelItem = values.find(
    ({ name, level }) => name && typeof level !== "number",
  )
  if (noLevelItem) {
    return messages.noLanguageLevel
  }

  const nativeItem = values.find(({ level }) => level === 6)
  if (!nativeItem) {
    return messages.noNativeLanguage
  }
}

const validateSkills = values => {
  if (noneOrEmpty(values)) {
    return messages.valueRequired
  } else if (values.length > 20) {
    return messages.tooMuchSkills
  }
}

const validateStartEndDates = values => {
  const startDateInFutureItem = values.find(
    ({ startDate }) =>
      startDate && compareDesc(parseISO(startDate), new Date()) < 0,
  )
  if (startDateInFutureItem) {
    return messages.startDateInFuture
  }

  const lateStartDateItem = values.find(
    ({ startDate, endDate }) =>
      startDate &&
      endDate &&
      compareDesc(parseISO(startDate), parseISO(endDate)) < 0,
  )
  if (lateStartDateItem) {
    return messages.startDaterLaterThanEndDate
  }

  const equalStartAndEndDateItem = values.find(
    ({ startDate, endDate }) =>
      startDate &&
      endDate &&
      compareDesc(parseISO(startDate), parseISO(endDate)) < 1,
  )
  if (equalStartAndEndDateItem) {
    return messages.startDateEqualEndDate
  }
}

const validateEducation = (values = []) => {
  const notCompletedItem = values.find(
    ({ institution, degree, fieldOfStudy, startDate }) =>
      xorObjectValues({
        institution,
        degree,
        fieldOfStudy,
        startDate,
      }),
  )

  if (notCompletedItem) {
    return messages.noEducationDetails
  }

  return validateStartEndDates(values)
}

const validateCertificates = (values = []) => {
  const notCompletedItem = values.find(
    ({ roleName, certificateName, issuingOrganization, issueDate }) =>
      xorObjectValues({
        roleName,
        certificateName,
        issuingOrganization,
        issueDate,
      }),
  )

  if (notCompletedItem) {
    return messages.noEducationDetails
  }
}

const validateExperience = (values = []) => {
  if (noneOrEmpty(values)) {
    return messages.valueRequired
  }

  const notCompletedItem = values.find(({ companyName, name, startDate }) =>
    xorObjectValues({
      companyName,
      name,
      startDate,
    }),
  )

  if (notCompletedItem) {
    return messages.noWorkExperienceDetails
  }

  return validateStartEndDates(values)
}

const validateReferences = references => {
  const hasInvalidUrl = (references || []).find(
    r => !isURL(r, { allow_underscores: true }),
  )
  if (hasInvalidUrl) {
    return messages.urlRequired
  }
}

export const ProfileProfessionalDetailsValidator = values => {
  const {
    languages,
    skills,
    education,
    workExperience,
    references,
    certificates,
  } = values
  const errors = {}

  errors.languages = validateLanguages(languages) || null
  errors.skills = validateSkills(skills) || null
  errors.education = validateEducation(education) || null
  errors.workExperience = validateExperience(workExperience) || null
  errors.references = validateReferences(references) || null
  errors.certificates = validateCertificates(certificates) || null

  return errors
}
