import firebase from "firebase/app"
import "firebase/messaging"
import { config } from "../config"

let app
let messaging

if (!firebase.apps.length) {
  app = firebase.initializeApp(config.firebase)
  try {
    messaging = app.messaging()
    messaging.usePublicVapidKey(config.firebase.publicVapidKey)
  } catch (err) {
    console.error(err)
  }
} else {
  try {
    messaging = app.messaging()
  } catch (err) {
    console.error(err)
  }
  app = firebase.app()
}

export { app, firebase, messaging }
export const { name } = app
