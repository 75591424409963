import React from "react"
import PropTypes from "prop-types"
import { mergeLeft } from "ramda"
import { FormattedMessage } from "react-intl"
import { FormGroup, PopoverBody } from "reactstrap"

import { LANG_LEVELS } from "../../../../default-options"
import { useLanguagesLoader } from "../../../../hooks/use-languages-loader"
import { ButtonWithIcon } from "../../../common/button-with-icon/button-with-icon"
import { LabelWithText } from "../../../common/label-with-text-component/label-with-text-component"
import { Select } from "../../../common/select-component"
import { InfoHint } from "../../../common/info-hint-component"

import styles from "./languages-section.module.scss"

export const LanguagesSection = ({ input, meta }) => {
  let { value: values, onChange } = input
  const { error, dirty, touched } = meta

  const { loadLanguages } = useLanguagesLoader({ selected: values })

  const addHandler = () => {
    if (values[0].id === -1 || values.length >= 5) return

    const newValue = { id: -1 }
    onChange([newValue, ...values])
  }

  const removeHandler = id => {
    const updatedItems = values.filter(v => v.id !== id)
    onChange(updatedItems)
  }

  const languageSelectorHandler = (newLanguageValue, oldLanguageValue) => {
    const updatedItems = values.map(v =>
      v.id === oldLanguageValue.id ? mergeLeft(newLanguageValue, v) : v,
    )
    onChange(updatedItems)
  }

  const levelSelectorHandler = (newLevelValue, oldLevelValue) => {
    const updatedItems = values.map(v =>
      v.id === oldLevelValue.id ? mergeLeft({ level: newLevelValue.id }, v) : v,
    )
    onChange(updatedItems)
  }

  // For the first render when "languages" is null
  if (!values.length) values = [{ id: -1 }]

  return (
    <FormGroup>
      <LabelWithText
        label={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.languages.label" />
          </span>
        }
        text={
          <span>
            <FormattedMessage id="app.registration.tabs.professional.input.languages.text" />
          </span>
        }
        isRequired
        hint={
          <InfoHint
            id="languagesHint"
            popover={
              <PopoverBody>
                <FormattedMessage
                  id="app.registration.tabs.professional.input.languages.hint"
                  values={{
                    link: (
                      <a
                        href="https://moberries.typeform.com/to/hXBGOH"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="app.registration.tabs.professional.input.languages.hint.link" />
                      </a>
                    ),
                  }}
                />
              </PopoverBody>
            }
          />
        }
      />

      {error && (dirty || touched) && (
        <p className={styles.errorMessage}>
          <FormattedMessage {...error} />
        </p>
      )}

      {values.map((item, i) => (
        <div key={item.id} className={styles.formItem}>
          <div className={styles.formItem__inputs}>
            <div className={styles.formItem__left}>
              <Select
                id="languages-language"
                async
                input={{
                  onChange: newLanguageValue => {
                    languageSelectorHandler(newLanguageValue, item)
                  },
                  value: item.id === -1 ? null : item,
                }}
                wrapper="div"
                meta={{}}
                loadOptions={loadLanguages}
                defaultOptions
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                placeholder={
                  <FormattedMessage id="app.registration.tabs.professional.input.languages.placeholder.language" />
                }
                noOptionsMessage={() => (
                  <FormattedMessage id="app.registration.tabs.professional.input.languages.placeholder.language" />
                )}
                backspaceRemovesValue={false}
                withCaret
              />
            </div>

            <div className={styles.formItem__right}>
              <Select
                id="languages-level"
                placeholder={
                  <FormattedMessage id="app.registration.tabs.professional.input.languages.placeholder.level" />
                }
                options={LANG_LEVELS}
                getOptionValue={option => option.id}
                getOptionLabel={option => <FormattedMessage {...option.name} />}
                backspaceRemovesValue={false}
                input={{
                  onChange: newLevelValue => {
                    levelSelectorHandler(newLevelValue, item)
                  },
                  value: LANG_LEVELS.find(l => l.id === item.level) || "",
                }}
                wrapper="div"
                isSearchable={false}
                withCaret
                meta={{}}
              />
            </div>
          </div>
          <div className={styles.formItem__action}>
            {i === 0 &&
            item.id !== -1 &&
            item.level > -1 &&
            values.length < 5 ? (
              <ButtonWithIcon iconName="plus" onClick={addHandler} />
            ) : (
              <ButtonWithIcon
                iconName="cross"
                onClick={() => removeHandler(item.id)}
              />
            )}
          </div>
        </div>
      ))}
    </FormGroup>
  )
}

LanguagesSection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}
