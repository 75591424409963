import React, { useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { defineMessages, FormattedMessage } from "react-intl"
import { path } from "ramda"

import { CLIENT_ERROR, CV_TYPES, FA_ICONS } from "../../../../constants"
import { connect } from "react-redux"
import { createCandidateCv } from "../../../../actions/candidate-actions"
import { LANG_LEVELS } from "../../../../pages/profile-page/profile-professional-details-panel"
import {
  candidateSelector,
  cvCreatingErrSelector,
  cvCreatingSelector,
  cvTypeSelector,
  cvUpdatingSelector,
  cvUrlsSelector,
} from "../../../../selectors/candidate-selector"
import { useOpenClose } from "../../../../hooks/use-open-close"
import { LoaderLogo } from "../../../common/loader-logo"
import { ResumePreviewModal } from "../resume-preview-modal/resume-preview-modal"
import { links } from "../../../../lib/external-links"

import styles from "./resume-creator.module.scss"
import { localeSelector } from "../../../../selectors/intl-selector"

const mapCandidateDetailsToCv = c => ({
  photoUrl: c.picture || null,
  firstname: c.firstName,
  lastname: c.lastName,
  email: c.email,
  phoneNumber: c.phoneNumber,
  languages: c.languages.map(l => ({
    language: l.name,
    proficiency: LANG_LEVELS.find(ll => ll.id === l.level).name,
  })),
  education: c.education.map(e => ({
    institution: e.institution.name,
    field: e.fieldOfStudy.name,
    degree: e.degree.name,
    startDate: e.startDate,
    endDate: e.endDate,
  })),
  skills: c.skills.map(s => ({ name: s.name })),
  experience: c.workExperience.map(j => ({
    role: j.name,
    company: j.companyName ?? "Unknown",
    startDate: j.startDate ?? "2001-01-01",
    endDate: j.endDate,
  })),
  preferences: {
    city: { name: c.city?.name },
    country: { name: c.city?.country?.name },
  },
  references: c.references,
  certificates: c.certificates.map(cert => ({
    name: cert.certificateName,
    organisation: cert.issuingOrganization,
    date: cert.issueDate,
  })),
})

const messages = defineMessages({
  defaultCreationError: { id: "app.cvCreator.error.default" },
  notCompletedProfileError: { id: "app.cvCreator.error.notCompleted" },
  successMessage: { id: "app.cvCreator.success" },
})

const getCVgeneratorErrorType = err => {
  const problem = path(["problem"], err)

  if (problem === CLIENT_ERROR) {
    const status = path(["response", "status"], err)

    if (status === 400) {
      return "notCompletedProfileError"
    }
  }

  return "defaultCreationError"
}

export const ResumeCreatorComponent = ({
  candidate,
  createCandidateCv,
  isCreating,
  isUploading,
  urls,
  cvType,
  cvCreatingError,
  locale,
}) => {
  const [isInitCreating, setIsInitCreating] = useState(false)

  const {
    value: previewIsOpen,
    open: openPreview,
    close: closePreview,
  } = useOpenClose({ initialValue: false })

  const protectedCvType = cvType || candidate.candidateCv?.cvType
  const isCorrectCVType = protectedCvType === CV_TYPES.CREATE

  const isLoading = isUploading || isCreating

  const shouldDisplayLoader = isInitCreating && isCorrectCVType && isLoading
  const shouldDisplayMessage = isInitCreating && isCorrectCVType && !isLoading
  const shouldDisplayPreview =
    !!urls.length && isCorrectCVType && !isLoading && !cvCreatingError

  return (
    <>
      <button
        className={styles.controlBtn}
        onClick={() => {
          setIsInitCreating(true)
          createCandidateCv({
            data: mapCandidateDetailsToCv(candidate),
            cvType: CV_TYPES.CREATE,
          })
        }}
      >
        <i className={cn(styles.controlBtn__icon, FA_ICONS.FILE_PLUS)} />
        <span className={styles.controlBtn__text}>
          <FormattedMessage id="app.registration.tabs.resume.create.process.init" />
        </span>
      </button>

      <div className={styles.results}>
        {shouldDisplayLoader && <LoaderLogo />}
        {shouldDisplayMessage && (
          <p
            className={cn(styles.results__message, {
              [styles.results__message_success]: !cvCreatingError,
              [styles.results__message_error]: cvCreatingError,
            })}
          >
            {!cvCreatingError ? (
              <FormattedMessage {...messages.successMessage} />
            ) : (
              <FormattedMessage
                {...messages[getCVgeneratorErrorType(cvCreatingError)]}
                values={{
                  a: (...children) => (
                    <a href={links[locale].support}>{children}</a>
                  ),
                  u: (...children) => <u>{children}</u>,
                }}
              />
            )}
          </p>
        )}
        {shouldDisplayPreview && (
          <button className={styles.previewBtn} onClick={openPreview}>
            <i className={cn(styles.results__icon, FA_ICONS.FILE_PDF)} />
            <p className={styles.results__label}>
              <FormattedMessage id="app.registration.tabs.resume.file.preview" />
            </p>
          </button>
        )}
      </div>

      <ResumePreviewModal
        isOpen={previewIsOpen}
        close={closePreview}
        urls={urls}
      />
    </>
  )
}

ResumeCreatorComponent.propTypes = {
  candidate: PropTypes.object.isRequired,
  createCandidateCv: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  urls: PropTypes.array.isRequired,
  cvType: PropTypes.string,
  cvCreatingError: PropTypes.object,
  locale: PropTypes.string,
}

const mapStateToProps = state => ({
  candidate: candidateSelector(state),
  isCreating: cvCreatingSelector(state),
  isUploading: cvUpdatingSelector(state),
  cvCreatingError: cvCreatingErrSelector(state),
  urls: cvUrlsSelector(state).map(u => ({ src: u, caption: "" })),
  cvType: cvTypeSelector(state),
  locale: localeSelector(state),
})

const mapDispatchToProps = {
  createCandidateCv,
}

export const ResumeCreator = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResumeCreatorComponent)
