import { useState } from "react"
import { useOpenClose } from "./use-open-close"

export const useCountdown = ({ seconds, onFinish }) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds)
  const { value: isRunning, open: run } = useOpenClose(false)

  const updateCountdown = () => {
    setSecondsLeft(current => {
      if (current > 0) {
        setTimeout(updateCountdown, 1000)
        return current - 1
      } else {
        if (onFinish) {
          onFinish()
        }
        return 0
      }
    })
  }

  return {
    secondsLeft,
    start: () => {
      if (!isRunning) {
        run()
        updateCountdown()
      }
    },
    isRunning,
  }
}
