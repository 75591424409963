import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { defineMessages, FormattedMessage } from "react-intl"
import footerLogo from "../../assets/img/mb-logo-footer.svg"
import { Container, Row, Col } from "reactstrap"
import { localeSelector } from "../../selectors/intl-selector"
import { links } from "../../lib/external-links"
import { Img } from "./img-component"
import { config } from "../../config"

const messages = defineMessages({
  footerHomepage: { id: "app.common.homepage" },
  footerHowto: { id: "app.footer.guide" },
  footerSupport: { id: "app.common.support" },
  footerJobBot: { id: "app.footer.jobBot" },
  footerAbout: { id: "app.footer.about" },
  footerFaq: { id: "app.footer.faq" },
  footerTerms: { id: "app.footer.terms" },
  footerPrivacy: { id: "app.common.privacy" },
})

export function FooterComponent({ locale }) {
  const { pathname } = useLocation()
  const isHidden = pathname === "/my-profile"

  if (isHidden) {
    return null
  }

  return (
    <footer className="main-footer bg-white text-center text-md-left">
      <Container>
        <Row className="py-3 py-md-5">
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].homePage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerHomepage} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].guide}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerHowto} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].support}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerSupport} />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].jobBot}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerJobBot} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].about}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerAbout} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].FAQ}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerFaq} />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3">
            <ul className="list-unstyled m-0">
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].terms}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerTerms} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].privacy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.footerPrivacy} />
                </a>
              </li>
              <li className="py-1">
                <a
                  className="text-muted"
                  href={links[locale].blog}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="12" md="3" className="text-md-right">
            <div className="py-1">
              <Img width="100" src={footerLogo} alt="Moberries Logo" />
            </div>
          </Col>
        </Row>
        <hr className="m-0 border-top" />
        <Row className="py-3 py-md-4">
          <Col xs="12" md="4">
            <span className="font-weight-light">
              &copy; Moberries {config.buildYear}
            </span>
          </Col>
          <Col xs="12" md="8" className="text-md-right">
            <ul className="list-inline m-0">
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.facebook.com/moberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://twitter.com/MoBerries"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.linkedin.com/company/10009040"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li className="list-inline-item mr-3">
                <a
                  className="text-muted"
                  href="https://www.youtube.com/channel/UC22gC5eRgLqqnVg9zjSI53w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  className="text-muted"
                  href="https://www.instagram.com/teammoberries/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

FooterComponent.propTypes = {
  locale: PropTypes.string.isRequired,
}

FooterComponent.defaultProps = {}

const mapDispatchToProps = {}

const mapStateToProps = function (state) {
  return {
    locale: localeSelector(state),
  }
}

export const Footer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FooterComponent)
